<template>
  <div class="app-header">
    <div class="app-header__content">
      <div class="app-header__top-bar">
        <img :src="LogoIcon" alt="" />
        <div class="app-header__top-bar-right-content">
          <notifications-button
            v-if="supplier"
            :audience="Audience.SUPPLIER"
            :audience-id="supplier.id"
            @clicked="togglePopupComponent(PopupComponent.NOTIFICATIONS)"
          />
          <user-profile-button
            :user="user"
            :organization="supplierOrganization"
            @clicked="togglePopupComponent(PopupComponent.USER_PROFILE)"
          />
        </div>
      </div>
    </div>
    <div
      v-show="state.popupComponent"
      ref="popupElement"
      class="app-header__popup"
    >
      <user-profile
        v-if="state.popupComponent === PopupComponent.USER_PROFILE"
        :user="user"
        :organization="supplierOrganization"
        @sign-out="signOut"
        @updated-user="updateUser"
      />
      <notifications
        v-if="supplier && state.popupComponent === PopupComponent.NOTIFICATIONS"
        :audience="Audience.SUPPLIER"
        :audience-id="supplier.id"
        :service-types-cms="serviceTypesCms"
        :org-number="supplier.orgNumber"
        @notification-clicked="handleNotificationClick"
        @close="togglePopupComponent(null)"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import LogoIcon from "@/assets/img/logo.svg";
import {
  computed,
  onBeforeUnmount,
  reactive,
  ref,
  watch,
  onMounted,
} from "vue";
import { useUserStore } from "@/stores/user";
import { useSupplierStore } from "@/stores/supplier";
import { Organization } from "@/stores/tender/types";
import { useMsalAuthentication } from "@/composables/useMsalAuthentication";
import { Audience, User } from "@/custom-types/GeneralTypes";
import {
  EventNotification,
  Notifications,
  NotificationsButton,
  NotificationType,
} from "@really/really-events-recorder-components";
import { RequestStateType } from "@/custom-types/GeneralTypes";
import UserProfile from "@/components/UserProfile/UserProfile.vue";
import UserProfileButton from "@/components/UserProfile/UserProfileButton.vue";
import vClickOutside from "v-click-outside";
import { useRouter } from "vue-router";
import { TenderState, TenderViewTabLabels } from "@/custom-types/GeneralTypes";
import { RouteNames } from "@/router/types";
import { useCmsStore } from "@/stores/cms";

enum PopupComponent {
  USER_PROFILE = "USER_PROFILE",
  NOTIFICATIONS = "NOTIFICATIONS",
}

interface PriceRequestSummary {
  tenderId: string;
  priceRequestId: string;
  tenderState: TenderState;
  requestState: RequestStateType;
  isServiceAgreement: boolean;
  isProject: boolean;
  // ... other fields
}

const { signOut } = useMsalAuthentication();
const cmsStore = useCmsStore();
const userStore = useUserStore();
const supplierStore = useSupplierStore();
const { bind, unbind } = vClickOutside.directive;
const router = useRouter();

const popupElement = ref<HTMLDivElement | null>(null);
const state = reactive({
  popupComponent: null as PopupComponent | null,
  clicksOutside: 0,
});

const serviceTypesCms = computed(() => cmsStore.serviceTypesCms);
const user = computed(() => userStore?.user);
const supplier = computed(() => supplierStore?.supplier);
const supplierOrganization = computed(() => {
  if (!supplier.value) {
    return;
  }
  const organization: Organization = {
    name: supplier.value?.sysName,
    orgNumber: supplier.value.orgNumber,
    locked: false,
  };
  return organization;
});

watch(
  () => state.popupComponent,
  (newVal, oldVal) => {
    if (!popupElement.value) {
      return;
    }
    if (oldVal && newVal) {
      state.clicksOutside -= 1;
    }
    if (!oldVal && newVal) {
      bind(popupElement.value, {
        value: () => {
          state.clicksOutside += 1;
          if (state.clicksOutside) {
            state.popupComponent = null;
          }
        },
      });
    } else if (!newVal) {
      unbind(popupElement.value);
      state.clicksOutside = 0;
    }
  },
);

onBeforeUnmount(() => {
  if (popupElement.value) {
    unbind(popupElement.value);
  }
});

const togglePopupComponent = (popupComponent: PopupComponent | null) => {
  state.popupComponent =
    state.popupComponent === popupComponent ? null : popupComponent;
};

const updateUser = (updatedUser: User) => {
  userStore.updateUser(updatedUser);
};

// Constants for better maintainability
const PENDING_STATES = [
  RequestStateType.PRICE_REQUESTED,
  RequestStateType.INFORMATION_REQUESTED,
  RequestStateType.OFFERED,
  RequestStateType.REJECTED,
  RequestStateType.UNANSWERED,
  RequestStateType.LOST,
] as RequestStateType[];

// Define view-specific tab mappings
const REQUEST_VIEW_TAB_MAP: Record<RequestStateType, string> = {
  [RequestStateType.PRICE_REQUESTED]: "new",
  [RequestStateType.INFORMATION_REQUESTED]: "active",
  [RequestStateType.OFFERED]: "active",
  [RequestStateType.LOST]: "lost",
  [RequestStateType.REJECTED]: "archived",
  [RequestStateType.UNANSWERED]: "archived",
  [RequestStateType.ACCEPTED]: "active",
  [RequestStateType.COMPLETED]: "completed",
  [RequestStateType.ARCHIVED]: "archived",
  [RequestStateType.WITHDRAWN]: "archived",
  [RequestStateType.DRAFT]: "archived",
} as const;

const AGREEMENT_VIEW_TAB_MAP = {
  SERVICE: {
    [RequestStateType.ACCEPTED]: "active-service",
    [RequestStateType.COMPLETED]: "completed-service",
  },
  PROJECT: {
    [RequestStateType.ACCEPTED]: "active-projects",
    [RequestStateType.COMPLETED]: "completed-projects",
  },
} as const;

const handleNotificationClick = (
  notification: EventNotification,
  priceRequestSummary: PriceRequestSummary | undefined,
  callbackFn: (navigationSuccessful: boolean) => void,
) => {
  if (!priceRequestSummary) {
    return;
  }

  const requestState = priceRequestSummary.requestState as RequestStateType;

  const isPendingState = PENDING_STATES.includes(requestState);
  // Determine route based on state
  const routeName = isPendingState
    ? RouteNames.MY_REQUESTS
    : RouteNames.MY_AGREEMENTS;

  let tabList: string;

  if (isPendingState) {
    // Handle pending requests
    tabList = REQUEST_VIEW_TAB_MAP[requestState];
  } else if (routeName === RouteNames.MY_AGREEMENTS) {
    // Handle agreements based on type and state
    const agreementType = priceRequestSummary.isProject ? "PROJECT" : "SERVICE";

    // Only access the map with known valid keys (ACCEPTED or COMPLETED)
    if (requestState === RequestStateType.ACCEPTED) {
      tabList = AGREEMENT_VIEW_TAB_MAP[agreementType]["accepted"];
    } else if (requestState === RequestStateType.COMPLETED) {
      tabList = AGREEMENT_VIEW_TAB_MAP[agreementType]["completed"];
    } else {
      // Default to active-service for any other state
      tabList = "active-service";
    }
  } else {
    // Handle other request states
    tabList = REQUEST_VIEW_TAB_MAP[requestState];
  }

  const baseQuery = {
    tabList,
    priceRequestId: priceRequestSummary.priceRequestId.toString(),
  };

  const notificationType = notification.category;
  const shouldShowMessages = [
    NotificationType.MessageSentEvent,
    NotificationType.InformationRequested,
  ].includes(notificationType);

  const query = shouldShowMessages
    ? { ...baseQuery, tab: TenderViewTabLabels.messages }
    : baseQuery;

  router.push({
    name: routeName,
    query,
  });

  callbackFn(true);
};

const initialize = () => {
  userStore.fetchLoggedInUser();
};

onMounted(() => {
  initialize();
});
</script>

<style lang="scss" scoped>
$header-z-index: 10000;

.app-header {
  height: calc(#{$headerTopBarHeight});

  &__content {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: $headerTopBarHeight;
    z-index: $header-z-index;
    background: #f7f0fa;
  }

  &__top-bar {
    height: $headerTopBarHeight;
    padding: 0 3rem;
    border-bottom: 1px solid #f1e6f5;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__top-bar-right-content {
    height: 100%;
    display: flex;
    align-items: center;
    gap: 1.5rem;
  }

  &__popup {
    visibility: hidden;
    z-index: calc(#{$header-z-index} + 1);
    background: red;
    position: fixed;
    top: $headerTopBarHeight;
    right: 0;
    width: 25rem;
    bottom: 0;

    > * {
      visibility: visible;
      background: white;
      box-shadow: 0 4px 15px 0 #00000026;
    }
  }
}
</style>
