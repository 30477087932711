import { FeedbackType, OfferStateType } from "@/custom-types/GeneralTypes";

export enum BillingCycle {
  Fixed = "fixed",
  Monthly = "monthly",
  Yearly = "yearly",
}

export enum RequestType {
  ROBOT = "Robot",
  SERVICE_WITHOUT_ROBOT = "ServiceWithoutRobot",
}

export type CustomerOrganization = {
  orgNumber: string;
  name: string;
  locked: boolean;
};

export type Address = {
  id: string;
  address: string;
  city: string;
  postalCode: string;
  county: string;
  fullAddress: string;
  cityAddress: string;
};

export type PropertyType = {
  id: number;
  sysLabel: string;
};

export type CustomerPropertyDto = {
  id: number;
  propertyTypeId: number;
  nickname: string;
  address: Address;
  propertyType: PropertyType;
};

export type SegmentValue = {
  formLabel: string;
  segmentLabel: string;
  unit: string | null;
  billingType: string;
  id: number;
  value: number;
  externalGrouping: string;
  formId: number;
  priceSegmentId: number;
  priceSegmentGroupId: number;
  serviceOfferId: number;
  inputLabel: string;
  frequencyValue: boolean;
};

export type AttachmentDto = {
  attachmentId: string;
  fileName: string;
  mimeType: string;
  attachmentUri: string | null;
};

export type PublicNote = {
  id: number;
  title: string;
  message: string;
  priceRequestId: number;
  createdOn: string;
  attachments: AttachmentDto[];
};

export type PriceRequest = {
  id: number;
  acceptedOfferDetails?: AcceptedOfferDetails;
  message: string;
  offerResponsePeriodInDays: number;
  messageAttachmentDtos: MessageAttachmentDto[];
  customerPropertyDto?: CustomerPropertyDto;
  tenderId: number;
  serviceTypeId: number;
  serviceCategoryId: number;
  customerPropertyId: number;
  customerPropertyTypeId: number;
  serviceTypeLabel: string;
  serviceCategoryLabel: string;
  requestType?: RequestType;
  wantedStartDate: string;
  surveyDateTime?: string | null;
  firstOfferDateTime: string;
  supplierRequests: SupplierRequest[];
  segmentValues?: SegmentValue[];
  priceRequestRulesId: number | null;
  customerPropertyAddress: string;
  customerPropertyTypeLabel: string;
  hasUnreadMessages?: boolean;
  createdOn: string;
  deadlineDate: string;
  unreadMessagesCount?: number;
};

export type AcceptedOfferDetails = {
  price: number;
  billingCycle: BillingCycle;
  acceptanceDate: string | null;
  completionDate: string | null;
};

export type MessageAttachmentDto = {
  attachmentId: string;
  fileName: string;
  mimeType: string;
  attachmentUri: string | null;
};

export type SupplierRequest = {
  id: number;
  supplierId: number;
  terminationPeriodInMonths?: number;
  supplierName: string;
  firstOfferDateTime: string;
  requestState: string;
  requestStateAudience: string;
  agreementOwnerUserId: string | null;
  hasFrameAgreement: boolean;
  frameAgreementId: number;
  serviceOffers: number[];
  archived: boolean;
  requestStateChanges: RequestStateChange[];
  supplierRequestAcceptances: SupplierRequestAcceptance[];
  offerCount: number;
};

export type FrameAgreement = {
  id: number;
  currentRevenue: number;
  revenueTarget: number | null;
  activationDate: string;
  expirationDate: string;
  serviceCategoryIds: number[];
  districtIds: number[];
  supplierId: number;
  organizationId: number;
  active: boolean;
  attachments: MessageAttachmentDto[];
};

export type RequestStateChange = {
  stateTo: string;
  changedBy: string;
  createdOn: string;
};

export type Tender = {
  customerOrganization: CustomerOrganization;
  tenderId: number;
  createdOn: string;
  tenderState: string;
  customerOrganizationId: number;
  customerOrganizationName: string | null;
  externalTenderId: string;
  integrationSource: string;
  integrationExternalId: string;
  validThrough: string;
  nickname: string | null;
  deleted: boolean;
  publicActor: boolean;
  priceRequests: PriceRequest[];
  supplierPortfolios: SupplierPortfolio[];
  tenderComments: TenderComment[];
  tenderVersions: TenderVersion[];
  tenderValue: number;
  acceptedOfferReferences: string[];
  billingCycles: BillingCycle[];
};

export interface SupplierRequestAcceptance {
  offerAcceptanceReasons: OfferAcceptanceReason[];
  comment?: string;
}

export interface OfferAcceptanceReason {
  id: number;
  reason: string;
}

export type SupplierPortfolio = {
  supplierId: number;
  externalSupplierPortfolioId: string;
  tenderId: number;
  serviceTypePackagePortfolio: ServiceTypePackagePortfolio[];
  isAccepted: boolean;
};

export type ServiceTypePackagePortfolio = {
  id: number;
  serviceTypeId: number;
  serviceTypeLabel: string;
  supplierPortfolioId: number;
  offerStatus: string;
  package: Package;
  serviceType: ServiceType;
  serviceTypePortfolioAcceptance: ServiceTypePortfolioAcceptance | null;
  serviceOffers: ServiceOffer[];
};

export type Package = {
  id: number;
  sysLabel: string;
};

export type ServiceTypePortfolioAcceptance = {
  acceptedOn: string;
  serviceTypePackagePortfolioId: number;
  startDate: string;
};

export type MandatorySupplierCost = {
  valueSegmentId: number;
  frequencySegmentId: number | null;
  itemTotalCost: number;
  itemCost: number;
  inputPrice: number;
  serviceOfferSegmentFrequencyValueId: number;
  externalGrouping: string;
  frequencyLabel: string | null;
  frequencyInputValue: number | null;
  itemTotal: number | null;
  itemUnit: string | null;
  limitedPeriodStart: string | null;
  limitedPeriodEnd: string | null;
  segmentId: number;
  segmentGroupId: number;
  serviceOfferPriceId: number;
  serviceOfferSegmentGroupPriceId: number;
  serviceOfferSegmentValueId: number;
  priceType: string;
  billingCycle: string;
  label: string;
  itemPriceUnit: string;
};

export type IndependentSupplierCost = {
  itemPrice: number;
  segmentId: number;
  segmentGroupId: number;
  serviceOfferPriceId: number;
  serviceOfferSegmentGroupPriceId: number;
  serviceOfferSegmentValueId: number;
  priceType: string;
  billingCycle: string;
  label: string;
  itemPriceUnit: string;
};

export type ServiceOffer = {
  mandatorySupplierCosts: MandatorySupplierCost[];
  independentSupplierCosts: IndependentSupplierCost[];
  yearOneAgreementValue: number;
  integrationSource: string;
  integrationExternalId: string;
  supplierOfficeId: number;
  suggestedStartDate: string | null;
  serviceTypePackagePortfolioId: number;
  frameAgreementId: number | null;
  offeredByUserId: string | null;
  acceptedByUserId: string | null;
  acceptanceDate: string | null;
  descriptions: Description[];
  serviceOfferAttachments: Attachment[];
  terminationPeriodInMonths: number | null;
  serviceOfferId: number;
  supplierRequestId: number;
  tenderId: number;
  externalTenderId: string;
  nickname: string;
  currentState: string;
  lastStateChange: string;
  dateOffered: string | null;
  bookingNumber: string;
  serviceCategoryLabel: string;
  serviceTypeLabel: string;
  customerOrgName: string;
  customerPropertyAddress: string;
  readyForCustomer?: boolean;
  isServiceAgreement?: boolean;
};

export type CustomerProperty = {
  id: number;
  propertyTypeId: number;
  nickname: string;
  address: Address;
  propertyType: PropertyType;
};

export type ServiceCategory = {
  id: number;
  categoryLabel: string;
  jobType: null;
};

export type ServiceOfferExtra = {
  id: number;
  priceRequestId: number;
  serviceOfferId: number;
  supplierId: number;
  descriptions: Description[];
  attachments: Attachment[];
  wantedStartDate: null;
  nickname: string;
};

export type ServiceOfferPrice = {
  priceGroupLabel: string;
  segmentGroupPrices: SegmentGroupPrice[];
  priceSummary: PriceSummary[];
  activePeriodStart: string | null;
  activePeriodEnd: string | null;
  numPeriod: number;
  priceGroupId: number;
  estimate: boolean;
  serviceOfferId: number;
  supplierCost: number;
  priceType: string;
  billingCycle: string;
};

export type SegmentGroupPrice = {
  segmentGroupName: string;
  supplierUnitCost: number;
  id: number;
  externalGrouping: string;
  serviceOfferPriceId: number;
  segmentGroupId: number;
  baseAmount: number;
  supplierCommission: number;
  supplierCost: number;
  reallyCut: number;
  unitCost: number;
  customerUnitCost: number;
  normalUnitCost: number;
  portfolioDiscount: number;
};

export type PriceSummary = {
  supplierCost: number;
  frequencyValue: number;
  frequencyLabel: string | null;
  periodStartMonth: number;
  periodEndMonth: number;
  billingCycle: string;
};

export type OfferStateChange = {
  stateTo: string;
  changedBy: string;
  createdOn: string;
};

export type ServiceType = {
  id: number;
  sysLabel: string;
};

export type Attachment = {
  attachmentId: string;
  fileName: string;
  mimeType: string;
  attachmentUri: string | null;
};

export type Description = {
  title: string;
  body: string;
};

export type TenderComment = {
  id: number;
  tenderId: number;
  comment: string;
};

export type TenderVersion = [];

export type InvoiceRecipient = {
  id: number;
  notificationType: string;
  email: string;
  organization: Organization;
};

export type Organization = {
  orgNumber: string;
  name: string;
  locked: boolean;
};

export type CompleteTenderDto = {
  userId: string;
  audience: string;
};

export type ChangeOfferStateDto = {
  supplierId: number;
  audience: string;
  state: string;
  userId: string;
};

export type RejectPriceRequestDto = {
  supplierId: number;
  audience: string;
  comment: string;
  userId: string;
};

export type InformationRequestedDto = {
  userId: string;
  comment: string;
  reasons: string[];
};

export type InformationRequestMessageDto = {
  customerPropertyId: number;
  supplierId: number;
  message: string;
  attachments: AttachmentDto[];
  tenderId: number;
  userId: string;
};

export type AgreementResponsibleDto = {
  AgreementOwnerUserId: string | null;
  SendNotification: boolean;
};

export type sendOfferDto = {
  supplierId: number;
  audience: string;
  state: string;
  userId: string;
  message: string;
  withdrawOtherOffers: boolean;
};

export type TenderArchiveDto = {
  state: string;
  userId: string;
  audience: string;
};

export type CloneableOffer = {
  serviceOfferId: number;
  tenderId: number;
  nickname: string | null;
  serviceCategoryLabel: string;
  customerOrgName: string;
  customerPropertyAddress: string;
};

export interface ActiveRequest {
  priceRequestId: number;
  supplierRequestId: number;
  offerCount: number;
  offerResponsePeriodInDays: number;
  createdOn: string;
  userResponsible: string | null;
  integrationSource: string;
  customerAddress: string;
  customerPosition: string;
  customerName: string;
  customerOrgNumber: string;
  tenderReference: string;
  categoryLabel: string;
  categoryId: number;
  supplierRequestState: string;
  hasUnreadMessages: boolean;
  deadline: string;
}

export type AgreementState = "Accepted" | "Completed";
export type AgreementType = "Project" | "ServiceAgreement" | "All";
// TODO: Add type for RequestType with values "serviceWithoutRobot" and "Robot"

export interface Agreement extends ActiveRequest {
  customerOrgNumber: string;
  customerName: string;
  bookingNumber: string;
  startDate: string;
  supplierId: number;
  acceptedSupplierRequestId: number;
  acceptedOfferId: number;
  acceptedDate: string;
  completedDate: string | null;
  billingCycles: BillingCycle;
  tenderId: number;
  nickName: string | null;
  portfolio: boolean;
  priceRequestId: number;
  priceRequestRulesId: number;
  requestSent: string;
  serviceCategoryId: number;
  serviceCategoryLabel: string;
  serviceTypeLabel: string;
  customerPropertyId: number;
  customerPropertyTypeId: number;
  customerPropertyAddress: string;
  customerPropertyTypeLabel: string;
  customerPropertyCoordinates: string;
  hasFrameAgreement: boolean;
  frameAgreementId: number;
  surveyDateTime: string | null;
  wantedStartDate: string | null;
  requestType: RequestType;
  requestState: string;
  unreadMessagesCount: number;
  isServiceAgreement: boolean;
}

export type PriceRequestDetails = {
  customerOrgId?: number;
  lastAddedSupplierDateTime: string;
  priceRequestAttachmentDtos: MessageAttachmentDto[];
  externalTenderId: string;
  requestMessage: string;
  responsePeriodInDays: number;
  tenderId: number;
  nickName: string | null;
  portfolio: boolean;
  priceRequestId: number;
  priceRequestRulesId: number;
  requestSent: string;
  serviceCategoryId: number;
  serviceCategoryLabel: string;
  serviceTypeLabel: string;
  customerPropertyId: number;
  customerPropertyTypeId: number;
  customerPropertyAddress: string;
  customerPropertyCoordinates: string;
  hasFrameAgreement: boolean;
  surveyDateTime: string | null;
  wantedStartDate: string;
  requestType: RequestType;
  requestState: string;
  createdOn: string;
};

export enum TenderViewTab {
  PRICE_REQUEST = "priceRequest",
  MESSAGES = "messages",
  OFFERS = "offers",
  AGREEMENT = "agreement",
  DOCUMENTATION = "documentation",
  ECONOMY = "economy",
}

export type TenderTab = {
  value: TenderViewTab;
  label: string;
  disabled?: boolean;
  component?: string;
};
export interface ServiceOfferType {
  serviceOfferId: number;
  supplierRequestId: number;
  tenderId: number;
  currentState: OfferStateType;
  descriptions: Array<unknown>;
  mandatorySupplierCosts: Array<unknown>;
  suggestedStartDate?: string;
  customerOrgName: string;
}

export interface SendOfferFormData {
  message: string;
  withdrawOtherOffers: boolean;
}

export interface WithdrawOfferOption {
  label: string;
  value: boolean;
}

export interface OfferRequirementsError {
  type: FeedbackType;
  messageTitle: string;
  messageInfo?: string;
}

export interface SendOfferCmsContent {
  forms?: {
    sendOfferForm?: {
      otherText?: Array<{
        label: string;
        title?: string;
        description?: string;
        supplierTermsConditions_text?: string;
        supplierTermsConditions_link?: string;
      }>;
      buttons?: Array<{
        label: string;
        title?: string;
      }>;
      inputs?: {
        messageInput?: {
          title?: string;
          placeholder?: string;
        };
        withdrawOffersInput?: {
          title?: string;
          RadioOptions?: Array<{
            label: string;
            yesOption?: string;
            noOption?: string;
          }>;
        };
      };
      formSections?: {
        errors?: {
          otherText?: Array<unknown>;
        };
      };
    };
  };
  pageSections?: Array<{
    label: string;
    title?: string;
    content?: Array<unknown>;
    icons?: {
      offerSentIcon?: string;
    };
    buttons?: {
      okayButton?: string;
    };
  }>;
  filedoc?: string;
}

interface CompetingBid {
  supplierName: string;
  amount: number;
  won: boolean;
}

export interface AgreementAcceptance {
  acceptedOn: string;
  customerIsPublicActor: boolean;
  acceptedBy: string;
  acceptanceReason: string[];
  acceptanceComment: string;
  competingBids: CompetingBid[];
}

export interface PagedResult<T> {
  count: number;
  pageSize: number;
  pages: number;
  items: T[];
}
