<template>
  <app-modal
    v-model="showModal"
    title="Slett varelinje"
    secondary-button-text="Avbryt"
    primary-button-text="Slett varelinje"
    :is-submitting="state.isSubmitting"
    :submission-error="state.submissionError"
    @secondary-button-click="closeModal"
    @primary-button-click="deleteSupplierCost"
  >
    <div class="modal-content__attachment">
      Dette vil fjerne varelinjen fra tilbudet ditt
    </div>
  </app-modal>
</template>

<script setup>
import { useTenderApi } from "@/services/api/useTenderApi";
import { reactive } from "vue";
import AppModal from "@/components/Base/BaseModal/AppModal.vue";

const emit = defineEmits(["cost-item-deleted"]);
const showModal = defineModel({ required: true });
const props = defineProps({
  supplierCost: {
    type: Object,
    required: true,
  },
  supplierId: {
    type: Number,
    required: true,
  },
  supplierRequestId: {
    type: Number,
    required: true,
  },
  serviceOfferId: {
    type: Number,
    required: true,
  },
});

const state = reactive({
  isSubmitting: false,
  submissionError: "",
});

const tenderApis = useTenderApi();

const deleteSupplierCost = async () => {
  if (state.isSubmitting) {
    return;
  }
  state.isSubmitting = true;
  try {
    await tenderApis.deleteSupplierCostItem(
      props.supplierId,
      props.supplierRequestId,
      props.serviceOfferId,
      props.supplierCost.serviceOfferSegmentGroupPriceId,
    );
    emit("cost-item-deleted", props.supplierCost);
    closeModal();
  } catch (error) {
    state.submissionError = error.toString();
    console.log("Error deleting supplier cost", error);
  } finally {
    state.isSubmitting = false;
  }
};
const closeModal = () => {
  showModal.value = false;
};
</script>

<style lang="scss" scoped>
.modal-content__description {
  font-weight: 400;
  font-size: 1rem;
  line-height: 20px;
  text-align: center;
  color: $color-black;
  padding-bottom: 50px;
}
</style>
