<template>
  <div class="public-actor-info">
    <div class="public-actor-info__header">
      <div class="public-actor-info__icon">
        <img :src="AccountBalanceIcon" alt="Info" />
      </div>
      <h4 class="public-actor-info__title">Offentlig aktør og innsyn</h4>
    </div>
    <p class="public-actor-info__description">
      Som offentlig aktør er {{ publicActorName }} pliktig til å opplyse om
      leverandører og priser i anbudsrunden.
    </p>
    <ul class="public-actor-info__bids">
      <li
        v-if="winningBid"
        class="public-actor-info__bid public-actor-info__bid--winner"
      >
        <div class="public-actor-info__bid-icon">
          <img :src="CheckmarkIcon" alt="Winner" />
        </div>
        <div class="public-actor-info__bid-info">
          <span class="public-actor-info__bid-label">Vinnende tilbud</span>
          <span class="public-actor-info__bid-company">{{
            winningBid.supplierName
          }}</span>
          <span class="public-actor-info__bid-value">
            Tilbudsverdi: {{ formatCurrency(winningBid.amount) }}
          </span>
        </div>
      </li>
      <li
        v-for="(bid, index) in otherBids"
        :key="index"
        class="public-actor-info__bid public-actor-info__bid--loser"
      >
        <div class="public-actor-info__bid-icon">
          <img :src="CloseIcon" alt="Close" />
        </div>
        <div class="public-actor-info__bid-info">
          <span class="public-actor-info__bid-company">{{
            bid.supplierName
          }}</span>
          <span class="public-actor-info__bid-value">
            Tilbudsverdi: {{ formatCurrency(bid.amount) }}
          </span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import AccountBalanceIcon from "@/assets/img/account-balance.svg";
import CheckmarkIcon from "@/assets/img/check-mark.svg";
import CloseIcon from "@/assets/img/close.svg";
import { CompetingBid } from "@/custom-types/GeneralTypes";

const props = defineProps<{
  publicActorName: string;
  competingBids: CompetingBid[];
}>();

const formatCurrency = (value: number): string => {
  return new Intl.NumberFormat("nb-NO", {
    style: "currency",
    currency: "NOK",
    minimumFractionDigits: 0,
  }).format(value);
};

const winningBid = computed(
  () => props.competingBids.find((bid) => bid.won) || null,
);

const otherBids = computed(
  () => props.competingBids.filter((bid) => !bid.won) || [],
);
</script>

<style lang="scss" scoped>
.public-actor-info {
  background: #f7f0fa;
  border-radius: 8px;
  padding: 16px;

  &__header {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
  }

  &__icon {
    width: 32px;
    height: 32px;
  }

  &__title {
    color: #1d1d1d;
    font-size: 14px;
    font-weight: 600;
  }

  &__description {
    color: #1d1d1d;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 16px;
  }

  &__bids {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  &__bid {
    display: flex;
    align-items: center;
    gap: 8px;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 8px;
    padding: 8px;
    margin-bottom: 4px;

    &--winner {
      background: white;
    }
  }

  &__bid-icon {
    width: 24px;
    height: 24px;

    img {
      border: 2px solid #1d1d1d;
      border-radius: 50%;
    }
  }

  &__bid-info {
    display: flex;
    flex-direction: column;
  }

  &__bid-label {
    color: #1d1d1d;
    font-size: 10px;
    font-weight: 500;
    text-transform: uppercase;
  }

  &__bid-company {
    color: #1d1d1d;
    font-size: 14px;
    font-weight: 600;
  }

  &__bid-value {
    color: #616161;
    font-size: 12px;
    font-weight: 500;
  }
}
</style>
