import { cloneDeep } from "lodash";

type CacheRecord<T> = {
  key: unknown;
  value: T;
  timestamp: number;
};

// Cache storage that can be accessed and cleared
const cacheStorage = new Map<string, Map<string, CacheRecord<unknown>>>();

// Clear all caches
export const clearAllCaches = (): void => {
  cacheStorage.forEach((cache) => cache.clear());
  console.log("All caches cleared");
};

// Clear cache for a specific function
export const clearFunctionCache = (cacheKey: string): void => {
  if (cacheStorage.has(cacheKey)) {
    cacheStorage.get(cacheKey)?.clear();
    console.log(`Cache cleared for: ${cacheKey}`);
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const cachingDecorator = <T extends (...args: any[]) => any>(
  func: T,
  options: {
    cacheKey?: string;
    maxAge?: number; // Cache TTL in milliseconds
  } = {},
): T => {
  const cacheKey = options.cacheKey || func.name || "anonymous";
  const maxAge = options.maxAge || 0; // 0 means no expiration

  // Initialize cache for this function if it doesn't exist
  if (!cacheStorage.has(cacheKey)) {
    cacheStorage.set(cacheKey, new Map());
  }

  const functionCache = cacheStorage.get(cacheKey)!;

  return new Proxy(func, {
    apply(target, thisArg, argArray) {
      const argKey = JSON.stringify(cloneDeep(argArray));
      const now = Date.now();
      const cachedRecord = functionCache.get(argKey);

      // Check if we have a valid cache entry
      if (
        cachedRecord &&
        (maxAge === 0 || now - cachedRecord.timestamp < maxAge)
      ) {
        return cachedRecord.value as ReturnType<T>;
      }

      // Execute the original function
      const result = Reflect.apply(target, thisArg, argArray);

      // Handle promises
      if (result instanceof Promise) {
        return result.then((resolvedValue) => {
          functionCache.set(argKey, {
            key: cloneDeep(argArray),
            value: resolvedValue,
            timestamp: now,
          });
          return resolvedValue;
        });
      }

      // Cache the result
      functionCache.set(argKey, {
        key: cloneDeep(argArray),
        value: result,
        timestamp: now,
      });

      return result;
    },
  }) as T;
};

// Initialize cache clearing on page load/refresh
if (typeof window !== "undefined") {
  window.addEventListener("load", clearAllCaches);
}

export default cachingDecorator;
