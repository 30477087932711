<template>
  <div class="agreements-view">
    <div class="agreements-view__header">Mine Prosjekter</div>
    <Tabs
      :value="activeTabValue"
      :lazy="true"
      :scrollable="false"
      :show-navigators="false"
      :select-on-focus="true"
      @update:value="(val) => handleTabChange(val as TabValue)"
    >
      <TabList>
        <Tab
          v-for="tab in tabs"
          :key="tab.value"
          v-tooltip.bottom="{
            value: getMessageTooltip(tab.value as TabValue),
            showDelay: 250,
            hideDelay: 150,
            class: 'tooltip-custom',
          }"
          :value="tab.value"
          :disabled="tab.disabled"
          :aria-label="getMessageTooltip(tab.value as TabValue)"
          @click="handleTabListChange(tab.value as TabValue)"
        >
          {{ tab.label }}
          <div class="badge-container ml-2">
            <Badge
              v-for="badge in getBadges(tab.value as TabValue)"
              :key="badge.value"
              :value="badge.value"
              :class="badge.class"
              :severity="badge.severity"
            />
          </div>
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel v-for="tab in tabs" :key="tab.value" :value="tab.value">
          <div
            v-if="shouldShowFiltersForTab(tab.value as TabValue)"
            class="filter-container"
          >
            <div class="filter-left">
              <Select
                v-model="sortOption"
                :options="availableSortOptions"
                option-label="label"
                option-value="id"
                placeholder="Sorter"
                class="sort-select"
                @change="handleSortChange"
              />
            </div>
            <div class="filter-middle">
              <Select
                v-model="categoryFilters[tab.value as TabValue]"
                v-tooltip.bottom="{
                  value: 'Filtrer etter tjenestekategori',
                  showDelay: 250,
                  hideDelay: 150,
                  class: 'tooltip-custom',
                }"
                :options="categoryOptions"
                filter
                option-label="title"
                placeholder="Filtrer etter tjenestekategori"
                class="category-filter"
                show-clear
                :loading="loadingState.requests"
                empty-filter-message="Ingen resultater funnet"
                empty-message="Ingen resultater funnet"
                @change="handleCategoryFilterChange"
              >
                <template #value="slotProps">
                  <div v-if="slotProps.value" class="flex items-center">
                    <div>{{ slotProps.value.title }}</div>
                  </div>
                  <span v-else>
                    {{ slotProps.placeholder }}
                  </span>
                </template>
              </Select>
            </div>
            <div class="filter-right">
              <IconField>
                <InputIcon class="pi pi-search" />
                <InputText
                  v-model="searchText"
                  placeholder="Søk"
                  class="search-input"
                  @input="handleSearchTextChange"
                />
              </IconField>
            </div>
          </div>
          <RequestList
            :loading="loadingState.requests"
            :error="requestErrors[tab.value]"
            :items="formatTendersList(tab.value as TabValue)[0]?.tenders || []"
            :title="titles[tab.value as TabValue]"
            :supplier-id="supplierId"
            @retry="() => handleRetry(tab.value as TabValue)"
            @agreement-click="handleAgreementClick"
          />

          <!-- Add "showing all items" message based on pagination state -->
          <div
            v-if="
              !loadingState.requests &&
              (formatTendersList(tab.value as TabValue)[0]?.tenders || [])
                .length > 0 &&
              (!shouldShowPaginationForTab(tab.value as TabValue) ||
                getPaginationForTab(tab.value as TabValue).totalRecords <= 10)
            "
            class="all-items-container"
          >
            <div
              v-if="categoryFilters[tab.value as TabValue] || searchText.trim()"
              class="all-items-message"
            >
              Viser
              {{
                (formatTendersList(tab.value as TabValue)[0]?.tenders || [])
                  .length
              }}
              av {{ getAgreementsList(tab.value as TabValue).length }} elementer
            </div>
            <div v-else class="all-items-message">
              Viser alle
              {{
                (formatTendersList(tab.value as TabValue)[0]?.tenders || [])
                  .length
              }}
              elementer
            </div>
          </div>

          <!-- Only show paginator for tabs that should have pagination (completed tabs) -->
          <div
            v-if="
              !loadingState.requests &&
              (formatTendersList(tab.value as TabValue)[0]?.tenders || [])
                .length > 0 &&
              shouldShowPaginationForTab(tab.value as TabValue) &&
              getPaginationForTab(tab.value as TabValue).totalRecords >= 11
            "
            class="paginator-container"
          >
            <Paginator
              :first="getPaginationForTab(tab.value as TabValue).first"
              :rows="getPaginationForTab(tab.value as TabValue).rows"
              :total-records="
                getPaginationForTab(tab.value as TabValue).totalRecords
              "
              :rows-per-page-options="[5, 10, 20, 50]"
              template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              @update:first="
                (value) =>
                  debouncedHandlePageChange(tab.value as TabValue, value)
              "
              @update:rows="
                (value) =>
                  debouncedHandleRowsPerPageChange(tab.value as TabValue, value)
              "
            >
              <template #start>
                <div class="pagination-info">
                  {{ getPaginationTextForTab(tab.value as TabValue) }}
                </div>
              </template>
            </Paginator>
          </div>
        </TabPanel>
      </TabPanels>
    </Tabs>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, onUnmounted, watch, provide } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useTenderApi } from "@/services/api/useTenderApi";
import { useLastVisitTime } from "@/composables/useLastVisitTime";
import { useCommunicationApi } from "@/services/api/useCommunicationApi";
import { useFeedbackCookie } from "@/utilities/useFeedbackCookie";
import { debounce } from "lodash";
import { format } from "date-fns";
import type {
  Agreement,
  AgreementState,
  AgreementType,
} from "@/stores/tender/types";
import type {
  UnreadMessageStatistics,
  ActivityStats,
} from "@/stores/communication/types";
import { mapToTenderFormat } from "@/utilities/tenderMapper";
import RequestList from "@/components/MyTenders/RequestList.vue";
import Tabs from "primevue/tabs";
import TabList from "primevue/tablist";
import Tab from "primevue/tab";
import TabPanels from "primevue/tabpanels";
import TabPanel from "primevue/tabpanel";
import Badge from "primevue/badge";
import Paginator from "primevue/paginator";
import {
  useTenderWidgets,
  ACTIVITY_STATS_KEY,
} from "@/composables/useTenderWidgets";
import { useSupplierStore } from "@/stores/supplier";
import Select from "primevue/select";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import InputText from "primevue/inputtext";
import { useServiceCategory } from "@/composables/useServiceCategory";
import { useCmsStore } from "@/stores/cms";
import { ServiceType } from "@/stores/cms/types";
import { RouteNames } from "@/router/types";

// Update BadgeProps interface to match existing usage
interface BadgeProps {
  text?: string;
  value?: string;
  class?: string;
  severity?: string;
  type?: "message" | "activity";
}

const supplierStore = useSupplierStore();
const router = useRouter();
const route = useRoute();
const { getAgreements, getRequestActivityStats } = useTenderApi();
const { getFromTime, updateLastVisitTime } = useLastVisitTime();
const { fetchSupplierUnreadMessageStatistics } = useCommunicationApi();
const {
  hasActiveServiceBeenViewed,
  hasActiveProjectBeenViewed,
  setActiveServiceViewed,
  setCompletedServiceViewed,
  setActiveProjectViewed,
  setCompletedProjectViewed,
  setNewAgreementViewed,
} = useFeedbackCookie();

// Constants
const REFRESH_INTERVAL = 5 * 60 * 1000; // 5 minutes

const TABS = {
  ACTIVE_SERVICE: "active-service",
  COMPLETED_SERVICE: "completed-service",
  ACTIVE_PROJECTS: "active-projects",
  COMPLETED_PROJECTS: "completed-projects",
} as const;

type TabValue = (typeof TABS)[keyof typeof TABS];

const TAB_TO_TYPE_MAP: Record<
  TabValue,
  { state: AgreementState; type: AgreementType }
> = {
  [TABS.ACTIVE_SERVICE]: { state: "Accepted", type: "ServiceAgreement" },
  [TABS.COMPLETED_SERVICE]: { state: "Completed", type: "ServiceAgreement" },
  [TABS.ACTIVE_PROJECTS]: { state: "Accepted", type: "Project" },
  [TABS.COMPLETED_PROJECTS]: { state: "Completed", type: "Project" },
};

// State
interface LoadingState {
  requests: boolean;
  stats: boolean;
}

const loadingState = ref<LoadingState>({
  requests: true,
  stats: true,
});

// Add search text state
const searchText = ref("");

const currentTab = computed(
  () => route.query.tab?.toString() || "priceRequest",
);
const activeTabValue = ref<TabValue>(TABS.ACTIVE_PROJECTS);
const activeServiceAgreements = ref<Agreement[]>([]);
const completedServiceAgreements = ref<Agreement[]>([]);
const activeProjects = ref<Agreement[]>([]);
const completedProjects = ref<Agreement[]>([]);
const lastRefresh = ref<number>(0);
const statsError = ref<string | null>(null);

// Update message stats interface
interface AgreementMessageStats extends UnreadMessageStatistics {
  "accepted-project"?: number;
  "completed-project"?: number;
  "accepted-service"?: number;
  "completed-service"?: number;
}

// Update activity stats interface
interface AgreementActivityStats extends ActivityStats {}

// Update state refs with new types
const messageStats = ref<AgreementMessageStats>({
  "InformationRequested-project": 0,
  "Offered-project": 0,
  "PriceRequested-project": 0,
  "Rejected-project": 0,
  "Offered-service": 0,
  "Rejected-service": 0,
  "Accepted-project": 0,
  "Completed-project": 0,
  "Accepted-service": 0,
  "Completed-service": 0,
});
const activityStats = ref<AgreementActivityStats[]>([]);
const lastStatsRefresh = ref<number>(0);

// Add error tracking per tab
const requestErrors = ref<Record<TabValue, string | undefined>>({
  [TABS.ACTIVE_SERVICE]: undefined,
  [TABS.COMPLETED_SERVICE]: undefined,
  [TABS.ACTIVE_PROJECTS]: undefined,
  [TABS.COMPLETED_PROJECTS]: undefined,
});

// Add viewedRequests ref
const viewedRequests = ref<Set<number | string>>(new Set());

// Define pagination settings interface
interface PaginationSettings {
  first: number;
  rows: number;
  totalRecords: number;
}

// Add pagination state for each tab
const activeServicePagination = ref<PaginationSettings>({
  first: 0,
  rows: 10,
  totalRecords: 0,
});

const completedServicePagination = ref<PaginationSettings>({
  first: 0,
  rows: 10,
  totalRecords: 0,
});

const activeProjectsPagination = ref<PaginationSettings>({
  first: 0,
  rows: 10,
  totalRecords: 0,
});

const completedProjectsPagination = ref<PaginationSettings>({
  first: 0,
  rows: 10,
  totalRecords: 0,
});

// Add a flag to prevent duplicate API calls
const isPageChanging = ref(false);

// Create functions to check if agreements are unviewed
const isActiveServiceUnviewed = (priceRequestId: number | string): boolean => {
  const priceRequestIdNumber = parseInt(priceRequestId.toString(), 10);
  return !(
    hasActiveServiceBeenViewed(priceRequestIdNumber) ||
    viewedRequests.value.has(priceRequestIdNumber)
  );
};

const isActiveProjectUnviewed = (priceRequestId: number | string): boolean => {
  const priceRequestIdNumber = parseInt(priceRequestId.toString(), 10);
  return !(
    hasActiveProjectBeenViewed(priceRequestIdNumber) ||
    viewedRequests.value.has(priceRequestIdNumber)
  );
};

const titles: Record<TabValue, string> = {
  [TABS.ACTIVE_SERVICE]: "Aktive serviceavtaler",
  [TABS.COMPLETED_SERVICE]: "Utførte serviceavtaler",
  [TABS.ACTIVE_PROJECTS]: "Aktive prosjekter",
  [TABS.COMPLETED_PROJECTS]: "Utførte prosjekter",
};

const tabs = [
  {
    label: "Aktive prosjekter",
    value: TABS.ACTIVE_PROJECTS,
    disabled: false,
  },
  {
    label: "Utførte prosjekter",
    value: TABS.COMPLETED_PROJECTS,
    disabled: false,
  },
  {
    label: "Aktive serviceavtaler",
    value: TABS.ACTIVE_SERVICE,
    disabled: false,
  },
  {
    label: "Arkiv serviceavtaler",
    value: TABS.COMPLETED_SERVICE,
    disabled: false,
  },
];

// Store getters
const supplier = computed(() => supplierStore.supplier);
const supplierId = computed(() => {
  if (!supplier.value?.id) return 0;
  return supplier.value.id;
});

// Computed property for message counts per tab
const getTabMessageCount = computed(() => {
  if (statsError.value || !messageStats.value) {
    return {
      [TABS.ACTIVE_SERVICE]: 0,
      [TABS.COMPLETED_SERVICE]: 0,
      [TABS.ACTIVE_PROJECTS]: 0,
      [TABS.COMPLETED_PROJECTS]: 0,
    };
  }

  return {
    [TABS.ACTIVE_SERVICE]: messageStats.value["Accepted-service"] || 0,
    [TABS.COMPLETED_SERVICE]: messageStats.value["Completed-service"] || 0,
    [TABS.ACTIVE_PROJECTS]: messageStats.value["Accepted-project"] || 0,
    [TABS.COMPLETED_PROJECTS]: messageStats.value["Completed-project"] || 0,
  };
});

// Update tooltip message
const getMessageTooltip = (tabValue: TabValue): string => {
  const messageCount = getTabMessageCount.value[tabValue];

  switch (tabValue) {
    case TABS.ACTIVE_SERVICE: {
      const activeServiceAgreements = activityStats.value?.filter(
        (stat) =>
          stat.requestState === "accepted" && stat.isServiceAgreement === true,
      );

      const unviewedCount =
        activeServiceAgreements?.reduce((count, stat) => {
          return (
            count +
            (stat.priceRequestIds?.filter(isActiveServiceUnviewed).length || 0)
          );
        }, 0) || 0;

      const messages = [];
      if (messageCount > 0) messages.push(`${messageCount} uleste meldinger`);
      if (unviewedCount > 0)
        messages.push(`${unviewedCount} nye aktive serviceavtaler`);

      return messages.length > 0 ? messages.join("\n") : "Ingen nye meldinger";
    }

    case TABS.COMPLETED_SERVICE: {
      const messages = [];
      if (messageCount > 0) messages.push(`${messageCount} uleste meldinger`);
      return messages.length > 0 ? messages.join("\n") : "Ingen nye meldinger";
    }

    case TABS.ACTIVE_PROJECTS: {
      const activeProjects = activityStats.value?.filter(
        (stat) =>
          stat.requestState === "accepted" && stat.isServiceAgreement === false,
      );

      const unviewedCount =
        activeProjects?.reduce((count, stat) => {
          return (
            count +
            (stat.priceRequestIds?.filter(isActiveProjectUnviewed).length || 0)
          );
        }, 0) || 0;

      const messages = [];
      if (messageCount > 0) messages.push(`${messageCount} uleste meldinger`);
      if (unviewedCount > 0)
        messages.push(`${unviewedCount} nye aktive prosjekter`);

      return messages.length > 0 ? messages.join("\n") : "Ingen nye meldinger";
    }

    case TABS.COMPLETED_PROJECTS: {
      const messages = [];
      if (messageCount > 0) messages.push(`${messageCount} uleste meldinger`);
      return messages.length > 0 ? messages.join("\n") : "Ingen nye meldinger";
    }
  }

  return "Ingen nye meldinger";
};

// Update getBadges function to show activity badges
const getBadges = (tabValue: TabValue): BadgeProps[] => {
  const badges: BadgeProps[] = [];
  const messageCount = getTabMessageCount.value[tabValue];

  if (messageCount > 0) {
    // badges.push({
    //   value: "",
    //   class: "activity-badge activity-dot",
    //   type: "message",
    // });
  }

  // Add activity badges for unviewed agreements
  switch (tabValue) {
    case TABS.ACTIVE_SERVICE: {
      const activeServiceAgreements = activityStats.value?.filter(
        (stat) =>
          stat.requestState === "accepted" && stat.isServiceAgreement === true,
      );

      const unviewedCount =
        activeServiceAgreements?.reduce((count, stat) => {
          return (
            count +
            (stat.priceRequestIds?.filter(isActiveServiceUnviewed).length || 0)
          );
        }, 0) || 0;

      if (unviewedCount > 0) {
        badges.push({
          value: "",
          class: "activity-badge activity-dot",
          type: "activity",
        });
      }
      break;
    }

    case TABS.ACTIVE_PROJECTS: {
      const activeProjects = activityStats.value?.filter(
        (stat) =>
          stat.requestState === "accepted" && stat.isServiceAgreement === false,
      );

      const unviewedCount =
        activeProjects?.reduce((count, stat) => {
          return (
            count +
            (stat.priceRequestIds?.filter(isActiveProjectUnviewed).length || 0)
          );
        }, 0) || 0;

      if (unviewedCount > 0) {
        badges.push({
          value: "",
          class: "activity-badge activity-dot",
          type: "activity",
        });
      }
      break;
    }
  }

  return badges;
};

// Helper function to determine if a tab should show pagination
const shouldShowPaginationForTab = (tabValue: TabValue): boolean => {
  // Only show pagination for completed tabs
  return (
    tabValue === TABS.COMPLETED_SERVICE || tabValue === TABS.COMPLETED_PROJECTS
  );
};

// Helper function to get pagination state for a tab
const getPaginationForTab = (tabValue: TabValue): PaginationSettings => {
  switch (tabValue) {
    case TABS.ACTIVE_SERVICE:
      return activeServicePagination.value;
    case TABS.COMPLETED_SERVICE:
      return completedServicePagination.value;
    case TABS.ACTIVE_PROJECTS:
      return activeProjectsPagination.value;
    case TABS.COMPLETED_PROJECTS:
      return completedProjectsPagination.value;
  }
};

// Helper function to update pagination state for a tab
const updatePaginationForTab = (
  tabValue: TabValue,
  update: Partial<PaginationSettings>,
): void => {
  switch (tabValue) {
    case TABS.ACTIVE_SERVICE:
      Object.assign(activeServicePagination.value, update);
      break;
    case TABS.COMPLETED_SERVICE:
      Object.assign(completedServicePagination.value, update);
      break;
    case TABS.ACTIVE_PROJECTS:
      Object.assign(activeProjectsPagination.value, update);
      break;
    case TABS.COMPLETED_PROJECTS:
      Object.assign(completedProjectsPagination.value, update);
      break;
  }
};

// Helper function to get pagination text for a specific tab
const getPaginationTextForTab = (tabValue: TabValue): string => {
  const { first, rows, totalRecords } = getPaginationForTab(tabValue);

  if (totalRecords === 0) {
    return "Ingen elementer";
  }

  const start = first + 1;
  const end = Math.min(first + rows, totalRecords);

  return `Viser ${start} til ${end} av totalt ${totalRecords} elementer`;
};

// Add refresh control
const shouldRefreshStats = (): boolean => {
  return Date.now() - lastStatsRefresh.value > REFRESH_INTERVAL;
};

// Fetch both message and activity statistics
const fetchStats = async (force = false) => {
  if (!supplier.value?.id || (!force && !shouldRefreshStats())) return;

  try {
    loadingState.value.stats = true;
    statsError.value = null;

    const date = new Date(getFromTime("new"));
    const from = format(date, "yyyy-MM-dd");

    const [messageResponse, activityResponse] = await Promise.all([
      fetchSupplierUnreadMessageStatistics(supplierId.value.toString()),
      getRequestActivityStats(supplierId.value, from),
    ]);

    messageStats.value = messageResponse?.data || messageStats.value;
    activityStats.value = activityResponse?.data || [];
    lastStatsRefresh.value = Date.now();
  } catch (error) {
    console.error("Error fetching stats:", error);
    statsError.value = "Failed to fetch updates";
  } finally {
    loadingState.value.stats = false;
  }
};

// Add a record to store total counts for each tab
const tabTotalCounts = ref<Record<TabValue, number | null>>({
  [TABS.ACTIVE_SERVICE]: null,
  [TABS.COMPLETED_SERVICE]: null,
  [TABS.ACTIVE_PROJECTS]: null,
  [TABS.COMPLETED_PROJECTS]: null,
});

// Computed properties for agreements lists
const getAgreementsList = (tabValue: TabValue): Agreement[] => {
  switch (tabValue) {
    case TABS.ACTIVE_SERVICE:
      return activeServiceAgreements.value;
    case TABS.COMPLETED_SERVICE:
      return completedServiceAgreements.value;
    case TABS.ACTIVE_PROJECTS:
      return activeProjects.value;
    case TABS.COMPLETED_PROJECTS:
      return completedProjects.value;
    default:
      return [];
  }
};

// Sorting functionality
enum AgreementSortOptions {
  NEWEST_FIRST = "newest",
  OLDEST_FIRST = "oldest",
  SERVICE = "service",
  ADDRESS = "address",
}

interface SortOption {
  label: string;
  id: AgreementSortOptions | null;
}

const availableSortOptions: SortOption[] = [
  { label: "Sorter", id: null },
  { label: "Adresse", id: AgreementSortOptions.ADDRESS },
  { label: "Eldste først", id: AgreementSortOptions.OLDEST_FIRST },
  { label: "Nyeste først", id: AgreementSortOptions.NEWEST_FIRST },
  { label: "Tjeneste", id: AgreementSortOptions.SERVICE },
];

const sortOption = ref<AgreementSortOptions | null>(null);

// Category filtering
interface CategoryOption {
  id: number;
  label: string;
  title: string;
}

const cmsStore = useCmsStore();
const { fetchServiceCategories } = useServiceCategory(supplierId.value || 0);

// Maintain selected category filter for each tab
const categoryFilters = ref<Record<TabValue, CategoryOption | null>>({
  [TABS.ACTIVE_SERVICE]: null,
  [TABS.COMPLETED_SERVICE]: null,
  [TABS.ACTIVE_PROJECTS]: null,
  [TABS.COMPLETED_PROJECTS]: null,
});

// Function to extract unique categories from agreements
const extractCategories = (agreements: Agreement[]): CategoryOption[] => {
  const uniqueCategories = new Map<string, CategoryOption>();

  // Get CMS data for translations
  const cms = cmsStore.serviceTypesCms as ServiceType[];

  if (!cms) {
    console.warn("CMS data missing for service categories");
    return [];
  }

  agreements.forEach((agreement) => {
    if (
      agreement.serviceCategoryLabel &&
      !uniqueCategories.has(agreement.serviceCategoryLabel)
    ) {
      // Find the matching service type from CMS
      const cmsServiceType = cms.find((serviceType) =>
        serviceType.serviceCategories?.some(
          (cat) => cat.label === agreement.serviceCategoryLabel,
        ),
      );

      // Find the matching category to get its title
      const categoryFromCms = cmsServiceType?.serviceCategories?.find(
        (cat) => cat.label === agreement.serviceCategoryLabel,
      );

      // Use the title from CMS if available, otherwise use the label
      const translatedTitle =
        categoryFromCms?.title || agreement.serviceCategoryLabel;

      uniqueCategories.set(agreement.serviceCategoryLabel, {
        id: agreement.serviceCategoryId,
        label: agreement.serviceCategoryLabel,
        title: translatedTitle,
      });
    }
  });

  return Array.from(uniqueCategories.values()).sort((a, b) =>
    a.title.localeCompare(b.title),
  );
};

// Computed property to get all unique categories across all agreements
const categoryOptions = computed((): CategoryOption[] => {
  const allCategories = [
    ...extractCategories(activeServiceAgreements.value),
    ...extractCategories(completedServiceAgreements.value),
    ...extractCategories(activeProjects.value),
    ...extractCategories(completedProjects.value),
  ];

  // Remove duplicates by using Map with category label as key
  const uniqueCategories = new Map<string, CategoryOption>();
  allCategories.forEach((category) => {
    if (!uniqueCategories.has(category.label)) {
      uniqueCategories.set(category.label, category);
    }
  });

  return Array.from(uniqueCategories.values()).sort((a, b) =>
    a.title.localeCompare(b.title),
  );
});

// Add filtered refs for each tab
const filteredActiveServiceAgreements = ref<Agreement[]>([]);
const filteredCompletedServiceAgreements = ref<Agreement[]>([]);
const filteredActiveProjects = ref<Agreement[]>([]);
const filteredCompletedProjects = ref<Agreement[]>([]);

// Sorting helper functions
const sortByDate = (
  dateStrA: string | null | undefined,
  dateStrB: string | null | undefined,
  oldestFirst: boolean,
): number => {
  const dateA = new Date(dateStrA || "");
  const dateB = new Date(dateStrB || "");

  if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
    return 0;
  }

  const oldestFirstCriteria = dateA.getTime() - dateB.getTime();
  return oldestFirst ? oldestFirstCriteria : -1 * oldestFirstCriteria;
};

const getServiceCategoryName = (
  categoryLabel: string,
  cms: ServiceType[],
): string => {
  // Find the matching service type from CMS
  const cmsServiceType = cms.find((serviceType) =>
    serviceType.serviceCategories?.some((cat) => cat.label === categoryLabel),
  );

  // Find the matching category to get its title
  const categoryFromCms = cmsServiceType?.serviceCategories?.find(
    (cat) => cat.label === categoryLabel,
  );

  // Use the title from CMS if available, otherwise use the label
  return categoryFromCms?.title || categoryLabel;
};

const sortAgreements = (
  agreements: Agreement[],
  selectedSortOption: AgreementSortOptions | null,
): Agreement[] => {
  if (!agreements.length || !selectedSortOption) {
    return agreements;
  }

  const cms = cmsStore.serviceTypesCms as ServiceType[];

  return [...agreements].sort((agreementA, agreementB) => {
    if (
      selectedSortOption === AgreementSortOptions.OLDEST_FIRST ||
      selectedSortOption === AgreementSortOptions.NEWEST_FIRST
    ) {
      return sortByDate(
        agreementA.acceptedDate,
        agreementB.acceptedDate,
        selectedSortOption === AgreementSortOptions.OLDEST_FIRST,
      );
    }

    if (selectedSortOption === AgreementSortOptions.SERVICE) {
      const serviceA = getServiceCategoryName(
        agreementA.serviceCategoryLabel,
        cms,
      );
      const serviceB = getServiceCategoryName(
        agreementB.serviceCategoryLabel,
        cms,
      );
      return serviceA.localeCompare(serviceB);
    }

    if (selectedSortOption === AgreementSortOptions.ADDRESS) {
      return (agreementA.customerPropertyAddress || "").localeCompare(
        agreementB.customerPropertyAddress || "",
      );
    }

    return 0;
  });
};

// Event handlers
const handleSortChange = () => {
  // Apply sorting and filters for the active tab
  applyFiltersAndSort(activeTabValue.value);
};

const handleCategoryFilterChange = debounce(() => {
  // Apply filters and sorting for the active tab
  applyFiltersAndSort(activeTabValue.value);
}, 300);

const handleSearchTextChange = debounce(() => {
  // Apply search filter and sort for the active tab
  applyFiltersAndSort(activeTabValue.value);
}, 300);

// Apply filters and sorting
const applyFiltersAndSort = (tabValue: TabValue) => {
  const selectedCategory = categoryFilters.value[tabValue];
  const selectedSortOption = sortOption.value;
  const searchQuery = searchText.value.trim().toLowerCase();

  // Get the appropriate source data based on tab
  let sourceData: Agreement[] = [];

  switch (tabValue) {
    case TABS.ACTIVE_SERVICE:
      sourceData = activeServiceAgreements.value;
      break;
    case TABS.COMPLETED_SERVICE:
      sourceData = completedServiceAgreements.value;
      break;
    case TABS.ACTIVE_PROJECTS:
      sourceData = activeProjects.value;
      break;
    case TABS.COMPLETED_PROJECTS:
      sourceData = completedProjects.value;
      break;
  }

  // First apply search filter
  let filtered = searchQuery
    ? sourceData.filter((agreement) =>
        [
          (agreement.bookingNumber || "").toString().toLowerCase(),
          (agreement.customerPropertyAddress || "").toLowerCase(),
          (agreement.customerName || "").toLowerCase(),
        ].some((field) => field.includes(searchQuery)),
      )
    : sourceData;

  // Then apply category filter
  if (selectedCategory) {
    filtered = filtered.filter(
      (agreement) => agreement.serviceCategoryLabel === selectedCategory.label,
    );
  }

  // Then apply sorting if needed
  filtered = selectedSortOption
    ? sortAgreements(filtered, selectedSortOption)
    : filtered;

  // Update the appropriate filtered ref
  switch (tabValue) {
    case TABS.ACTIVE_SERVICE:
      filteredActiveServiceAgreements.value = filtered;
      break;
    case TABS.COMPLETED_SERVICE:
      filteredCompletedServiceAgreements.value = filtered;
      break;
    case TABS.ACTIVE_PROJECTS:
      filteredActiveProjects.value = filtered;
      break;
    case TABS.COMPLETED_PROJECTS:
      filteredCompletedProjects.value = filtered;
      break;
  }
};

// Add watchers for filtering
watch(
  [
    activeServiceAgreements,
    completedServiceAgreements,
    activeProjects,
    completedProjects,
  ],
  () => {
    applyFiltersAndSort(TABS.ACTIVE_SERVICE);
    applyFiltersAndSort(TABS.COMPLETED_SERVICE);
    applyFiltersAndSort(TABS.ACTIVE_PROJECTS);
    applyFiltersAndSort(TABS.COMPLETED_PROJECTS);
  },
);

// Watch for tab changes to ensure filters and sorting are applied
watch(activeTabValue, (newTabValue) => {
  applyFiltersAndSort(newTabValue);
});

// Watch for sort option changes
watch(sortOption, () => {
  applyFiltersAndSort(activeTabValue.value);
});

// Watch for search text changes
watch(searchText, () => {
  applyFiltersAndSort(activeTabValue.value);
});

// Methods
const fetchAgreements = async (tabValue: TabValue) => {
  if (!supplier.value?.id) {
    console.error("No supplier ID available");
    requestErrors.value[tabValue] = "Leverandør-ID mangler";
    loadingState.value.requests = false;
    return;
  }

  try {
    loadingState.value.requests = true;
    requestErrors.value[tabValue] = undefined;

    const { state, type } = TAB_TO_TYPE_MAP[tabValue];

    // For active tabs, don't use pagination parameters
    let skip: number | undefined;
    let take: number | undefined;

    if (shouldShowPaginationForTab(tabValue)) {
      // Get pagination parameters only for tabs that use pagination
      const { first, rows } = getPaginationForTab(tabValue);
      skip = first;
      take = rows;
    }

    // Fetch the data with appropriate pagination settings (or without for active tabs)
    const response = await getAgreements(
      supplierId.value,
      state,
      type,
      skip,
      take,
    );

    if (!response || !response.data) {
      throw new Error("No data received from API");
    }

    // Update total count for this tab if we receive it
    if (response.data.count !== undefined) {
      tabTotalCounts.value[tabValue] = response.data.count;

      // Update pagination state with total records
      updatePaginationForTab(tabValue, { totalRecords: response.data.count });

      console.log(
        `Updated total count for ${tabValue}: ${response.data.count} items`,
      );
    }

    const responseItems = response.data.items;
    console.log(
      `Received ${responseItems.length} of ${tabTotalCounts.value[tabValue]} total agreements for ${tabValue}`,
    );

    // Sort items by acceptedDate in descending order
    const sortedItems = [...responseItems].sort(
      (a, b) =>
        new Date(b.acceptedDate).getTime() - new Date(a.acceptedDate).getTime(),
    );

    // Filter based on current tab - note that the API is already filtering
    // by type (ServiceAgreement or Project) and state (Accepted or Completed)
    // but we're applying the filter here as well to be extra sure
    let filteredItems = sortedItems;

    switch (tabValue) {
      case TABS.ACTIVE_SERVICE:
        filteredItems = sortedItems.filter(
          (agreement) =>
            agreement.requestState === "accepted" &&
            agreement.isServiceAgreement === true,
        );
        break;
      case TABS.COMPLETED_SERVICE:
        filteredItems = sortedItems.filter(
          (agreement) =>
            agreement.requestState === "completed" &&
            agreement.isServiceAgreement === true,
        );
        break;
      case TABS.ACTIVE_PROJECTS:
        filteredItems = sortedItems.filter(
          (agreement) =>
            agreement.requestState === "accepted" &&
            agreement.isServiceAgreement === false,
        );
        break;
      case TABS.COMPLETED_PROJECTS:
        filteredItems = sortedItems.filter(
          (agreement) =>
            agreement.requestState === "completed" &&
            agreement.isServiceAgreement === false,
        );
        break;
    }

    // Update the appropriate state based on tab
    switch (tabValue) {
      case TABS.ACTIVE_SERVICE:
        activeServiceAgreements.value = filteredItems;
        break;
      case TABS.COMPLETED_SERVICE:
        completedServiceAgreements.value = filteredItems;
        break;
      case TABS.ACTIVE_PROJECTS:
        activeProjects.value = filteredItems;
        break;
      case TABS.COMPLETED_PROJECTS:
        completedProjects.value = filteredItems;
        break;
    }

    lastRefresh.value = Date.now();
  } catch (error) {
    const message = "Kunne ikke hente avtaler";
    requestErrors.value[tabValue] = message;
    console.error(`${message} for ${tabValue}:`, error);

    // Ensure we have empty arrays rather than undefined
    switch (tabValue) {
      case TABS.ACTIVE_SERVICE:
        activeServiceAgreements.value = [];
        break;
      case TABS.COMPLETED_SERVICE:
        completedServiceAgreements.value = [];
        break;
      case TABS.ACTIVE_PROJECTS:
        activeProjects.value = [];
        break;
      case TABS.COMPLETED_PROJECTS:
        completedProjects.value = [];
        break;
    }
  } finally {
    loadingState.value.requests = false;
  }
};

const handleTabListChange = (tabValue: TabValue) => {
  handleTabChange(tabValue);
};

const handleTabChange = async (
  tabValue: TabValue,
  priceRequestId?: string | number,
) => {
  // Reset search text and category filter when switching tabs
  searchText.value = "";
  categoryFilters.value[tabValue] = null;

  // Update the active tab value first
  activeTabValue.value = tabValue;

  // Get priceRequestId from route if not provided
  const routePriceRequestId = route.params.priceRequestId;
  const finalPriceRequestId =
    priceRequestId ||
    (routePriceRequestId ? routePriceRequestId.toString() : undefined);

  // Preserve priceRequestId if it exists in current route
  const query: Record<string, string> = {
    tab: currentTab.value,
    tabList: tabValue,
  };

  // Mark agreement as viewed if priceRequestId exists
  if (finalPriceRequestId) {
    query.priceRequestId = finalPriceRequestId.toString();
    handleAgreementClick(finalPriceRequestId);
  }

  // Update URL with new tab
  await router.replace({ query });

  // Update last visit time
  updateLastVisitTime("new");

  // Reset pagination state for this tab
  updatePaginationForTab(tabValue, { first: 0 });

  await fetchAgreements(tabValue);
  await fetchStats(true);
};

// Add a watch for route changes
watch(
  () => route.query.tabList,
  async (newTabList) => {
    if (newTabList && Object.values(TABS).includes(newTabList as TabValue)) {
      const newTabValue = newTabList as TabValue;
      if (newTabValue !== activeTabValue.value) {
        await handleTabChange(newTabValue);
      }
    }
  },
);

// Add a watch for query parameters to handle tab changes from URL query
watch(
  () => route.query,
  async (newQuery) => {
    const tabFromUrl = newQuery.tabList?.toString();

    if (tabFromUrl && Object.values(TABS).includes(tabFromUrl as TabValue)) {
      const newTabValue = tabFromUrl as TabValue;

      // Only trigger tab change if the tab has actually changed
      if (newTabValue !== activeTabValue.value) {
        console.debug(
          `Tab changed from query: ${activeTabValue.value} to ${newTabValue}`,
        );
        await handleTabChange(newTabValue);
      }
    }
  },
  { immediate: false }, // Not immediate because we handle initial load in onMounted
);

const handleRetry = async (tabValue: TabValue) => {
  requestErrors.value[tabValue] = undefined;
  await handleTabChange(tabValue);
};

// Add handleAgreementClick function
const handleAgreementClick = (priceRequestId: number | string) => {
  if (!priceRequestId) return;

  // Mark agreement as viewed based on the current tab
  switch (activeTabValue.value) {
    case TABS.ACTIVE_SERVICE:
      setActiveServiceViewed(priceRequestId);
      break;
    case TABS.COMPLETED_SERVICE:
      setCompletedServiceViewed(priceRequestId);
      break;
    case TABS.ACTIVE_PROJECTS:
      setActiveProjectViewed(priceRequestId);
      break;
    case TABS.COMPLETED_PROJECTS:
      setCompletedProjectViewed(priceRequestId);
      break;
  }

  // Also mark as viewed in the general new agreement cookie for compatibility with TenderList
  setNewAgreementViewed(priceRequestId);

  // Navigate to the agreement details
  router.push({
    name: RouteNames.MY_AGREEMENTS,
    params: {
      priceRequestId: priceRequestId.toString(),
    },
    query: {
      tabList: activeTabValue.value,
      tab: currentTab.value,
    },
  });
};

// Add smooth scroll to top function
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

// Visibility change handler
const handleVisibilityChange = debounce(() => {
  if (document.visibilityState === "visible") {
    fetchStats(true);
  }
}, 1000);

// Initialization
const initializeData = async () => {
  if (!supplier.value?.id) {
    console.error("No supplier ID available for initialization");
    statsError.value = "Failed to initialize data: No supplier ID";
    return;
  }

  try {
    loadingState.value.requests = true;

    // Fetch service categories for the filter
    await fetchServiceCategories();

    // Set initial state from URL or default
    const tabFromUrl = route.query.tabList?.toString();
    if (tabFromUrl && Object.values(TABS).includes(tabFromUrl as TabValue)) {
      activeTabValue.value = tabFromUrl as TabValue;
    }

    // Clear any existing data for the active tab
    switch (activeTabValue.value) {
      case TABS.ACTIVE_SERVICE:
        activeServiceAgreements.value = [];
        break;
      case TABS.COMPLETED_SERVICE:
        completedServiceAgreements.value = [];
        break;
      case TABS.ACTIVE_PROJECTS:
        activeProjects.value = [];
        break;
      case TABS.COMPLETED_PROJECTS:
        completedProjects.value = [];
        break;
    }

    // Fetch agreements for the active tab
    await fetchAgreements(activeTabValue.value);

    // Fetch stats after agreements are loaded
    await fetchStats(true);
  } catch (error) {
    console.error("Error initializing data:", error);
    statsError.value = "Failed to initialize data";

    // Ensure we have empty arrays rather than undefined
    activeServiceAgreements.value = activeServiceAgreements.value || [];
    completedServiceAgreements.value = completedServiceAgreements.value || [];
    activeProjects.value = activeProjects.value || [];
    completedProjects.value = completedProjects.value || [];
  } finally {
    loadingState.value.requests = false;
  }
};

// Add pagination event handlers
const handlePageChange = async (tabValue: TabValue, newFirst: number) => {
  // Store previous state to detect if we need to fetch data
  const prevFirst = getPaginationForTab(tabValue).first;

  if (prevFirst === newFirst || isPageChanging.value) {
    // Skip if the page hasn't actually changed or if a page change is already in progress
    return;
  }

  // Set flag to prevent duplicate calls
  isPageChanging.value = true;

  // Calculate current page for logging
  const currentPage =
    Math.floor(newFirst / getPaginationForTab(tabValue).rows) + 1;
  console.log(
    `Page changed from ${Math.floor(prevFirst / getPaginationForTab(tabValue).rows) + 1} to ${currentPage}`,
  );

  // Update pagination state first
  updatePaginationForTab(tabValue, { first: newFirst });

  // Fetch data with new pagination settings
  try {
    await fetchAgreements(tabValue);
  } catch (error) {
    console.error("Error fetching data after page change:", error);
    // If there was an error, rollback to previous state
    updatePaginationForTab(tabValue, { first: prevFirst });
  } finally {
    // Reset flag after API call completes
    setTimeout(() => {
      isPageChanging.value = false;
    }, 50); // Short delay to prevent race conditions
  }
};

const handleRowsPerPageChange = async (tabValue: TabValue, newRows: number) => {
  // Reset to first page and update rows per page
  updatePaginationForTab(tabValue, { first: 0, rows: newRows });

  // Fetch data with new pagination settings
  await fetchAgreements(tabValue);
};

// Debounced versions of handlers
const debouncedHandlePageChange = debounce(
  (tabValue: TabValue, newFirst: number) => {
    if (!isPageChanging.value) {
      handlePageChange(tabValue, newFirst);
    }
  },
  50,
);

const debouncedHandleRowsPerPageChange = debounce(
  (tabValue: TabValue, newRows: number) => {
    if (!isPageChanging.value) {
      handleRowsPerPageChange(tabValue, newRows);
    }
  },
  50,
);

onMounted(() => {
  // Scroll to top smoothly
  scrollToTop();

  // Always initialize with tabList=active-service on initial load if not set
  if (!route.query.tabList) {
    router.replace({
      query: {
        tabList: TABS.ACTIVE_PROJECTS,
      },
    });
  }

  // Set initial state from URL or default
  const tabFromUrl = route.query.tabList?.toString();
  if (tabFromUrl && Object.values(TABS).includes(tabFromUrl as TabValue)) {
    activeTabValue.value = tabFromUrl as TabValue;
  }

  initializeData();

  // Set up periodic refresh
  const refreshInterval = window.setInterval(() => {
    if (shouldRefreshStats()) {
      fetchStats();
    }
  }, REFRESH_INTERVAL);

  // Add visibility change handling
  document.addEventListener("visibilitychange", handleVisibilityChange);

  // Cleanup
  onUnmounted(() => {
    clearInterval(refreshInterval);
    document.removeEventListener("visibilitychange", handleVisibilityChange);
  });
});

// Provide the activity stats to components that need it
provide(ACTIVITY_STATS_KEY, activityStats);

// Initialize the tender widgets composable
// This function is used by child components via injection
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { shouldShowNewAgreementWidget } = useTenderWidgets();

const formatTendersList = (tabValue: TabValue) => {
  // Get filtered agreements based on tab
  let agreements: Agreement[] = [];

  switch (tabValue) {
    case TABS.ACTIVE_SERVICE:
      agreements =
        filteredActiveServiceAgreements.value.length > 0 ||
        categoryFilters.value[tabValue] ||
        searchText.value.trim()
          ? filteredActiveServiceAgreements.value
          : activeServiceAgreements.value;
      break;
    case TABS.COMPLETED_SERVICE:
      agreements =
        filteredCompletedServiceAgreements.value.length > 0 ||
        categoryFilters.value[tabValue] ||
        searchText.value.trim()
          ? filteredCompletedServiceAgreements.value
          : completedServiceAgreements.value;
      break;
    case TABS.ACTIVE_PROJECTS:
      agreements =
        filteredActiveProjects.value.length > 0 ||
        categoryFilters.value[tabValue] ||
        searchText.value.trim()
          ? filteredActiveProjects.value
          : activeProjects.value;
      break;
    case TABS.COMPLETED_PROJECTS:
      agreements =
        filteredCompletedProjects.value.length > 0 ||
        categoryFilters.value[tabValue] ||
        searchText.value.trim()
          ? filteredCompletedProjects.value
          : completedProjects.value;
      break;
    default:
      return [];
  }

  if (!agreements.length) return [];

  const hasFilters = categoryFilters.value[tabValue] || searchText.value.trim();
  const totalCount = getAgreementsList(tabValue).length;
  const filteredCount = agreements.length;

  const title = hasFilters
    ? `${titles[tabValue]} (${filteredCount} / ${totalCount})`
    : `${titles[tabValue]} (${filteredCount})`;

  return [
    {
      title,
      tenders: agreements.map((agreement) => {
        // Transform Agreement to match BaseTenderData interface
        const tenderData = {
          priceRequestId: agreement.priceRequestId,
          createdOn: agreement.createdOn,
          tenderReference: agreement.tenderReference,
          integrationSource: agreement.integrationSource,
          deadline: agreement.deadline,
          customerName: agreement.customerName,
          customerAddress: agreement.customerPropertyAddress || "",
          customerPosition: agreement.customerPropertyCoordinates || "",
          categoryId: agreement.serviceCategoryId,
          categoryLabel: agreement.serviceCategoryLabel,
          supplierRequestId: agreement.acceptedSupplierRequestId,
          supplierRequestState: agreement.requestState,
          supplierId: agreement.supplierId,
          userResponsible: agreement.userResponsible,
          customerOrgNumber: agreement.customerOrgNumber,
          hasFrameAgreement: agreement.hasFrameAgreement,
          frameAgreementId: agreement.frameAgreementId,
          serviceTypeId: 0,
          serviceCategoryId: agreement.serviceCategoryId,
          customerPropertyId: agreement.customerPropertyId,
          customerPropertyTypeId: agreement.customerPropertyTypeId,
          customerPropertyTypeLabel: agreement.customerPropertyTypeLabel,
          serviceCategoryLabel: agreement.serviceCategoryLabel,
          serviceTypeLabel: agreement.serviceTypeLabel,
          requestType: agreement.requestType,
          wantedStartDate: agreement.wantedStartDate || undefined,
          surveyDateTime: agreement.surveyDateTime || undefined,
          supplierName: agreement.customerName,
          bookingNumber: agreement.bookingNumber,
          tenderId: agreement.tenderId,
          billingCycles: [agreement.billingCycles],
          unreadMessagesCount: agreement.unreadMessagesCount,
        };

        return mapToTenderFormat(tenderData, "agreement", supplierId.value);
      }),
    },
  ];
};

// Helper function to determine if a tab should show filters
const shouldShowFiltersForTab = (tabValue: TabValue): boolean => {
  // Only show filters for active tabs
  return tabValue === TABS.ACTIVE_SERVICE || tabValue === TABS.ACTIVE_PROJECTS;
};
</script>

<style lang="scss" scoped>
.agreements-view {
  margin: 0 2rem 3rem;

  &__header {
    margin-top: 1rem;
    display: flex;
    gap: 0.5rem;
    align-items: baseline;
    text-transform: uppercase;
    font-size: 2.5rem;
    font-weight: 500;
    color: $color-black;
  }

  :deep(.p-tablist-tab-list) {
    background: transparent;
  }

  :deep(.p-tabpanels) {
    padding: 0 0 2rem;
    background: transparent;
  }

  :deep(.p-tab) {
    position: relative;
    transition: all 0.2s ease-in-out;
    display: flex;
    align-items: anchor-center;
    gap: 4px;

    // Add dotted underline on hover
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      border-bottom: 1px dotted transparent;
      transition: border-color 0.2s ease-in-out;
    }

    &:hover::after {
      border-color: $color-primary;
    }
  }

  :deep(.p-tab-active) {
    color: $color-primary;
    border-color: $color-primary;
    border-bottom: 1px solid $color-primary !important;

    &::after {
      display: none; // Hide dotted underline for active tab
      border-bottom: 1px solid $color-primary !important;
    }
  }
}

:deep(.p-tablist-active-bar) {
  background-color: $color-primary;
}

.badge-container {
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
}

:deep(.custom-badge) {
  background-color: $color-primary;
  font-size: 0.75rem;
  font-weight: 500;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
}

:deep(.activity-badge.activity-dot) {
  background-color: $color-error;
  min-width: 0.5rem !important;
  height: 0.5rem !important;
  border-radius: 50%;
  padding: 0;
  position: absolute !important;
  top: 0.25rem;
  right: 0.25rem;
}

:deep(.p-tooltip.p-tooltip-bottom.tooltip-custom) {
  .p-tooltip-text {
    background-color: $color-primary;
    color: white;
  }

  .p-tooltip-arrow {
    border-bottom-color: $color-primary;
  }
}

.agreements-list-view {
  min-height: 100vh;
  padding-top: 1rem;
}

.paginator-container {
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
}

.pagination-info {
  display: inline-block;
  padding: 0 0.75rem;
  font-weight: 500;
  color: $color-black;
  min-width: 250px;
  text-align: center;
  margin: 0 1rem;
  align-self: center;
}

:deep(.p-paginator) {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;

  .p-paginator-page.p-highlight {
    background-color: $color-primary;
    color: white;
    font-weight: bold;
  }

  .p-paginator-element {
    margin: 0 0.1rem;
    min-width: 2.5rem;
    height: 2.5rem;

    &:focus {
      box-shadow: 0 0 0 2px rgba($color-primary, 0.2);
    }

    &:hover {
      background-color: rgba($color-primary, 0.1);
    }
  }
}

.all-items-container {
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.all-items-message {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: $color-black;
  border-radius: 0.25rem;
}

.filter-container {
  display: flex;
  justify-content: flex-start;
  padding: 1rem 0 0 0;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.filter-left {
  width: 200px;
}

.filter-middle {
  width: 300px;
}

.filter-right {
  flex: 1;
  max-width: 400px;
}

.sort-select,
.search-input,
.category-filter {
  width: 100%;
}

.search-input {
  height: 40px;
}

:deep(.p-dropdown) {
  width: 100%;
}

:deep(.p-dropdown-panel) {
  .p-dropdown-items {
    padding: 0.5rem 0;
  }

  .p-dropdown-item {
    padding: 0.5rem 1rem;

    &.p-highlight {
      background-color: rgba($color-primary, 0.1);
      color: $color-primary;
      font-weight: 500;
    }
  }
}
</style>
