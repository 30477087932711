// A simple cache service for agreement details
// This avoids refetching data when navigating between tabs

interface CacheItem<T> {
  data: T;
  timestamp: number;
}

class AgreementCacheService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private cache: Record<string, CacheItem<any>> = {};

  // Cache expiration time (30 minutes in milliseconds)
  private expirationTime = 30 * 60 * 1000;

  // Generate a cache key based on important identifiers
  private generateKey(
    tenderId: number,
    supplierId: number,
    serviceOfferId: number,
  ): string {
    return `agreement_${tenderId}_${supplierId}_${serviceOfferId}`;
  }

  // Store data in the cache
  set<T>(
    tenderId: number,
    supplierId: number,
    serviceOfferId: number,
    data: T,
  ): void {
    const key = this.generateKey(tenderId, supplierId, serviceOfferId);
    this.cache[key] = {
      data,
      timestamp: Date.now(),
    };
  }

  // Get data from the cache if it exists and is not expired
  get<T>(
    tenderId: number,
    supplierId: number,
    serviceOfferId: number,
  ): T | null {
    const key = this.generateKey(tenderId, supplierId, serviceOfferId);
    const item = this.cache[key];

    if (!item) return null;

    // Check if the cache item has expired
    if (Date.now() - item.timestamp > this.expirationTime) {
      delete this.cache[key];
      return null;
    }

    return item.data as T;
  }

  // Clear a specific item from the cache
  clear(tenderId: number, supplierId: number, serviceOfferId: number): void {
    const key = this.generateKey(tenderId, supplierId, serviceOfferId);
    delete this.cache[key];
  }

  // Clear all cached items
  clearAll(): void {
    this.cache = {};
  }
}

// Export a singleton instance
export const agreementCache = new AgreementCacheService();
