<template>
  <div class="offer-sent">
    <img :src="offerSentIconUrl" alt="Sent" />
    <div class="offer-sent__title">{{ offerSentCms.title }}</div>
    <div class="offer-sent__description-text">
      <custom-block-content :blocks="processedContent" />
    </div>
    <div class="offer-sent__okay-button">
      <primary-button @click="handlePrimaryButtonClick">
        {{ okayButtonText }}
      </primary-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import PrimaryButton from "@/components/Buttons/PrimaryButton.vue";
import CustomBlockContent from "@/components/CustomBlockContent/CustomBlockContent.vue";
import {
  getCmsItem,
  getCmsItemText,
  getIconUrl,
} from "@/utilities/cmsUtilities";
import type { SendOfferCmsContent } from "@/stores/tender/types";

interface Props {
  sendOfferPageCms: SendOfferCmsContent;
  customerOrgName: string;
}

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: "close"): void;
}>();

const offerSentCms = computed(
  () => getCmsItem(props.sendOfferPageCms.pageSections, "offerSent") || {},
);

const okayButtonText = computed(() =>
  getCmsItemText(offerSentCms.value.buttons, "okayButton"),
);

const offerSentIconUrl = computed(() =>
  getIconUrl(offerSentCms.value.icons, "offerSentIcon"),
);

const processedContent = computed(() => {
  const content = JSON.parse(JSON.stringify(offerSentCms.value.content || []));
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return content.map((block: any) => {
    if (block.children) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      block.children = block.children.map((child: any) => {
        if (child.text) {
          child.text = child.text.replace(
            "Eiendomsselskapet AS",
            props.customerOrgName,
          );
        }
        return child;
      });
    }
    return block;
  });
});

const handlePrimaryButtonClick = () => {
  emit("close");
};
</script>

<style lang="scss" scoped>
.offer-sent {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    font-size: $font-size-2xl;
    font-weight: 600;
  }

  &__description-text {
    margin-top: 2rem;
    text-align: center;
    font-size: $font-size-base;
  }

  &__okay-button {
    margin-top: 2.5rem;
  }
}
</style>
