<template>
  <div class="work-description">
    <div class="work-description__title">
      {{ title }}
    </div>
    <div class="work-description__description">
      <custom-block-content :blocks="descriptionContent" />
    </div>
    <div v-if="workDescriptions.length" class="work-description__list">
      <div
        v-for="(descriptionItem, index) in workDescriptions"
        :key="descriptionItem.id"
        class="work-description__list-item"
      >
        <work-description-item
          :count="index + 1"
          :title="descriptionItem.title"
          :body="descriptionItem.body"
          :read-only="readOnly"
          @edit-item="openNewDescriptionModal(descriptionItem)"
          @delete-item="onDeleteWorkDescriptionItem(descriptionItem)"
        />
      </div>
    </div>
    <div v-else class="work-description__empty-state">
      <empty-state text="Ingen beskrivelse lagt til" />
    </div>
    <div v-if="!readOnly" class="work-description__new-point-button">
      <secondary-button
        :icon-src="newPointButtonIcon"
        :text="newPointButtonText"
        @click="openNewDescriptionModal"
      />
    </div>
    <delete-work-description-item-modal
      v-if="state.workDescriptionToDelete"
      v-model="state.showDeleteWorkDescriptionModal"
      :supplier-id="supplierId"
      :supplier-request-id="supplierRequestId"
      :service-offer-id="serviceOfferId"
      :description-item="state.workDescriptionToDelete"
      @item-deleted="onWorkDescriptionItemDeleted"
    />
    <new-work-description-modal
      v-model="state.showNewWorkDescriptionModal"
      :supplier-id="supplierId"
      :supplier-request-id="supplierRequestId"
      :service-offer-id="serviceOfferId"
      :work-description="state.workDescriptionToEdit"
      :form-cms="newDescriptionFormCms"
      @new-description="handleNewWorkDescription"
      @add-new-work-description="state.workDescriptionToEdit = null"
    />
  </div>
</template>

<script setup>
import { computed, reactive } from "vue";
import EmptyState from "@/components/EmptyState/EmptyState.vue";
import SecondaryButton from "@/components/Buttons/SecondaryButton.vue";
import WorkDescriptionItem from "@/components/SupplierOfferWorkDescription/WorkDescriptionItem.vue";
import DeleteWorkDescriptionItemModal from "@/components/SupplierOfferWorkDescription/DeleteWorkDescriptionItemModal.vue";
import NewWorkDescriptionModal from "@/components/NewWorkDescriptionModal/NewWorkDescriptionModal.vue";
import CustomBlockContent from "@/components/CustomBlockContent/CustomBlockContent.vue";
import cmsService from "@/services/cmsService";

const emit = defineEmits(["new-description", "remove-description"]);
const props = defineProps({
  pageCms: {
    type: Object,
    required: true,
  },
  supplierId: {
    type: Number,
    required: true,
  },
  supplierRequestId: {
    type: Number,
    required: true,
  },
  serviceOfferId: {
    type: Number,
    required: true,
  },
  workDescriptions: {
    type: Array,
    required: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
});

const state = reactive({
  showNewWorkDescriptionModal: false,
  showDeleteWorkDescriptionModal: false,
  workDescriptionToDelete: undefined,
  workDescriptionToEdit: undefined,
  localDescriptions: [],
});

const newDescriptionFormCms = computed(() =>
  props.pageCms.forms.find((section) => section.label === "newDescriptionForm"),
);

const workDescriptionCms = computed(() =>
  props.pageCms.pageSections.find(
    (section) => section.label === "workDescription",
  ),
);

const title = computed(() => workDescriptionCms.value.title);
const descriptionContent = computed(() => workDescriptionCms.value.content);

const newPointButton = computed(() =>
  workDescriptionCms.value.buttons.find(
    (button) => button.label === "newPointButton",
  ),
);

const newPointButtonText = computed(() => newPointButton.value.title);
const newPointButtonIcon = computed(() =>
  cmsService.resolveImage(newPointButton.value.icons[0].icon).url(),
);

const handleNewWorkDescription = (newDescription) => {
  emit("new-description", newDescription);
};

const onWorkDescriptionItemDeleted = (descriptionItem) => {
  emit("remove-description", descriptionItem);
};

const onDeleteWorkDescriptionItem = (descriptionItem) => {
  state.workDescriptionToDelete = descriptionItem;
  state.showDeleteWorkDescriptionModal = true;
};

const openNewDescriptionModal = (workDescription = null) => {
  state.workDescriptionToEdit = workDescription;
  state.showNewWorkDescriptionModal = true;
};
</script>

<style lang="scss" scoped>
.work-description {
  &__title {
    font-weight: 600;
    font-size: $font-size-2xl;
  }

  &__description {
    margin: 0;
    font-weight: 400;
    font-size: $font-size-sm;
  }

  &__empty-state {
    margin-top: 1rem;
  }

  &__list {
    margin-top: 1rem;
  }

  &__list-item {
    &:not(:first-child) {
      margin-top: 1rem;
    }
  }

  &__new-point-button {
    margin-top: 1rem;
  }
}
</style>
