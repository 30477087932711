import { ref } from "vue";
import { useSupplierStore } from "@/stores/supplier";
import { useCmsStore } from "@/stores/cms";
import { useSupplierApi } from "@/services/api/useSupplierApi";
import { ContentType } from "@/custom-types/CmsContentTypes";
import type { ServiceCategory, ServiceType } from "@/stores/supplier/types";

export interface FormattedServiceType {
  id: number | undefined;
  title: string;
  label: string;
  categories: FormattedCategory[];
}

export interface FormattedCategory {
  title: string;
  label: string;
  id?: number;
}

interface CmsServiceType {
  label: string;
  name?: string;
  serviceCategories?: { label: string; title?: string }[];
}

interface ServiceTypeAndCategory {
  cmsServiceType: CmsServiceType | undefined;
  cmsServiceCategory: { label: string; title?: string } | undefined;
}

export const useServiceCategory = (supplierId: number) => {
  const supplierStore = useSupplierStore();
  const cmsStore = useCmsStore();
  const { getSupplierServiceCategories } = useSupplierApi();

  const supplierServiceTypes = ref<FormattedServiceType[]>([]);

  const fetchServiceCategories = async () => {
    try {
      const response = await getSupplierServiceCategories({ supplierId });
      const categories = response?.data;
      if (!categories) {
        console.log("No categories found");
        return;
      }
      const cms = cmsStore[ContentType.SERVICE_TYPES_CMS] as CmsServiceType[];
      const serviceTypes = supplierStore.serviceTypes;
      supplierServiceTypes.value = formatServiceTypeCategories(
        categories,
        cms,
        serviceTypes,
      );
    } catch (error) {
      console.error("Error fetching service categories:", error);
    }
  };

  const findMatchingCmsServiceType = (
    cms: CmsServiceType[],
    categoryLabel: string,
  ) => {
    if (!cms || !Array.isArray(cms)) return undefined;

    return cms.find((serviceType) =>
      serviceType.serviceCategories?.some((cat) => cat.label === categoryLabel),
    );
  };

  const findMatchingServiceType = (
    serviceTypes: ServiceType[],
    cmsServiceType: CmsServiceType,
  ) => {
    return serviceTypes.find(
      (serviceType) => serviceType.sysLabel === cmsServiceType.label,
    );
  };

  const findMatchingServiceCategory = (
    cmsServiceType: CmsServiceType,
    categoryLabel: string,
  ) => {
    return cmsServiceType.serviceCategories?.find(
      (cat) => cat.label === categoryLabel,
    );
  };

  const formatServiceTypeCategories = (
    categories: ServiceCategory[],
    cms: CmsServiceType[],
    serviceTypes: ServiceType[],
  ): FormattedServiceType[] => {
    if (!cms || !Array.isArray(cms) || !serviceTypes) {
      console.warn("CMS or serviceTypes data missing or invalid");
      return [];
    }

    const formatted = categories.reduce(
      (acc, category) => {
        const matchedCmsServiceType = findMatchingCmsServiceType(
          cms,
          category.categoryLabel,
        );
        if (!matchedCmsServiceType) return acc;

        const matchedServiceType = findMatchingServiceType(
          serviceTypes,
          matchedCmsServiceType,
        );
        if (!matchedServiceType) return acc;

        const key = matchedCmsServiceType.label;
        if (!acc[key]) {
          acc[key] = {
            id: matchedServiceType.id,
            title: matchedCmsServiceType.name || matchedCmsServiceType.label,
            label: matchedCmsServiceType.label,
            categories: [],
          };
        }

        const formattedCategory = {
          title:
            findMatchingServiceCategory(
              matchedCmsServiceType,
              category.categoryLabel,
            )?.title || category.categoryLabel,
          label: category.categoryLabel,
          id: category.id,
        };

        acc[key].categories.push(formattedCategory);
        return acc;
      },
      {} as Record<string, FormattedServiceType>,
    );

    return Object.values(formatted);
  };

  const getServiceTypeAndCategory = (
    label: string | undefined,
  ): ServiceTypeAndCategory => {
    if (!label) return getEmptyResult();

    const cms = cmsStore[ContentType.SERVICE_TYPES_CMS] as CmsServiceType[];
    const cmsServiceType = findMatchingCmsServiceType(cms, label);
    if (!cmsServiceType) return getEmptyResult();

    return {
      cmsServiceType,
      cmsServiceCategory: findMatchingServiceCategory(cmsServiceType, label),
    };
  };

  // Helper function for consistent empty results
  const getEmptyResult = (): ServiceTypeAndCategory => ({
    cmsServiceType: undefined,
    cmsServiceCategory: undefined,
  });

  return {
    supplierServiceTypes,
    fetchServiceCategories,
    getServiceTypeAndCategory,
  };
};
