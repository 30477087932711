export enum RouteNames {
  SUPPLIER_ADMIN = "supplierAdmin",
  MY_REQUESTS = "myRequests",
  MY_AGREEMENTS = "myAgreements",
  SUPPLIER_PROFILE = "supplierProfile",
  MY_REGISTRATIONS = "myRegistrations",
  CREATE_OFFER = "createOffer",
  EDIT_OFFER = "editOffer",
  TENDER_OFFER = "tenderOffer",
  LOADING = "loading",
  ERROR = "error",
  FILE_VIEWER = "fileViewer",
  USER_WELCOME = "userWelcome",
}
