import { executeApiRequest } from "@/services/api/apiService";
import {
  FinanceApi,
  FinanceApiEndpoints,
} from "@/config/api/finance/financeApi";
import type { InvoiceDTO } from "@/custom-types/GeneralTypes";
import { useToastErrorHandler } from "@/composables/useToastErrorHandler";

const useFinanceApiService = () => {
  const { withToastErrorHandling } = useToastErrorHandler();

  const getTenderInvoices = async (params: {
    supplierId: number;
    bookingNumber: string;
  }) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<InvoiceDTO[]>(
          {
            endpointKey: Symbol.for(FinanceApiEndpoints.GET_TENDER_INVOICES),
            config: FinanceApi,
          },
          {
            pathParams: [
              "Supplier",
              params.supplierId,
              "BookingNumber",
              params.bookingNumber,
              "Invoices",
            ],
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Økonomifeil",
          detail: "Kunne ikke hente fakturaer for anbudet",
        },
      },
    );
  };

  return {
    getTenderInvoices,
  };
};

export const useFinanceApi = useFinanceApiService;
