import { defineStore } from "pinia";
import {
  Supplier,
  ServiceCategory,
  SustainabilityGoal,
  Certification,
  SupplierAddress,
  ServiceType,
} from "./types";
import { useSupplierApi } from "@/services/api/useSupplierApi";
import { useSystemApi } from "@/services/api/useSystemApi";
import { useMsalAuthentication } from "@/composables/useMsalAuthentication";

// In-memory logo cache
interface LogoCache {
  [supplierId: number]: {
    logo: string;
    timestamp: number;
  };
}

export const useSupplierStore = defineStore("supplier", {
  state: () => ({
    supplier: null as Supplier | null,
    serviceTypes: [] as ServiceType[],
    serviceCategories: [] as ServiceCategory[],
    sustainabilityGoals: [] as SustainabilityGoal[],
    certifications: [] as Certification[],
    supplierAddresses: [] as SupplierAddress[],
    loading: false,
    error: null as string | null,
    logoCache: {} as LogoCache, // Add in-memory logo cache to the store
  }),
  actions: {
    // Save logo to in-memory cache
    saveLogo(supplierId: number, logo: string) {
      this.logoCache[supplierId] = {
        logo,
        timestamp: Date.now(),
      };
    },

    // Get logo from in-memory cache
    getLogo(supplierId: number): string | null {
      const cachedItem = this.logoCache[supplierId];
      if (!cachedItem) return null;

      // Check if cache is still valid (7 days = 604800000 ms)
      const SEVEN_DAYS_MS = 7 * 24 * 60 * 60 * 1000;
      if (Date.now() - cachedItem.timestamp > SEVEN_DAYS_MS) {
        // Cache expired, remove it
        delete this.logoCache[supplierId];
        return null;
      }

      return cachedItem.logo;
    },

    async fetchSupplierByUserId(userId: string) {
      this.loading = true;
      this.error = null;

      try {
        const { getSupplierByUserId } = useSupplierApi();
        const { signOut } = useMsalAuthentication();
        const response = await getSupplierByUserId(userId);
        if (!response) {
          return await signOut();
        }

        if (response.data) {
          this.supplier = response.data;
        }

        // Check if we need to fetch the logo
        if (this.supplier && this.supplier.id && !this.supplier.logo) {
          await this.fetchSupplierLogo(this.supplier.id);
        }
      } catch (error) {
        this.error = error instanceof Error ? error.message : "Unknown error";
      } finally {
        this.loading = false;
      }
    },

    async fetchSupplierLogo(supplierId: number) {
      if (!supplierId) return;

      // First check if we have it in the cache
      const cachedLogo = this.getLogo(supplierId);
      if (cachedLogo && this.supplier) {
        this.supplier.logo = cachedLogo;
        return;
      }

      try {
        const { getSupplierLogo } = useSupplierApi();
        const response = await getSupplierLogo(supplierId);

        if (!response || !response.data || !response.data.logo) {
          return;
        }

        // Save the logo to the supplier state
        if (this.supplier) {
          this.supplier.logo = response.data.logo;
        }

        // Cache the logo for future use
        this.saveLogo(supplierId, response.data.logo);
      } catch (error) {
        console.error("Failed to fetch supplier logo:", error);
      }
    },

    updateSupplier(supplier: Partial<Supplier>) {
      if (this.supplier) {
        this.supplier = { ...this.supplier, ...supplier };

        // If logo is updated, update the cache
        if (supplier.logo && this.supplier.id) {
          this.saveLogo(this.supplier.id, supplier.logo);
        }
      }
    },

    async fetchServiceTypes() {
      this.loading = true;
      this.error = null;

      try {
        const { getServiceTypes } = useSystemApi();
        const response = await getServiceTypes();
        if (!response) {
          return;
        }
        this.serviceTypes = response.data;
      } catch (error) {
        this.error = error instanceof Error ? error.message : "Unknown error";
      } finally {
        this.loading = false;
      }
    },
  },
});
