<template>
  <div class="service-offer-item" :class="containerClasses">
    <img
      :src="DocumentIcon"
      alt="Document icon"
      class="service-offer-item__icon"
    />

    <p class="service-offer-item__offer-title">
      {{ offerName }}
    </p>

    <div class="service-offer-item__amount-section">
      <p class="service-offer-item__price">
        {{ formattedPrice }}
      </p>
      <p class="service-offer-item__total-text">
        <span
          class="contract-value-text"
          tabindex="0"
          role="button"
          aria-label="Vis forklaring av kontraktsverdi"
          @click="togglePopover"
          @keydown.enter="togglePopover"
          >Kontraktsverdi</span
        >: {{ totalPriceText }} kr ekskl. mva

        <Popover ref="popover">
          <p class="popover-content">
            Kontraktsverdi er beregnet som faste kostnader + årlig verdi +
            månedlig verdi
          </p>
        </Popover>
      </p>
    </div>

    <hr class="service-offer-item__vertical-line" />

    <div class="service-offer-item__status-section" :class="offer.currentState">
      <p class="service-offer-item__offer-status">
        {{ offerStateLabel }}
      </p>
      <p class="service-offer-item__last-changed">
        {{ formattedDate }}
      </p>
    </div>

    <div class="service-offer-item__menu-section">
      <offer-actions-menu
        :tender="tender"
        :offer="offer"
        :supplier-id="supplierId"
        @item-action="(action: string) => $emit('item-action', action, offer)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import dayjs from "dayjs";
import { formatCurrency } from "@/utilities/numberUtils";
import { OfferStateType } from "@/custom-types/GeneralTypes";
import type { ServiceOffer } from "@/custom-types/GeneralTypes";
import type { Tender } from "@/stores/tender/types";
import OfferActionsMenu from "./OfferActionsMenu.vue";
import DocumentIcon from "@/assets/img/document.svg";
import Popover from "primevue/popover";

interface ServiceOfferItemProps {
  offer: ServiceOffer;
  tender: Tender;
  supplierId: number;
  frequencyOptions?: Record<string, unknown>;
  isFirst: boolean;
  isLast: boolean;
}

// interface ServiceOfferItemEmits {
//   (e: "send", offerId: number): void;
//   (e: "delete", offerId: number): void;
//   (e: "withdraw", offerId: number): void;
//   (e: "edit", offerId: number): void;
//   (e: "copy", offer: ServiceOffer): void;
//   (e: "view-agreement", offerId: number): void;
// }

const props = withDefaults(defineProps<ServiceOfferItemProps>(), {
  frequencyOptions: () => ({}),
});

// const emit = defineEmits<ServiceOfferItemEmits>();

defineEmits<{
  (e: "item-action", action: string, offer: ServiceOffer): void;
}>();

// Computed properties
const containerClasses = computed(() => ({
  first: props.isFirst,
  last: props.isLast,
}));

const offerName = computed(() => {
  const defaultName = "Tilbud på tjenestetype";
  return props.offer?.nickname ?? defaultName;
});

const formattedPrice = computed(() => {
  const price = props.offer?.yearOneAgreementValue ?? 0;
  if (!price) return "Ingen pris angitt";
  return `${formatCurrency(price)} kr ekskl. mva`;
});

const totalPriceText = computed(() => {
  const price = props.offer?.yearOneAgreementValue ?? 0;
  return `${formatCurrency(price)}`;
});

const offerStateLabel = computed(() => {
  const stateMapping = {
    [OfferStateType.DRAFT]: "Utkast",
    [OfferStateType.WITHDRAWN]: "Trukket tilbake",
    [OfferStateType.OFFERED]: "Sendt til kunde",
    [OfferStateType.ACCEPTED]: "Signert avtale",
    [OfferStateType.REJECTED]: "Avvist av kunde",
    [OfferStateType.COMPLETED]: "Utført avtale",
    [OfferStateType.LOST]: "Tapt",
  };

  return stateMapping[props.offer?.currentState ?? OfferStateType.DRAFT];
});

const formattedDate = computed(() => {
  const date = props.offer?.lastStateChange;
  return date ? dayjs(date).format("HH:mm:ss - DD.MM.YYYY") : "";
});

// Event handlers
// const handleSend = () => emit("send", props.offer.serviceOfferId);
// const handleDelete = () => emit("delete", props.offer.serviceOfferId);
// const handleWithdraw = () => emit("withdraw", props.offer.serviceOfferId);
// const handleEdit = () => emit("edit", props.offer.serviceOfferId);
// const handleCopy = () => emit("copy", props.offer);
// const handleViewAgreement = () =>
//   emit("view-agreement", props.offer.serviceOfferId);

const popover = ref<InstanceType<typeof Popover>>();
const togglePopover = (event: Event) => {
  popover.value?.toggle(event);
};

// const handleMenuAction = (action: string) => {
//   const actionHandlers: Record<string, () => void> = {
//     "send-to-customer": handleSend,
//     "delete-draft": handleDelete,
//     "revoke-offer": handleWithdraw,
//     edit: handleEdit,
//     copy: handleCopy,
//     "see-agreement": handleViewAgreement,
//   };

//   actionHandlers[action]?.();
// };
</script>

<style lang="scss" scoped>
.service-offer-item {
  $spacing: 1rem;
  $border-radius: 0.25rem;
  $border-color: #9a9a9a;
  $primary-color: #000000;
  $secondary-color: #787878;

  display: flex;
  align-items: center;
  padding: $spacing;
  background: #fdfbfa;
  border: 1px solid $border-color;

  &__icon,
  &__offer-title,
  &__price {
    margin-left: $spacing;
  }

  &__offer-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 22rem;
    text-align: left;
    font-weight: 600;
    font-size: 16px;
    color: $primary-color;
    margin-bottom: 0;
  }

  &__amount-section {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    text-align: right;
  }

  &__price,
  &__offer-status {
    font-weight: 600;
    font-size: 14px;
    color: $primary-color;
    margin-bottom: 0;
  }

  &__total-text,
  &__last-changed {
    font-weight: 400;
    font-size: 14px;
    color: $secondary-color;
    margin-bottom: 0;
  }

  &__vertical-line {
    width: 1px;
    height: 2.5rem;
    background: $border-color;
    margin: 0 $spacing;
  }

  &__status-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 0.5rem;
    width: 14rem;
    height: 3rem;
    border-radius: $border-radius;

    &.draft {
      background: #f4ede4;
    }
    &.offered {
      background: #f7e2b5;
    }
    &.withdrawn,
    &.rejected,
    &.lost {
      background: #f4e4e4;
    }
    &.accepted,
    &.completed {
      background: #8cd0ac;
    }
  }

  &__menu-section {
    margin-left: $spacing;
  }

  &.first {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  &.last {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}

.contract-value-text {
  position: relative;
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    bottom: -3px;
    left: 0;
    background: #7c3373;
  }

  &:hover::after {
    height: 4px;
  }
}

.popover-content {
  padding: 0.5rem;
  max-width: 300px;
  margin: 0;
  font-size: 12px;
}
</style>
