import { ref } from "vue";
import { useTenderApi } from "@/services/api/useTenderApi";

export const useFrequencyOptions = () => {
  const { getActivityFormFrequencyOptions } = useTenderApi();
  const frequencyOptions = ref<string[] | null>(null);

  const fetchActivityFormFrequencyOptions = async (): Promise<void> => {
    try {
      const response = await getActivityFormFrequencyOptions();
      frequencyOptions.value = response.data;
    } catch (error) {
      console.error("Error fetching activity form frequency options:", error);
      frequencyOptions.value = null;
    }
  };

  return {
    frequencyOptions,
    fetchActivityFormFrequencyOptions,
  };
};
