import { RouteLocationNormalizedLoaded } from "vue-router";
import { parseCssStringToPx } from "@/utilities/stringUtils";
import scssVariables from "@/assets/scss/_variables_alias.module.scss";

export const useTenderScroll = (route: RouteLocationNormalizedLoaded) => {
  const scrollToRow = (id: number): void => {
    const element = document.querySelector(`#row-${id}`);
    if (!element) return;

    const appHeaderHeight =
      parseCssStringToPx(scssVariables.headerTopBarHeight ?? "0px") +
      parseCssStringToPx(scssVariables.headerBottomBarHeight ?? "0px");
    const offsetFromHeader = 20;

    window.scrollTo({
      behavior: "smooth",
      top:
        element.getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        (appHeaderHeight + offsetFromHeader),
    });
  };

  const handleScrollToRow = (): void => {
    const priceRequestId = parseInt(route.query.priceRequestId as string);
    scrollToRow(priceRequestId);
  };

  return {
    scrollToRow,
    handleScrollToRow,
  };
};
