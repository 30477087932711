<template>
  <div class="requests-view">
    <div class="requests-view__header">Mine forespørsler</div>
    <Tabs
      :value="activeTabValue"
      :lazy="true"
      :scrollable="false"
      :show-navigators="false"
      :select-on-focus="true"
      @update:value="handleTabChange"
    >
      <TabList>
        <Tab
          v-for="(tab, index) in tabs"
          :key="index"
          v-tooltip.bottom="{
            value: getMessageTooltip(tab.value as TabValue),
            showDelay: 250,
            hideDelay: 150,
            class: 'tooltip-custom',
          }"
          :value="tab.value"
          :disabled="tab.disabled"
          :aria-label="getMessageTooltip(tab.value as TabValue)"
          @click="handleTabListChange(TAB_TO_TYPE_MAP[tab.value as TabValue])"
        >
          {{ tab.label }}
          <div class="badge-container ml-2">
            <Badge
              v-for="badge in getBadges(tab.value as TabValue)"
              :key="badge.value"
              :value="badge.value"
              :class="badge.class"
              :severity="badge.severity"
            />
          </div>
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel v-for="tab in tabs" :key="tab.value" :value="tab.value">
          <div
            v-if="
              asTabValue(tab.value) === TABS.NEW ||
              asTabValue(tab.value) === TABS.ACTIVE
            "
            class="filter-container"
          >
            <div class="filter-left">
              <Select
                v-model="sortOption"
                :options="availableSortOptions"
                option-label="label"
                option-value="id"
                placeholder="Sorter"
                class="sort-select"
                @change="handleSortChange"
              />
            </div>
            <div class="filter-middle">
              <Select
                v-model="categoryFilters[asTabValue(tab.value)]"
                v-tooltip.bottom="{
                  value: 'Filtrer etter tjenestekategori',
                  showDelay: 250,
                  hideDelay: 150,
                  class: 'tooltip-custom',
                }"
                :options="categoryOptions"
                filter
                option-label="title"
                placeholder="Filtrer etter tjenestekategori"
                filter-placeholder="Søk"
                class="category-filter"
                show-clear
                :loading="loadingState.requests"
                empty-filter-message="Ingen resultater funnet"
                empty-message="Ingen resultater funnet"
                @change="handleCategoryFilterChange"
              >
                <template #value="slotProps">
                  <div v-if="slotProps.value" class="flex items-center">
                    <div>{{ slotProps.value.title }}</div>
                  </div>
                  <span v-else>
                    {{ slotProps.placeholder }}
                  </span>
                </template>
              </Select>
            </div>
            <div class="filter-right">
              <IconField>
                <InputIcon class="pi pi-search" />
                <InputText
                  v-model="searchText"
                  placeholder="Søk"
                  class="search-input"
                  @input="handleSearchTextChange"
                />
              </IconField>
            </div>
          </div>
          <RequestList
            :loading="loadingState.requests"
            :error="requestErrors[asTabValue(tab.value)]"
            :items="getRequestsForTab(asTabValue(tab.value))"
            :title="getTabTitle(asTabValue(tab.value))"
            :supplier-id="supplierId"
            @retry="() => handleRetry(asTabValue(tab.value))"
            @reload-tender-list="handleReloadTenderList"
          />
          <div
            v-if="
              !loadingState.requests &&
              getRequestsForTab(asTabValue(tab.value)).length > 0 &&
              (asTabValue(tab.value) === TABS.NEW ||
                asTabValue(tab.value) === TABS.ACTIVE)
            "
            class="all-items-container"
          >
            <div
              v-if="categoryFilters[asTabValue(tab.value)] || searchText.trim()"
              class="all-items-message"
            >
              Viser
              {{ getRequestsForTab(asTabValue(tab.value)).length }}
              av {{ getTotalCountForTab(asTabValue(tab.value)) }} elementer
            </div>
            <div v-else class="all-items-message">
              Viser alle
              {{ getRequestsForTab(asTabValue(tab.value)).length }} elementer
            </div>
          </div>
          <div
            v-if="
              !loadingState.requests &&
              getRequestsForTab(asTabValue(tab.value)).length > 0 &&
              (asTabValue(tab.value) === TABS.LOST ||
                asTabValue(tab.value) === TABS.ARCHIVED) &&
              getPaginationForTab(asTabValue(tab.value)).totalRecords <= 10
            "
            class="all-items-container"
          >
            <div
              v-if="categoryFilters[asTabValue(tab.value)] || searchText.trim()"
              class="all-items-message"
            >
              Viser
              {{ getRequestsForTab(asTabValue(tab.value)).length }}
              av {{ getTotalCountForTab(asTabValue(tab.value)) }} elementer
            </div>
            <div v-else class="all-items-message">
              Viser alle
              {{ getRequestsForTab(asTabValue(tab.value)).length }} elementer
            </div>
          </div>
          <div
            v-if="
              !loadingState.requests &&
              getRequestsForTab(asTabValue(tab.value)).length > 0 &&
              (asTabValue(tab.value) === TABS.LOST ||
                asTabValue(tab.value) === TABS.ARCHIVED) &&
              getPaginationForTab(asTabValue(tab.value)).totalRecords >= 11
            "
            class="paginator-container"
          >
            <Paginator
              :first="getPaginationForTab(asTabValue(tab.value)).first"
              :rows="getPaginationForTab(asTabValue(tab.value)).rows"
              :total-records="
                getPaginationForTab(asTabValue(tab.value)).totalRecords
              "
              :rows-per-page-options="[5, 10, 20, 50]"
              template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              @update:first="
                (value) =>
                  debouncedHandlePageChange(asTabValue(tab.value), value)
              "
              @update:rows="
                (value) =>
                  debouncedHandleRowsPerPageChange(asTabValue(tab.value), value)
              "
            >
              <template #start>
                <div class="pagination-info">
                  {{ getPaginationTextForTab(asTabValue(tab.value)) }}
                </div>
              </template>
            </Paginator>
          </div>
        </TabPanel>
      </TabPanels>
    </Tabs>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, onUnmounted, watch } from "vue";
import { useTenderApi } from "@/services/api/useTenderApi";
import { useLastVisitTime } from "@/composables/useLastVisitTime";
import { useRouter, useRoute } from "vue-router";
import Tabs from "primevue/tabs";
import TabList from "primevue/tablist";
import Tab from "primevue/tab";
import TabPanels from "primevue/tabpanels";
import TabPanel from "primevue/tabpanel";
import Badge from "primevue/badge";
import type { ActiveRequest } from "@/stores/tender/types";
import { mapToTenderFormat } from "@/utilities/tenderMapper";
import { debounce } from "lodash";
import { format } from "date-fns";
import dayjs from "dayjs";
import { useCommunicationApi } from "@/services/api/useCommunicationApi";
import type {
  UnreadMessageStatistics,
  ActivityStats,
} from "@/stores/communication/types";
import { RequestStateType } from "@/custom-types/GeneralTypes";
import RequestList from "@/components/MyTenders/RequestList.vue";
import { useFeedbackCookie } from "@/utilities/useFeedbackCookie";
import { useSupplierStore } from "@/stores/supplier";
import Paginator from "primevue/paginator";
import Select from "primevue/select";
import InputText from "primevue/inputtext";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import { useServiceCategory } from "@/composables/useServiceCategory";
import { useCmsStore } from "@/stores/cms";
import { ContentType } from "@/custom-types/CmsContentTypes";
import { ServiceType } from "@/stores/cms/types";

const supplierStore = useSupplierStore();
const {
  getActiveRequests,
  getLostRequests,
  getArchivedRequests,
  getRequestActivityStats,
} = useTenderApi();
const { getFromTime, updateLastVisitTime } = useLastVisitTime();
const router = useRouter();
const route = useRoute();

// Constants
const REFRESH_INTERVAL = 5 * 60 * 1000; // 5 minutes

const TABS = {
  NEW: "0",
  ACTIVE: "1",
  LOST: "2",
  ARCHIVED: "3",
} as const;

type TabValue = (typeof TABS)[keyof typeof TABS];

const TAB_TO_TYPE_MAP: Record<
  TabValue,
  "new" | "active" | "lost" | "archived"
> = {
  [TABS.NEW]: "new",
  [TABS.ACTIVE]: "active",
  [TABS.LOST]: "lost",
  [TABS.ARCHIVED]: "archived",
};

// State management
interface LoadingState {
  requests: boolean;
  stats: boolean;
}

const loadingState = ref<LoadingState>({
  requests: true,
  stats: true,
});

// URL parameter handling
const currentTabList = computed(() => route.query.tabList?.toString() || "new");
const currentTab = computed(
  () => route.query.tab?.toString() || "priceRequest",
);
const activeTabValue = ref<string>(TABS.NEW);

// Define type for pagination state
interface PaginationSettings {
  first: number;
  rows: number;
  totalRecords: number;
}

// Pagination state - using individual refs for each tab to avoid type issues
const newTabPagination = ref<PaginationSettings>({
  first: 0,
  rows: 10,
  totalRecords: 0,
});

const activeTabPagination = ref<PaginationSettings>({
  first: 0,
  rows: 10,
  totalRecords: 0,
});

const lostTabPagination = ref<PaginationSettings>({
  first: 0,
  rows: 10,
  totalRecords: 0,
});

const archivedTabPagination = ref<PaginationSettings>({
  first: 0,
  rows: 10,
  totalRecords: 0,
});

// Helper function to get the pagination state for a tab
const getPaginationForTab = (tabValue: TabValue): PaginationSettings => {
  switch (tabValue) {
    case TABS.NEW:
      return newTabPagination.value;
    case TABS.ACTIVE:
      return activeTabPagination.value;
    case TABS.LOST:
      return lostTabPagination.value;
    case TABS.ARCHIVED:
      return archivedTabPagination.value;
  }
};

// Helper function to update the pagination state for a tab
const updatePaginationForTab = (
  tabValue: TabValue,
  update: Partial<PaginationSettings>,
): void => {
  switch (tabValue) {
    case TABS.NEW:
      Object.assign(newTabPagination.value, update);
      break;
    case TABS.ACTIVE:
      Object.assign(activeTabPagination.value, update);
      break;
    case TABS.LOST:
      Object.assign(lostTabPagination.value, update);
      break;
    case TABS.ARCHIVED:
      Object.assign(archivedTabPagination.value, update);
      break;
  }
};

const activeRequests = ref<ActiveRequest[]>([]);
const lostRequests = ref<ActiveRequest[]>([]);
const archivedRequests = ref<ActiveRequest[]>([]);
const messageStats = ref<UnreadMessageStatistics>({
  "InformationRequested-project": 0,
  "Offered-project": 0,
  "PriceRequested-project": 0,
  "Rejected-project": 0,
  "Offered-service": 0,
  "Rejected-service": 0,
  "Accepted-project": 0,
  "Accepted-service": 0,
  "Completed-project": 0,
  "Completed-service": 0,
});
const activityStats = ref<ActivityStats[]>([]);
const lastStatsRefresh = ref<number>(0);
const statsError = ref<string | null>(null);

// Add error tracking per tab
const requestErrors = ref<Record<TabValue, string | undefined>>({
  [TABS.NEW]: undefined,
  [TABS.ACTIVE]: undefined,
  [TABS.LOST]: undefined,
  [TABS.ARCHIVED]: undefined,
});

// Tabs configuration
const tabs = [
  { label: "Nye", value: TABS.NEW, disabled: false },
  { label: "Aktive", value: TABS.ACTIVE, disabled: false },
  { label: "Tapt", value: TABS.LOST, disabled: false },
  { label: "Arkiv", value: TABS.ARCHIVED, disabled: false },
];

// Store getters
const supplier = computed(() => supplierStore.supplier);
const supplierId = computed(() => {
  if (!supplier.value?.id) return 0;
  return supplier.value.id;
});
// Update the destructuring of useFeedbackCookie
const {
  hasNewRequestBeenViewed,
  setNewRequestViewed,
  hasLostRequestBeenViewed,
  hasArchivedRequestBeenViewed,
  setLostRequestViewed,
  setArchivedRequestViewed,
} = useFeedbackCookie();

// Add after other refs
const viewedRequests = ref<Set<number | string>>(new Set());

// Category filtering
interface CategoryOption {
  id: number;
  label: string;
  title: string;
}

const { fetchServiceCategories } = useServiceCategory(supplierId.value || 0);

// Sorting functionality
enum TenderSortOptions {
  NEWEST_FIRST = "newest",
  OLDEST_FIRST = "oldest",
  SERVICE = "service",
  ADDRESS = "address",
}

interface SortOption {
  label: string;
  id: TenderSortOptions | null;
}

const availableSortOptions: SortOption[] = [
  { label: "Sorter", id: null },
  { label: "Adresse", id: TenderSortOptions.ADDRESS },
  { label: "Eldste først", id: TenderSortOptions.OLDEST_FIRST },
  { label: "Nyeste først", id: TenderSortOptions.NEWEST_FIRST },
  { label: "Tjeneste", id: TenderSortOptions.SERVICE },
];

const sortOption = ref<TenderSortOptions | null>(null);

// Sorting helper functions
const sortByDate = (
  dateStrA: string | null | undefined,
  dateStrB: string | null | undefined,
  oldestFirst: boolean,
): number => {
  const dateA = dayjs(dateStrA);
  const dateB = dayjs(dateStrB);
  if (!dateA.isValid() || !dateB.isValid()) {
    return 0;
  }
  const oldestFirstCriteria =
    dateA.toDate().getTime() - dateB.toDate().getTime();
  return oldestFirst ? oldestFirstCriteria : -1 * oldestFirstCriteria;
};

const getServiceCategoryName = (
  categoryLabel: string,
  cms: ServiceType[],
): string => {
  // Find the matching service type from CMS
  const cmsServiceType = cms.find((serviceType) =>
    serviceType.serviceCategories?.some((cat) => cat.label === categoryLabel),
  );

  // Find the matching category to get its title
  const categoryFromCms = cmsServiceType?.serviceCategories?.find(
    (cat) => cat.label === categoryLabel,
  );

  // Use the title from CMS if available, otherwise use the label
  return categoryFromCms?.title || categoryLabel;
};

const sortRequests = (
  requests: ActiveRequest[],
  selectedSortOption: TenderSortOptions | null,
): ActiveRequest[] => {
  if (!requests.length || !selectedSortOption) {
    return requests;
  }

  const cmsStore = useCmsStore();
  const cms = cmsStore[ContentType.SERVICE_TYPES_CMS] as ServiceType[];

  return [...requests].sort((requestA, requestB) => {
    if (
      selectedSortOption === TenderSortOptions.OLDEST_FIRST ||
      selectedSortOption === TenderSortOptions.NEWEST_FIRST
    ) {
      return sortByDate(
        requestA.createdOn,
        requestB.createdOn,
        selectedSortOption === TenderSortOptions.OLDEST_FIRST,
      );
    }

    if (selectedSortOption === TenderSortOptions.SERVICE) {
      const serviceA = getServiceCategoryName(requestA.categoryLabel, cms);
      const serviceB = getServiceCategoryName(requestB.categoryLabel, cms);
      return serviceA.localeCompare(serviceB);
    }

    if (selectedSortOption === TenderSortOptions.ADDRESS) {
      return (requestA.customerAddress || "").localeCompare(
        requestB.customerAddress || "",
      );
    }

    return 0;
  });
};

// Handle sort change
const handleSortChange = () => {
  // Apply sorting and filters for the active tab
  applyFiltersAndSort(activeTabValue.value as TabValue);
};

// Create a ref for filtered requests per tab
const filteredNewRequests = ref<ActiveRequest[]>([]);
const filteredActiveRequests = ref<ActiveRequest[]>([]);
const filteredLostRequests = ref<ActiveRequest[]>([]);
const filteredArchivedRequests = ref<ActiveRequest[]>([]);

// Maintain selected category filter for each tab
const categoryFilters = ref<Record<TabValue, CategoryOption | null>>({
  [TABS.NEW]: null,
  [TABS.ACTIVE]: null,
  [TABS.LOST]: null,
  [TABS.ARCHIVED]: null,
});

// Function to extract unique categories from requests
const extractCategories = (requests: ActiveRequest[]): CategoryOption[] => {
  const uniqueCategories = new Map<string, CategoryOption>();

  // Get CMS data for translations
  const cmsStore = useCmsStore();
  const cms = cmsStore.serviceTypesCms as ServiceType[];

  if (!cms) {
    console.warn("CMS data missing for service categories");
    return [];
  }

  requests.forEach((request) => {
    if (request.categoryLabel && !uniqueCategories.has(request.categoryLabel)) {
      // Find the matching service type from CMS
      const cmsServiceType = cms.find((serviceType) =>
        serviceType.serviceCategories?.some(
          (cat) => cat.label === request.categoryLabel,
        ),
      );

      // Find the matching category to get its title
      const categoryFromCms = cmsServiceType?.serviceCategories?.find(
        (cat) => cat.label === request.categoryLabel,
      );

      // Use the title from CMS if available, otherwise use the label
      const translatedTitle = categoryFromCms?.title || request.categoryLabel;

      uniqueCategories.set(request.categoryLabel, {
        id: request.categoryId,
        label: request.categoryLabel,
        title: translatedTitle,
      });
    }
  });

  return Array.from(uniqueCategories.values()).sort((a, b) =>
    a.title.localeCompare(b.title),
  );
};

// Computed property to get all unique categories across all requests
const categoryOptions = computed((): CategoryOption[] => {
  const allCategories = [
    ...extractCategories(activeRequests.value),
    ...extractCategories(lostRequests.value),
    ...extractCategories(archivedRequests.value),
  ];

  // Remove duplicates by using Map with category label as key
  const uniqueCategories = new Map<string, CategoryOption>();
  allCategories.forEach((category) => {
    if (!uniqueCategories.has(category.label)) {
      uniqueCategories.set(category.label, category);
    }
  });

  return Array.from(uniqueCategories.values()).sort((a, b) =>
    a.title.localeCompare(b.title),
  );
});

// Handle category filter change
const handleCategoryFilterChange = debounce(() => {
  // Apply filters and sorting for the active tab
  applyFiltersAndSort(activeTabValue.value as TabValue);
}, 300);

// Add searchText ref and filtering functionality
const searchText = ref("");

const handleSearchTextChange = debounce(() => {
  // Apply search filter and sort for the active tab
  applyFiltersAndSort(activeTabValue.value as TabValue);
}, 300);

// Update applyFiltersAndSort function to include search filtering
const applyFiltersAndSort = (tabValue: TabValue) => {
  const selectedCategory = categoryFilters.value[tabValue];
  const selectedSortOption = sortOption.value;
  const searchQuery = searchText.value.trim().toLowerCase();

  // Declare filtered variable outside switch statement
  let filtered: ActiveRequest[] = [];

  switch (tabValue) {
    case TABS.NEW:
      // First apply search filter
      filtered = searchQuery
        ? sortedNewRequests.value.filter((request) =>
            [
              (request.tenderReference || "").toString().toLowerCase(),
              (request.customerAddress || "").toLowerCase(),
              (request.customerName || "").toLowerCase(),
            ].some((field) => field.includes(searchQuery)),
          )
        : sortedNewRequests.value;

      // Then apply category filter
      filtered = selectedCategory
        ? filtered.filter(
            (request) => request.categoryLabel === selectedCategory.label,
          )
        : filtered;

      // Then apply sorting if needed
      filteredNewRequests.value = selectedSortOption
        ? sortRequests(filtered, selectedSortOption)
        : filtered;
      break;
    case TABS.ACTIVE:
      // First apply search filter
      filtered = searchQuery
        ? sortedActiveRequests.value.filter((request) =>
            [
              (request.tenderReference || "").toString().toLowerCase(),
              (request.customerAddress || "").toLowerCase(),
              (request.customerName || "").toLowerCase(),
            ].some((field) => field.includes(searchQuery)),
          )
        : sortedActiveRequests.value;

      // Then apply category filter
      filtered = selectedCategory
        ? filtered.filter(
            (request) => request.categoryLabel === selectedCategory.label,
          )
        : filtered;

      // Then apply sorting if needed
      filteredActiveRequests.value = selectedSortOption
        ? sortRequests(filtered, selectedSortOption)
        : filtered;
      break;
    case TABS.LOST:
      // First apply search filter
      filtered = searchQuery
        ? lostRequests.value.filter((request) =>
            [
              (request.tenderReference || "").toString().toLowerCase(),
              (request.customerAddress || "").toLowerCase(),
              (request.customerName || "").toLowerCase(),
            ].some((field) => field.includes(searchQuery)),
          )
        : lostRequests.value;

      // Then apply category filter
      filtered = selectedCategory
        ? filtered.filter(
            (request) => request.categoryLabel === selectedCategory.label,
          )
        : filtered;

      // Then apply sorting if needed
      filteredLostRequests.value = selectedSortOption
        ? sortRequests(filtered, selectedSortOption)
        : filtered;
      break;
    case TABS.ARCHIVED:
      // First apply search filter
      filtered = searchQuery
        ? archivedRequests.value.filter((request) =>
            [
              (request.tenderReference || "").toString().toLowerCase(),
              (request.customerAddress || "").toLowerCase(),
              (request.customerName || "").toLowerCase(),
            ].some((field) => field.includes(searchQuery)),
          )
        : archivedRequests.value;

      // Then apply category filter
      filtered = selectedCategory
        ? filtered.filter(
            (request) => request.categoryLabel === selectedCategory.label,
          )
        : filtered;

      // Then apply sorting if needed
      filteredArchivedRequests.value = selectedSortOption
        ? sortRequests(filtered, selectedSortOption)
        : filtered;
      break;
  }
};

// Watch for search text changes
watch(searchText, () => {
  applyFiltersAndSort(activeTabValue.value as TabValue);
});

// Watch for changes in request data and reapply filters and sorting
watch([activeRequests, lostRequests, archivedRequests], () => {
  applyFiltersAndSort(TABS.NEW);
  applyFiltersAndSort(TABS.ACTIVE);
  applyFiltersAndSort(TABS.LOST);
  applyFiltersAndSort(TABS.ARCHIVED);
});

// Watch for tab changes to ensure filters and sorting are applied
watch(activeTabValue, (newTabValue) => {
  if (typeof newTabValue === "string") {
    applyFiltersAndSort(newTabValue as TabValue);
  }
});

// Watch for sort option changes
watch(sortOption, () => {
  applyFiltersAndSort(activeTabValue.value as TabValue);
});

// Add a flag to prevent duplicate API calls
const isPageChanging = ref(false);

// Add a record to store total counts for each tab
const tabTotalCounts = ref<Record<TabValue, number | null>>({
  [TABS.NEW]: null,
  [TABS.ACTIVE]: null,
  [TABS.LOST]: null,
  [TABS.ARCHIVED]: null,
});

// Update navigation handlers
const handleTabListChange = (tabList: string) => {
  const newTabValue = Object.keys(TAB_TO_TYPE_MAP).find(
    (key) => TAB_TO_TYPE_MAP[key as TabValue] === tabList,
  ) as TabValue;

  if (newTabValue) {
    handleTabChange(newTabValue);
  }
};

// Add refresh control
const shouldRefreshStats = (): boolean => {
  return Date.now() - lastStatsRefresh.value > REFRESH_INTERVAL;
};

// Split active requests into new and active
const newRequests = computed(() => {
  if (!activeRequests.value.length) return [];

  // For the New tab, we're already filtering in fetchActiveRequests
  // but we'll filter here too to be sure
  if (activeTabValue.value === TABS.NEW) {
    return activeRequests.value;
  }

  // If we're not on the New tab, filter out only the price requested items
  return activeRequests.value.filter(
    (request) =>
      request.supplierRequestState === RequestStateType.PRICE_REQUESTED,
  );
});

const sortedNewRequests = computed(() => {
  // The items are already sorted in fetchActiveRequests, but we'll sort again to be sure
  return [...newRequests.value].sort(
    (a, b) => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime(),
  );
});

const newRequestsList = computed(() => {
  // If there are no items after filtering, return an empty list
  if (searchText.value.trim() && filteredNewRequests.value.length === 0) {
    return [
      {
        title: `Nye forespørsler (0)`,
        tenders: [],
      },
    ];
  }

  return [
    {
      title: `Nye forespørsler (${sortedNewRequests.value.length})`,
      tenders: (filteredNewRequests.value.length > 0 ||
      categoryFilters.value[TABS.NEW] !== null ||
      searchText.value.trim()
        ? filteredNewRequests.value
        : sortedNewRequests.value
      ).map((request) =>
        mapToTenderFormat(request, "request", supplierId.value),
      ),
    },
  ];
});

const sortedActiveRequests = computed(() => {
  if (!activeRequests.value.length) return [];

  // For the Active tab, we're already filtering in fetchActiveRequests
  // but we'll filter here too to be sure
  if (activeTabValue.value === TABS.ACTIVE) {
    return activeRequests.value;
  }

  // If we're not on the Active tab, filter only the information requested and offered items
  const filteredRequests = activeRequests.value.filter(
    (request) =>
      request.supplierRequestState === RequestStateType.INFORMATION_REQUESTED ||
      request.supplierRequestState === RequestStateType.OFFERED,
  );

  // The items are already sorted in fetchActiveRequests, but we'll sort again to be sure
  return filteredRequests.sort(
    (a, b) => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime(),
  );
});

const activeRequestsList = computed(() => {
  // If there are no items after filtering, return an empty list
  if (searchText.value.trim() && filteredActiveRequests.value.length === 0) {
    return [
      {
        title: `Aktive forespørsler (0)`,
        tenders: [],
      },
    ];
  }

  return [
    {
      title: `Aktive forespørsler (${sortedActiveRequests.value.length})`,
      tenders: (filteredActiveRequests.value.length > 0 ||
      categoryFilters.value[TABS.ACTIVE] !== null ||
      searchText.value.trim()
        ? filteredActiveRequests.value
        : sortedActiveRequests.value
      ).map((request) =>
        mapToTenderFormat(request, "request", supplierId.value),
      ),
    },
  ];
});

const lostRequestsList = computed(() => {
  if (!lostRequests.value.length) return [];

  // If there are no items after filtering, return an empty list
  if (searchText.value.trim() && filteredLostRequests.value.length === 0) {
    return [
      {
        title: `Tapte forespørsler (0)`,
        tenders: [],
      },
    ];
  }

  const requestsToUse =
    filteredLostRequests.value.length > 0 ||
    categoryFilters.value[TABS.LOST] !== null ||
    searchText.value.trim()
      ? filteredLostRequests.value
      : lostRequests.value;

  const sortedRequests = [...requestsToUse].sort((a, b) => {
    return new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime();
  });

  return [
    {
      title: `Tapte forespørsler (${sortedRequests.length})`,
      tenders: sortedRequests.map((request) =>
        mapToTenderFormat(request, "request", supplierId.value),
      ),
    },
  ];
});

const archivedRequestsList = computed(() => {
  if (!archivedRequests.value.length) return [];

  // If there are no items after filtering, return an empty list
  if (searchText.value.trim() && filteredArchivedRequests.value.length === 0) {
    return [
      {
        title: `Arkiverte forespørsler (0)`,
        tenders: [],
      },
    ];
  }

  const requestsToUse =
    filteredArchivedRequests.value.length > 0 ||
    categoryFilters.value[TABS.ARCHIVED] !== null ||
    searchText.value.trim()
      ? filteredArchivedRequests.value
      : archivedRequests.value;

  const sortedRequests = [...requestsToUse].sort((a, b) => {
    return new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime();
  });

  return [
    {
      title: `Arkiverte forespørsler (${sortedRequests.length})`,
      tenders: sortedRequests.map((request) =>
        mapToTenderFormat(request, "request", supplierId.value),
      ),
    },
  ];
});

// Methods
const fetchActiveRequests = async () => {
  if (!supplier.value?.id) return;

  try {
    loadingState.value.requests = true;

    // Determine which tab we're fetching for
    const currentTab = activeTabValue.value;

    // Only clear errors for the current tab
    if (currentTab === TABS.NEW) {
      requestErrors.value[TABS.NEW] = undefined;
    } else if (currentTab === TABS.ACTIVE) {
      requestErrors.value[TABS.ACTIVE] = undefined;
    }

    // For New and Active tabs, don't use pagination - fetch all data
    // For other tabs, use pagination parameters
    const skip = 0;
    const take = 1000; // Use a large number to get all items

    console.log(
      `Fetching requests for ${currentTab} with skip=${skip}, take=${take} (pagination removed for New/Active tabs)`,
    );

    const response = await getActiveRequests(supplier.value.id, skip, take);
    if (!response || !response.data) {
      console.log("No data received from API");
      return;
    }
    const responseItems = [...response.data.items];

    // Sort items by createdOn in descending order
    const sortedItems = responseItems.sort(
      (a, b) =>
        new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime(),
    );

    // Filter based on current tab
    let filteredItems = sortedItems;
    if (currentTab === TABS.NEW) {
      // For New tab, only show price requested items
      filteredItems = sortedItems.filter(
        (request) =>
          request.supplierRequestState === RequestStateType.PRICE_REQUESTED,
      );
      console.log(`Filtered to ${filteredItems.length} NEW requests`);
    } else if (currentTab === TABS.ACTIVE) {
      // For Active tab, only show information requested and offered items
      filteredItems = sortedItems.filter(
        (request) =>
          request.supplierRequestState ===
            RequestStateType.INFORMATION_REQUESTED ||
          request.supplierRequestState === RequestStateType.OFFERED,
      );
      console.log(`Filtered to ${filteredItems.length} ACTIVE requests`);
    }

    activeRequests.value = filteredItems;

    // Update pagination state with total records
    // This is still needed for other tabs that might use the same data
    if (currentTab === TABS.NEW) {
      // Count only NEW items for the New tab pagination
      const newItemsCount = responseItems.filter(
        (request) =>
          request.supplierRequestState === RequestStateType.PRICE_REQUESTED,
      ).length;
      updatePaginationForTab(TABS.NEW, { totalRecords: newItemsCount });
    } else if (currentTab === TABS.ACTIVE) {
      // Count only ACTIVE items for the Active tab pagination
      const activeItemsCount = responseItems.filter(
        (request) =>
          request.supplierRequestState ===
            RequestStateType.INFORMATION_REQUESTED ||
          request.supplierRequestState === RequestStateType.OFFERED,
      ).length;
      updatePaginationForTab(TABS.ACTIVE, { totalRecords: activeItemsCount });
    }
  } catch (error) {
    const message = "Kunne ikke hente forespørsler";

    // Only set error for the current tab
    if (activeTabValue.value === TABS.NEW) {
      requestErrors.value[TABS.NEW] = message;
    } else if (activeTabValue.value === TABS.ACTIVE) {
      requestErrors.value[TABS.ACTIVE] = message;
    }

    console.error(message, error);
  } finally {
    loadingState.value.requests = false;
  }
};

const fetchLostRequests = async () => {
  if (!supplier.value?.id) return;

  try {
    loadingState.value.requests = true;
    requestErrors.value[TABS.LOST] = undefined;

    // Get pagination parameters
    const { first, rows } = getPaginationForTab(TABS.LOST);
    const skip = first;
    const take = rows;

    // Fetch the data with current pagination settings
    const response = await getLostRequests(supplier.value.id, skip, take);

    if (!response || !response.data) {
      throw new Error("No data received from API");
    }

    // Update total count for this tab if we receive it
    if (response.data.count !== undefined) {
      tabTotalCounts.value[TABS.LOST] = response.data.count;

      // Update pagination state with total records
      updatePaginationForTab(TABS.LOST, { totalRecords: response.data.count });

      console.log(
        `Updated total count for lost requests: ${response.data.count} items`,
      );
    }

    const responseItems = response.data.items;
    console.log(
      `Received ${responseItems.length} of ${tabTotalCounts.value[TABS.LOST]} total lost requests`,
    );

    // Sort items by createdOn in descending order
    const sortedItems = [...responseItems].sort(
      (a, b) =>
        new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime(),
    );

    lostRequests.value = sortedItems;
  } catch (error) {
    const message = "Kunne ikke hente tapte forespørsler";
    requestErrors.value[TABS.LOST] = message;
    console.error("Error fetching lost requests:", error);
  } finally {
    loadingState.value.requests = false;
  }
};

const fetchArchivedRequests = async () => {
  if (!supplier.value?.id) return;

  try {
    loadingState.value.requests = true;
    requestErrors.value[TABS.ARCHIVED] = undefined;

    // Get pagination parameters
    const { first, rows } = getPaginationForTab(TABS.ARCHIVED);
    const skip = first;
    const take = rows;

    // Fetch the data with current pagination settings
    const response = await getArchivedRequests(supplier.value.id, skip, take);

    if (!response || !response.data) {
      throw new Error("No data received from API");
    }

    // Update total count for this tab if we receive it
    if (response.data.count !== undefined) {
      tabTotalCounts.value[TABS.ARCHIVED] = response.data.count;

      // Update pagination state with total records
      updatePaginationForTab(TABS.ARCHIVED, {
        totalRecords: response.data.count,
      });

      console.log(
        `Updated total count for archived requests: ${response.data.count} items`,
      );
    }

    const responseItems = response.data.items;
    console.log(
      `Received ${responseItems.length} of ${tabTotalCounts.value[TABS.ARCHIVED]} total archived requests`,
    );

    // Sort items by createdOn in descending order
    const sortedItems = [...responseItems].sort(
      (a, b) =>
        new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime(),
    );

    archivedRequests.value = sortedItems;
  } catch (error) {
    const message = "Kunne ikke hente arkiverte forespørsler";
    requestErrors.value[TABS.ARCHIVED] = message;
    console.error("Error fetching archived requests:", error);
  } finally {
    loadingState.value.requests = false;
  }
};

// API hooks
const { fetchSupplierUnreadMessageStatistics } = useCommunicationApi();

// Fetch both message and activity statistics
const fetchStats = async (force = false) => {
  if (!supplier.value?.id || (!force && !shouldRefreshStats())) return;

  try {
    loadingState.value.stats = true;
    statsError.value = null;

    const tabType = TAB_TO_TYPE_MAP[currentTabList.value as TabValue];
    const date = new Date(getFromTime(tabType));
    const from = format(date, "yyyy-MM-dd");

    const [messageResponse, activityResponse] = await Promise.all([
      fetchSupplierUnreadMessageStatistics(
        supplier.value?.id?.toString() ?? "",
      ),
      getRequestActivityStats(supplier.value?.id, from),
    ]);

    messageStats.value = messageResponse?.data || messageStats.value;
    activityStats.value = activityResponse?.data || [];
    lastStatsRefresh.value = Date.now();
  } catch (error) {
    console.error("Error fetching stats:", error);
    statsError.value = "Failed to fetch updates";
  } finally {
    loadingState.value.stats = false;
  }
};

// Update initialization
onMounted(() => {
  // Scroll to top smoothly
  scrollToTop();

  // Always initialize with tabList=new on initial load
  if (!route.query.tabList) {
    router.replace({
      query: {
        tabList: "new",
      },
    });
  }

  // Set initial state
  activeTabValue.value =
    TABS[currentTabList.value.toUpperCase() as keyof typeof TABS] || TABS.NEW;

  // Initialize data
  initializeData();

  // Set up periodic refresh
  const refreshInterval = window.setInterval(() => {
    fetchStats();
  }, REFRESH_INTERVAL);

  // Add visibility change handling
  document.addEventListener("visibilitychange", handleVisibilityChange);

  // Cleanup on unmount
  onUnmounted(() => {
    clearInterval(refreshInterval);
    document.removeEventListener("visibilitychange", handleVisibilityChange);
  });
});

// Tab visibility handler
const handleVisibilityChange = debounce(() => {
  if (document.visibilityState === "visible") {
    fetchStats(true);
  }
}, 1000);

// Update the getTabMessageCount computed property
const getTabMessageCount = computed(() => {
  if (statsError.value || !messageStats.value) {
    return {
      [TABS.NEW]: 0,
      [TABS.ACTIVE]: 0,
      [TABS.LOST]: 0,
      [TABS.ARCHIVED]: 0,
    };
  }

  // Helper function to combine project and service counts
  const getCombinedCount = (baseKey: string): number => {
    const projectKey = `${baseKey}-project` as keyof typeof messageStats.value;
    const serviceKey = `${baseKey}-service` as keyof typeof messageStats.value;
    return (
      (messageStats.value[projectKey] || 0) +
      (messageStats.value[serviceKey] || 0)
    );
  };

  return {
    [TABS.NEW]: getCombinedCount("PriceRequested"),
    [TABS.ACTIVE]:
      getCombinedCount("InformationRequested") + getCombinedCount("Offered"),
    [TABS.LOST]: 0, // No specific message count for lost
    [TABS.ARCHIVED]: getCombinedCount("Rejected"),
  };
});

// Create separate functions for each tab type
const isNewRequestUnviewed = (priceRequestId: number | string): boolean => {
  const priceRequestIdNumber = parseInt(priceRequestId.toString(), 10);
  return !(
    hasNewRequestBeenViewed(priceRequestIdNumber) ||
    viewedRequests.value.has(priceRequestIdNumber)
  );
};

const isLostRequestUnviewed = (priceRequestId: number | string): boolean => {
  const priceRequestIdNumber = parseInt(priceRequestId.toString(), 10);
  return !(
    hasLostRequestBeenViewed(priceRequestIdNumber) ||
    viewedRequests.value.has(priceRequestIdNumber)
  );
};

const isArchivedRequestUnviewed = (
  priceRequestId: number | string,
): boolean => {
  const priceRequestIdNumber = parseInt(priceRequestId.toString(), 10);
  return !(
    hasArchivedRequestBeenViewed(priceRequestIdNumber) ||
    viewedRequests.value.has(priceRequestIdNumber)
  );
};

const getMessageTooltip = (tabValue: TabValue): string => {
  switch (tabValue) {
    case TABS.NEW: {
      const messageCount = getTabMessageCount.value[TABS.NEW];
      const newRequest = activityStats.value?.find(
        (stat) => stat.requestState === "priceRequested",
      );

      const unviewedCount =
        newRequest?.priceRequestIds?.filter(isNewRequestUnviewed).length ?? 0;

      const messages = [];
      if (messageCount > 0) messages.push(`${messageCount} uleste meldinger`);
      if (unviewedCount > 0) messages.push(`${unviewedCount} nye forespørsler`);

      return messages.length > 0 ? messages.join("\n") : "Ingen nye meldinger";
    }

    case TABS.ACTIVE: {
      const messageCount = getTabMessageCount.value[TABS.ACTIVE];

      const messages = [];
      if (messageCount > 0) {
        messages.push(`${messageCount} uleste meldinger`);
      }

      return messages.length > 0 ? messages.join("\n") : "Ingen nye meldinger";
    }

    case TABS.LOST: {
      const lostRequests = activityStats.value?.filter(
        (stat) => stat.requestState === "lost",
      );

      const unviewedLostCount =
        lostRequests?.reduce((count, stat) => {
          return (
            count +
            (stat.priceRequestIds?.filter(isLostRequestUnviewed).length || 0)
          );
        }, 0) || 0;

      const messages = [];
      if (unviewedLostCount > 0)
        messages.push(`${unviewedLostCount} nye tapte forespørsler`);

      return messages.length > 0 ? messages.join("\n") : "Ingen nye meldinger";
    }

    case TABS.ARCHIVED: {
      const archivedRequests = activityStats.value?.filter(
        (stat) =>
          stat.requestState === "rejected" ||
          stat.requestState === "unanswered",
      );

      const unviewedArchivedCount =
        archivedRequests?.reduce((count, stat) => {
          return (
            count +
            (stat.priceRequestIds?.filter(isArchivedRequestUnviewed).length ||
              0)
          );
        }, 0) || 0;

      const messages = [];
      if (unviewedArchivedCount > 0)
        messages.push(`${unviewedArchivedCount} nye arkiverte forespørsler`);

      return messages.length > 0 ? messages.join("\n") : "Ingen nye meldinger";
    }
  }

  return "Ingen nye meldinger";
};

// Update getBadges for the new activity stats structure
const getBadges = (tabValue: TabValue) => {
  const badges = [];

  if (tabValue === TABS.NEW) {
    const newRequest = activityStats.value?.find(
      (stat) => stat.requestState === "priceRequested",
    );

    const newActivityCount =
      newRequest?.priceRequestIds?.filter(isNewRequestUnviewed).length ?? 0;

    if (newActivityCount > 0) {
      badges.push({
        value: "",
        class: "activity-badge activity-dot",
        severity: "danger",
      });
    }
  }

  if (tabValue === TABS.LOST) {
    const lostRequests = activityStats.value?.filter(
      (stat) => stat.requestState === "lost",
    );

    const lostActivityCount =
      lostRequests?.reduce((count, stat) => {
        return (
          count +
          (stat.priceRequestIds?.filter(isLostRequestUnviewed).length || 0)
        );
      }, 0) || 0;

    if (lostActivityCount > 0) {
      badges.push({
        value: "",
        class: "activity-badge activity-dot",
        severity: "danger",
      });
    }
  }

  if (tabValue === TABS.ARCHIVED) {
    const rejectedRequests = activityStats.value?.filter(
      (stat) =>
        stat.requestState === "rejected" || stat.requestState === "unanswered",
    );

    const archivedActivityCount =
      rejectedRequests?.reduce((count, stat) => {
        return (
          count +
          (stat.priceRequestIds?.filter(isArchivedRequestUnviewed).length || 0)
        );
      }, 0) || 0;

    if (archivedActivityCount > 0) {
      badges.push({
        value: "",
        class: "activity-badge activity-dot",
        severity: "danger",
      });
    }
  }

  return badges;
};

// Helper to get requests for each tab
const getRequestsForTab = (tabValue: TabValue) => {
  switch (tabValue) {
    case TABS.NEW:
      return newRequestsList.value[0]?.tenders ?? [];
    case TABS.ACTIVE:
      return activeRequestsList.value[0]?.tenders ?? [];
    case TABS.LOST:
      return lostRequestsList.value[0]?.tenders ?? [];
    case TABS.ARCHIVED:
      return archivedRequestsList.value[0]?.tenders ?? [];
    default:
      return [];
  }
};

// Helper function to get total count for a tab
const getTotalCountForTab = (tabValue: TabValue): number => {
  const searchQuery = searchText.value.trim().toLowerCase();
  const selectedCategory = categoryFilters.value[tabValue];

  // Declare variables outside of switch cases
  let filteredNew = sortedNewRequests.value;
  let filteredActive = sortedActiveRequests.value;
  let filteredLost = lostRequests.value;
  let filteredArchived = archivedRequests.value;

  switch (tabValue) {
    case TABS.NEW:
      // Apply search filter if present
      if (searchQuery) {
        filteredNew = filteredNew.filter((request) =>
          [
            (request.tenderReference || "").toString().toLowerCase(),
            (request.customerAddress || "").toLowerCase(),
            (request.customerName || "").toLowerCase(),
          ].some((field) => field.includes(searchQuery)),
        );
      }

      // Apply category filter if present
      if (selectedCategory) {
        filteredNew = filteredNew.filter(
          (request) => request.categoryLabel === selectedCategory.label,
        );
      }

      return filteredNew.length;

    case TABS.ACTIVE:
      // Apply search filter if present
      if (searchQuery) {
        filteredActive = filteredActive.filter((request) =>
          [
            (request.tenderReference || "").toString().toLowerCase(),
            (request.customerAddress || "").toLowerCase(),
            (request.customerName || "").toLowerCase(),
          ].some((field) => field.includes(searchQuery)),
        );
      }

      // Apply category filter if present
      if (selectedCategory) {
        filteredActive = filteredActive.filter(
          (request) => request.categoryLabel === selectedCategory.label,
        );
      }

      return filteredActive.length;

    case TABS.LOST:
      // Apply search filter if present
      if (searchQuery) {
        filteredLost = filteredLost.filter((request) =>
          [
            (request.tenderReference || "").toString().toLowerCase(),
            (request.customerAddress || "").toLowerCase(),
            (request.customerName || "").toLowerCase(),
          ].some((field) => field.includes(searchQuery)),
        );
      }

      // Apply category filter if present
      if (selectedCategory) {
        filteredLost = filteredLost.filter(
          (request) => request.categoryLabel === selectedCategory.label,
        );
      }

      return filteredLost.length;

    case TABS.ARCHIVED:
      // Apply search filter if present
      if (searchQuery) {
        filteredArchived = filteredArchived.filter((request) =>
          [
            (request.tenderReference || "").toString().toLowerCase(),
            (request.customerAddress || "").toLowerCase(),
            (request.customerName || "").toLowerCase(),
          ].some((field) => field.includes(searchQuery)),
        );
      }

      // Apply category filter if present
      if (selectedCategory) {
        filteredArchived = filteredArchived.filter(
          (request) => request.categoryLabel === selectedCategory.label,
        );
      }

      return filteredArchived.length;

    default:
      return 0;
  }
};

// Helper to get title for each tab
const getTabTitle = (tabValue: TabValue) => {
  const counts = {
    [TABS.NEW]: newRequestsList.value[0]?.tenders.length || 0,
    [TABS.ACTIVE]: activeRequestsList.value[0]?.tenders.length || 0,
    [TABS.LOST]: lostRequestsList.value[0]?.tenders.length || 0,
    [TABS.ARCHIVED]: archivedRequestsList.value[0]?.tenders.length || 0,
  };

  // Get total count before filtering
  const totalCounts = {
    [TABS.NEW]: getTotalCountForTab(TABS.NEW),
    [TABS.ACTIVE]: getTotalCountForTab(TABS.ACTIVE),
    [TABS.LOST]: getTotalCountForTab(TABS.LOST),
    [TABS.ARCHIVED]: getTotalCountForTab(TABS.ARCHIVED),
  };

  const titles = {
    [TABS.NEW]: "Nye forespørsler",
    [TABS.ACTIVE]: "Aktive forespørsler",
    [TABS.LOST]: "Tapte forespørsler",
    [TABS.ARCHIVED]: "Arkiverte forespørsler",
  };

  const hasFilters = categoryFilters.value[tabValue] || searchText.value.trim();

  // Show filtered count / total count when filter is applied
  if (hasFilters) {
    return `${titles[tabValue]} (${counts[tabValue]} / ${totalCounts[tabValue]})`;
  }

  return `${titles[tabValue]} (${counts[tabValue]})`;
};

const handleRetry = async (tabValue: TabValue) => {
  requestErrors.value[tabValue] = undefined;
  await handleTabChange(tabValue);
};

const handleTabChange = async (value: string | number) => {
  const tabValue = value.toString() as TabValue;

  // Reset search text and category filter when switching tabs
  searchText.value = "";
  categoryFilters.value[tabValue] = null;

  // If the tab hasn't changed, don't reset pagination or reload data
  if (tabValue === activeTabValue.value && !isPageChanging.value) {
    return;
  }

  activeTabValue.value = tabValue;

  // Get the corresponding tab type
  const tabType = TAB_TO_TYPE_MAP[tabValue as TabValue];

  // Preserve priceRequestId if it exists in current route
  const query: Record<string, string> = {
    tab: currentTab.value,
    tabList: tabType,
  };

  if (route.query.priceRequestId) {
    query.priceRequestId = route.query.priceRequestId.toString();
  }

  // Update URL with new tab
  await router.replace({ query });

  updateLastVisitTime(tabType);

  // Reset pagination state for this tab only when switching tabs
  updatePaginationForTab(tabValue, { first: 0 });

  // Reset loading state before fetching
  loadingState.value.requests = true;

  try {
    // Clear current data before fetching new data
    switch (tabValue) {
      case TABS.NEW:
      case TABS.ACTIVE:
        activeRequests.value = [];
        await fetchActiveRequests();
        break;
      case TABS.LOST:
        lostRequests.value = [];
        await fetchLostRequests();
        break;
      case TABS.ARCHIVED:
        archivedRequests.value = [];
        await fetchArchivedRequests();
        break;
    }

    await fetchStats(true);
  } catch (error) {
    console.error("Error changing tab:", error);
  } finally {
    loadingState.value.requests = false;
  }
};

// Add a watch for route changes
watch(
  () => route.query.tabList,
  async (newTabList) => {
    if (newTabList) {
      const newTabValue = Object.keys(TAB_TO_TYPE_MAP).find(
        (key) => TAB_TO_TYPE_MAP[key as TabValue] === newTabList,
      ) as TabValue;

      if (newTabValue && newTabValue !== activeTabValue.value) {
        await handleTabChange(newTabValue);
      }
    }
  },
);

// Improved initial data loading
const initializeData = async () => {
  if (!supplier.value?.id) return;

  try {
    loadingState.value = {
      requests: true,
      stats: true,
    };

    // Make sure CMS data is loaded first
    // Fetch service categories
    await fetchServiceCategories();

    // Then fetch requests for the current tab
    switch (currentTabList.value) {
      case TAB_TO_TYPE_MAP[TABS.NEW]:
        await fetchActiveRequests();
        break;
      case TAB_TO_TYPE_MAP[TABS.ACTIVE]:
        await fetchActiveRequests();
        break;
      case TAB_TO_TYPE_MAP[TABS.LOST]:
        await fetchLostRequests();
        break;
      case TAB_TO_TYPE_MAP[TABS.ARCHIVED]:
        await fetchArchivedRequests();
        break;
    }

    // Apply filters for all tabs
    applyFiltersAndSort(TABS.NEW);
    applyFiltersAndSort(TABS.ACTIVE);
    applyFiltersAndSort(TABS.LOST);
    applyFiltersAndSort(TABS.ARCHIVED);

    // Only after requests are loaded, fetch message stats
    await fetchStats(true);
  } catch (error) {
    console.error("Error initializing data:", error);
    statsError.value = "Failed to initialize data";
  } finally {
    loadingState.value = {
      requests: false,
      stats: false,
    };
  }
};

const handleReloadTenderList = async () => {
  await initializeData();
};

// Add smooth scroll to top function
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

const markRequestAsViewed = (
  priceRequestId: number | string,
  tabType?: string,
) => {
  const priceRequestIdNumber = parseInt(priceRequestId.toString(), 10);
  viewedRequests.value.add(priceRequestIdNumber);

  // Determine which type of request is being viewed based on the current tab
  const currentTabType =
    tabType || TAB_TO_TYPE_MAP[activeTabValue.value as TabValue];

  switch (currentTabType) {
    case "new":
      setNewRequestViewed(priceRequestIdNumber);
      break;
    case "lost":
      setLostRequestViewed(priceRequestIdNumber);
      break;
    case "archived":
      setArchivedRequestViewed(priceRequestIdNumber);
      break;
  }
};

// Add watch for route changes to handle request viewing
watch(
  () => route.query.priceRequestId,
  (newPriceRequestId) => {
    if (newPriceRequestId) {
      const priceRequestId = parseInt(newPriceRequestId.toString(), 10);
      const currentTabType = TAB_TO_TYPE_MAP[activeTabValue.value as TabValue];
      markRequestAsViewed(priceRequestId, currentTabType);
    }
  },
  { immediate: true },
);

// Update the pagination event handlers
const handlePageChange = async (tabValue: TabValue, newFirst: number) => {
  // Only apply pagination to Lost and Archived tabs
  if (tabValue !== TABS.LOST && tabValue !== TABS.ARCHIVED) {
    console.log(
      `Pagination skipped for tab ${tabValue} (pagination disabled for New/Active tabs)`,
    );
    return;
  }

  // Store previous state to detect if we need to fetch data
  const prevFirst = getPaginationForTab(tabValue).first;

  if (prevFirst === newFirst || isPageChanging.value) {
    // Skip if the page hasn't actually changed or if a page change is already in progress
    return;
  }

  // Set flag to prevent duplicate calls
  isPageChanging.value = true;

  // Calculate current page for logging
  const currentPage =
    Math.floor(newFirst / getPaginationForTab(tabValue).rows) + 1;
  console.log(
    `Page changed from ${Math.floor(prevFirst / getPaginationForTab(tabValue).rows) + 1} to ${currentPage}`,
  );

  // Update pagination state first
  updatePaginationForTab(tabValue, { first: newFirst });

  // Fetch data with new pagination settings
  try {
    switch (tabValue) {
      case TABS.LOST:
        await fetchLostRequests();
        break;
      case TABS.ARCHIVED:
        await fetchArchivedRequests();
        break;
    }
  } catch (error) {
    console.error("Error fetching data after page change:", error);
    // If there was an error, rollback to previous state
    updatePaginationForTab(tabValue, { first: prevFirst });
  } finally {
    // Reset flag after API call completes
    setTimeout(() => {
      isPageChanging.value = false;
    }, 50); // Short delay to prevent race conditions
  }
};

const handleRowsPerPageChange = async (tabValue: TabValue, newRows: number) => {
  // Only apply pagination to Lost and Archived tabs
  if (tabValue !== TABS.LOST && tabValue !== TABS.ARCHIVED) {
    console.log(
      `Rows per page change skipped for tab ${tabValue} (pagination disabled for New/Active tabs)`,
    );
    return;
  }

  // Reset to first page and update rows per page
  updatePaginationForTab(tabValue, { first: 0, rows: newRows });

  // Fetch data with new pagination settings
  switch (tabValue) {
    case TABS.LOST:
      await fetchLostRequests();
      break;
    case TABS.ARCHIVED:
      await fetchArchivedRequests();
      break;
  }
};

// Add this after the handlePageChange function
const debouncedHandlePageChange = debounce(
  (tabValue: TabValue, newFirst: number) => {
    if (!isPageChanging.value) {
      handlePageChange(tabValue, newFirst);
    }
  },
  50,
);

// Add this after the handleRowsPerPageChange function
const debouncedHandleRowsPerPageChange = debounce(
  (tabValue: TabValue, newRows: number) => {
    if (!isPageChanging.value) {
      handleRowsPerPageChange(tabValue, newRows);
    }
  },
  50,
);

// Helper function to assert TabValue type
const asTabValue = (value: string): TabValue => {
  if (
    value === TABS.NEW ||
    value === TABS.ACTIVE ||
    value === TABS.LOST ||
    value === TABS.ARCHIVED
  ) {
    return value;
  }
  return TABS.NEW; // Default fallback
};

// Helper function to get pagination text for a specific tab
const getPaginationTextForTab = (tabValue: TabValue): string => {
  const { first, rows, totalRecords } = getPaginationForTab(tabValue);

  if (totalRecords === 0) {
    return "Ingen elementer";
  }

  const start = first + 1;
  const end = Math.min(first + rows, totalRecords);

  return `Viser ${start} til ${end} av totalt ${totalRecords} elementer`;
};
</script>

<style lang="scss" scoped>
.requests-view {
  margin: 0 2rem 3rem;

  .requests-view__header {
    margin-top: 1rem;
    display: flex;
    gap: 0.5rem;
    align-items: baseline;
    text-transform: uppercase;
    font-size: 2.5rem;
    font-weight: 500;
    color: $color-black;
  }

  :deep(.p-tablist-tab-list) {
    background: transparent;
  }

  :deep(.p-tabpanels) {
    padding: 0;
    background: transparent;
  }

  :deep(.p-tab) {
    position: relative;
    transition: all 0.2s ease-in-out;
    display: flex;
    align-items: anchor-center;
    gap: 4px;

    // Add dotted underline on hover
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      border-bottom: 1px dotted transparent;
      transition: border-color 0.2s ease-in-out;
    }

    &:hover::after {
      border-color: $color-primary;
    }
  }

  :deep(.p-tab-active) {
    color: $color-primary;
    border-color: $color-primary;
    border-bottom: 1px solid $color-primary !important;

    &::after {
      display: none; // Hide dotted underline for active tab
      border-bottom: 1px solid $color-primary !important;
    }
  }
}

:deep(.p-tablist-active-bar) {
  background-color: $color-primary;
}

.badge-container {
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
}

:deep(.custom-badge) {
  background-color: $color-primary;
  font-size: 0.75rem;
  font-weight: 500;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
}

:deep(.activity-badge.activity-dot) {
  background-color: $color-error;
  min-width: 0.5rem !important;
  height: 0.5rem !important;
  border-radius: 50%;
  padding: 0;
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
}

.error-indicator {
  display: inline-flex;
  align-items: center;
  color: $color-error;
  margin-left: 0.5rem;
  cursor: help;

  i {
    font-size: 0.875rem;
  }
}

:deep(.p-tooltip.p-tooltip-bottom.tooltip-custom) {
  .p-tooltip-text {
    background-color: $color-primary;
    color: white;
  }

  .p-tooltip-arrow {
    border-bottom-color: $color-primary;
  }
}

.tenders-view {
  min-height: 100vh;
  padding-top: 1rem;
}

.activity-tooltip {
  background-color: white;
  color: black;
}

.paginator-container {
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
}

.pagination-info {
  display: inline-block;
  padding: 0 0.75rem;
  font-weight: 500;
  color: $color-black;
  min-width: 250px;
  text-align: center;
  margin: 0 1rem;
  align-self: center;
}

:deep(.p-paginator) {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;

  .p-paginator-page.p-highlight {
    background-color: $color-primary;
    color: white;
    font-weight: bold;
  }

  .p-paginator-element {
    margin: 0 0.1rem;
    min-width: 2.5rem;
    height: 2.5rem;

    &:focus {
      box-shadow: 0 0 0 2px rgba($color-primary, 0.2);
    }

    &:hover {
      background-color: rgba($color-primary, 0.1);
    }
  }
}

.all-items-container {
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.all-items-message {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: $color-black;
  border-radius: 0.25rem;
}

.filter-container {
  display: flex;
  justify-content: flex-start;
  padding: 1rem 0 0 0;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.filter-left {
  width: 200px;
}

.filter-middle {
  width: auto;
}

.filter-right {
  flex: 1;
  max-width: 400px;
}

.sort-select,
.search-input,
.category-filter {
  width: 100%;
}

.search-input {
  height: 40px;
}

:deep(.p-dropdown) {
  width: 100%;
}

:deep(.p-dropdown-panel) {
  .p-dropdown-items {
    padding: 0.5rem 0;
  }

  .p-dropdown-item {
    padding: 0.5rem 1rem;

    &.p-highlight {
      background-color: rgba($color-primary, 0.1);
      color: $color-primary;
      font-weight: 500;
    }
  }
}
</style>
