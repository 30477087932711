<template>
  <confirmation-modal
    v-model="showModal"
    :status-icon="DocumentUserIcon"
    :icon-on-edge="false"
    title="Ny ansvarlig"
    :description="descriptionText"
    secondary-button-text="Avbryt"
    primary-button-text="OK"
    :no-close-on-background="true"
    :is-submitting="isSubmitting"
    :error="submissionError"
    @secondary-click="handleSecondaryButtonClick"
    @primary-click="handlePrimaryButtonClick"
  >
    <template v-if="user" #extraContent>
      <div class="new-agreement-responsible__allocation-notification">
        <div class="flex items-center gap-2">
          <Checkbox
            v-model="sendNotification"
            :binary="true"
            input-id="send-notification"
          />
          <label for="send-notification">{{ sendNotificationText }}</label>
        </div>
      </div>
    </template>
  </confirmation-modal>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import ConfirmationModal from "@/components/ConfirmationModal/ConfirmationModal.vue";
import Checkbox from "primevue/checkbox";
import DocumentUserIcon from "@/assets/img/document-user.svg";
import { useTenderApi } from "@/services/api/useTenderApi";
import { Tender, PriceRequest } from "@/stores/tender/types";

interface User {
  userId: string;
  displayName: string;
}

interface ApiError {
  response?: {
    data?: unknown;
  };
}

interface Props {
  modelValue: boolean;
  user: User | null;
  tender: Tender;
  priceRequest: PriceRequest;
  supplierId: number;
  tenderId: number | null;
}

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: "update:modelValue", value: boolean): void;
  (e: "success"): void;
}>();

const { setAgreementResponsible } = useTenderApi();

// State
const sendNotification = ref(true);
const isSubmitting = ref(false);
const submissionError = ref("");

// Computed
const showModal = computed({
  get: () => props.modelValue,
  set: (value: boolean) => emit("update:modelValue", value),
});

const userName = computed(() =>
  props.user ? props.user.displayName : "Alle brukere",
);

const descriptionText = computed(
  () =>
    `${userName.value} vil motta alle varslinger knyttet til denne forespørselen.`,
);

const sendNotificationText = computed(() => {
  const displayName = props.user?.displayName;
  const firstName = displayName ? displayName.split(" ")[0] : "";
  return `Send varsling om tildeling til ${firstName}`;
});

const supplierRequestId = computed(
  () => props.priceRequest.supplierRequests[0]?.id ?? null,
);

// Single watcher to reset state when modal opens
watch(
  () => props.modelValue,
  (newValue) => {
    if (newValue) {
      // Reset all states when modal opens
      isSubmitting.value = false;
      submissionError.value = "";
      sendNotification.value = true;
    }
  },
);

// Methods
const handleSecondaryButtonClick = () => {
  showModal.value = false;
};

const handlePrimaryButtonClick = async () => {
  isSubmitting.value = true;
  submissionError.value = "";

  try {
    await setAgreementResponsible({
      supplierId: props.supplierId,
      supplierRequestId: supplierRequestId.value ?? 0,
      data: {
        AgreementOwnerUserId: props.user?.userId ?? null,
        SendNotification: props.user ? sendNotification.value : false,
      },
    });
    showModal.value = false;
    emit("success");
  } catch (error: unknown) {
    const apiError = error as ApiError;
    const errorMessage = apiError?.response?.data;
    submissionError.value = errorMessage
      ? JSON.stringify(errorMessage)
      : "Submission error";
  } finally {
    isSubmitting.value = false;
  }
};
</script>

<style lang="scss" scoped>
.new-agreement-responsible {
  &__allocation-notification {
    margin-top: 1.5rem;
    font-size: $font-size-base;
  }
}
</style>
