import { computed, type Ref } from "vue";
import { formatToLongDateString } from "@/utilities/dateUtils";
import type { PriceRequestDetails } from "@/stores/tender/types";
import dayjs from "dayjs";

export const usePriceRequestDetails = (props: {
  priceRequestDetails: Ref<PriceRequestDetails | null>;
  serviceCategory: Ref<string>;
}) => {
  const effectiveServiceCategory = computed(() => props.serviceCategory.value);

  const effectiveReferenceCode = computed(() =>
    props.priceRequestDetails.value
      ? `#${props.priceRequestDetails.value.externalTenderId}`
      : "",
  );

  const effectiveRequestSent = computed(() =>
    props.priceRequestDetails.value
      ? formatToLongDateString(props.priceRequestDetails.value.requestSent)
      : "",
  );

  const effectiveDesiredStartDate = computed(() =>
    props.priceRequestDetails.value
      ? formatToLongDateString(props.priceRequestDetails.value.wantedStartDate)
      : "",
  );

  const effectiveJointInspection = computed(() => {
    const dateTime = props.priceRequestDetails.value?.surveyDateTime;
    return dateTime
      ? `${formatToLongDateString(dateTime)} kl. ${dayjs(dateTime).format("HH:mm")}`
      : "";
  });

  const effectiveSharedMessage = computed(
    () =>
      props.priceRequestDetails.value?.requestMessage ??
      "Ingen delte meldinger for øyeblikket",
  );

  return {
    effectiveReferenceCode,
    effectiveServiceCategory,
    effectiveRequestSent,
    effectiveDesiredStartDate,
    effectiveJointInspection,
    effectiveSharedMessage,
  };
};
