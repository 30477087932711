<template>
  <div
    class="public-note"
    :class="classes"
    @mouseenter="isHovered = true"
    @mouseleave="isHovered = false"
    @click="handleNoteClick"
  >
    <div v-if="isUnread" class="public-note__notification">
      <img :src="DotIcon" alt="unread" />
    </div>
    <div class="public-note__content">
      <div class="public-note__header">
        <div class="public-note__title">{{ publicNote.title }}</div>
        <div class="public-note__date">{{ formattedCreatedOn }}</div>
        <div class="public-note__arrow">
          <img :src="ArrowRightIcon" alt="arrow-right" />
        </div>
      </div>
      <div class="public-note__message">{{ publicNote.message }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { formatDateBasedOnTimeLapsed } from "@/utilities/dateUtils";
import ArrowRightIcon from "@/assets/img/arrow-right-black.svg";
import DotIcon from "@/assets/img/dot.svg";
import { PublicNote } from "@/stores/tender/types";

const props = defineProps<{
  publicNote: PublicNote;
  isUnread?: boolean;
}>();

const emit = defineEmits<{
  (e: "clicked", note: PublicNote): void;
}>();

const isHovered = ref(false);

const formattedCreatedOn = computed(() =>
  formatDateBasedOnTimeLapsed(props.publicNote.createdOn),
);

const classes = computed(() => ({
  "public-note--unread": props.isUnread,
  "public-note--hover": isHovered.value,
}));

const handleNoteClick = () => {
  emit("clicked", props.publicNote);
};
</script>

<style scoped lang="scss">
.public-note {
  width: 100%;
  padding: 16px 16px 16px 10px;
  background-color: #fafafa;
  border-radius: 8px;
  border: 1px solid #ededed;
  display: flex;
  align-items: flex-start;
  gap: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &--unread {
    background-color: #eefdff;
  }

  &--hover {
    background-color: #f4f4f4;

    &.public-note--unread {
      background-color: #dff6fa;
    }
  }

  &__notification {
    width: 12px;
    height: 12px;
    margin-right: 12px;

    img {
      margin-top: -5px;
    }
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__title {
    flex: 1;
    color: #1d1d1d;
    font-size: 14px;
    font-weight: 600;
  }

  &__date {
    color: #1d1d1d;
    font-size: 12px;
    font-weight: 600;
    text-align: right;
  }

  &__arrow {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__message {
    color: #1d1d1d;
    font-size: 14px;
    font-weight: 400;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
</style>
