import { MandatorySupplierCost } from "@/custom-types/GeneralTypes";
import { roundNumber } from "@/utilities/numberUtils";

export enum SupplierCostFrequencyGroup {
  Fixed = "Fixed",
  Monthly = "Monthly",
  Yearly = "Yearly",
}
export enum SupplierCostFrequencyGroupLabel {
  Fixed = "",
  Monthly = "defaultMonthlyFrequencySegment",
  Yearly = "defaultYearlyFrequencySegment",
}

const extractFrequencyFromFrequencyLabel = (
  frequencyLabel: string | undefined | null,
) => {
  return (frequencyLabel || "").split("-").pop()?.trim();
};
const extractFrequencyGroupFromFrequencyLabel = (
  frequencyLabel: string | undefined | null,
) => {
  const frequencyGroupLabel = (frequencyLabel || "").split("-").shift()?.trim();
  if (frequencyGroupLabel === SupplierCostFrequencyGroupLabel.Monthly) {
    return SupplierCostFrequencyGroup.Monthly;
  } else if (frequencyGroupLabel === SupplierCostFrequencyGroupLabel.Yearly) {
    return SupplierCostFrequencyGroup.Yearly;
  }
  return SupplierCostFrequencyGroup.Fixed;
};
const formatLimitedPeriod = (
  startMonth: string | null | undefined,
  endMonth: string | null | undefined,
) => {
  if (!startMonth || !endMonth) {
    return "";
  }
  if (startMonth.length >= 3) {
    startMonth = startMonth.slice(0, 3);
  }
  if (endMonth.length >= 3) {
    endMonth = endMonth.slice(0, 3);
  }
  return `(${startMonth} - ${endMonth})`;
};
const getInputItemCost = (supplierCost: MandatorySupplierCost | undefined) => {
  if (!supplierCost) {
    return undefined;
  }
  return roundNumber(supplierCost.inputPrice / supplierCost.itemTotal, 2);
};

type UseSupplierCostItem = () => {
  extractFrequencyFromFrequencyLabel: typeof extractFrequencyFromFrequencyLabel;
  extractFrequencyGroupFromFrequencyLabel: typeof extractFrequencyGroupFromFrequencyLabel;
  formatLimitedPeriod: typeof formatLimitedPeriod;
  getInputItemCost: typeof getInputItemCost;
};

const useSupplierCostItem: UseSupplierCostItem = () => {
  return {
    extractFrequencyFromFrequencyLabel,
    extractFrequencyGroupFromFrequencyLabel,
    formatLimitedPeriod,
    getInputItemCost,
  };
};

export default useSupplierCostItem;
