<template>
  <b-modal
    v-model="showModal"
    centered
    hide-header
    hide-footer
    body-class="p-0"
  >
    <div class="reject-price-request">
      <div class="reject-price-request__header">
        <div class="reject-price-request__title">
          {{ cms.rejectRequest }}
        </div>
        <button class="reject-price-request__close-button" @click="closeModal">
          <img :src="`/images/24/close-white.svg`" alt="Close" />
        </button>
      </div>
      <div class="reject-price-request__body">
        <form @submit.prevent="submitForm">
          <div class="reject-price-request__description">
            {{ cms.helpUnderstand }}
          </div>
          <div>
            <div
              v-for="reason in cms.reasons"
              :key="reason.id"
              class="reject-price-request__predefined-reason"
            >
              <v-field
                v-slot="{ componentField }"
                name="selectedReason"
                type="radio"
                :value="reason.text"
                :rules="string().required()"
              >
                <base-radio-input v-bind="componentField" :value="reason.text">
                  <span class="reject-price-request__predefined-reason-label">
                    {{ reason.text }}
                  </span>
                </base-radio-input>
              </v-field>
            </div>
            <div
              v-if="state.stabilizedFormErrors.selectedReason"
              class="reject-price-request__input-error"
            >
              {{ cms.provideReasonError }}
            </div>
          </div>
          <div
            v-if="showCustomInputField"
            class="reject-price-request__custom-reason"
          >
            <div class="reject-price-request__custom-reason-label">
              {{ cms.provideReason }}
            </div>
            <div class="reject-price-request__custom-reason-input-wrapper">
              <v-field
                v-slot="{ componentField, errors }"
                name="customReason"
                :rules="string().required()"
                :validate-on-input="true"
              >
                <base-input v-bind="componentField" />
                <div
                  v-if="errors.length"
                  class="reject-price-request__input-error"
                >
                  {{ cms.customReasonError }}
                </div>
              </v-field>
            </div>
          </div>
          <div class="reject-price-request__buttons">
            <primary-button
              :is-submitting="state.isLoading"
              :disabled="state.isLoading"
              type="submit"
            >
              {{ cms.rejectRequestButton }}
            </primary-button>
            <button
              :disabled="state.isLoading"
              class="reject-price-request__cancel-button"
              @click="closeModal"
            >
              {{ cms.cancelButton }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </b-modal>
</template>

<script setup>
import { rejectPriceRequestCms } from "@/components/RejectPriceRequestModal/RejectPriceRequestCms";
import PrimaryButton from "@/components/Buttons/PrimaryButton.vue";
import { computed, reactive, watch } from "vue";
import { Field as VField, useForm } from "vee-validate";
import { string } from "yup";
import BaseRadioInput from "@/components/Base/BaseRadioInput/BaseRadioInput.vue";
import BaseInput from "@/components/Base/BaseInput/BaseInput.vue";
import { cloneDeep, isEqual } from "lodash";
import { useTenderApi } from "@/services/api/useTenderApi";

const emit = defineEmits(["update:modelValue", "price-request-rejected"]);

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  tenderId: {
    type: Number,
    required: true,
  },
  priceRequestId: {
    type: [Number, null],
    required: true,
  },
  supplierRequestId: {
    type: [Number, null],
    required: true,
  },
  supplierId: {
    type: Number,
    required: true,
  },
  userId: {
    type: String,
    required: true,
  },
});

const state = reactive({
  stabilizedFormErrors: {},
  isLoading: false,
});

const {
  errors: formErrors,
  resetForm,
  handleSubmit,
  values,
} = useForm({
  initialValues: {
    selectedReason: "",
    customReason: "",
  },
  keepValuesOnUnmount: true,
});

const showModal = computed({
  get() {
    return props.modelValue;
  },
  set(show) {
    emit("update:modelValue", show);
  },
});
const cms = computed(() => {
  return rejectPriceRequestCms;
});
const showCustomInputField = computed(() => {
  return values.selectedReason === "Annet";
});

watch(
  () => showModal.value,
  (newVal) => {
    if (newVal) {
      state.isLoading = false;
      resetForm();
    }
  },
);

watch(
  () => formErrors.value,
  (newVal) => {
    if (!isEqual(cloneDeep(newVal), cloneDeep(state.stabilizedFormErrors))) {
      state.stabilizedFormErrors = cloneDeep(newVal);
    }
  },
  { deep: true, immediate: true },
);

const { updateTenderArchive, sendRejectPriceRequest } = useTenderApi();

const submitForm = handleSubmit(async (values) => {
  state.isLoading = true;
  const reason = showCustomInputField.value
    ? values.customReason
    : values.selectedReason;
  const data = {
    UserId: props.userId,
    SupplierId: props.supplierId,
    Reason: reason,
    Audience: "Supplier",
  };
  try {
    const response = await sendRejectPriceRequest({
      supplierId: props.supplierId,
      supplierRequestId: props.supplierRequestId,
      data: data,
    });

    emit("price-request-rejected");

    if (response.status === 200) {
      await archiveTender();
      closeModal();
    }
  } catch (error) {
    state.isLoading = false;
  }
});

const archiveTender = async () => {
  try {
    const archiveData = {
      state: "Archived",
      userId: props.userId,
      audience: "Supplier",
    };

    await updateTenderArchive({
      tenderId: props.tenderId,
      requestId: props.supplierRequestId,
      flag: "true",
      data: archiveData,
    });

    // router.push({
    //   name: RouteNames.MY_AGREEMENTS,
    //   query: {
    //     priceRequestId: props.priceRequestId,
    //   },
    // });
  } catch (error) {
    console.error("Error archiving tender:", error);
  }
};

const closeModal = () => {
  showModal.value = false;
};
</script>

<style lang="scss" scoped>
.reject-price-request {
  &__header {
    background: #611f69;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.875rem 1.5rem;
  }

  &__title {
    font-size: $font-size-xl;
    font-weight: 700;
    color: $color-white;
  }

  &__close-button {
    background: transparent;
    border: none;
  }

  &__body {
    padding: 2rem 1.5rem 6rem;
  }

  &__description {
    font-size: $font-size-lg;
    font-weight: 500;
  }

  &__predefined-reason {
    margin-top: 2rem;
  }

  &__predefined-reason-label {
    margin-left: 1rem;
    font-size: $font-size-base;
    font-weight: 600;
  }

  &__custom-reason {
    margin-top: 2rem;
  }

  &__custom-reason-label {
    font-size: $font-size-base;
    font-weight: 700;
  }

  &__custom-reason-input-wrapper {
    margin-top: 0.875rem;
  }

  &__input-error {
    margin-top: 0.25rem;
    color: #de0000;
    font-size: $font-size-base;
    font-weight: 700;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
    gap: 1rem;
  }

  &__cancel-button {
    font-size: $font-size-lg;
    font-weight: 500;
    border: none;
    background: none;
    padding: 0;
    border-bottom: 2px solid #1d1d1d;
  }
}

:deep(*) {
  font-family: "Montserrat", sans-serif;
}
</style>
