<template>
  <div class="send-offer-form">
    <div class="send-offer-form__title">{{ title }}</div>
    <p class="send-offer-form__description">
      {{ description }}
    </p>
    <label class="send-offer-form__message-input-label">
      {{ messageInputCmsTitle }}
      <base-text-area
        v-model="state.formData.message"
        class="send-offer-form__message-input"
        rows="5"
        :placeholder="messageInputCmsPlaceholder"
      />
    </label>
    <div class="send-offer-form__withdraw-offers">
      <div class="send-offer-form__withdraw-offers-label">
        {{ withdrawOffersInputCms.title }}
      </div>
      <div class="send-offer-form__withdraw-offers-options">
        <div v-for="(option, index) in withdrawOfferOptions" :key="index">
          <base-radio-input
            v-model="state.formData.withdrawOtherOffers"
            :label="option.label"
            :value="option.value"
          />
        </div>
      </div>
    </div>
    <div v-if="offerRequirementsError" class="send-offer-form__error-message">
      <feedback-overlay
        :feedback-type="offerRequirementsError.type"
        :message-title="offerRequirementsError.messageTitle"
        :message-info="offerRequirementsError.messageInfo"
        @close="clearError"
      />
    </div>
    <div class="send-offer-form__buttons">
      <tertiary-button :disabled="state.isSubmitting" @click="cancel">
        {{ cancelButtonText }}
      </tertiary-button>
      <primary-button
        :disabled="!!offerRequirementsError || state.isSubmitting"
        :is-submitting="state.isSubmitting"
        @click="submitForm"
      >
        {{ sendOfferButtonText }}
      </primary-button>
    </div>
    <div class="send-offer-form__terms">
      <div class="send-offer-form__terms-content">
        <span>{{ supplierTermsConditionsText }}</span>
        <span class="send-offer-form__terms-link" @click="handleDownloadTerms">
          {{ supplierTermsConditionsLinkText }}
        </span>
        <span>.</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive } from "vue";
import dayjs from "dayjs";
import TertiaryButton from "@/components/Buttons/TertiaryButton.vue";
import PrimaryButton from "@/components/Buttons/PrimaryButton.vue";
import BaseTextArea from "@/components/Base/BaseTextArea/BaseTextArea.vue";
import BaseRadioInput from "@/components/Base/BaseRadioInput/BaseRadioInput.vue";
import FeedbackOverlay from "@/components/FeedbackOverlay/FeedbackOverlay.vue";
import {
  getCmsItem,
  getCmsItemText,
  getFileUrl,
} from "@/utilities/cmsUtilities";
import cmsService from "@/services/cmsService";
import { useTenderApi } from "@/services/api/useTenderApi";
import { useCommunicationApi } from "@/services/api/useCommunicationApi";
import {
  type ServiceOffer,
  type OfferRequirementsError,
  type WithdrawOfferOption,
} from "@/stores/tender/types";
import {
  FeedbackType,
  MessageType,
  OfferStateType,
  MessageDomain,
  OfferStatus,
  ServiceOffer as ServiceOfferType,
} from "@/custom-types/GeneralTypes";
import type { SendMessageDto } from "@/stores/communication/types";
import { Pages } from "@/stores/cms/types";
import { AxiosError } from "axios";
import { useUserStore } from "@/stores/user";

interface Props {
  serviceOffer: ServiceOffer & ServiceOfferType;
  supplierId: number;
  priceRequestId: number;
  sendOfferPageCms: Pages;
}

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: "cancel"): void;
  (e: "offer-sent"): void;
  (e: "offer-message-sent"): void;
  (e: "done"): void;
}>();

const userStore = useUserStore();

// Ensure user data is loaded on component mount
onMounted(async () => {
  if (!userStore.isUserLoaded) {
    await userStore.fetchLoggedInUser();
  }
});

const userId = computed(() => {
  if (!userStore.isUserLoaded) {
    userStore.fetchLoggedInUser();
  }
  return userStore.user?.userId;
});

const { changeServiceOfferState } = useTenderApi();
const { sendMessage, fetchSupplierConversations } = useCommunicationApi();

const state = reactive({
  formData: {
    message: "",
    withdrawOtherOffers: true,
  },
  error: null as OfferRequirementsError | null,
  isSubmitting: false,
  termsAndConditionsCms: null as Pages | null,
});

const sendOfferFormCms = computed(() =>
  getCmsItem(props.sendOfferPageCms.forms, "sendOfferForm"),
);
const title = computed(() =>
  getCmsItemText(sendOfferFormCms.value?.otherText, "title"),
);
const description = computed(() =>
  getCmsItemText(sendOfferFormCms.value?.otherText, "description"),
);

const cancelButtonText = computed(() =>
  getCmsItemText(sendOfferFormCms.value?.buttons, "cancelButton"),
);
const sendOfferButtonText = computed(() =>
  getCmsItemText(sendOfferFormCms.value?.buttons, "sendOfferButton"),
);
const messageInputCms = computed(() =>
  getCmsItem(sendOfferFormCms.value?.inputs, "messageInput"),
);
const messageInputCmsTitle = computed(() => messageInputCms.value?.title || "");
const messageInputCmsPlaceholder = computed(
  () => messageInputCms.value?.placeholder || "",
);
const withdrawOffersInputCms = computed(
  () => getCmsItem(sendOfferFormCms.value?.inputs, "withdrawOffersInput") || {},
);
const withdrawOfferOptions = computed((): WithdrawOfferOption[] => [
  {
    label: getCmsItemText(
      withdrawOffersInputCms.value?.RadioOptions,
      "yesOption",
    ),
    value: true,
  },
  {
    label: getCmsItemText(
      withdrawOffersInputCms.value?.RadioOptions,
      "noOption",
    ),
    value: false,
  },
]);
const offerRequirementsError = computed((): OfferRequirementsError | null => {
  const { serviceOffer } = props;
  if (serviceOffer?.readyForCustomer === false) {
    return createError(
      "Minimum data før sending: 1) Minst én fast kostnadslinje 2) Minst én beskrivelseslinje",
    );
  }
  if (serviceOffer?.readyForCustomer === true) {
    return null;
  }
  const hasRequiredData =
    serviceOffer?.descriptions?.length &&
    serviceOffer?.mandatorySupplierCosts?.length;
  if (!hasRequiredData) {
    return createError(
      "Minimum data før sending: 1) Minst én fast kostnadslinje 2) Minst én beskrivelseslinje",
    );
  }
  if (isWantedStartDateInPast.value) {
    return createError(
      "Ønsket startdato er i fortiden. Vennligst velg en fremtidig dato.",
    );
  }
  return null;
});

const createError = (messageTitle: string): OfferRequirementsError => ({
  type: FeedbackType.ERROR,
  messageTitle,
});

const isWantedStartDateInPast = computed(() => {
  const startDate = props.serviceOffer.suggestedStartDate;
  if (!startDate) return true;
  return dayjs(startDate).isBefore(dayjs(), "day");
});

const supplierTermsConditionsText = computed(() =>
  getCmsItemText(
    sendOfferFormCms.value?.otherText,
    "supplierTermsConditions_text",
  ),
);

const supplierTermsConditionsLinkText = computed(() =>
  getCmsItemText(
    sendOfferFormCms.value?.otherText,
    "supplierTermsConditions_link",
  ),
);

const cancel = () => {
  emit("cancel");
};

const submitForm = async () => {
  if (offerRequirementsError.value || state.isSubmitting) return;

  const messageCopy = state.formData.message;
  state.isSubmitting = true;
  state.error = null;

  try {
    await sendOffer();
  } catch (err) {
    state.isSubmitting = false;
    return;
  }

  try {
    if (messageCopy.trim()) {
      await sendOfferMessage(messageCopy);
    }
  } catch (err) {
    console.error(err);
  }

  state.isSubmitting = false;
  emit("done");
};

const sendOffer = async () => {
  if (!userId.value) {
    throw new Error("User ID is required");
  }
  try {
    const sendOfferDto = {
      userId: userId.value,
      withdrawOtherOffers: state.formData.withdrawOtherOffers,
    };
    await changeServiceOfferState(
      props.supplierId,
      props.serviceOffer.supplierRequestId,
      props.serviceOffer.serviceOfferId,
      OfferStatus.OFFERED,
      sendOfferDto,
    );

    emit("offer-sent");
  } catch (err) {
    const errorMessage =
      err instanceof AxiosError ? err?.response?.data : undefined;
    const errorsCms =
      sendOfferFormCms.value?.formSections?.errors?.otherText || [];
    const matchingErrorCms = errorsCms.find(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (cms: any) => cms.label === errorMessage,
    );
    state.error = {
      type: FeedbackType.ERROR,
      messageTitle: matchingErrorCms ? matchingErrorCms.title : errorMessage,
    };
    throw err;
  }
};

const sendOfferMessage = async (messageText: string) => {
  if (!userId.value) {
    throw new Error("User ID is required");
  }

  if (!messageText.trim()) {
    return;
  }

  const conversationsResponse = await fetchSupplierConversations(
    props.serviceOffer.supplierRequestId,
  );

  const conversationId =
    conversationsResponse?.data[0]?.message?.conversationId || null;

  const senderName = userStore.user?.name || "";
  const senderEmail = userStore.user?.email || "";

  const messageDto: SendMessageDto = {
    conversationId,
    messageType: MessageType.PRICE_OFFER,
    messageBody: messageText,
    attachments: [],
    senderUserId: userId.value,
    senderName,
    senderEmail,
    supplierId: props.supplierId,
    supplierRequestId: props.serviceOffer.supplierRequestId,
    serviceOfferId: props.serviceOffer.serviceOfferId,
    tenderId: props.serviceOffer.tenderId,
    rootMessage: !conversationId,
    messageDomain: MessageDomain.SUPPLIER_CUSTOMER,
    price: null,
  };

  try {
    await sendMessage(messageDto);
    emit("offer-message-sent");
  } catch (err) {
    console.error("Error sending offer message:", err);
    throw err;
  }
};

const clearError = () => {
  if (
    state.error?.messageTitle ===
    "Minimum data før sending: 1) Minst én fast kostnadslinje 2) Minst én beskrivelseslinje"
  ) {
    return;
  }
  state.error = null;
};

const fetchTermsAndConditionsCms = async () => {
  const response = await cmsService.getTermsAndConditionsCms();
  if (response && response.length) {
    state.termsAndConditionsCms = response[0];
  }
};

const handleDownloadTerms = () => {
  const url = getFileUrl(props.sendOfferPageCms?.filedoc, "supplierTerms");
  window.open(url, "_blank");
};

onMounted(() => {
  if (props.serviceOffer.currentState !== OfferStateType.DRAFT) {
    return emit("done");
  }
  fetchTermsAndConditionsCms();
});
</script>

<style lang="scss" scoped>
.send-offer-form {
  &__title {
    font-size: $font-size-2xl;
    font-weight: 600;
  }

  &__description {
    margin-top: 1rem;
    font-size: $font-size-base;
  }

  &__message-input-label {
    width: 100%;
    font-size: $font-size-sm;
    font-weight: 600;
    margin: 0;
  }

  &__message-input {
    margin-top: 0.5rem;
    display: block;
    width: 100%;
    resize: none;
    border-radius: 4px;
    padding: 0.75rem 1rem;
  }

  &__withdraw-offers {
    margin-top: 1rem;
  }

  &__withdraw-offers-label {
    font-size: $font-size-sm;
    font-weight: 600;
  }

  &__withdraw-offers-options {
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &__error-message {
    margin-top: 1.5rem;
  }

  &__buttons {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 1rem;
  }

  &__terms {
    margin-top: 1rem;
    padding: 1rem;
    background: #f4f4f4;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 0.625rem;
  }

  &__terms-content {
    flex: 1;
    font-size: $font-size-base;
    color: black;
    word-wrap: break-word;
  }

  &__terms-link {
    text-decoration: underline;
    cursor: pointer;
    display: inline;
    margin-left: 4px;

    &:hover {
      opacity: 0.8;
    }
  }
}
</style>
