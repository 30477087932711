<template>
  <div ref="messagesContainerRef" class="message-list" @scroll="handleScroll">
    <div
      v-for="(message, index) in messages"
      :key="message.id"
      class="message-list__message"
      :class="messageClasses(message)"
      :data-message-id="message.id"
    >
      <MessageItem
        :message="message"
        :supplier-id="supplierId"
        :price-request-id="priceRequestId"
        :supplier-request-id="supplierRequestId"
        :aria-setsize="messages.length"
        :aria-posinset="index + 1"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch, nextTick, onUnmounted } from "vue";
import MessageItem from "./MessageItem.vue";
import { MessageDomain } from "@/custom-types/GeneralTypes";
import type { Message } from "@/stores/communication/types";

interface Props {
  messages: Message[];
  supplierId: number;
  priceRequestId?: number;
  supplierRequestId?: number;
}

const props = defineProps<Props>();

const messagesContainerRef = ref<HTMLElement | null>(null);
const showScrollIndicator = ref(false);
const isScrollable = ref(false);

const messageClasses = (message: Message) => ({
  "message-list__message--supplier":
    message.messageDomain === MessageDomain.SUPPLIER_CUSTOMER,
  "message-list__message--customer":
    message.messageDomain !== MessageDomain.SUPPLIER_CUSTOMER,
});

const checkScrollability = () => {
  if (!messagesContainerRef.value) return;

  const { scrollHeight, clientHeight, scrollTop } = messagesContainerRef.value;
  isScrollable.value = scrollHeight > clientHeight;
  showScrollIndicator.value =
    isScrollable.value && scrollHeight - clientHeight - scrollTop > 100;
};

const scrollToBottom = (behavior: ScrollBehavior = "auto") => {
  if (!messagesContainerRef.value) return;

  messagesContainerRef.value.scrollTo({
    top: messagesContainerRef.value.scrollHeight,
    behavior,
  });
};

const handleScroll = () => {
  checkScrollability();
};

// Scroll to bottom when new messages are added
watch(
  () => props.messages.length,
  () => {
    nextTick(() => {
      scrollToBottom("smooth");
      checkScrollability();
    });
  },
);

// Initial setup
onMounted(() => {
  scrollToBottom();
  checkScrollability();

  // Check scrollability on window resize
  window.addEventListener("resize", checkScrollability);

  // Cleanup
  onUnmounted(() => {
    window.removeEventListener("resize", checkScrollability);
  });
});
</script>

<style lang="scss" scoped>
.message-list {
  height: 100%;
  padding: 1rem;
  overflow-y: auto;
  scroll-behavior: smooth;
  position: relative;

  &__message {
    margin-bottom: 1rem;
    width: 80%;
    opacity: 0;
    animation: fadeIn 0.3s ease forwards;

    &--supplier {
      margin-left: auto;
    }

    &--customer {
      margin-right: auto;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__scroll-indicator {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    background: $color-white;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: $shadow-base;
    opacity: 0;
    visibility: hidden;
    transition:
      opacity 0.2s ease,
      visibility 0.2s ease;
    cursor: pointer;

    &--visible {
      opacity: 1;
      visibility: visible;
    }

    &:hover {
      background: $color-grey-lighter;
    }
  }

  &__scroll-icon {
    width: 1.25rem;
    height: 1.25rem;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
