import { computed, Ref } from "vue";
import { useSupplierStore } from "@/stores/supplier";
import { useCms } from "@/composables/useCms";
import { ContentType } from "@/custom-types/CmsContentTypes";

interface UseSupplierCmsData {
  isSupplierLoaded: Ref<boolean>;
  fetchSupplierAndCmsContent: () => Promise<void>;
}

const criticalContentKeys: ContentType[] = [
  ContentType.SERVICE_TYPES_CMS,
  ContentType.SUPPLIER_TASKS_PAGE_CMS,
];

const nonCriticalContentKeys: ContentType[] = [
  ContentType.SUPPLIER_PROFILE_PAGE_CMS,
  ContentType.SUPPLIER_CERTIFICATIONS_CMS,
  ContentType.SUPPLIER_SUSTAINABILITY_GOALS_CMS,
  ContentType.SUPPLIER_REGISTRATIONS_PAGE_CMS,
];

export function useSupplierCmsData(): UseSupplierCmsData {
  const supplierStore = useSupplierStore();

  const { fetchAndSetCmsContent } = useCms();

  const isSupplierLoaded = computed(() => !!supplierStore.supplier);

  const fetchSupplierAndCmsContent = async (): Promise<void> => {
    try {
      await fetchCmsContent(criticalContentKeys, "critical");
      await fetchCmsContent(nonCriticalContentKeys, "non-critical");
    } catch (error) {
      console.error("Error fetching supplier and CMS content:", error);
    }
  };

  const fetchCmsContent = async (
    contentKeys: ContentType[],
    contentType: "critical" | "non-critical",
  ): Promise<void> => {
    try {
      await Promise.all(contentKeys.map((key) => fetchAndSetCmsContent(key)));
    } catch (error) {
      console.error(`Error fetching ${contentType} CMS content:`, error);
      // TODO: Handle content fetch error based on contentType
    }
  };

  return { isSupplierLoaded, fetchSupplierAndCmsContent };
}
