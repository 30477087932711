<template>
  <confirmation-modal
    v-model="showModal"
    :status-icon="statusIcon"
    :title="cms.title"
    :description="cms.subtitle"
    :secondary-button-text="cms.secondaryButtonText"
    :primary-button-text="cms.primaryButtonText"
    :is-submitting="isSubmitting"
    @secondary-click="closeModal"
    @primary-click="handlePrimaryClick"
  >
    <template v-if="hasSubmissionError" #errorMessage>
      <p class="error">{{ TEXT.submissionErrorMessage }}</p>
    </template>
  </confirmation-modal>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useRouter } from "vue-router";
import ConfirmationModal from "@/components/ConfirmationModal/ConfirmationModal.vue";
import { useTenderApi } from "@/services/api/useTenderApi";
import { RouteNames } from "@/router/types";
import { useUserStore } from "@/stores/user";
const router = useRouter();

const TEXT = {
  submissionErrorMessage: "Kunne ikke fullføre tilbudet",
  ok: "Ok",
  cancel: "Avbryt",
  confirmationTitle: "Er du sikker på at du vil markere oppdraget som utført?",
  confirmationSubtitle: "Oppdraget vil bli flyttet til Avsluttet / Arkivert.",
  successTitle: "Tilbudet er gjennomført",
  successSubtitle: "",
} as const;

interface Props {
  modelValue: boolean;
  serviceOfferId: number | null;
  tenderId: number | null;
  supplierId?: number | null;
  supplierRequestId?: number | null;
}

const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits<{
  (e: "update:modelValue", value: boolean): void;
  (e: "completed"): void;
}>();

// State
const tenderOfferCompleted = ref(false);
const isSubmitting = ref(false);
const hasSubmissionError = ref(false);

// Store
const userStore = useUserStore();
const userId = computed(() => userStore.user?.userId);

// API
const { completeTenderOfferBySupplier } = useTenderApi();

// Computed
const showModal = computed({
  get: () => props.modelValue,
  set: (value: boolean) => emit("update:modelValue", value),
});

const cms = computed(() => {
  const cmsMapping = {
    title: tenderOfferCompleted.value
      ? TEXT.successTitle
      : TEXT.confirmationTitle,
    subtitle: tenderOfferCompleted.value
      ? TEXT.successSubtitle
      : TEXT.confirmationSubtitle,
    primaryButtonText: TEXT.ok,
    secondaryButtonText: tenderOfferCompleted.value ? "" : TEXT.cancel,
  };

  return cmsMapping;
});

const statusIcon = computed(() =>
  tenderOfferCompleted.value
    ? `/images/utility/round-check-mark.svg`
    : `/images/utility/round-question-mark.svg`,
);

// Methods
const closeModal = () => {
  showModal.value = false;
};

const navigateToCompletedProjects = () => {
  router.push({
    name: RouteNames.MY_AGREEMENTS,
    query: {
      tab: "priceRequest",
      tabList: "completed-projects",
    },
  });
};

const completeTenderOffer = async () => {
  isSubmitting.value = true;
  hasSubmissionError.value = false;

  if (!userId.value) {
    throw new Error("User ID is required");
  }

  const data = {
    userId: userId.value,
    audience: "Supplier" as const,
  };

  try {
    if (
      !props.supplierId ||
      !props.supplierRequestId ||
      !props.serviceOfferId
    ) {
      throw new Error(
        "Missing required props: supplierId or supplierRequestId or serviceOfferId",
      );
    }

    const response = await completeTenderOfferBySupplier({
      supplierId: props.supplierId,
      supplierRequestId: props.supplierRequestId,
      serviceOfferId: props.serviceOfferId,
      data,
    });

    if (response.status === 200) {
      tenderOfferCompleted.value = true;
      setTimeout(() => {
        emit("completed");
        navigateToCompletedProjects();
      }, 1500);
    } else {
      hasSubmissionError.value = true;
    }
  } catch (error) {
    hasSubmissionError.value = true;
    console.error("Error completing tender offer: ", error);
  } finally {
    setTimeout(() => {
      isSubmitting.value = false;
    }, 1500);
  }
};

const handlePrimaryClick = () => {
  if (tenderOfferCompleted.value) {
    closeModal();
    navigateToCompletedProjects();
  } else {
    if (!userId.value) {
      throw new Error("User ID is required");
    }
    completeTenderOffer();
  }
};

// Watchers
watch(
  () => showModal.value,
  (newVal) => {
    if (newVal) {
      tenderOfferCompleted.value = false;
      isSubmitting.value = false;
      hasSubmissionError.value = false;
    }
  },
);
</script>

<style lang="scss" scoped>
.error {
  font-weight: $font-weight-bold;
  font-size: $font-size-base;
  color: $color-error;
  margin-top: 0.16667rem;
}
</style>
