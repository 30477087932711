import {
  CustomerApi,
  CustomerApiEndpoints,
} from "@/config/api/customer/customerApi";
import { executeApiRequest } from "@/services/api/apiService";
import { useToastErrorHandler } from "@/composables/useToastErrorHandler";

const useCustomerApiService = () => {
  const { withToastErrorHandling } = useToastErrorHandler();

  const getCustomerDetailsForGivenPriceRequest = async (
    supplierId: number,
    priceRequestId: number,
  ) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<unknown>(
          {
            endpointKey: Symbol.for(
              CustomerApiEndpoints.GET_CUSTOMER_DETAILS_FOR_GIVEN_PRICE_REQUEST,
            ),
            config: CustomerApi,
          },
          {
            pathParams: [supplierId, "request", priceRequestId, "details"],
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Kundefeil",
          detail: "Kunne ikke hente kundedetaljer for prisforespørselen",
        },
      },
    );
  };

  return {
    getCustomerDetailsForGivenPriceRequest,
  };
};

export const useCustomerApi = useCustomerApiService;
