<template>
  <div>
    <div class="partners__title">Parter</div>
    <div class="partners__description">
      Under er alle partene i avtalen oppgitt.
    </div>
    <div class="partners__content">
      <div
        v-for="(partner, index) in partners"
        :key="index"
        class="partners__partner-wrapper"
      >
        <agreement-partner
          :title="partner.title"
          :partner-details="partner.details"
          :collapsible="collapsible"
          :expanded="collapsible ? expanded : true"
          @toggle-collapse="handleToggleCollapse"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue";
import { useSupplierStore } from "@/stores/supplier";
import AgreementPartner from "./AgreementPartner.vue";
import { useCustomerApi } from "@/services/api/useCustomerApi";
import { useSupplierApi } from "@/services/api/useSupplierApi";

interface Props {
  serviceOffer: {
    customerOrgName: string;
  };
  supplierId: number;
  priceRequestId: number;
  supplierOrgNumber: string;
  collapsible?: boolean;
}

interface Address {
  address?: string;
  postalCode?: string;
  city?: string;
}

interface CustomerDetails {
  streetAddress?: string;
  orgNumber?: string;
  postalCode?: string;
  city?: string;
}

const props = withDefaults(defineProps<Props>(), {
  collapsible: true,
});

const supplierStore = useSupplierStore();
const { getCustomerDetailsForGivenPriceRequest } = useCustomerApi();
const { getSupplierAddresses } = useSupplierApi();

const expanded = ref(false);
const supplierAddresses = ref<{ address: Address }[]>([]);
const customerDetails = ref<CustomerDetails>();

const getCityAddress = (address?: Address | CustomerDetails): string => {
  if (!address) return "";
  const postalCode = address.postalCode || "";
  const city = address.city || "";
  return [postalCode, city].join(" ").trim();
};

const supplier = computed(() => supplierStore.supplier);
const supplierAddress = computed(() => supplierAddresses.value?.[0]?.address);

const getFormattedOrgNumber = (orgNumber?: string): string =>
  `Org. nr.: ${orgNumber || "--"}`;

const partners = computed(() => [
  {
    title: "Kunde",
    details: [
      props.serviceOffer.customerOrgName,
      customerDetails.value?.streetAddress || "",
      getCityAddress(customerDetails.value),
      getFormattedOrgNumber(customerDetails.value?.orgNumber),
    ].filter(Boolean),
  },
  {
    title: "Leverandør",
    details: [
      supplier.value?.sysName,
      supplierAddress.value?.address || "-",
      getCityAddress(supplierAddress.value),
      getFormattedOrgNumber(props.supplierOrgNumber),
    ].filter(Boolean),
  },
]);

const handleToggleCollapse = () => {
  expanded.value = !expanded.value;
};

const fetchCustomerDetails = async () => {
  try {
    const response = await getCustomerDetailsForGivenPriceRequest(
      props.supplierId,
      props.priceRequestId,
    );
    customerDetails.value = response.data as CustomerDetails;
  } catch (error) {
    console.error("Error fetching customer details:", error);
    customerDetails.value = undefined;
  }
};

const fetchSupplierAddresses = async () => {
  try {
    const { data } = await getSupplierAddresses({
      supplierId: props.supplierId,
    });
    supplierAddresses.value = data || [];
  } catch (error) {
    console.error("Error fetching supplier addresses:", error);
    supplierAddresses.value = [];
  }
};

onMounted(async () => {
  if (!props.supplierId) return;
  await Promise.all([fetchSupplierAddresses(), fetchCustomerDetails()]);
});
</script>

<style lang="scss" scoped>
.partners {
  &__title {
    font-weight: 600;
    font-size: $font-size-2xl;
  }

  &__description {
    margin-top: 0.75rem;
    font-weight: 400;
    font-size: $font-size-base;
  }

  &__content {
    margin-top: 1rem;
    display: flex;
    gap: 2rem;
  }

  &__partner-wrapper {
    width: 50%;
  }
}
</style>
