<template>
  <div class="messages-view">
    <ChatView
      :tender="updatedTender"
      :supplier-id="supplierId"
      :is-request-state-accepted="isRequestStateAccepted"
      :price-request="priceRequest"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import ChatView from "./ChatView.vue";
import { RequestState } from "@/custom-types/GeneralTypes";
import type { Tender } from "@/stores/tender/types";
import { findSupplierRequest } from "@/utilities/tenderUtils";
import type { PriceRequestDetails } from "@/stores/tender/types";

interface Props {
  tender: Tender;
  supplierId: number;
  priceRequestDetails: PriceRequestDetails;
}

const props = defineProps<Props>();

const isRequestStateAccepted = computed(() => {
  const supplierRequest = findSupplierRequest(props.tender, props.supplierId);
  return supplierRequest?.requestState === RequestState.ACCEPTED;
});

const priceRequest = computed(() => {
  return props.tender.priceRequests[0];
});

const updatedTender = computed(() => {
  return {
    ...props.tender,
    tenderId: props.priceRequestDetails.tenderId,
  };
});
</script>

<style lang="scss" scoped>
.messages-view {
  min-height: 28rem;
  background-color: $color-white;
  text-align: left;
  font-family: $primary-font;
  color: $color-black;
}
</style>
