import { computed, markRaw } from "vue";
import type { Component } from "vue";
import { TenderViewTab } from "@/stores/tender/types";
import PriceRequestDetailsShell from "@/components/PriceRequestDetails/PriceRequestDetailsShell.vue";
import MessagingShell from "@/components/Messaging/MessagingShell.vue";
import AgreementDetailsShell from "@/components/AgreementDetails/AgreementDetailsShell.vue";
import ServiceOffersShell from "@/components/ServiceOffers/ServiceOffersShell.vue";
import ServiceReportShell from "@/components/ServiceReport/ServiceReportShell.vue";
import EconomyShell from "@/components/Economy/EconomyShell.vue";

export interface TabDefinition {
  value: string;
  label: string;
  component: Component;
  disabled?: boolean;
}

const TAB_DEFINITIONS: Record<TenderViewTab, Omit<TabDefinition, "value">> = {
  [TenderViewTab.PRICE_REQUEST]: {
    label: "Prisforespørsel",
    component: markRaw(PriceRequestDetailsShell),
  },
  [TenderViewTab.MESSAGES]: {
    label: "Meldinger",
    component: markRaw(MessagingShell),
  },
  [TenderViewTab.AGREEMENT]: {
    label: "Avtale",
    component: markRaw(AgreementDetailsShell),
  },
  [TenderViewTab.OFFERS]: {
    label: "Tilbud",
    component: markRaw(ServiceOffersShell),
  },
  [TenderViewTab.DOCUMENTATION]: {
    label: "Dokumentasjon",
    component: markRaw(ServiceReportShell),
  },
  [TenderViewTab.ECONOMY]: {
    label: "Økonomi",
    component: markRaw(EconomyShell),
  },
};

const TENDER_TAB_SETS = {
  default: [
    TenderViewTab.PRICE_REQUEST,
    TenderViewTab.MESSAGES,
    TenderViewTab.OFFERS,
  ],
  withAgreement: [
    TenderViewTab.PRICE_REQUEST,
    TenderViewTab.AGREEMENT,
    TenderViewTab.MESSAGES,
    TenderViewTab.DOCUMENTATION,
    TenderViewTab.ECONOMY,
  ],
  completed: [
    TenderViewTab.PRICE_REQUEST,
    TenderViewTab.AGREEMENT,
    TenderViewTab.MESSAGES,
    TenderViewTab.DOCUMENTATION,
    TenderViewTab.ECONOMY,
  ],
} as const;

export const useTenderTabs = (
  hasActiveAgreement: boolean,
  isCompleted: boolean,
) => {
  const getAvailableTabs = computed((): TabDefinition[] => {
    let tabSet;
    if (isCompleted) {
      tabSet = TENDER_TAB_SETS.completed;
    } else {
      tabSet = hasActiveAgreement
        ? TENDER_TAB_SETS.withAgreement
        : TENDER_TAB_SETS.default;
    }

    return tabSet.map((tab) => ({
      value: tab,
      ...TAB_DEFINITIONS[tab],
    }));
  });

  return {
    getAvailableTabs,
    TAB_DEFINITIONS,
  };
};
