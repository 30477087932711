<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<template>
  <teleport to="body">
    <div>
      <b-modal
        v-model="showModal"
        :teleport-disabled="true"
        :no-close-on-backdrop="true"
        body-class="create-price-request-modal__body p-0"
        centered
        hide-footer
        hide-header
        size="lg"
      >
        <div v-if="formCms" class="create-price-request-modal">
          <b-form
            class="create-price-request-modal__form"
            @submit.prevent="submitForm"
          >
            <div class="create-price-request-modal__header">
              <div class="create-price-request-modal__header-title">
                {{ formCms.modalTitle }}
              </div>
              <div class="create-price-request-modal__header-subtitle">
                {{ formCms.modalSubtitle }}
              </div>
            </div>

            <b-form-group
              :label="formCms.inputs?.selectServiceTypeInput?.title"
              label-for="service-type-select"
              class="create-price-request-modal__group"
            >
              <v-field
                v-slot="{ componentField, errors }"
                name="serviceType"
                :rules="validationRules.required"
              >
                <b-form-select
                  id="service-type-select"
                  v-bind="componentField"
                  :style="selectStyle"
                  :options="serviceTypeSelectOptions"
                  :disabled="hasSingleService"
                  class="create-price-request-modal__select"
                />
                <b-form-invalid-feedback :state="!errors.length">
                  {{ formCms.inputs?.selectServiceTypeInput?.error }}
                </b-form-invalid-feedback>
              </v-field>
            </b-form-group>

            <b-form-group
              :label="formCms.inputs?.selectCategoryInput?.title"
              label-for="service-type-category-select"
              class="create-price-request-modal__group"
            >
              <v-field
                v-slot="{ componentField, errors }"
                name="serviceTypeCategory"
                :rules="validationRules.required"
              >
                <b-form-select
                  id="service-type-category-select"
                  v-bind="componentField"
                  :style="selectStyle"
                  :options="serviceCategorySelectOptions"
                  class="create-price-request-modal__select"
                />
                <b-form-invalid-feedback :state="!errors.length">
                  {{ formCms.inputs?.selectCategoryInput?.error }}
                </b-form-invalid-feedback>
              </v-field>
            </b-form-group>

            <b-form-group
              :label="formCms.inputs?.selectResponsibleUserInput?.title"
              label-for="select-responsible-user"
              class="create-price-request-modal__group"
            >
              <v-field
                v-slot="{ componentField, errors }"
                name="selectResponsibleUser"
              >
                <b-form-select
                  id="select-responsible-user"
                  v-bind="componentField"
                  :style="selectStyle"
                  :options="responsibleUserSelectOptions"
                  class="create-price-request-modal__select"
                />
                <b-form-invalid-feedback :state="!errors.length">
                  {{ formCms.inputs?.selectResponsibleUserInput?.error }}
                </b-form-invalid-feedback>
              </v-field>
            </b-form-group>

            <b-form-group
              :label="formCms.inputs?.descriptionInput?.title"
              label-for="description-textarea"
              class="create-price-request-modal__group-textarea"
            >
              <v-field
                v-slot="{ componentField, errors }"
                name="description"
                :rules="validationRules.required"
              >
                <b-form-textarea
                  id="description-textarea"
                  v-bind="componentField"
                  :placeholder="formCms.inputs?.descriptionInput?.placeholder"
                  rows="4"
                  class="create-price-request-modal__textarea"
                />
                <b-form-invalid-feedback :state="!errors.length">
                  {{ formCms.inputs?.descriptionInput?.error }}
                </b-form-invalid-feedback>
              </v-field>
            </b-form-group>

            <div class="create-price-request-modal__attachments">
              <div class="create-price-request-modal__header-subtitle">
                {{ formCms.inputs?.attachmentsInput?.title }}
              </div>
              <div
                v-if="values.attachments.length"
                class="create-price-request-modal__attachments"
              >
                <div
                  v-for="attachment in values.attachments"
                  :key="attachment.id"
                  class="create-price-request-modal__attachment"
                >
                  <attachment-file
                    :attachment="attachment"
                    @remove="handleRemoveAttachment(attachment)"
                  />
                </div>
              </div>
              <file-input
                v-slot="{ openFileInput }"
                :valid-file-extensions="validFileExtensions"
                @selected-files="handleSelectedFiles"
              >
                <button
                  class="btn create-price-request-modal__upload-btn"
                  @click.prevent="openFileInput"
                >
                  <img
                    src="/images/upload.svg"
                    alt="Upload Icon"
                    class="btn__icon"
                  />
                  {{ formCms.buttons?.uploadButton }}
                </button>
              </file-input>
            </div>

            <!-- Form Submission Feedback -->
            <b-alert
              :show="feedbackState.dismissCountDown"
              dismissible
              :variant="feedbackState.variant as BaseColorVariant"
              @dismissed="feedbackState.dismissCountDown = 0"
              @dismiss-count-down="handleCountDownChanged"
              >{{ feedbackState.message }}
            </b-alert>

            <div class="create-price-request-modal__btn-actions">
              <b-button
                :disabled="isSubmitting"
                class="btn create-price-request-modal__cancel-btn"
                @click.prevent="handleCancel"
              >
                {{ formCms.buttons?.cancelButton }}
              </b-button>
              <b-button
                type="submit"
                :disabled="isSubmitting"
                class="btn create-price-request-modal__submit-btn"
              >
                <b-spinner
                  v-if="isSubmitting"
                  class="create-price-request-modal__spinner"
                />
                {{ formCms.buttons?.submitButton }}
              </b-button>
            </div>
          </b-form>
        </div>
      </b-modal>
    </div>
  </teleport>
</template>

<script setup lang="ts">
import { computed, ref, watch, onMounted } from "vue";
import { Field as VField, useForm } from "vee-validate";
import { string } from "yup";
import { cloneDeep } from "lodash";
import { useRouter } from "vue-router";
import dayjs from "dayjs";

import FileInput from "@/components/FileInput/FileInput.vue";
import AttachmentFile from "./AttachmentFile.vue";
import { uuidv4 } from "@/utilities/uuid";
import {
  convertFileToBase64,
  getFilesWithValidExtension,
  getFilesWithValidSize,
} from "@/utilities/fileUtils";
import cmsService from "@/services/cmsService";
import { useTenderApi } from "@/services/api/useTenderApi";
import { useUserStore } from "@/stores/user";
import { RouteNames } from "@/router/types";
import type { Tender } from "@/stores/tender/types";

// Types
type BaseColorVariant = "danger" | "success" | "warning" | "info";

interface Attachment {
  id: string;
  file: File;
  fileName: string;
  attachmentUri: string | null;
}

interface FormValues {
  serviceType: string;
  serviceTypeCategory: string;
  selectResponsibleUser: string;
  description: string;
  attachments: Attachment[];
}

interface FeedbackState {
  dismissSecs: number;
  dismissCountDown: number;
  variant: string;
  message: string;
}

interface FormCmsInput {
  title: string;
  error: string;
  placeholder?: string;
}

interface FormCms {
  modalTitle: string;
  modalSubtitle: string;
  inputs: {
    [key: string]: FormCmsInput;
  };
  buttons: {
    [key: string]: string;
  };
}

interface ServiceTypeCategory {
  id: number;
  label: string;
  title: string;
}

interface ServiceType {
  id: number;
  label: string;
  title: string;
  categories: ServiceTypeCategory[];
}

interface SupplierUser {
  userId: string;
  displayName: string;
  email: string;
}

export interface TenderSubmissionDto {
  supplierIds: number[];
  parentTenderId: string;
  integrationSource: string;
  integrationExternalId: string;
  customerOrganizationDto: {
    orgNumber: string;
    name: string;
  };
  orderingUserEmail: string;
  propertySubmissions: {
    serviceTypeFormDtos: {
      categoryId: number;
      categoryLabel: string;
      categoryLabelText: null;
      jobType: string;
      propertyTypeId: number;
      propertyTypeLabel: string;
      propertyTypeLabelText: null;
      robot: boolean;
      supplierTypeId: number;
      supplierTypeLabel: string;
      supplierTypeLabelText: null;
      message: string;
      attachments: {
        fileName: string;
        fileContent: string;
      }[];
      wantedStartDate: Date;
    }[];
    propertyDto: {
      propertyTypeId: number;
      addressDto: {
        address: string;
        postalCode: string;
        county: string;
        city: string;
      };
      nickName: string;
      id: number;
    };
  }[];
}

interface Props {
  modelValue: boolean;
  supplierServiceTypes: ServiceType[];
  supplierUsers: SupplierUser[] | null;
  tender: Tender;
  supplierId: number;
}

// Props definition
const props = defineProps<Props>();
const emit = defineEmits(["update:modelValue"]);

// Composition API hooks
const userStore = useUserStore();
const router = useRouter();
const { submitTender, setAgreementResponsible } = useTenderApi();

// State
const isSubmitting = ref(false);
const formCms = ref<FormCms | null>(null);
const validFileExtensions = ref<string[]>([".pdf", ".jpg", ".jpeg", ".png"]);
const maxFileSizeInMB = ref(20);
const selectIcon = ref("/images/drop-down.svg");

// Validation rules
const validationRules = {
  required: string().required(),
};

// Feedback state
const feedbackState = ref<FeedbackState>({
  dismissSecs: 10,
  dismissCountDown: 0,
  variant: "danger",
  message: "",
});

// Form setup
const initialValues: FormValues = {
  serviceType: "",
  serviceTypeCategory: "",
  selectResponsibleUser: "",
  description: "",
  attachments: [],
};

const { resetForm, handleSubmit, values, setValues } = useForm<FormValues>({
  initialValues,
});

// Computed properties
const showModal = computed({
  get() {
    return props.modelValue;
  },
  set(newVal: boolean) {
    emit("update:modelValue", newVal);
  },
});

const user = computed(() => userStore.user);

const serviceTypeSelectOptions = computed(() => {
  return [
    { value: "", text: "Velg tjeneste" },
    ...props.supplierServiceTypes.map((serviceType) => ({
      value: serviceType.label,
      text: serviceType.title,
    })),
  ];
});

const serviceCategorySelectOptions = computed(() => {
  const selectedServiceType = props.supplierServiceTypes.find(
    (serviceType) => serviceType.label === values.serviceType,
  );
  const serviceTypeCategories = selectedServiceType?.categories || [];

  return [
    { value: "", text: "Velg kategori" },
    ...serviceTypeCategories.map((category) => ({
      value: category.label,
      text: category.title,
    })),
  ];
});

const responsibleUserSelectOptions = computed(() => {
  const responsibleUsers = props.supplierUsers || [];

  return [
    { value: "", text: "Alle brukere ansvarlig" },
    ...responsibleUsers.map((user) => ({
      value: user.userId,
      text: `${user.displayName} - ${user.email}`,
    })),
  ];
});

const hasSingleService = computed(() => {
  return props.supplierServiceTypes.length === 1;
});

const selectStyle = computed(() => {
  return {
    background: "none",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right center",
    backgroundPositionX: "revert-layer",
    backgroundImage: `url(${selectIcon.value})`,
  };
});

const supplierRequestId = computed((): number | undefined => {
  return props.tender.priceRequests?.[0]?.supplierRequests?.[0]?.id;
});

// Watchers
watch(
  () => showModal.value,
  (newVal) => {
    if (newVal) {
      resetForm();
      if (hasSingleService.value) {
        setValues(
          {
            ...initialValues,
            serviceType: props.supplierServiceTypes[0]?.label || "",
          },
          false,
        );
      }
    }
  },
);

watch(
  () => values.serviceType,
  () => {
    if (values.serviceTypeCategory) {
      setValues(
        {
          ...values,
          serviceTypeCategory: "",
        },
        false,
      );
    }
  },
);

// Methods
const handleCountDownChanged = (dismissCountDown: number): void => {
  feedbackState.value.dismissCountDown = dismissCountDown;
};

const showAlert = (variant: string, message: string): void => {
  feedbackState.value.variant = variant;
  feedbackState.value.message = message;
  feedbackState.value.dismissCountDown = feedbackState.value.dismissSecs;
};

const handleCancel = (): void => {
  showModal.value = false;
};

const handleSelectedFiles = (files: FileList): void => {
  if (!files.length) return;

  let validFiles = getFilesWithValidExtension(
    Array.from(files),
    validFileExtensions.value,
  );

  validFiles = getFilesWithValidSize(validFiles, maxFileSizeInMB.value);

  const newAttachments: Attachment[] = validFiles.map((file) => ({
    id: uuidv4(),
    file,
    fileName: file.name,
    attachmentUri: null,
  }));

  setValues(
    {
      ...values,
      attachments: [...newAttachments, ...values.attachments],
    },
    false,
  );
};

const handleRemoveAttachment = (attachment: Attachment): void => {
  const attachments = cloneDeep(values.attachments);
  const filteredAttachments = attachments.filter(
    (item) => item.id !== attachment.id,
  );

  setValues(
    {
      ...values,
      attachments: filteredAttachments,
    },
    false,
  );
};

const getMinimumStartDate = (): Date => {
  return dayjs().add(7, "day").toDate();
};

const constructDto = async (): Promise<TenderSubmissionDto> => {
  const { propertySubmissions } = await getPropertySubmissions();

  return {
    supplierIds: [props.supplierId],
    parentTenderId: props.tender.tenderId.toString(),
    integrationSource: "SupplierAdmin",
    integrationExternalId: uuidv4(),
    customerOrganizationDto: {
      orgNumber: props.tender.customerOrganization.orgNumber,
      name: props.tender.customerOrganization.name,
    },
    orderingUserEmail: user.value?.email || "",
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    propertySubmissions: propertySubmissions as any,
  };
};

const getPropertySubmissions = async () => {
  const { priceRequests } = props.tender;
  const {
    customerPropertyAddress,
    customerPropertyId,
    customerPropertyTypeLabel,
    customerPropertyTypeId,
  } = priceRequests?.[0] ?? {};

  const selectedServiceType = props.supplierServiceTypes.find(
    (type) => type.label === values.serviceType,
  );

  if (!selectedServiceType) {
    throw new Error("Selected service type not found");
  }

  const selectedServiceTypeCategory = selectedServiceType.categories.find(
    (category) => category.label === values.serviceTypeCategory,
  );

  if (!selectedServiceTypeCategory) {
    throw new Error("Selected service category not found");
  }

  const supplierServiceTypeId = selectedServiceType.id;
  const serviceCategoryId = selectedServiceTypeCategory.id;

  const augmentedAttachments = await Promise.all(
    values.attachments.map(async (attachment) => {
      try {
        const fileContent = await convertFileToBase64(attachment.file);
        return {
          fileName: attachment.file.name,
          fileContent,
        };
      } catch (error) {
        console.error("Error converting file to base64:", error);
        throw error;
      }
    }),
  );

  const serviceTypeFormDtos = [
    {
      categoryId: serviceCategoryId,
      categoryLabel: values.serviceTypeCategory,
      categoryLabelText: null,
      jobType: "",
      propertyTypeId: customerPropertyTypeId,
      propertyTypeLabel: customerPropertyTypeLabel,
      propertyTypeLabelText: null,
      robot: false,
      supplierTypeId: supplierServiceTypeId,
      supplierTypeLabel: values.serviceType,
      supplierTypeLabelText: null,
      message: values.description,
      attachments: augmentedAttachments,
      wantedStartDate: getMinimumStartDate(),
    },
  ];

  const propertyDto = {
    propertyTypeId: customerPropertyTypeId,
    addressDto: {
      address: customerPropertyAddress,
      postalCode: "",
      county: "",
      city: "",
    },
    nickName: "",
    id: customerPropertyId,
  };

  return {
    propertySubmissions: [
      {
        serviceTypeFormDtos,
        propertyDto,
      },
    ],
  };
};

const updateAgreementResponsible = async (): Promise<void> => {
  if (
    !values.selectResponsibleUser ||
    !props.supplierUsers ||
    !supplierRequestId.value
  ) {
    return;
  }

  const selectedResponsibleUser = props.supplierUsers.find(
    (user) => user.userId === values.selectResponsibleUser,
  );

  if (!selectedResponsibleUser) {
    return;
  }

  try {
    await setAgreementResponsible({
      supplierId: props.supplierId,
      supplierRequestId: supplierRequestId.value,
      data: {
        AgreementOwnerUserId: selectedResponsibleUser.userId,
        SendNotification: true,
      },
    });
  } catch (error) {
    console.error("Error setting agreement responsible:", error);
    throw error;
  }
};

const redirectAfterDelay = (): void => {
  const { priceRequests } = props.tender;
  const priceRequestId = priceRequests[0]?.id;
  showModal.value = false;
  router.push({
    name: RouteNames.CREATE_OFFER,
    params: {
      priceRequestId: priceRequestId,
      supplierRequestId: supplierRequestId.value,
    },
  });
};

const handleError = (error: unknown): void => {
  console.error(error);
  showAlert("danger", "Feil ved innlevering av tilbud");
};

const handleSuccessfulSubmission = async (): Promise<void> => {
  try {
    await updateAgreementResponsible();
    showAlert("success", "Prisforespørselen ble sendt inn");
    setTimeout(() => {
      redirectAfterDelay();
    }, 2500);
  } catch (error) {
    handleError(error);
  }
};

const submitForm = handleSubmit(async () => {
  if (isSubmitting.value) return;

  isSubmitting.value = true;

  try {
    const dto = await constructDto();
    const response = await submitTender(dto);

    if ([200, 204].includes(response.status)) {
      await handleSuccessfulSubmission();
    }
  } catch (error) {
    handleError(error);
  } finally {
    isSubmitting.value = false;
  }
});

// Lifecycle hooks
const fetchPageCMSData = async () => {
  try {
    const data = await cmsService.getSupplierMyAgreementsPage();
    return data[0];
  } catch (error) {
    console.error("Error fetching CMS data:", error);
    return null;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const initializeCmsData = (pageCms: any): void => {
  if (!pageCms) return;

  const { forms } = pageCms;
  const form = forms.find(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (form: any) => form.label === "createPriceRequestModalForm",
  );

  if (form) {
    const { otherText, inputs, buttons } = form;

    const modalTitle = otherText.find(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (text: any) => text.label === "modalTitle",
    )?.title;

    const modalSubtitle = otherText.find(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (text: any) => text.label === "modalSubtitle",
    )?.title;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const formInputs = inputs.reduce((acc: any, input: any) => {
      acc[input.label] = {
        title: input.title,
        error: input.error,
        placeholder: input.placeholder,
      };
      return acc;
    }, {});

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const formButtons = buttons.reduce((acc: any, button: any) => {
      acc[button.label] = button.title;
      return acc;
    }, {});

    formCms.value = {
      modalTitle,
      modalSubtitle,
      inputs: formInputs,
      buttons: formButtons,
    };
  }
};

onMounted(async () => {
  const pageCms = await fetchPageCMSData();
  initializeCmsData(pageCms);
});
</script>

<style lang="scss" scoped>
$base-padding: 20px;
$base-border: 1px solid #000;
$base-font-color: #333;
$sub-font-color: #666;
$highlight-color: rgba(0, 0, 0, 0.05);
$active-color: rgba(0, 0, 0, 0.1);
$primary-color: #611f69;
$primary-color-darker: #5b0050;

.create-price-request-modal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 40px 56px;
  gap: 16px;
  font-family: "Montserrat", serif;

  &__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }

  &__header-title {
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 16px;
  }

  &__header-subtitle {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 16px;
  }

  &__group {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 336px;
  }

  &__group label {
    font-size: 16px;
  }

  &__group-textarea {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    min-width: 400px;
  }

  &__group-textarea label {
    font-size: 16px;
  }

  &__attachment {
    display: flex;
    flex-direction: column;
  }

  &__upload-btn {
    display: flex;
    padding: 0 24px 0 16px;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: $color-primary-pink;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400 !important;
    line-height: normal;
    text-transform: none !important;

    &:hover {
      background: $color-primary-pink-darker;
      transform: scale(1.05);
      color: #000;
    }

    &:active {
      background: $color-primary-pink-darker;
      color: #000;
      transform: scale(1);
    }
  }

  &__attachments {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  &__attachment {
    &:not(:first-child) {
      margin-top: 0.5rem;
    }
  }

  &__btn-actions {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    gap: 26px;
  }

  &__cancel-btn {
    background-color: transparent;
    color: $primary-color;
    border: 2px solid $primary-color !important;

    &:hover {
      background-color: transparent;
      color: $primary-color;
      border-color: $primary-color-darker;
      transform: scale(1.05);
    }

    &:active {
      transform: scale(1);
      background-color: transparent;
    }

    &:disabled {
      background-color: transparent;
      color: $primary-color;
    }
  }

  &__submit-btn {
    background-color: $primary-color;
    border: 2px solid $primary-color !important;
    color: white;

    &:focus {
      background-color: $primary-color-darker;
    }

    &:hover {
      background-color: $primary-color-darker;
      transform: scale(1.05);
    }

    &:active {
      transform: scale(1);
      background-color: $primary-color-darker;
    }

    &:disabled {
      background-color: $primary-color-darker;
      color: white;
    }
  }

  &__spinner {
    font-size: 14px;
    width: 1rem;
    height: 1rem;
    margin-right: 4px;
  }
}

.btn {
  padding: 16px 40px;
  border: none;
  border-radius: 6px;
  font-weight: bold;
  cursor: pointer;
  font-size: 14px;
  line-height: normal;
  text-transform: uppercase;
  transition:
    background-color 0.3s,
    transform 0.2s;
}

.btn__icon {
  margin-right: 8px;
  vertical-align: middle;
}

:deep(.form-control) {
  border-radius: 4px;
  border: 1px solid #1d1d1d;
}

:deep(.modal-body) {
  display: flex;
  padding: 40px 56px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

:deep(.form-select) {
  display: flex;
  align-items: center;
  padding: 0 16px;
  border: 1px solid #1d1d1d !important;
  border-radius: 4px;
  height: 48px;
  box-shadow: none;

  &:focus {
    box-shadow: none;
  }
}

:deep(.modal-lg) {
  max-width: 580px !important;
}

:deep(*) {
  font-family: "Montserrat", sans-serif;
}
</style>
