import { ApiConfig } from "@/config/api/types";
import { authHeader } from "@/config/api/headers";

export enum SupplierApiEndpoints {
  GET_SUPPLIER_BY_USER_ID = "getSupplierByUserId",
  GET_SUPPLIER_SUSTAINABILITY_GOALS = "getSupplierSustainabilityGoals",
  GET_SUPPLIER_CERTIFICATIONS = "getSupplierCertifications",
  GET_SUPPLIER_SERVICE_CATEGORIES = "getSupplierServiceCategories",
  GET_SUPPLIER_ADDRESSES = "getSupplierAddresses",
  FETCH_CATEGORIES_BY_SUPPLIER_TYPE = "fetchCategoriesBySupplierType",
  UPDATE_SUPPLIER_PROFILE = "updateSupplierProfile",
  CREATE_SUPPLIER_USER = "createSupplierUser",
  GET_SUPPLIER_BY_ORG_NUMBER = "getSupplierByOrgNumber",
  CREATE_SUPPLIER_PROFILE = "createSupplierProfile",
  EDIT_SUPPLIER_LOGO = "editSupplierLogo",
  GET_SUPPLIER_LOGO = "getSupplierLogo",
}

export const SupplierApi: ApiConfig = {
  baseUri: import.meta.env.VITE_APP_BASE_URI || "",
  contextPath: "/Supplier",
  headers: [authHeader],
  endpoints: [
    {
      key: Symbol.for(SupplierApiEndpoints.FETCH_CATEGORIES_BY_SUPPLIER_TYPE),
      method: "GET",
      url: "/categories",
      description: "Fetch categories by supplier type",
    },
    {
      key: Symbol.for(SupplierApiEndpoints.GET_SUPPLIER_BY_USER_ID),
      method: "GET",
      url: "/user",
      description: "Get supplier by user ID",
    },
    {
      key: Symbol.for(SupplierApiEndpoints.GET_SUPPLIER_SUSTAINABILITY_GOALS),
      method: "GET",
      url: "",
      description: "Get supplier sustainability goals",
    },
    {
      key: Symbol.for(SupplierApiEndpoints.GET_SUPPLIER_CERTIFICATIONS),
      method: "GET",
      url: "",
      description: "Get supplier certifications",
    },
    {
      key: Symbol.for(SupplierApiEndpoints.GET_SUPPLIER_SERVICE_CATEGORIES),
      method: "GET",
      url: "",
      description: "Get supplier service categories",
    },
    {
      key: Symbol.for(SupplierApiEndpoints.GET_SUPPLIER_ADDRESSES),
      method: "GET",
      url: "",
      description: "Get supplier addresses",
    },
    {
      key: Symbol.for(SupplierApiEndpoints.UPDATE_SUPPLIER_PROFILE),
      method: "PUT",
      url: "",
      description: "Update supplier",
    },
    {
      key: Symbol.for(SupplierApiEndpoints.CREATE_SUPPLIER_PROFILE),
      method: "POST",
      url: "/Create",
      description: "Create supplier",
    },
    {
      key: Symbol.for(SupplierApiEndpoints.CREATE_SUPPLIER_USER),
      method: "PATCH",
      url: "",
      description: "Create supplier user",
    },
    {
      key: Symbol.for(SupplierApiEndpoints.GET_SUPPLIER_BY_ORG_NUMBER),
      method: "GET",
      url: "/orgNumber",
      description: "Get supplier by org number",
    },
    {
      key: Symbol.for(SupplierApiEndpoints.EDIT_SUPPLIER_LOGO),
      method: "PATCH",
      url: "",
      description: "Edit supplier logo",
    },
    {
      key: Symbol.for(SupplierApiEndpoints.GET_SUPPLIER_LOGO),
      method: "GET",
      url: "",
      description: "Get supplier logo",
    },
  ],
};
