<template>
  <div v-if="isSupplierLoaded" class="supplier-admin">
    <app-header />
    <div class="supplier-admin__content">
      <sidebar />
      <main class="supplier-admin__main">
        <router-view />
      </main>
    </div>
  </div>
  <loading-view v-else />
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useSupplierCmsData } from "@/composables/useSupplierCmsData";
import LoadingView from "@/views/LoadingView/LoadingView.vue";
import AppHeader from "@/ui/Header/AppHeader.vue";
import Sidebar from "@/ui/Sidebar/Sidebar.vue";
import { useSupplierStore } from "@/stores/supplier";
import { useSupplierUsersStore } from "@/stores/supplierUsers";

const { isSupplierLoaded, fetchSupplierAndCmsContent } = useSupplierCmsData();
const isLoading = ref(true);
const supplierStore = useSupplierStore();
const supplierUsersStore = useSupplierUsersStore();

const initialize = async () => {
  try {
    await fetchSupplierAndCmsContent();

    // Fetch supplier users after supplier data is loaded
    if (supplierStore.supplier?.id) {
      const supplierUsers = supplierUsersStore.getSupplierUsers;
      if (supplierUsers.length === 0) {
        await supplierUsersStore.fetchSupplierUsers(supplierStore.supplier.id);
      }
    }

    isLoading.value = false;
  } catch (error) {
    console.error("Error initializing component:", error);
  }
};
initialize();
</script>

<style lang="scss" scoped>
.supplier-admin {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &__content {
    display: grid;
    grid-template-columns: auto 1fr;
    flex: 1;
    height: calc(100vh - #{$headerTopBarHeight});
  }

  &__main {
    overflow-y: auto;
  }
}
</style>
