<template>
  <div class="tender-offer">
    <router-view v-if="supplier" :supplier="supplier" />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useSupplierStore } from "@/stores/supplier";

const supplierStore = useSupplierStore();

// Make supplier reactive by computing it from the store
const supplier = computed(() => supplierStore.supplier);
</script>

<style lang="scss" scoped>
.tender-offer {
  &__loader {
    display: flex;
    justify-content: center;
    padding: 4rem 0;
  }
}
</style>
