import type {
  Tender,
  PriceRequest,
  SupplierRequest,
  RequestType,
  BillingCycle,
} from "@/stores/tender/types";

interface BaseTenderData {
  priceRequestId: number;
  createdOn: string;
  tenderReference: string;
  integrationSource: string;
  deadline: string;
  customerName: string;
  customerAddress: string;
  customerPosition: string;
  categoryId: number;
  categoryLabel: string;
  supplierRequestId: number;
  supplierRequestState: string;
  supplierId?: number;
  userResponsible?: string | null;
  offerResponsePeriodInDays?: number;
  customerOrgNumber?: string;
  customerOrganizationId?: number;
  terminationPeriodInMonths?: number;
  priceRequestRulesId?: number;
  serviceTypeId?: number;
  serviceCategoryId?: number;
  customerPropertyId?: number;
  customerPropertyTypeId?: number;
  serviceCategoryLabel?: string;
  serviceTypeLabel?: string;
  requestType?: RequestType;
  wantedStartDate?: string;
  surveyDateTime?: string;
  supplierName?: string;
  supplierOrgNumber?: number;
  bookingNumber?: string;
  tenderId?: number;
  hasUnreadMessages?: boolean;
  billingCycles?: BillingCycle[];
  offerCount?: number;
  unreadMessagesCount?: number;
  hasFrameAgreement?: boolean;
  frameAgreementId?: number;
  customerPropertyTypeLabel?: string;
}

export const mapToTenderFormat = (
  data: BaseTenderData,
  type: "request" | "agreement",
  supplierId?: number,
): Tender => {
  const supplierRequest: SupplierRequest = {
    id: data.supplierRequestId,
    supplierId: supplierId ?? data.supplierId ?? 0,
    requestState:
      data.supplierRequestState.charAt(0).toUpperCase() +
      data.supplierRequestState.slice(1),
    agreementOwnerUserId: data.userResponsible || null,
    frameAgreementId: data.frameAgreementId ?? 0,
    terminationPeriodInMonths: data.terminationPeriodInMonths ?? 0,
    hasFrameAgreement: data.hasFrameAgreement ?? false,
    archived: false,
    requestStateChanges: [],
    serviceOffers: [],
    requestStateAudience: "Customer",
    supplierName: data.customerName || "",
    firstOfferDateTime: "0001-01-01T00:00:00Z",
    supplierRequestAcceptances: [],
    offerCount: data.offerCount ?? 0,
  };

  const priceRequest: PriceRequest = {
    id: data.priceRequestId,
    message: "",
    firstOfferDateTime:
      type === "agreement" ? data.createdOn : "0001-01-01T00:00:00Z",
    tenderId: data.tenderId ?? 0,
    serviceTypeId: data.serviceTypeId ?? 0,
    serviceCategoryId: data.categoryId ?? 0,
    customerPropertyId: data.customerPropertyId ?? 0,
    customerPropertyTypeId: data.customerPropertyTypeId ?? 0,
    customerPropertyTypeLabel: data.customerPropertyTypeLabel ?? "",
    serviceCategoryLabel: data.categoryLabel ?? "",
    serviceTypeLabel: data.serviceTypeLabel ?? "",
    wantedStartDate: data.wantedStartDate ?? "",
    priceRequestRulesId: data.priceRequestRulesId ?? 0,
    offerResponsePeriodInDays:
      type === "request" ? (data.offerResponsePeriodInDays ?? 0) : 0,
    supplierRequests: [supplierRequest],
    messageAttachmentDtos: [],
    segmentValues: [],
    customerPropertyAddress: data.customerAddress ?? "",
    hasUnreadMessages: data.hasUnreadMessages ?? false,
    unreadMessagesCount: data.unreadMessagesCount ?? 0,
    createdOn: data.createdOn,
    deadlineDate: data.deadline ?? "",
  };

  return {
    tenderId: data.tenderId ?? 0,
    createdOn: data.createdOn,
    customerOrganizationId: data.customerOrganizationId ?? 0,
    externalTenderId: data.tenderReference,
    integrationSource:
      data.integrationSource.charAt(0).toUpperCase() +
      data.integrationSource.slice(1),
    integrationExternalId: "",
    validThrough: "",
    nickname: "",
    deleted: false,
    tenderState:
      data.supplierRequestState.charAt(0).toUpperCase() +
      data.supplierRequestState.slice(1),
    customerOrganizationName: data.customerName,
    priceRequests: [priceRequest],
    publicActor: false,
    acceptedOfferReferences: [data.bookingNumber ?? ""],
    customerOrganization: {
      name: data.customerName,
      orgNumber: data.customerOrgNumber || "",
      locked: false,
    },
    supplierPortfolios: [],
    tenderComments: [],
    tenderVersions: [],
    billingCycles: data.billingCycles ?? [],
    tenderValue: 0,
  };
};
