import { computed, inject, ref, type Ref } from "vue";
import { RequestState } from "@/custom-types/GeneralTypes";
import type { Tender } from "@/stores/tender/types";
import dayjs from "dayjs";
import { useFeedbackCookie } from "@/utilities/useFeedbackCookie";
import type { ActivityStats } from "@/stores/communication/types";

const NEW_THRESHOLD_DAYS = 3;

// Create a symbol for the activity stats injection key
export const ACTIVITY_STATS_KEY = Symbol("activityStats");

export const useTenderWidgets = () => {
  const {
    hasNewRequestBeenViewed,
    hasMessagesBeenViewed,
    isMessageBadgeHidden,
    hasLostRequestBeenViewed,
    hasNewAgreementBeenViewed,
  } = useFeedbackCookie();

  // Try to get activity stats from injection, or use an empty array as fallback
  const activityStats = inject<Ref<ActivityStats[]>>(
    ACTIVITY_STATS_KEY,
    ref([]),
  );

  const isWithinThreshold = (date: string) =>
    dayjs().diff(dayjs(date), "days") <= NEW_THRESHOLD_DAYS;

  // Helper function to check if a price request ID is in the accepted agreements list
  const isPriceRequestInAcceptedAgreements = (
    priceRequestId: number,
  ): boolean => {
    if (!activityStats.value || activityStats.value.length === 0) return true;
    // Find all accepted agreements
    const acceptedAgreements = activityStats.value.filter(
      (stat: ActivityStats) => stat.requestState === "accepted",
    );
    // Check if the price request ID is in any of the accepted agreements
    return acceptedAgreements.some((agreement: ActivityStats) =>
      agreement.priceRequestIds.includes(priceRequestId),
    );
  };

  const shouldShowNewRequestWidget = (tender: Tender): boolean => {
    const priceRequest = tender.priceRequests[0];
    if (!priceRequest?.id) return false;

    if (
      priceRequest.supplierRequests[0]?.requestState !==
      RequestState.PRICE_REQUESTED
    ) {
      return false;
    }

    return (
      isWithinThreshold(priceRequest.createdOn) &&
      !hasNewRequestBeenViewed(priceRequest.id)
    );
  };

  const shouldShowUnreadMessagesWidget = computed(() => {
    return (tender: Tender): boolean => {
      const priceRequest = tender.priceRequests[0];
      if (!priceRequest?.id) {
        return false;
      }

      if (
        !priceRequest.hasUnreadMessages ||
        priceRequest.unreadMessagesCount === 0
      ) {
        return false;
      }

      const isViewed = hasMessagesBeenViewed(priceRequest.id);

      return isWithinThreshold(priceRequest.createdOn) && !isViewed;
    };
  });

  const shouldShowMessageBadge = computed(() => {
    return (tender: Tender): boolean => {
      const priceRequest = tender.priceRequests[0];
      if (!priceRequest?.id) {
        return false;
      }

      if (
        !priceRequest.hasUnreadMessages &&
        (priceRequest.unreadMessagesCount === 0 ||
          !priceRequest.unreadMessagesCount)
      ) {
        return false;
      }

      // Check if badge has been explicitly hidden
      if (priceRequest?.id && isMessageBadgeHidden(priceRequest?.id)) {
        return false;
      }

      return true;
    };
  });

  const shouldShowCustomerChoseAnotherSupplierWidget = (
    tender: Tender,
  ): boolean => {
    const priceRequest = tender.priceRequests[0];
    if (!priceRequest?.id) return false;

    if (
      priceRequest.supplierRequests[0]?.requestState !== RequestState.LOST &&
      priceRequest.supplierRequests[0]?.requestState !== RequestState.UNANSWERED
    ) {
      return false;
    }

    return !hasLostRequestBeenViewed(priceRequest.id);
  };

  const shouldShowNewAgreementWidget = (tender: Tender): boolean => {
    const priceRequest = tender.priceRequests[0];
    if (!priceRequest?.id) return false;

    if (
      priceRequest.supplierRequests[0]?.requestState !== RequestState.ACCEPTED
    ) {
      return false;
    }
    // Check if the price request ID is in the list of accepted agreements
    if (!isPriceRequestInAcceptedAgreements(priceRequest.id)) {
      return false;
    }
    // Check if the agreement has been viewed using any of the available methods
    const isViewed = hasNewAgreementBeenViewed(priceRequest.id);

    return isWithinThreshold(priceRequest.createdOn) && !isViewed;
  };

  return {
    shouldShowNewRequestWidget,
    shouldShowUnreadMessagesWidget,
    shouldShowMessageBadge,
    shouldShowCustomerChoseAnotherSupplierWidget,
    shouldShowNewAgreementWidget,
  };
};
