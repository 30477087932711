import { computed, reactive } from "vue";
import { useRouter } from "vue-router";
import { RouteNames } from "@/router/types";
import { FileRecord, getFileUrl } from "@/utilities/cmsUtils";
import { formatCurrency } from "@/utilities/numberUtils";
import { BillingCycleUnits, BillingCycle } from "@/custom-types/GeneralTypes";
import { ContentType } from "@/custom-types/CmsContentTypes";
import { Tender, ServiceOffer, FrameAgreement } from "@/stores/tender/types";
import useFileDownload from "@/composables/useFileDownload";
import { useFileAttachmentApi } from "@/services/api/useFileAttachmentApi";
import { useTenderApi } from "@/services/api/useTenderApi";
import { useCms } from "@/composables/useCms";
import { useUsersApi } from "@/services/api/useUserApi";
import { formatDateToDotSeparated } from "@/utilities/dateUtils";
import type { UserDTO } from "@/custom-types/GeneralTypes";
import { useSupplierStore } from "@/stores/supplier";

// Types
interface ExtendedBillableState {
  termsAndConditionsCms: TermsAndConditionsPageCms | null;
  frameAgreement: FrameAgreement | null;
  isLoadingFrameAgreement: boolean;
  offeredByUser: UserDTO | null;
  acceptedByUser: UserDTO | null;
  nickname: string;
  supplierCost: string;
  supplierCostSubtitle: string;
  serviceCategoryTitle: string;
  suggestedStartDate: string;
  averageCost: string;
  acceptedServiceOffer: ExtendedServiceOffer | null;
}

interface ServiceType {
  label: string;
  serviceCategories: ServiceCategory[];
}

interface ServiceCategory {
  label: string;
  title: string;
}

interface ExtendedBillableProps {
  tender: Tender;
  serviceTypesCms: ServiceType[];
  supplierId: number;
}

interface TermsAndConditionsPageCms {
  filedoc: FileRecord[];
}

export interface SignatureUsers {
  id: number;
  label: string;
  date: string;
  name: string;
  company: string;
}

interface ExtendedServiceOffer extends ServiceOffer {}

// Constants
const BUTTON_LABELS = {
  VIEW_DETAILS: "SE DETALJER",
  DOWNLOAD_AGREEMENT: "LAST NED AVTALE",
  DOWNLOAD_TERMS: "LAST NED VILKÅR",
} as const;

const BILLING_CYCLE_MAP = {
  Monthly: "MONTHLY",
  Yearly: "YEARLY",
  Fixed: "FIXED",
} as const;

const BILLING_CYCLE_PRIORITY = [
  BillingCycle.YEARLY,
  BillingCycle.MONTHLY,
  BillingCycle.FIXED,
] as const;

const COST_SUFFIX = "ekskl. mva" as const;

export const useExtendedBillable = ({
  tender,
  supplierId,
  serviceTypesCms,
}: ExtendedBillableProps) => {
  // Composables
  const router = useRouter();
  const { viewFileInNewWindow, viewOrderConfirmationDocument } =
    useFileDownload();
  const { getAttachment } = useFileAttachmentApi();
  const { getFrameAgreement } = useTenderApi();
  const { fetchCmsContent } = useCms();
  const { getUserInfo } = useUsersApi();
  const supplierStore = useSupplierStore();

  // State
  const state = reactive<ExtendedBillableState>({
    termsAndConditionsCms: null,
    frameAgreement: null,
    isLoadingFrameAgreement: false,
    nickname: "",
    supplierCost: "",
    supplierCostSubtitle: "",
    serviceCategoryTitle: "",
    suggestedStartDate: "",
    offeredByUser: null,
    acceptedByUser: null,
    acceptedServiceOffer: null,
    averageCost: "",
  });

  // Computed Properties
  const priceRequest = computed(() => tender.priceRequests?.[0]);

  const supplierRequest = computed(() =>
    priceRequest.value?.supplierRequests.find(
      (request) => request.supplierId === supplierId,
    ),
  );

  const hasFrameAgreement = computed(
    () => supplierRequest.value?.hasFrameAgreement ?? false,
  );

  const frameAgreementId = computed(
    () => supplierRequest.value?.frameAgreementId || null,
  );

  const supplierName = computed(() => {
    return supplierStore?.supplier?.sysName ?? "";
  });

  // Helper Functions
  const convertBillingCycleToPeriodUnit = (cycle?: string): string => {
    if (!cycle) return "";
    const enumKey = BILLING_CYCLE_MAP[cycle as keyof typeof BILLING_CYCLE_MAP];
    return enumKey ? BillingCycleUnits[enumKey] : "";
  };

  const formatPriceWithBillingPeriod = (
    amount?: number,
    billingCycle = "",
  ): string => {
    if (!amount) return "";
    return `${formatCurrency(amount)} ${billingCycle} ${COST_SUFFIX}`.trim();
  };

  const determinePrimaryBillingCycle = (
    serviceOffer: ServiceOffer,
  ): BillingCycle => {
    if (!serviceOffer?.mandatorySupplierCosts?.length) {
      return BillingCycle.FIXED;
    }

    const billingCycles = serviceOffer.mandatorySupplierCosts.map(
      (cost) => cost.billingCycle,
    );

    return (
      BILLING_CYCLE_PRIORITY.find((cycle) => billingCycles.includes(cycle)) ??
      BillingCycle.FIXED
    );
  };

  const findServiceCategoryTitle = (
    serviceTypeLabel: string,
    serviceCategoryLabel: string,
  ): string => {
    const serviceType = serviceTypesCms.find(
      (type) => type.label === serviceTypeLabel,
    );
    if (!serviceType) return "";

    const serviceCategory = serviceType.serviceCategories.find(
      (category) => category.label === serviceCategoryLabel,
    );
    return serviceCategory?.title ?? "";
  };

  const getServiceCategoryTitle = (): string => {
    const priceRequest = tender.priceRequests?.[0];
    if (
      !priceRequest?.serviceTypeLabel ||
      !priceRequest?.serviceCategoryLabel
    ) {
      return "";
    }

    return findServiceCategoryTitle(
      priceRequest.serviceTypeLabel,
      priceRequest.serviceCategoryLabel,
    );
  };

  // API Functions
  const fetchUserDetails = async (userId: string): Promise<UserDTO | null> => {
    try {
      const response = await getUserInfo(userId);
      return response.data;
    } catch (error) {
      console.error(`Error fetching user details for ${userId}:`, error);
      return null;
    }
  };

  const fetchSignatureUsers = async (
    acceptedByUserId: string | null,
    offeredByUserId: string | null,
  ) => {
    if (offeredByUserId) {
      state.offeredByUser = await fetchUserDetails(offeredByUserId);
    }

    if (acceptedByUserId) {
      state.acceptedByUser = await fetchUserDetails(acceptedByUserId);
    }
  };

  const fetchFrameAgreementDetails = async (): Promise<void> => {
    if (
      !hasFrameAgreement.value ||
      !frameAgreementId.value ||
      !tender.customerOrganizationId
    ) {
      state.isLoadingFrameAgreement = false;
      return;
    }

    state.isLoadingFrameAgreement = true;
    try {
      const response = await getFrameAgreement({
        organizationId: tender.customerOrganizationId,
        frameAgreementId: frameAgreementId.value,
      });
      state.frameAgreement = response.data;
    } catch (error) {
      console.error("Error fetching frame agreement:", error);
      state.frameAgreement = null;
    } finally {
      state.isLoadingFrameAgreement = false;
    }
  };

  const fetchTermsAndConditions = async (): Promise<void> => {
    try {
      const response = await fetchCmsContent(
        ContentType.TERMS_AND_CONDITIONS_PAGE_CMS,
      );
      state.termsAndConditionsCms = response as TermsAndConditionsPageCms;
    } catch (error) {
      console.error("Error fetching terms and conditions:", error);
      state.termsAndConditionsCms = null;
    }
  };

  // Action Handlers
  const handleDownloadTerms = async (): Promise<void> => {
    try {
      if (hasFrameAgreement.value && state.frameAgreement) {
        await downloadFrameAgreementAttachment();
      } else {
        openTermsAndConditions();
      }
    } catch (error) {
      console.error("Error downloading terms:", error);
    }
  };

  const downloadFrameAgreementAttachment = async (): Promise<void> => {
    const attachment = state.frameAgreement?.attachments[0];
    if (!attachment) throw new Error("No frame agreement attachment found");

    const response = await getAttachment({
      attachmentId: attachment.attachmentId,
      tenderId: tender.tenderId,
      fileName: attachment.fileName,
      supplierId: supplierId,
    });

    await viewFileInNewWindow(response.data, router);
  };

  const openTermsAndConditions = (): void => {
    const filedoc = state.termsAndConditionsCms?.filedoc;
    if (!filedoc) throw new Error("Terms and conditions not found");

    const url = getFileUrl(filedoc, "seeAllTerms");
    if (!url) throw new Error("Terms and conditions URL not found");

    window.open(url, "_blank", "noopener,noreferrer");
  };

  const handleViewAgreement = async (): Promise<void> => {
    if (!state.acceptedServiceOffer?.serviceOfferId) return;

    try {
      await viewOrderConfirmationDocument(
        tender.tenderId,
        state.acceptedServiceOffer.serviceOfferId,
        router,
      );
    } catch (error) {
      console.error("Error viewing order confirmation:", error);
    }
  };

  const navigateToOfferDetails = (): void => {
    router.push({
      name: RouteNames.EDIT_OFFER,
      params: {
        tenderId: tender.tenderId,
        offerId: state.acceptedServiceOffer?.serviceOfferId,
        priceRequestId: priceRequest.value?.id,
        supplierRequestId: supplierRequest.value?.id,
      },
    });
  };

  // Price Calculations
  const getFormattedSupplierCost = (serviceOffer: ServiceOffer) => {
    if (!serviceOffer) return "";

    const billingCycle = convertBillingCycleToPeriodUnit(
      determinePrimaryBillingCycle(serviceOffer),
    );
    return formatPriceWithBillingPeriod(
      serviceOffer.yearOneAgreementValue,
      billingCycle,
    );
  };

  const getSupplierCostSubtitle = (serviceOffer: ServiceOffer) => {
    if (!serviceOffer) return "";

    const billingCycle = convertBillingCycleToPeriodUnit(
      determinePrimaryBillingCycle(serviceOffer),
    );
    return `Gjennomsnittlig kostnad per ${billingCycle} ${COST_SUFFIX}`;
  };

  const getAverageCost = (yearOneAgreementValue: number) => {
    return formatCurrency(yearOneAgreementValue);
  };

  // Initialize
  const initialize = async (serviceOffer: ServiceOffer): Promise<void> => {
    state.acceptedServiceOffer =
      serviceOffer as unknown as ExtendedServiceOffer;
    state.nickname = serviceOffer?.nickname ?? "Tilbud på tjenestetype";
    state.supplierCost = getFormattedSupplierCost(serviceOffer);
    state.supplierCostSubtitle = getSupplierCostSubtitle(serviceOffer);
    state.serviceCategoryTitle = getServiceCategoryTitle();
    state.suggestedStartDate =
      formatDateToDotSeparated(serviceOffer?.suggestedStartDate ?? "") ?? "--";
    state.averageCost = getAverageCost(serviceOffer.yearOneAgreementValue);

    await Promise.all([
      fetchTermsAndConditions(),
      fetchFrameAgreementDetails(),
      fetchSignatureUsers(
        serviceOffer.acceptedByUserId ?? null,
        serviceOffer.offeredByUserId ?? null,
      ),
    ]);
  };

  // Computed Properties for UI
  const signatureUsers = computed<SignatureUsers[]>(() => {
    const dateOffered = state.acceptedServiceOffer?.dateOffered;
    const acceptanceDate = state.acceptedServiceOffer?.acceptanceDate;

    return [
      {
        id: 1,
        label: "Utstedt av",
        date: dateOffered
          ? formatDateToDotSeparated(dateOffered)
          : "Dato ikke tilgjengelig",
        name: state.offeredByUser?.displayName ?? "--",
        company: supplierName.value ?? "--",
      },
      {
        id: 2,
        label: "Aksept av",
        date: acceptanceDate
          ? formatDateToDotSeparated(acceptanceDate)
          : "Dato ikke tilgjengelig",
        name: state.acceptedByUser?.displayName ?? "--",
        company: tender.customerOrganization?.name ?? "--",
      },
    ];
  });

  const actionButtons = computed(() => {
    const buttons = [
      {
        id: 1,
        label: BUTTON_LABELS.VIEW_DETAILS,
        action: navigateToOfferDetails,
      },
      {
        id: 2,
        label: BUTTON_LABELS.DOWNLOAD_AGREEMENT,
        action: handleViewAgreement,
      },
    ];

    if (hasFrameAgreement.value && state.frameAgreement?.attachments.length) {
      return [
        ...buttons,
        {
          id: 3,
          label: BUTTON_LABELS.DOWNLOAD_TERMS,
          action: handleDownloadTerms,
        },
      ];
    }

    return buttons;
  });

  return {
    state,
    actionButtons,
    hasFrameAgreement,
    initialize,
    navigateToOfferDetails,
    handleViewAgreement,
    handleDownloadTerms,
    signatureUsers,
  };
};
