<template>
  <div class="feedback-notification" :class="notificationClass">
    <div class="feedback-notification__content">
      <div class="feedback-notification__icon">
        <img :src="iconSrc" :alt="iconAlt" />
      </div>
      <div class="feedback-notification__text">
        <div class="feedback-notification__title">
          {{ title }}
        </div>
        <div class="feedback-notification__subtitle">
          {{ subtitle }}
        </div>
      </div>
    </div>
    <button class="feedback-notification__button" @click="handleFeedbackClick">
      {{ buttonText }}
    </button>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import CheckIcon from "@/assets/img/check-mark.svg";
import CloseIcon from "@/assets/img/close.svg";

interface Props {
  isAccepted: boolean;
}

const props = defineProps<Props>();

const emit = defineEmits(["read-feedback"]);

const notificationClass = computed(() => ({
  "feedback-notification--accepted": props.isAccepted,
  "feedback-notification--rejected": !props.isAccepted,
}));

const iconSrc = computed(() => (props.isAccepted ? CheckIcon : CloseIcon));
const iconAlt = computed(() => (props.isAccepted ? "Check" : "Close"));

const title = computed(() =>
  props.isAccepted
    ? "Kunde valgte ditt tilbud"
    : "Kunde valgte annen leverandør",
);

const subtitle = "Se tilbakemelding fra kunde her";

const buttonText = "Les tilbakemelding";

const handleFeedbackClick = () => {
  emit("read-feedback", props.isAccepted);
};
</script>

<style lang="scss" scoped>
.feedback-notification {
  width: 100%;
  height: 100%;
  padding: 8px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;

  &--accepted {
    background: #d7f4e5;
  }

  &--rejected {
    background: #f7f0fa;
  }

  &__content {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__icon {
    width: 24px;
    height: 24px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 1px solid #1d1d1d;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    max-width: 264px;
  }

  &__title {
    color: #1d1d1d;
    font-size: 12px;
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    word-wrap: break-word;
  }

  &__subtitle {
    color: #616161;
    font-size: 12px;
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    word-wrap: break-word;
  }

  &__button {
    padding: 12.5px 20px;
    border-radius: 24px;
    border: 1px solid #1d1d1d;
    background: transparent;
    color: #1d1d1d;
    font-size: 12px;
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
  }
}
</style>
