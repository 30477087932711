import { ApiResponse, EndpointFactory } from "@/config/api/types";
import axios, { AxiosError, ResponseType } from "axios";
import { useUserStore } from "@/stores/user";
import { useMsalAuthentication } from "@/composables/useMsalAuthentication";
import { AlertableError } from "@/composables/useToastErrorHandler";

const { acquireApiAccessToken } = useMsalAuthentication();

const resolvePathParams = (pathParams?: Array<string | number>) => {
  return pathParams
    ? pathParams.map((param) => param.toString()).join("/")
    : "";
};

export const executeApiRequest = async <RESPONSE>(
  endpointFactory: EndpointFactory,
  requestPayload?: {
    data?: unknown;
    pathParams?: Array<string | number>;
    queryParams?: Record<string, unknown>;
    responseType?: ResponseType;
    signal?: AbortSignal;
    preventCache?: boolean;
  },
): Promise<ApiResponse<RESPONSE>> => {
  const { config, endpointKey } = endpointFactory;
  const endpoint = config.endpoints.find(
    (endpoint) => endpoint.key === endpointKey,
  );
  if (!endpoint) {
    throw new AlertableError(
      `Endpoint with key ${String(endpointKey)} not found`,
      {
        severity: "error",
        summary: "API Configuration Error",
        detail: `Endpoint with key ${String(endpointKey)} not found`,
      },
    );
  }
  const requestHeaders: Record<string, string | number | boolean> =
    config.headers.reduce(
      (accumulatorObject, header) =>
        Object.assign(accumulatorObject, { [header.key]: header.value }),
      {},
    );

  if (requestPayload?.preventCache || endpoint.method === "GET") {
    requestHeaders["Cache-Control"] = "no-cache, no-store, must-revalidate";
    requestHeaders["Pragma"] = "no-cache";
    requestHeaders["Expires"] = "0";
  }

  if (!requestHeaders.Authorization) {
    const store = useUserStore();
    const msalAccount = store.userAccount;
    try {
      const accessToken = msalAccount
        ? await acquireApiAccessToken(msalAccount)
        : undefined;
      if (accessToken) {
        requestHeaders.Authorization = `Bearer ${accessToken}`;
      }
    } catch (error) {
      console.log("Error acquiring access token", error);
      throw new AlertableError("Authentication error", {
        severity: "error",
        summary: "Authentication Error",
        detail: "Failed to acquire access token for API request",
      });
    }
  }

  try {
    const apiClient = axios.create({
      baseURL: `${config.baseUri}${config.contextPath}`,
      timeout: 3000000,
      headers: requestHeaders,
      responseType: requestPayload?.responseType,
    });

    const url = `${endpoint.url}/${resolvePathParams(requestPayload?.pathParams)}`;
    const response = await apiClient.request<RESPONSE>({
      method: endpoint.method,
      url: url,
      data: requestPayload?.data,
      params: requestPayload?.queryParams,
      signal: requestPayload?.signal,
    });

    return {
      data: response.data,
      status: response.status,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      const status = axiosError.response?.status;
      let errorSummary = "API Error";
      let errorDetail =
        "An unexpected error occurred while communicating with the server";

      // Extract error message from response if available
      if (
        axiosError.response?.data &&
        typeof axiosError.response.data === "object"
      ) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const data = axiosError.response.data as any;
        errorDetail = data.message || data.error || errorDetail;
      }

      // Customize error based on status code
      if (status === 401 || status === 403) {
        errorSummary = "Authentication Error";
        errorDetail = "You are not authorized to perform this action";
      } else if (status === 404) {
        errorDetail = "The requested resource was not found";
      } else if (status === 500) {
        errorSummary = "Server Error";
        errorDetail = "The server encountered an error. Please try again later";
      } else if (status && status >= 400 && status < 500) {
        errorSummary = "Request Error";
      }

      throw new AlertableError(errorDetail, {
        severity: "error",
        summary: errorSummary,
        detail: errorDetail,
      });
    }

    // Re-throw other errors
    throw error;
  }
};
