import { ref, computed } from "vue";
import { useTenderApi } from "@/services/api/useTenderApi";
import type { Tender, FrameAgreement } from "@/stores/tender/types";
import { Ref } from "vue";

export const usePriceRequestFrameAgreement = (
  tender: Tender,
  supplierId: number,
  hasFrameAgreement: Ref<boolean>,
  customerOrganizationId: Ref<number | null>,
) => {
  const { getFrameAgreement } = useTenderApi();
  const frameAgreement = ref<FrameAgreement | null>(null);
  const isLoading = ref(true);

  const supplierRequest = computed(() =>
    tender.priceRequests?.[0]?.supplierRequests?.find(
      (req) => req.supplierId === supplierId,
    ),
  );

  const frameAgreementId = computed(
    () => supplierRequest.value?.frameAgreementId,
  );

  const fetchFrameAgreement = async () => {
    if (
      !hasFrameAgreement.value ||
      !frameAgreementId.value ||
      !customerOrganizationId.value
    ) {
      isLoading.value = false;
      return;
    }

    try {
      const response = await getFrameAgreement({
        organizationId: customerOrganizationId.value,
        frameAgreementId: frameAgreementId.value,
      });

      if (!response?.data) {
        throw new Error("No response data");
      }

      frameAgreement.value = response.data;
    } catch (error) {
      console.error("Error fetching frame agreement:", error);
      frameAgreement.value = null;
    } finally {
      isLoading.value = false;
    }
  };

  return {
    frameAgreement,
    isLoadingFrameAgreement: isLoading,
    fetchFrameAgreement,
  };
};
