<template>
  <BaseServiceOfferModal
    v-model="showModal"
    :icon="DeleteDraftOfferModalIcon"
    :title="title"
    :description="description"
    :align-items="'center'"
  >
    <!-- Buttons slot -->
    <template #buttons>
      <div class="modal__action-buttons">
        <tertiary-button :disabled="isSubmitting" @click="showModal = false">{{
          cancelButtonText
        }}</tertiary-button>
        <primary-button
          :is-submitting="isSubmitting"
          @click="deleteDraftOffer"
          >{{ deleteButtonText }}</primary-button
        >
      </div>
    </template>
  </BaseServiceOfferModal>
</template>

<script setup lang="ts">
import { ref, computed, watch } from "vue";
import TertiaryButton from "@/components/Buttons/TertiaryButton.vue";
import PrimaryButton from "@/components/Buttons/PrimaryButton.vue";
import BaseServiceOfferModal from "./BaseServiceOfferModal.vue";
import DeleteDraftOfferModalIcon from "@/assets/img/delete.svg";

interface Props {
  modelValue: boolean;
  messageDeletedText?: string;
}

const props = withDefaults(defineProps<Props>(), {
  messageDeletedText: "",
});

const emit = defineEmits<{
  (e: "update:modelValue", value: boolean): void;
  (e: "delete-draft-offer"): void;
}>();

const isSubmitting = ref(false);
const showMessageDeletedText = ref(false);

const showModal = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
});

// Hardcoded text in Norwegian
const title = "Slett kladd";
const description = [
  {
    content:
      "Er du sikker på at du vil slette kladden? Denne handlingen kan ikke angres.",
  },
];
const cancelButtonText = "Avbryt";
const deleteButtonText = "Slett";

const closeModalWithDelay = () => {
  setTimeout(() => {
    isSubmitting.value = false;
    showModal.value = false;
  }, 3000);
};

const deleteDraftOffer = () => {
  isSubmitting.value = true;
  emit("delete-draft-offer");
  closeModalWithDelay();
};

watch(
  () => props.messageDeletedText,
  (val) => {
    if (val) {
      showMessageDeletedText.value = true;
      closeModalWithDelay();
    }
  },
);
</script>

<style lang="scss" scoped>
.modal {
  &__action-response-text {
    margin-top: 40px;
    font-weight: 600;
    font-size: $font-size-base;
    line-height: 20px;
    text-align: center;
  }
  &__action-buttons {
    margin-top: 56px;
    display: flex;
    justify-content: space-between;

    > button {
      padding: 1rem 2.5rem;
      font-weight: 600;
      font-size: $font-size-base;
      line-height: 20px;
    }
  }
}
</style>
