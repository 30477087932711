<template>
  <div class="company-upload-logo">
    <input
      ref="fileInput"
      type="file"
      accept=".jpg, .jpeg, .png, .gif"
      style="display: none"
      @change="handleFileUpload"
    />
    <div v-if="isUploading" class="company-upload-logo__loading">
      <div class="company-upload-logo__spinner" />
      <span class="company-upload-logo__loading-text">Laster opp...</span>
    </div>
    <div v-else-if="uploadedImage" class="company-upload-logo__image-container">
      <img
        :src="uploadedImage"
        alt="Uploaded Logo"
        class="company-upload-logo__image"
      />
      <div class="company-upload-logo__overlay">
        <button
          class="company-upload-logo__button-image"
          @click="triggerFileInput"
        >
          <img :src="uploadIcon" alt="Upload" />
        </button>
        <button class="company-upload-logo__button-image" @click="removeImage">
          <img :src="closeIcon" alt="Remove" />
        </button>
      </div>
    </div>
    <div v-else>
      <button class="company-upload-logo__button" @click="triggerFileInput">
        {{ buttonText }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, PropType, watch, onMounted } from "vue";
import { useSupplierApi } from "@/services/api/useSupplierApi";

const { editSupplierLogo, getSupplierLogo } = useSupplierApi();

const props = defineProps({
  buttonText: {
    type: String as PropType<string>,
    required: true,
  },
  uploadIcon: {
    type: String as PropType<string>,
    required: true,
  },
  closeIcon: {
    type: String as PropType<string>,
    required: true,
  },
  logo: {
    type: String as PropType<string>,
    default: "",
  },
  supplierId: {
    type: Number as PropType<number>,
    required: true,
  },
});

const emit = defineEmits(["remove", "update:logo"]);

const fileInput = ref<HTMLInputElement | null>(null);
const uploadedImage = ref<string | null>(null);
const isUploading = ref(false);

watch(
  () => props.logo,
  (newLogo) => {
    if (newLogo) {
      uploadedImage.value = `data:image/png;base64,${newLogo}`;
    } else {
      uploadedImage.value = null;
    }
  },
  { immediate: true },
);

const triggerFileInput = () => {
  fileInput.value?.click();
};

const handleFileUpload = async (event: Event) => {
  const target = event.target as HTMLInputElement;
  if (target.files && target.files.length > 0) {
    const file = target.files[0];
    if (file) {
      isUploading.value = true;
      const reader = new FileReader();
      reader.onload = async () => {
        const base64String = reader.result as string;
        const base64Logo = base64String.split(",")[1];
        if (base64Logo) {
          try {
            await editSupplierLogo({
              supplierId: props.supplierId,
              logo: base64Logo,
            });
            uploadedImage.value = base64String;
            emit("update:logo", base64Logo);
          } catch (error) {
            console.error("Failed to update supplier logo:", error);
          } finally {
            isUploading.value = false;
          }
        } else {
          isUploading.value = false;
        }
      };
      reader.onerror = () => {
        isUploading.value = false;
        console.error("Error reading file");
      };
      reader.readAsDataURL(file);
    }
  }
};

const fetchLogo = async () => {
  try {
    isUploading.value = true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data: any = await getSupplierLogo(props.supplierId);
    if (data && data.data) {
      uploadedImage.value = `data:image/png;base64,${data.data.logo}`;
    }
  } catch (error) {
    console.error("Failed to fetch logo:", error);
  } finally {
    isUploading.value = false;
  }
};

onMounted(() => {
  fetchLogo();
});

const removeImage = () => {
  uploadedImage.value = null;
  emit("remove");
};
</script>

<style lang="scss" scoped>
.company-upload-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1px dashed rgba(0, 0, 0, 0.5);
  background: var(--White, #fff);
  height: 14rem;
  position: relative;

  &__loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &__spinner {
    width: 40px;
    height: 40px;
    border: 4px solid rgba(68, 27, 73, 0.2);
    border-top: 4px solid #441b49;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 0.5rem;
  }

  &__loading-text {
    font-size: 0.875rem;
    color: #441b49;
    font-weight: 500;
  }

  &__image-container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    &:hover .company-upload-logo__overlay {
      opacity: 1;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: row;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &__button-image {
    padding: 0.5rem;
    border-radius: 100px;
    background: rgba(255, 255, 255, 0.8);
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }

    &:focus {
      outline: none;
    }

    &:active {
      transform: scale(0.95);
    }

    img {
      width: 2.5rem;
      height: 2.5rem;
    }
  }

  &__button {
    padding: 0.6rem 1.2rem;
    background-color: transparent;
    border-radius: 24px;
    border: 1px solid var(--Black, #1d1d1d);
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: $font-size-xs;
    font-weight: 600;
    text-transform: uppercase;
    color: #1d1d1d;

    &:hover {
      background-color: var(--Black, #1d1d1d);
      color: var(--White, #ffffff);
    }

    &:focus {
      outline: none;
    }

    &:active {
      transform: scale(0.95);
    }
  }

  &__placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  &__text {
    color: rgba(0, 0, 0, 0.5);
    font-weight: 400;
    font-size: $font-size-xs;
  }

  &__upload-response-container {
    padding: 16px 0;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
