<template>
  <confirmation-modal
    v-model="showModal"
    title="Slett beskrivelse"
    description="Dette vil fjerne beskrivelsen fra tilbudet ditt"
    secondary-button-text="Avbryt"
    primary-button-text="Slett bESKRIVELSE"
    :is-submitting="state.isSubmitting"
    @secondary-click="closeModal"
    @primary-click="handlePrimaryClick"
  >
    <template #status-icon>
      <img
        :src="`/images/document-sent.svg`"
        class="delete-work-description__status-icon"
        alt="Status icon"
      />
    </template>
  </confirmation-modal>
</template>

<script setup>
import ConfirmationModal from "@/components/ConfirmationModal/ConfirmationModal.vue";
import { useTenderApi } from "@/services/api/useTenderApi";
import { reactive } from "vue";

const showModal = defineModel({ required: true });
const props = defineProps({
  supplierId: {
    type: Number,
    required: true,
  },
  supplierRequestId: {
    type: Number,
    required: true,
  },
  serviceOfferId: {
    type: Number,
    required: true,
  },
  descriptionItem: {
    type: Object,
    required: true,
  },
});
const emit = defineEmits(["item-deleted"]);

const tenderApis = useTenderApi();

const state = reactive({
  isSubmitting: false,
});

const closeModal = () => {
  showModal.value = false;
};
const handlePrimaryClick = async () => {
  if (state.isSubmitting) {
    return;
  }
  state.isSubmitting = true;
  try {
    await tenderApis.deleteServiceOfferDescription(
      props.supplierId,
      props.supplierRequestId,
      props.serviceOfferId,
      props.descriptionItem.id,
    );
    emit("item-deleted", props.descriptionItem);
    closeModal();
  } catch (error) {
    console.log("Error deleting description", error);
  } finally {
    state.isSubmitting = false;
  }
};
</script>

<style lang="scss" scoped>
.delete-work-description {
  &__status-icon {
    width: 4rem;
  }
}
</style>
