import { ref, computed } from "vue";
import { useCommunicationApi } from "@/services/api/useCommunicationApi";
import type {
  Conversation,
  SendMessageDto,
  Message,
  MessageThread,
} from "@/stores/communication/types";
import { MessageDomain, MessageTypes } from "@/custom-types/GeneralTypes";
import { getMessageAttachmentDto } from "@/utilities/fileUtils";
import type { Tender } from "@/stores/tender/types";
import { User } from "@/custom-types/GeneralTypes";
import { useFeedbackCookie } from "@/utilities/useFeedbackCookie";

interface ConversationOptions {
  tender: Tender;
  supplierId: number;
  supplierRequestId: number;
  user: User;
}

interface MessagePayload {
  conversationId: string | null;
  content: string | null;
  files: File[];
  onSuccess: () => void;
  onError: (error: Error) => void;
}

interface ApiError extends Error {
  response?: {
    data?: string;
    message?: string;
  };
}

export function useConversations({
  tender,
  supplierId,
  supplierRequestId,
  user,
}: ConversationOptions) {
  const communicationApi = useCommunicationApi();
  const { setMessagesViewed, hasMessagesBeenViewed, hideMessageBadge } =
    useFeedbackCookie();

  // State
  const messageThreads = ref<Conversation[] | null>(null);
  const activeThreadId = ref("");
  const isThreadVisible = ref(false);
  const errorMessage = ref<string | null>(null);

  // Computed
  const activeThread = computed((): MessageThread | null => {
    if (!messageThreads.value) return null;
    if (!activeThreadId.value)
      return {
        latestMessage: {
          id: 0,
          senderUserId: "",
          conversationId: null,
          messageBody: "",
          senderName: "",
          senderEmail: "",
          createdOn: new Date().toISOString(),
          messageRead: false,
          rootMessage: true,
          supplierRequestId: supplierRequestId,
          tenderId: tender.tenderId,
          serviceOfferId: null,
          messageType: MessageTypes.GeneralMessage,
          messageDomain: MessageDomain.SUPPLIER_CUSTOMER,
          price: null,
          attachments: [],
        },
        message: {
          id: 0,
          conversationId: null,
          messageBody: "",
          createdOn: new Date().toISOString(),
          senderUserId: "",
          senderName: "",
          senderEmail: "",
          messageType: MessageTypes.GeneralMessage,
          messageDomain: MessageDomain.SUPPLIER_CUSTOMER,
          price: null,
          attachments: [],
          messageRead: false,
          rootMessage: true,
          supplierRequestId: supplierRequestId,
          tenderId: tender.tenderId,
          serviceOfferId: null,
        },
        customerMessageCount: 0,
        supplierMessageCount: 0,
        messageCount: 0,
        read: true,
        thread: [],
      };
    return (
      messageThreads.value.find(
        (thread) =>
          thread.latestMessage.conversationId === activeThreadId.value,
      ) ?? null
    );
  });

  // Methods
  const fetchMessageThreads = async () => {
    try {
      if (!supplierRequestId) {
        console.warn("No supplierRequestId found. Skipping fetch operation.");
        messageThreads.value = [];
        return;
      }

      const response =
        await communicationApi.fetchSupplierConversations(supplierRequestId);
      messageThreads.value = response.data;
      errorMessage.value = null;
    } catch (error) {
      const apiError = error as ApiError;
      console.error("Failed to fetch message threads:", apiError);
      errorMessage.value =
        apiError.response?.data ||
        apiError.message ||
        "Failed to fetch messages. Please try again later.";
      messageThreads.value = [];
    }
  };

  const fetchThreadMessages = async (threadId: string) => {
    const response = await communicationApi.fetchConversationMessages(threadId);
    const newThread = response.data as MessageThread;

    if (!messageThreads.value) {
      messageThreads.value = [];
    }

    const existingThread = messageThreads.value.find(
      (thread) => thread.latestMessage.conversationId === threadId,
    );

    if (existingThread) {
      Object.assign(existingThread, newThread);
    } else {
      messageThreads.value.push(newThread);
    }
  };

  const handleThreadSelect = async (threadId: string) => {
    activeThreadId.value = threadId;
    const selectedThread = messageThreads.value?.find(
      (thread) => thread.latestMessage.conversationId === threadId,
    );

    if (!selectedThread?.thread?.length) {
      await fetchThreadMessages(threadId);
    }

    // Mark messages as read when thread is opened
    const thread = messageThreads.value?.find(
      (t) => t.latestMessage.conversationId === threadId,
    );
    if (thread?.thread) {
      const unreadMessageIds = thread.thread
        .filter((message) => !message.messageRead)
        .map((message) => message.id.toString());

      const priceRequestId = tender.priceRequests[0]?.id;
      if (unreadMessageIds.length > 0) {
        try {
          await communicationApi.markMessagesRead(unreadMessageIds);
          // Update local message read status
          thread.thread.forEach((message) => {
            message.messageRead = true;
          });

          // Only set messages as viewed if they haven't been viewed before
          if (priceRequestId && !hasMessagesBeenViewed(priceRequestId)) {
            console.log("Setting messages as viewed");

            setMessagesViewed(priceRequestId);
            hideMessageBadge(priceRequestId);
          }
        } catch (error) {
          console.error("Failed to mark messages as read:", error);
        }
      }
    }

    isThreadVisible.value = true;
  };

  const handleThreadClose = () => {
    if (messageThreads.value?.length) {
      isThreadVisible.value = false;
      activeThreadId.value = "";
    }
  };

  const createThread = () => {
    activeThreadId.value = "";
    isThreadVisible.value = true;
  };

  const sendMessage = async (payload: MessagePayload) => {
    const currentThread = messageThreads.value?.find(
      (thread) =>
        thread.latestMessage.conversationId === payload.conversationId,
    );

    const fileAttachments = await Promise.all(
      payload.files.map((file) => getMessageAttachmentDto(file)),
    );

    const messageData: SendMessageDto = {
      conversationId: currentThread?.latestMessage.conversationId ?? null,
      messageType: MessageTypes.GeneralMessage,
      messageBody: payload.content,
      attachments: fileAttachments,
      senderUserId: user.userId,
      senderName: user.name,
      senderEmail: user.email,
      supplierId,
      supplierRequestId,
      tenderId: tender.tenderId,
      rootMessage: !currentThread,
      messageDomain: MessageDomain.SUPPLIER_CUSTOMER,
      price: null,
      customerOrgNumber: tender.customerOrganization.orgNumber,
    };

    try {
      const response = await communicationApi.sendMessage(messageData);
      const newThreadId = (response.data as Message).conversationId;
      if (newThreadId) {
        await fetchThreadMessages(newThreadId);
        if (!activeThreadId.value) {
          activeThreadId.value = newThreadId;
        }
        isThreadVisible.value = true;
      }
      payload.onSuccess();
      errorMessage.value = null;
    } catch (error) {
      const apiError = error as ApiError;
      console.error("Error sending message:", apiError);
      errorMessage.value =
        apiError.response?.data ||
        apiError.message ||
        "Failed to send message. Please try again.";
      payload.onError(apiError);
    }
  };

  return {
    // State
    messageThreads,
    isThreadVisible,
    activeThreadId,
    errorMessage,

    // Computed
    activeThread,

    // Methods
    fetchMessageThreads,
    fetchThreadMessages,
    handleThreadSelect,
    handleThreadClose,
    createThread,
    sendMessage,
  };
}
