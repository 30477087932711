import type { BillablePriceSummaryDetail } from "@/custom-types/serviceOffers";

export interface PriceDetail {
  text: string;
  price: string;
}

export function updateBillablePriceDetails(
  details: PriceDetail[],
  averagePricePerYearText: string,
  singleCostText: string,
  totalMonthlyText: string,
): PriceDetail[] {
  return updateBillablePriceSummaryDetails(
    details,
    averagePricePerYearText,
    singleCostText,
    totalMonthlyText,
  );
}

export function updateBillablePriceSummaryDetails(
  details: PriceDetail[],
  averagePricePerYearText: string,
  singleCostText: string,
  monthlySuffix: string, // e.g. " per måned"
): BillablePriceSummaryDetail[] {
  // A Set to keep track of (transformed text + price) combinations.
  const seen = new Set<string>();
  const result: PriceDetail[] = [];

  for (const item of details) {
    // Skip if text is empty (or only whitespace)
    if (!item.text.trim()) continue;

    // Determine the transformed text.
    // For details matching the special texts, leave them as-is.
    // Otherwise, assume they are monthly items and append the suffix if not already present.
    let transformedText = item.text;
    if (
      item.text !== singleCostText &&
      item.text !== averagePricePerYearText &&
      monthlySuffix &&
      !item.text.endsWith(monthlySuffix)
    ) {
      transformedText = `${item.text}${monthlySuffix}`;
    }

    // Use a key combining the transformed text and price.
    const key = `${transformedText}|${item.price}`;
    if (!seen.has(key)) {
      seen.add(key);
      result.push({ text: transformedText, price: item.price });
    }
  }

  return result;
}
