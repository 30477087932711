import { ApiConfig } from "@/config/api/types";
import { authHeader } from "@/config/api/headers";

export enum CommunicationApiEndpoints {
  SEND_MESSAGE = "sendMessage",
  GET_SUPPLIER_CONVERSATIONS = "getSupplierConversations",
  GET_CONVERSATION_MESSAGES = "getConversationMessages",
  GET_SUPPLIER_UNREAD_MESSAGES_COUNT = "getSupplierUnreadMessagesCount",
  GET_ALL_SUPPLIER_UNREAD_MESSAGES_COUNT = "getAllSupplierUnreadMessagesCount",
  MARK_MESSAGES_READ = "markMessagesRead",
  GET_SUPPLIER_UNREAD_MESSAGE_STATISTICS = "getSupplierUnreadMessageStatistics",
}

export const CommunicationApi: ApiConfig = {
  baseUri: import.meta.env.VITE_APP_BASE_URI || "",
  contextPath: "/SupplierCommunications",
  headers: [authHeader],
  endpoints: [
    {
      key: Symbol.for(CommunicationApiEndpoints.SEND_MESSAGE),
      method: "POST",
      url: "/Create",
      description: "Send a message",
    },
    {
      key: Symbol.for(CommunicationApiEndpoints.GET_SUPPLIER_CONVERSATIONS),
      method: "GET",
      url: "/request",
      description: "Fetch supplier conversations",
    },
    {
      key: Symbol.for(CommunicationApiEndpoints.GET_CONVERSATION_MESSAGES),
      method: "GET",
      url: "/conversation",
      description: "Fetch conversation messages",
    },
    {
      key: Symbol.for(
        CommunicationApiEndpoints.GET_SUPPLIER_UNREAD_MESSAGES_COUNT,
      ),
      method: "GET",
      url: "/UnreadMessages/address",
      description: "Fetch supplier unread messages count",
    },
    {
      key: Symbol.for(
        CommunicationApiEndpoints.GET_ALL_SUPPLIER_UNREAD_MESSAGES_COUNT,
      ),
      method: "GET",
      url: "/UnreadMessages/Supplier",
      description: "Fetch all supplier unread messages count",
    },
    {
      key: Symbol.for(CommunicationApiEndpoints.MARK_MESSAGES_READ),
      method: "PATCH",
      url: "/read",
      description: "Mark messages as read",
    },
    {
      key: Symbol.for(
        CommunicationApiEndpoints.GET_SUPPLIER_UNREAD_MESSAGE_STATISTICS,
      ),
      method: "GET",
      url: "/Supplier",
      description: "Fetch supplier unread message statistics",
    },
  ],
};
