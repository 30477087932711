import {
  CompanyRegistryApi,
  CompanyRegistryApiEndpoints,
} from "@/config/api/companyRegistry/companyRegistryApi";
import { executeApiRequest } from "@/services/api/apiService";
import { useToastErrorHandler } from "@/composables/useToastErrorHandler";

export type Company = {
  companyName: string;
  orgNumber: string;
};

const useCompanyRegistryApiService = () => {
  const { withToastErrorHandling } = useToastErrorHandler();

  const findCompany = async (orgNumber: string) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<Company[]>(
          {
            config: CompanyRegistryApi,
            endpointKey: Symbol.for(CompanyRegistryApiEndpoints.FIND_COMPANY),
          },
          {
            pathParams: [orgNumber],
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Selskapssøkfeil",
          detail: "Kunne ikke finne selskap med dette organisasjonsnummeret",
        },
      },
    );
  };

  return {
    findCompany,
  };
};

export const useCompanyRegistryApi = useCompanyRegistryApiService;
