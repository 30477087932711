import { useLocalStorage } from "@vueuse/core";
import { formatISO, subDays, isValid, parseISO, max } from "date-fns";
import { debounce } from "lodash";

type TabType =
  | "new"
  | "active"
  | "lost"
  | "archived"
  | "active-service"
  | "completed-service"
  | "active-projects"
  | "completed-projects";

interface LastVisitTimes {
  new: string;
  active: string;
  lost: string;
  archived: string;
  "active-service": string;
  "completed-service": string;
  "active-projects": string;
  "completed-projects": string;
}

export const useLastVisitTime = () => {
  // Constants
  const FALLBACK_DAYS = 7;
  const DEBOUNCE_MS = 1000;
  const STORAGE_KEY = "supplier_requests_last_visit";

  // Store last visit times for each tab
  const lastVisitTimes = useLocalStorage<LastVisitTimes>(STORAGE_KEY, {
    new: "",
    active: "",
    lost: "",
    archived: "",
    "active-service": "",
    "completed-service": "",
    "active-projects": "",
    "completed-projects": "",
  });

  /**
   * Validates and returns a proper date, falling back to 7 days ago if invalid
   */
  const getValidDate = (dateStr: string): Date => {
    if (!dateStr) {
      return subDays(new Date(), FALLBACK_DAYS);
    }

    try {
      const parsedDate = parseISO(dateStr);
      return isValid(parsedDate)
        ? parsedDate
        : subDays(new Date(), FALLBACK_DAYS);
    } catch {
      return subDays(new Date(), FALLBACK_DAYS);
    }
  };

  /**
   * Gets the ISO formatted time string for when a tab was last visited
   */
  const getFromTime = (tab: TabType): string => {
    const lastVisit = lastVisitTimes.value[tab as keyof LastVisitTimes];
    const minDate = subDays(new Date(), 3);

    if (!lastVisit) {
      return formatISO(minDate);
    }

    const parsedDate = parseISO(lastVisit);
    return formatISO(
      isValid(parsedDate) ? max([parsedDate, minDate]) : minDate,
    );
  };

  /**
   * Updates the last visit time for a specific tab
   * Debounced to prevent excessive storage updates
   */
  const updateLastVisitTime = debounce((tab: TabType): void => {
    if (!Object.keys(lastVisitTimes.value).includes(tab)) {
      console.warn(`Invalid tab type: ${tab}`);
      return;
    }

    lastVisitTimes.value = {
      ...lastVisitTimes.value,
      [tab]: formatISO(new Date()),
    };
  }, DEBOUNCE_MS);

  /**
   * Clears all last visit times
   */
  const clearLastVisitTimes = (): void => {
    lastVisitTimes.value = {
      new: "",
      active: "",
      lost: "",
      archived: "",
      "active-service": "",
      "completed-service": "",
      "active-projects": "",
      "completed-projects": "",
    };
  };

  return {
    getFromTime,
    getValidDate,
    updateLastVisitTime,
    clearLastVisitTimes,
  };
};
