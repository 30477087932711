<template>
  <div class="previous-offers">
    <div class="previous-offers__title">{{ title }}</div>
    <div
      v-if="reusableServiceOffers.length"
      class="previous-offers__offers-list"
    >
      <div
        v-for="offer in visibleOffers"
        :key="offer.id"
        class="previous-offers__offer-item"
      >
        <base-new-offer-item
          :title="offer.title"
          :subtitle="offer.subtitle"
          :right-text="offer.amount"
          :left-icon-src="leftIconSrc"
          :right-icon-src="rightIconSrc"
          @clicked="onOfferItemClick(offer.originalData)"
        />
      </div>
      <button
        v-if="showSeeMoreOffersButton"
        class="previous-offers__see-more-button"
        @click="seeMoreOffers"
      >
        {{ seeMoreText }}
      </button>
    </div>
    <div v-else class="previous-offers__no-offers">
      <no-previous-offers />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive } from "vue";
import Mustache from "mustache";
import NoPreviousOffers from "./NoPreviousOffers.vue";
import BaseNewOfferItem from "./BaseNewOfferItem.vue";
import { getCmsItemText, getIconUrl } from "@/utilities/cmsUtils";
import type { CloneableOffer, ServiceOffer } from "@/stores/tender/types";
import { Pages, ServiceType } from "@/stores/cms/types";
import cmsService from "@/services/cmsService";

const props = defineProps<{
  pageCms: Pages;
  cloneableOffers: CloneableOffer[];
  supplierId: number;
}>();
const emit = defineEmits(["new-offer"]);

const state = reactive({
  collapsedOffersCount: 4,
  showAllOffers: false,
  serviceTypesCms: undefined as undefined | Array<ServiceType>,
});

const title = computed(() =>
  getCmsItemText(props.pageCms?.otherText, "previousOffersTitle"),
);
const reusableServiceOffers = computed(() =>
  props.cloneableOffers.map((offer) => {
    return {
      id: offer.serviceOfferId,
      tenderId: offer.tenderId,
      title: offer.nickname || "Tilbud på tjenestetype",
      subtitle: formatSubtitle(offer),
      amount: "",
      serviceOffer: {} as ServiceOffer,
      serviceOfferId: offer.serviceOfferId,
      originalData: offer,
    };
  }),
);
const visibleOffers = computed(() =>
  state.showAllOffers
    ? reusableServiceOffers.value
    : reusableServiceOffers.value.slice(0, state.collapsedOffersCount),
);
const showSeeMoreOffersButton = computed(
  () =>
    reusableServiceOffers.value.length > state.collapsedOffersCount &&
    !state.showAllOffers,
);
const leftIconSrc = computed(() =>
  getIconUrl(props.pageCms?.icons, "reuseOfferIcon"),
);
const rightIconSrc = computed(() =>
  getIconUrl(props.pageCms?.icons, "arrowRightIcon"),
);
const seeMoreText = computed(() => {
  const text = getCmsItemText(props.pageCms?.otherText, "seeMoreOffersText");
  return Mustache.render(text, {
    count: visibleOffers.value.length,
    total: reusableServiceOffers.value.length,
  });
});
const getTranslatedServiceCategory = (serviceCategoryLabel: string) => {
  const matchedServiceType = findMatchingCmsServiceType(
    state.serviceTypesCms || [],
    serviceCategoryLabel,
  );
  const matchedServiceCategory = matchedServiceType
    ? findMatchingServiceCategory(matchedServiceType, serviceCategoryLabel)
    : null;

  if (matchedServiceType && matchedServiceCategory) {
    return `${matchedServiceType.name} - ${matchedServiceCategory.title}`;
  }
  return serviceCategoryLabel;
};
const formatSubtitle = (offer: CloneableOffer): string => {
  const parts = [
    getTranslatedServiceCategory(offer.serviceCategoryLabel),
    offer.customerOrgName,
    offer.customerPropertyAddress,
  ].filter(Boolean);

  return parts.join(" - ");
};

const findMatchingCmsServiceType = (
  cms: Array<ServiceType>,
  categoryLabel: string,
) =>
  cms.find((serviceType) =>
    serviceType.serviceCategories?.some(
      (record) => record.label === categoryLabel,
    ),
  );

const findMatchingServiceCategory = (
  cmsServiceType: ServiceType,
  categoryLabel: string,
) =>
  cmsServiceType.serviceCategories?.find(
    (record) => record.label === categoryLabel,
  );

const seeMoreOffers = () => {
  state.showAllOffers = true;
};

const onOfferItemClick = (offer: CloneableOffer) => {
  emit("new-offer", offer);
};
const initialize = async () => {
  state.serviceTypesCms = await cmsService.getServiceTypesContent();
};
initialize();
</script>

<style lang="scss" scoped>
.previous-offers {
  &__title {
    font-weight: 600;
    font-size: $font-size-base;
    color: $color-black;
  }

  &__no-offers {
    margin-top: 1rem;
  }

  &__offers-list {
    margin-top: 0.625rem;
  }

  &__offer-item {
    &:not(:first-child) {
      margin-top: 0.5rem;
    }
  }

  &__see-more-button {
    margin-top: 1rem;
    background: transparent;
    border: none;
    font-weight: 600;
    font-size: $font-size-sm;
    text-decoration: underline;
  }
}
</style>
