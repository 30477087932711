<template>
  <div class="thread-message" :class="threadMessageClasses">
    <div class="thread-message__head">
      <div class="thread-message__title">
        {{ formattedMessage.senderName }}
      </div>
      <div class="thread-message__date-created">
        {{ formattedMessage.createdOn }}
      </div>
    </div>

    <div class="thread-message__body">
      <div class="thread-message__text">
        <message-renderer :message="formattedMessage.messageBody" />
      </div>

      <div
        v-if="formattedMessage.attachments.length"
        class="thread-message__attachments"
        role="list"
      >
        <div
          v-for="attachment in formattedMessage.attachments"
          :key="attachment.attachmentId"
          class="thread-message__attachment"
          role="listitem"
        >
          <MessageAttachment
            :attachment="attachment"
            :supplier-id="supplierId"
          />
        </div>
      </div>
    </div>

    <div class="thread-message__see-offer">
      <button
        v-if="showSeeOfferButton"
        class="thread-message__see-offer-button"
        :aria-label="seeOfferText"
        @click="handleSeeOfferClick"
      >
        <img :src="NewTabIcon" alt="" aria-hidden="true" />
        <span class="ms-2">{{ seeOfferText }}</span>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useRouter } from "vue-router";
import dayjs from "dayjs";
import MessageRenderer from "@/components/MessageRenderer/MessageRenderer.vue";
import MessageAttachment from "./MessageAttachment.vue";
import { MessageDomain } from "@/custom-types/GeneralTypes";
import { RouteNames } from "@/router/types";
import type { Message, Attachment } from "@/stores/communication/types";
import NewTabIcon from "@/assets/img/new-tab.svg";

interface Props {
  message: Message;
  supplierId: number;
  priceRequestId?: number;
  supplierRequestId?: number;
}

interface FormattedMessage {
  senderName: string;
  createdOn: string;
  messageBody: string;
  attachments: Attachment[];
}

const props = defineProps<Props>();
const router = useRouter();

const seeOfferText = "Se tilbud";

const formattedMessage = computed<FormattedMessage>(() => ({
  senderName: props.message.senderName,
  createdOn: dayjs(props.message.createdOn).format("DD.MM.YYYY HH:mm"),
  messageBody: props.message.messageBody,
  attachments: props.message.attachments ?? [],
}));

const threadMessageClasses = computed(() => ({
  "thread-message--light-purple":
    props.message.messageDomain === MessageDomain.SUPPLIER_CUSTOMER,
}));

const showSeeOfferButton = computed(
  () =>
    props.message.messageDomain === MessageDomain.SUPPLIER_CUSTOMER &&
    props.message.serviceOfferId &&
    props.message.tenderId,
);

const handleSeeOfferClick = () => {
  if (!props.message.serviceOfferId || !props.message.tenderId) return;

  router.push({
    name: RouteNames.EDIT_OFFER,
    params: {
      priceRequestId: props.priceRequestId?.toString(),
      supplierRequestId: props.supplierRequestId?.toString(),
      offerId: props.message.serviceOfferId.toString(),
    },
  });
};
</script>

<style lang="scss" scoped>
.thread-message {
  background: $color-grey-lighter;
  border: 1px solid $color-secondary-lighter;
  border-radius: 4px;
  padding: 1rem;
  line-height: 1.2;

  &--light-purple {
    background: $color-primary-pink;
  }

  &__head {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    font-weight: $font-weight-semi-bold;
    font-size: $font-size-sm;
  }

  &__date-created {
    font-weight: $font-weight-regular;
    font-size: $font-size-sm;
  }

  &__text {
    margin-top: 0.5rem;
    font-weight: $font-weight-light;
    font-size: $font-size-sm;
  }

  &__attachments {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 1rem;
  }

  &__attachment {
    max-width: 20rem;
  }

  &__see-offer {
    margin-top: 1.25rem;
  }

  &__see-offer-button {
    border: 1px solid $color-black;
    border-radius: 8px;
    padding: 0.625rem 1.5rem 0.5rem;
    background: transparent;
    font-size: $font-size-sm;
    transition: background-color 0.2s ease;

    &:hover {
      background: $color-grey-lighter;
    }

    img {
      margin-bottom: 0.25rem;
    }
  }
}
</style>
