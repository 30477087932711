import { ref } from "vue";
import { createCookieCache } from "@/utilities/cookieUtils";
import { useTenderApi } from "@/services/api/useTenderApi";

// Create a cache for price request details with a 2-hour expiration (shorter than others since this data might change more frequently)
const priceRequestCache = createCookieCache("price_request_details", 0.0833); // 2 hours = 0.0833 days

// In-memory cache for the current session
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const inMemoryCache = new Map<string, any>();

/**
 * Composable for caching and retrieving price request details
 */
export const usePriceRequestCache = () => {
  const { getPriceRequestDetails } = useTenderApi();
  const isLoading = ref(false);
  const error = ref<Error | null>(null);

  /**
   * Generate a unique cache key for a price request
   */
  const getCacheKey = (supplierId: number, priceRequestId: number): string => {
    return `${supplierId}_${priceRequestId}`;
  };

  /**
   * Check if price request details are in memory or cookie cache
   */
  const isInCache = (supplierId: number, priceRequestId: number): boolean => {
    const key = getCacheKey(supplierId, priceRequestId);
    return inMemoryCache.has(key) || priceRequestCache.isDataCached(key);
  };

  /**
   * Get price request details from cache or API
   * @param supplierId The supplier ID
   * @param priceRequestId The price request ID
   * @param forceRefresh Whether to force a refresh from the API
   * @returns The price request details
   */
  const getPriceRequestDetailsWithCache = async (
    supplierId: number,
    priceRequestId: number,
    forceRefresh = false,
  ) => {
    if (!supplierId || !priceRequestId) {
      error.value = new Error("Missing supplierId or priceRequestId");
      return null;
    }

    const cacheKey = getCacheKey(supplierId, priceRequestId);

    // Return from memory cache if available and not forcing refresh
    if (!forceRefresh && inMemoryCache.has(cacheKey)) {
      console.log(`Using in-memory cache for price request ${priceRequestId}`);
      return inMemoryCache.get(cacheKey);
    }

    // Try cookie cache if not in memory and not forcing refresh
    if (!forceRefresh && priceRequestCache.isDataCached(cacheKey)) {
      const cachedData = priceRequestCache.getFromCache(cacheKey);
      if (cachedData) {
        console.log(`Using cookie cache for price request ${priceRequestId}`);
        // Update in-memory cache
        inMemoryCache.set(cacheKey, cachedData);
        return cachedData;
      }
    }

    // If not in cache or forcing refresh, fetch from API
    isLoading.value = true;
    error.value = null;

    try {
      console.log(`Fetching price request ${priceRequestId} from API`);
      const response = await getPriceRequestDetails({
        supplierId,
        priceRequestId,
      });

      const data = response.data;

      // Store in both caches
      inMemoryCache.set(cacheKey, data);
      priceRequestCache.saveToCache(cacheKey, data);

      return data;
    } catch (err) {
      console.error("Error fetching price request details:", err);
      error.value =
        err instanceof Error
          ? err
          : new Error("Unknown error fetching price request details");
      return null;
    } finally {
      isLoading.value = false;
    }
  };

  /**
   * Clear the cache for a specific price request
   */
  const clearCache = (supplierId: number, priceRequestId: number) => {
    const key = getCacheKey(supplierId, priceRequestId);
    inMemoryCache.delete(key);
    priceRequestCache.removeFromCache(key);
  };

  /**
   * Clear all cached price request details
   */
  const clearAllCache = () => {
    inMemoryCache.clear();
    // We can't easily clear all cookie cache entries, but they will expire naturally
  };

  return {
    isLoading,
    error,
    getPriceRequestDetailsWithCache,
    isInCache,
    clearCache,
    clearAllCache,
  };
};
