import cmsService from "@/services/cms/cmsService";
import config from "@/config/cms/cmsConfig";
import { Icon } from "@/stores/cms/types";
import { SanityImageSource } from "@sanity/image-url/lib/types/types";

interface OtherTextRecord {
  label: string;
  title: string;
}

interface IconRecord {
  label: string;
  icon: {
    _type: string;
    asset: {
      _ref: string;
    };
  };
}

interface FileRecord {
  label: string;
  document: {
    asset: {
      _ref: string;
    };
  };
}

interface ServiceCategory {
  label: string;
  title: string;
}

interface ServiceType {
  label: string;
  name: string;
  serviceCategories?: ServiceCategory[];
}

const getOtherText = (otherText: OtherTextRecord[], label: string): string => {
  const text = otherText.find((record) => record.label === label);
  return text ? text.title : "";
};

const getIconUrl = (icons: Array<Icon> | undefined, label: string): string => {
  const matchingIcon = icons?.find((icon) => icon.label === label);
  return matchingIcon
    ? cmsService.resolveImage(matchingIcon.icon as SanityImageSource).url()
    : "";
};

const getFileUrl = (files: FileRecord[], label: string): string => {
  const filedoc = files.find((filedoc) => filedoc.label === label);
  if (!filedoc) return "";
  const ref = filedoc.document.asset._ref;
  const [, id, extension] = ref.split("-");
  return `https://cdn.sanity.io/files/${config.projectId}/${config.dataset}/${id}.${extension}`;
};

const getCmsItem = <T extends { label?: string }>(
  items: Array<T> | undefined,
  label: string,
): T | undefined => {
  return items?.find(
    (record) => record.label?.toLowerCase() === label.toLowerCase(),
  );
};

const getCmsItemText = <T extends { label?: string; title?: string }>(
  items: Array<T> | undefined,
  label: string,
): string => {
  const item = getCmsItem(items, label);
  return item?.title ?? "";
};

export {
  getOtherText,
  getIconUrl,
  getFileUrl,
  getCmsItem,
  getCmsItemText,
  // Type exports for reuse in other files
  type OtherTextRecord,
  type IconRecord,
  type FileRecord,
  type ServiceCategory,
  type ServiceType,
};
