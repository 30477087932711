<template>
  <div class="fixed-cost-section">
    <div class="fixed-cost-section__title">{{ title }}</div>
    <div class="fixed-cost-section__description">
      <custom-block-content :blocks="descriptionContent" />
    </div>
    <div v-if="mandatorySupplierCosts.length" class="fixed-cost-section__list">
      <div
        v-for="(supplierCost, index) in mandatorySupplierCosts"
        :key="supplierCost.id"
        class="fixed-cost-section__list-item"
      >
        <fixed-cost-item
          :mandatory-supplier-cost="supplierCost"
          :is-first="index === 0"
          :is-last="index === mandatorySupplierCosts.length - 1"
          :fixed-cost-section-cms="fixedCostSectionCms"
          :activity-form-cms="activityFormCms"
          :read-only="readOnly"
          :service-activities-cms="serviceActivitiesCms"
          @open-edit-item-modal="openNewActivityModal(supplierCost)"
          @open-delete-item-modal="openDeleteActivityItemModal(supplierCost)"
        />
      </div>
    </div>
    <div v-else class="fixed-cost-section__empty-state">
      <empty-state :text="NoActivityText" />
    </div>
    <div v-if="!readOnly" class="fixed-cost-section__new-point-button">
      <secondary-button
        :icon-src="newProductLineButtonIcon"
        :text="newProductLineButtonText"
        @click="openNewActivityModal"
      />
    </div>
    <div class="fixed-cost-section__billing-summary">
      <total-billable-price
        v-if="showTotalBillablePrice"
        :page-cms="pageCms"
        :mandatory-supplier-costs="mandatorySupplierCosts"
        :frequency-options="frequencyOptions"
        :service-activities-cms="serviceActivitiesCms"
      />
    </div>
    <delete-supplier-cost-modal
      v-if="state.recordToDelete"
      v-model="state.showDeleteActivityModal"
      :supplier-id="supplierInfo.id"
      :supplier-request-id="serviceOffer.supplierRequestId"
      :service-offer-id="serviceOffer.serviceOfferId"
      :supplier-cost="state.recordToDelete"
      @cost-item-deleted="handleDeletedCost"
    />
    <activity-modal
      v-model="state.showActivityModal"
      :cost-type="ActivityCostType.Fixed"
      :frame-agreement-id="serviceOffer.frameAgreementId"
      :supplier-cost="state.recordToEdit"
      :activity-form-cms="activityFormCms"
      :frequency-options="frequencyOptions"
      :service-activities-cms="serviceActivitiesCms"
      :supplier-info="supplierInfo"
      :system-price-groups="systemPriceGroups"
      :supplier-request-id="serviceOffer.supplierRequestId"
      :service-offer-id="serviceOffer.serviceOfferId"
      @new-activity-added="handleNewCost"
      @add-new-activity="state.recordToEdit = null"
      @cost-item-deleted="handleDeletedCost"
    />
  </div>
</template>

<script setup>
import cmsService from "@/services/cmsService";
import EmptyState from "@/components/EmptyState/EmptyState.vue";
import SecondaryButton from "@/components/Buttons/SecondaryButton.vue";
import CustomBlockContent from "@/components/CustomBlockContent/CustomBlockContent.vue";
import ActivityModal from "@/components/ActivityModal/ActivityModal.vue";
import TotalBillablePrice from "./TotalBillablePrice.vue";
import { ActivityCostType } from "@/custom-types/types";
import FixedCostItem from "./FixedCostItem.vue";
import { computed, reactive } from "vue";
import DeleteSupplierCostModal from "@/components/ActivitiesCosts/DeleteItemModal/DeleteSupplierCostModal.vue";

const emit = defineEmits(["remove-mandatory-cost", "new-mandatory-cost"]);
const props = defineProps({
  mandatorySupplierCosts: {
    type: Array,
    required: true,
  },
  pageCms: {
    type: Object,
    required: true,
  },
  frequencyOptions: {
    type: Object,
    required: true,
  },
  serviceActivitiesCms: {
    type: Array,
    required: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  supplierInfo: {
    type: Object,
    required: true,
  },
  systemPriceGroups: {
    type: Array,
    required: true,
  },
  serviceOffer: {
    type: Object,
    required: true,
  },
});

const state = reactive({
  showActivityModal: false,
  showDeleteActivityModal: false,
  recordToDelete: null,
  recordToEdit: null,
  newFixedCostFormCms: null,
});

const showTotalBillablePrice = computed(() => {
  return props.mandatorySupplierCosts.some(
    (record) => record.billingCycle || record.frequencyLabel,
  );
});

const activityFormCms = computed(() => {
  return props.pageCms.forms.find((form) => form.label === "activityForm");
});
const fixedCostSectionCms = computed(() => {
  return props.pageCms.pageSections.find(
    (section) => section.label === "fixedCostsAndActivities",
  );
});
const title = computed(() => {
  return fixedCostSectionCms.value.title;
});
const descriptionContent = computed(() => {
  return fixedCostSectionCms.value.content[0];
});
const NoActivityText = computed(() => {
  return fixedCostSectionCms.value.otherText.find(
    (text) => text.label === "noCostsAndActivitiesAdded",
  ).title;
});
const newProductLineButtonIcon = computed(() => {
  const icon = fixedCostSectionCms.value.buttons.find(
    (text) => text.label === "newProductButton",
  ).icons[0].icon;
  return getIconUrl(icon).toString() || "";
});
const newProductLineButtonText = computed(() => {
  return fixedCostSectionCms.value.buttons.find(
    (text) => text.label === "newProductButton",
  ).title;
});

const getIconUrl = (icon) => {
  return cmsService.resolveImage(icon);
};
const openNewActivityModal = (activity = null) => {
  state.recordToEdit = activity;
  state.showActivityModal = true;
};
const openDeleteActivityItemModal = (activity) => {
  state.recordToDelete = activity;
  state.showDeleteActivityModal = true;
};
const handleDeletedCost = (supplierCost) => {
  emit("remove-mandatory-cost", supplierCost);
};
const handleNewCost = (targetActivity) => {
  emit("new-mandatory-cost", targetActivity);
};
</script>

<style lang="scss" scoped>
.fixed-cost-section {
  margin-top: 2.5rem;

  &__title {
    font-weight: 600;
    font-size: $font-size-lg;
    line-height: 22px;
    color: #1d1d1d;
    margin-bottom: 8px;
  }

  &__description {
    margin-top: 16px;
    font-weight: 400;
    font-size: $font-size-sm;
    line-height: 17px;
    color: #1d1d1d;
    margin-bottom: 16px;
  }

  &__list {
    margin-top: 16px;
  }

  &__empty-state {
    margin-top: 16px;
  }

  &__new-point-button {
    margin-top: 16px;
  }

  &__billing-summary {
    margin-top: 1.25rem;
  }
}
</style>
@/types/AppTypes
