<template>
  <div class="active-conversation">
    <button
      v-if="showBackButton"
      class="active-conversation__back-button"
      @click="handleBackClick"
    >
      <img :src="ArrowLeftIcon" alt="ArrowLeft" />
      <span>Tilbake</span>
    </button>
    <template v-if="!isLoadingMessages">
      <div class="active-conversation__messages">
        <MessageList
          :messages="messageThreadMessages"
          :supplier-id="supplierId"
          :price-request-id="priceRequestId"
          :supplier-request-id="supplierRequestId"
        />
      </div>
      <div class="active-conversation__new-message">
        <MessageComposer
          :message-thread="messageThread"
          @send-message="handleSendMessage"
        />
      </div>
      <div
        v-if="error"
        class="active-conversation__error"
        role="alert"
        aria-live="polite"
      >
        <img :src="AlertIcon" alt="" aria-hidden="true" />
        <span>{{ error }}</span>
      </div>
    </template>
    <div v-else class="active-conversation__loader">
      <b-spinner type="grow" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import MessageList from "./MessageList.vue";
import MessageComposer from "./MessageComposer.vue";
import { MessageDomain } from "@/custom-types/GeneralTypes";
import { RequestStateType } from "@/custom-types/GeneralTypes";
import type { Message, MessageThread } from "@/stores/communication/types";
import type { Tender } from "@/stores/tender/types";
import AlertIcon from "@/assets/img/alert.svg";
import ArrowLeftIcon from "@/assets/img/arrow-left.svg";
import type {
  ApiError,
  SendMessagePayload,
} from "@/stores/communication/types";
import { useTenderApi } from "@/services/api/useTenderApi";
import { useUserStore } from "@/stores/user";

interface Props {
  messageThread: MessageThread | null;
  tender: Tender;
  supplierId: number;
  showBackButton: boolean;
  priceRequestId?: number;
  supplierRequestId?: number;
}

const userStore = useUserStore();
const userId = computed(() => userStore.user?.userId);

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: "back-button-clicked"): void;
  (e: "send-message", payload: SendMessagePayload): void;
}>();

const messageThreadMessages = computed<Message[]>(
  () => props.messageThread?.thread || [],
);

const isLoadingMessages = computed(
  () => props.messageThread && !props.messageThread.thread,
);

const error = ref<string>("");

const handleBackClick = () => {
  emit("back-button-clicked");
};

const handleSendMessage = async (payload: SendMessagePayload) => {
  console.log("payload", payload);
  try {
    // Check if this is either the first message overall or first from supplier
    const isFirstMessage = messageThreadMessages.value?.length === 0;
    const isFirstSupplierMessage = !messageThreadMessages.value?.some(
      (message) => message.messageDomain === MessageDomain.SUPPLIER_CUSTOMER,
    );
    const isPriceRequested =
      props.tender?.priceRequests?.[0]?.supplierRequests?.some(
        (request) =>
          request.supplierId === props.supplierId &&
          request.requestState?.toLowerCase() ===
            RequestStateType.PRICE_REQUESTED.toLowerCase(),
      );

    await emit("send-message", {
      ...payload,
      successCallback: async () => {
        error.value = "";

        // If this is either the first message or first supplier message and the tender is in PriceRequested state,
        // set the state to InformationRequested
        if (
          (isFirstMessage || isFirstSupplierMessage) &&
          isPriceRequested &&
          props.supplierRequestId
        ) {
          try {
            const tenderApi = useTenderApi();

            if (!userId.value) {
              throw new Error("User ID is not set");
            }

            await tenderApi.setInformationRequested({
              supplierId: props.supplierId,
              supplierRequestId: props.supplierRequestId,
              data: {
                comment: "InformationRequested",
                reasons: ["InformationRequested"],
                userId: userId.value,
              },
            });
            console.log("State changed to InformationRequested");
          } catch (err) {
            console.error("Failed to set InformationRequested state:", err);
            // We don't want to fail the original message sending operation
            // if the state change fails, so we just log the error
          }
        }

        payload.successCallback();
      },
      errorCallback: (err?: ApiError) => {
        error.value = ""; // Clear any previous errors
        payload.errorCallback(err);
      },
    });
  } catch (e) {
    payload.errorCallback(e as ApiError);
  }
};
</script>

<style lang="scss" scoped>
.active-conversation {
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  height: 440px;
  display: flex;
  flex-direction: column;
  position: relative;

  &__messages {
    flex-grow: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column-reverse;
  }

  &__loader {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__back-button {
    position: absolute;
    top: 0.625rem;
    left: 1rem;
    background: $color-primary-pink-dark;
    border: 1px solid #000000;
    border-radius: 4px;
    padding: 0.75rem 1rem;
    display: flex;
    gap: 0.625rem;
    z-index: 99;

    &:hover {
      background: $color-primary-pink-darker;
    }
  }

  &__error {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1rem;
    background-color: rgba($color-error, 0.1);
    border: 1px solid rgba($color-error, 0.2);
    border-radius: 4px;
    color: $color-error;
    margin: 1rem;
    font-size: $font-size-sm;
    font-weight: 500;

    img {
      width: 1rem;
      height: 1rem;
    }
  }
}
</style>
