<template>
  <div class="message-list-skeleton">
    <div class="message-list-skeleton__messages">
      <div v-for="i in 4" :key="i" class="message-list-skeleton__message">
        <Skeleton
          :width="getRandomWidth()"
          height="40px"
          class="message-list-skeleton__content"
          :style="{ marginLeft: i % 2 === 0 ? 'auto' : '0' }"
        />
        <div
          class="message-list-skeleton__details"
          :style="{ alignItems: i % 2 === 0 ? 'flex-end' : 'flex-start' }"
        >
          <Skeleton width="100px" height="14px" class="mb-2" />
          <Skeleton width="140px" height="14px" />
        </div>
      </div>
    </div>
    <div class="message-list-skeleton__composer">
      <Skeleton height="60px" border-radius="8px" />
    </div>
  </div>
</template>

<script setup lang="ts">
import Skeleton from "primevue/skeleton";

const getRandomWidth = () => {
  const widths = ["40%", "50%", "60%"];
  return widths[Math.floor(Math.random() * widths.length)];
};
</script>

<style lang="scss" scoped>
.message-list-skeleton {
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  height: 440px;
  display: flex;
  flex-direction: column;
  position: relative;

  &__messages {
    flex-grow: 1;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    overflow-y: auto;
  }

  &__message {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    max-width: 100%;
  }

  &__content {
    border-radius: 8px;
  }

  &__details {
    display: flex;
    flex-direction: column;
    padding: 0 0.5rem;
  }

  &__composer {
    padding: 1rem;
    border-top: 1px solid #e4e4e4;
  }
}
</style>
