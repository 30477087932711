import { computed, ref, watch } from "vue";
import {
  PriceRequestDetails,
  Tender,
  TenderViewTab,
} from "@/stores/tender/types";
import { RequestStateType } from "@/custom-types/GeneralTypes";
import { RouteLocationNormalized as Route, Router } from "vue-router";
import { RouteNames } from "@/router/types";
import { usePriceRequestCache } from "@/composables/usePriceRequestCache";

type TenderNavigationProps = {
  tender: Tender;
  supplierId: number;
};

export const useTenderNavigation = (
  props: TenderNavigationProps,
  route: Route,
  router: Router,
) => {
  const { getPriceRequestDetailsWithCache } = usePriceRequestCache();
  const tenderDetails = ref<Tender>({ ...props.tender });
  const priceRequestDetails = ref<PriceRequestDetails | null>(null);
  const isLoading = ref(true);

  // URL parameter handling
  const activeTabValue = computed(
    () => route.query.tab?.toString() || TenderViewTab.PRICE_REQUEST.toString(),
  );
  const activeTabList = computed(
    () => route.query.tabList?.toString() || "new",
  );

  const supplierRequestId = computed(() => {
    return tenderDetails.value?.priceRequests?.[0]?.supplierRequests[0]?.id;
  });

  const hasActiveAgreement = computed(() => {
    return tenderDetails.value?.priceRequests?.[0]?.supplierRequests.some(
      (request) =>
        request.supplierId === props.supplierId &&
        (request.requestState === RequestStateType.ACCEPTED ||
          request.requestState === RequestStateType.COMPLETED),
    );
  });

  const navigateToOfferCreation = () => {
    router.push({
      name: RouteNames.CREATE_OFFER,
      params: {
        priceRequestId: tenderDetails.value.priceRequests[0]?.id,
        supplierRequestId: supplierRequestId.value,
      },
    });
  };

  const handleTabChange = (tab: string) => {
    router.replace({
      query: {
        ...route.query,
        tab,
      },
    });
  };

  const handleTabListChange = (tabList: string) => {
    router.replace({
      query: {
        tabList,
      },
    });
  };

  watch(
    () => props.tender,
    (newTender) => {
      tenderDetails.value = { ...newTender };
    },
  );

  const fetchPriceRequestDetails = async () => {
    try {
      if (!props.supplierId || !props.tender?.priceRequests?.[0]?.id) return;

      const priceRequestId = props.tender.priceRequests[0].id;

      // Use the cached version if available
      const data = await getPriceRequestDetailsWithCache(
        props.supplierId,
        priceRequestId,
      );

      if (data) {
        priceRequestDetails.value = data;
      }

      isLoading.value = false;
    } catch (error) {
      console.error("Error fetching tender details:", error);
      isLoading.value = false;
    }
  };

  return {
    tenderDetails,
    isLoading,
    activeTabValue,
    activeTabList,
    handleTabChange,
    handleTabListChange,
    fetchPriceRequestDetails,
    priceRequestDetails,
    supplierRequestId,
    navigateToOfferCreation,
    hasActiveAgreement,
  };
};
