<template>
  <confirmation-modal
    v-model="showModal"
    :status-icon="statusIcon"
    :title="cms.title"
    :description="cms.subtitle"
    :secondary-button-text="cms.secondaryButtonText"
    :primary-button-text="cms.primaryButtonText"
    :is-submitting="isSubmitting"
    @secondary-click="closeModal"
    @primary-click="handlePrimaryClick"
  >
    <template v-if="hasSubmissionError" #errorMessage>
      <p class="error">{{ submissionErrorMessage }}</p>
    </template>
  </confirmation-modal>
</template>

<script>
import ConfirmationModal from "@/components/ConfirmationModal/ConfirmationModal";
import { deleteServiceReportCms } from "@/components/DeleteServiceReportModal/DeleteServiceReportCms";
import { useServiceReportApi } from "@/services/api/useServiceReportApi";

export default {
  components: { ConfirmationModal },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    serviceReportId: {
      type: Number,
      required: true,
    },
    submissionErrorMessage: {
      type: String,
      default: deleteServiceReportCms.submissionErrorMessage,
    },
  },
  emits: ["update:modelValue", "deleted"],
  data() {
    return {
      serviceReportDeleted: false,
      isSubmitting: false,
      hasSubmissionError: false,
    };
  },
  computed: {
    showModal: {
      get() {
        return this.modelValue;
      },
      set(show) {
        this.$emit("update:modelValue", show);
        this.$nextTick(() => {
          if (!show && this.serviceReportDeleted) {
            this.$emit("deleted");
          }
        });
      },
    },
    cms() {
      return this.serviceReportDeleted
        ? {
            title: deleteServiceReportCms.successTitle,
            subtitle: deleteServiceReportCms.successSubtitle,
            primaryButtonText: deleteServiceReportCms.ok,
            secondaryButtonText: "",
          }
        : {
            title: deleteServiceReportCms.confirmationTitle,
            subtitle: deleteServiceReportCms.confirmationSubtitle,
            primaryButtonText: deleteServiceReportCms.delete,
            secondaryButtonText: deleteServiceReportCms.cancel,
          };
    },
    statusIcon() {
      return this.serviceReportDeleted
        ? `/images/utility/round-check-mark.svg`
        : `/images/utility/round-question-mark.svg`;
    },
  },
  watch: {
    showModal(newVal) {
      if (newVal) {
        this.serviceReportDeleted = false;
        this.isSubmitting = false;
        this.hasSubmissionError = false;
      }
    },
  },
  methods: {
    async deleteOffer() {
      this.isSubmitting = true;
      this.hasSubmissionError = false;
      const { deleteServiceReport } = useServiceReportApi();
      try {
        await deleteServiceReport(this.serviceReportId);
        this.serviceReportDeleted = true;
      } catch (error) {
        this.hasSubmissionError = true;
      } finally {
        this.isSubmitting = false;
      }
    },
    closeModal() {
      this.showModal = false;
    },
    handlePrimaryClick() {
      if (this.serviceReportDeleted) {
        this.closeModal();
      } else {
        this.deleteOffer();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.error {
  font-weight: $font-weight-bold;
  font-size: $font-size-base;
  color: $color-error;
  margin-top: 0.16667rem;
}
</style>
