<template>
  <b-modal
    v-model="showModal"
    :no-close-on-backdrop="true"
    centered
    hide-footer
    hide-header
    body-class="p-0"
    size="lg"
  >
    <form class="work-description" @submit.prevent="">
      <div class="work-description__title">
        <custom-block-content :blocks="title" />
      </div>
      <div class="work-description__field">
        <div class="work-description__field-title">
          {{ headingInputCms.title }}
        </div>
        <div class="work-description__field-description">
          {{ headingInputCms.description }}
        </div>
        <v-field
          v-slot="{ field, errors }"
          name="heading"
          :rules="fieldRules"
          :validate-on-input="true"
        >
          <div v-if="errors.length" class="work-description__field-error">
            <input-error :text="headingInputCms.error" />
          </div>
          <input
            v-bind="field"
            class="work-description__field-input"
            :placeholder="headingInputCms.placeholder"
          />
        </v-field>
      </div>
      <div class="work-description__field">
        <div class="work-description__field-title">
          {{ descriptionInputCms.title }}
        </div>
        <div class="work-description__field-description">
          {{ descriptionInputCms.description }}
        </div>
        <v-field
          v-slot="{ field, errors }"
          name="body"
          :rules="fieldRules"
          :validate-on-input="true"
        >
          <div v-if="errors.length" class="work-description__field-error">
            <input-error :text="descriptionInputCms.error" />
          </div>
          <textarea
            v-bind="field"
            ref="textArea"
            class="work-description__field-input work-description__field-input--textarea"
            :placeholder="descriptionInputCms.placeholder"
          />
        </v-field>
      </div>
      <div class="work-description__action-buttons">
        <tertiary-button @click="closeModal">{{
          getButtonText("cancelButton")
        }}</tertiary-button>
        <b-button
          class="work-description__submit-btn"
          @click.prevent="saveWorkDescription(false)"
        >
          <b-spinner
            v-if="state.isSubmitting && !state.addNewAfterSaving"
            class="work-description__button-spinner"
          />
          {{ getButtonText("saveButton") }}
        </b-button>
        <b-button
          class="work-description__submit-btn"
          @click.prevent="saveWorkDescription(true)"
        >
          <b-spinner
            v-if="state.isSubmitting && state.addNewAfterSaving"
            class="work-description__button-spinner"
          />{{ getButtonText("saveAndAddNewButton") }}</b-button
        >
      </div>
    </form>
  </b-modal>
</template>

<script setup>
import TertiaryButton from "@/components/Buttons/TertiaryButton.vue";
import CustomBlockContent from "@/components/CustomBlockContent/CustomBlockContent.vue";
import InputError from "@/components/InputError/InputError.vue";
import { computed, reactive, watch } from "vue";
import { useForm } from "vee-validate";
import { string } from "yup";
import { Field as VField } from "vee-validate";
import { useTenderApi } from "@/services/api/useTenderApi";

const emit = defineEmits([
  "input",
  "update:modelValue",
  "new-description",
  "add-new-work-description",
]);

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  supplierId: {
    type: Number,
    required: true,
  },
  supplierRequestId: {
    type: Number,
    required: true,
  },
  serviceOfferId: {
    type: Number,
    required: true,
  },
  workDescription: {
    type: [Object, null],
    default: null,
  },
  formCms: {
    type: Object,
    required: true,
  },
});

const tenderApis = useTenderApi();

const state = reactive({
  isSubmitting: false,
  addNewAfterSaving: false,
});

const fieldRules = string().required();
const { resetForm, setValues, handleSubmit } = useForm({
  initialValues: {
    heading: "",
    body: "",
  },
});

const title = computed(() => {
  return props.formCms.title.content;
});
const headingInputCms = computed(() => {
  return getInputTitle("headingInput");
});
const descriptionInputCms = computed(() => {
  return getInputTitle("descriptionInput");
});
const showModal = computed({
  get() {
    return props.modelValue;
  },
  set(newVal) {
    emit("update:modelValue", newVal);
  },
});

watch(
  () => showModal.value,
  (newVal) => {
    if (newVal) {
      resetForm();
      if (props.workDescription) {
        setValues({
          heading: props.workDescription.title,
          body: props.workDescription.body,
        });
      }
    }
  },
);

const getButtonText = (buttonLabel) => {
  const button = props.formCms.buttons.find(
    (button) => button.label === buttonLabel,
  );
  return button?.title;
};
const getInputTitle = (inputLabel) => {
  return props.formCms.inputs.find((input) => input.label === inputLabel);
};
const closeModal = () => {
  showModal.value = false;
};
const saveWorkDescription = async (addNewAfterSaving) => {
  if (state.isSubmitting) {
    return;
  }
  await handleSubmit(async (values) => {
    state.isSubmitting = true;
    state.addNewAfterSaving = addNewAfterSaving;
    try {
      const workDescriptionDto = {
        title: values.heading,
        body: values.body,
      };
      let newDescription;
      if (props.workDescription) {
        newDescription = Object.assign(workDescriptionDto, {
          id: props.workDescription.id,
        });
        await tenderApis.updateServiceOfferDescription(
          props.supplierId,
          props.supplierRequestId,
          props.serviceOfferId,
          newDescription,
        );
      } else {
        const response = props.workDescription
          ? await tenderApis.updateServiceOfferDescription(
              props.supplierId,
              props.supplierRequestId,
              props.serviceOfferId,
              Object.assign(workDescriptionDto, {
                id: props.workDescription.id,
              }),
            )
          : await tenderApis.createServiceOfferDescription(
              props.supplierId,
              props.supplierRequestId,
              props.serviceOfferId,
              workDescriptionDto,
            );
        newDescription = response.data;
      }
      emit("new-description", newDescription);
      if (addNewAfterSaving) {
        emit("add-new-work-description");
      } else {
        closeModal();
      }
      resetForm();
    } catch (error) {
      console.log("Error creating description", error);
    } finally {
      state.isSubmitting = false;
      state.addNewAfterSaving = false;
    }
  })();
};
</script>

<style lang="scss" scoped>
.work-description {
  padding: 1.5rem 1.5rem 2rem 1.5rem;
  font-family: "Montserrat", serif;

  :deep {
    &__title h2 {
      font-weight: 600;
      font-size: $font-size-2xl;
    }
  }

  &__field {
    margin-top: 1.5rem;
  }

  &__field-title {
    font-weight: 600;
    font-size: $font-size-base;
  }

  &__field-description {
    font-weight: 400;
    font-size: $font-size-sm;
  }

  &__field-error {
    margin-top: 0.5rem;
  }

  &__field-input {
    margin-top: 0.5rem;
    width: 100%;
    border: 1px solid #1d1d1d;
    border-radius: 4px;
    padding: 0.85rem 1rem;
    font-weight: 400;
    font-size: $font-size-sm;
    line-height: 1.2;

    &--textarea {
      resize: none;
      height: 225px;
    }
  }

  &__action-buttons {
    margin-top: 1.5rem;
    display: flex;
    justify-content: space-between;

    > button {
      padding: 1rem 2.5rem;
    }
  }

  &__submit-btn {
    background-color: $color-primary;
    border: 2px solid $color-primary !important;
    color: white;
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    &:focus {
      background-color: $color-primary-darker;
    }

    &:hover {
      background-color: $color-primary-darker;
      transform: scale(1.05);
    }

    &:active {
      transform: scale(1);
      background-color: $color-primary-darker;
    }

    &:disabled {
      background-color: $color-primary-darker;
      color: white;
    }
  }

  &__button-spinner {
    width: 0.8125rem;
    height: 0.8125rem;
  }
}

:deep(.modal-lg) {
  max-width: 680px !important;
}
</style>
@/utilities/uuid
