<template>
  <div class="request-list">
    <template v-if="error">
      <div class="error-state">
        <i class="pi pi-exclamation-triangle" />
        <h3>Beklager, noe gikk galt</h3>
        <p>{{ error }}</p>
        <BaseButton text="Prøv igjen" @click="$emit('retry')" />
      </div>
    </template>
    <template v-else-if="loading">
      <SkeletonTenderList :item-count="20" />
    </template>
    <template v-else-if="!items.length">
      <div class="empty-state">
        <i class="pi pi-inbox" />
        <h3>
          {{
            currentPage === "myRequests"
              ? "Ingen forespørsler"
              : "Ingen prosjekter"
          }}
        </h3>
        <p>
          {{
            currentPage === "myRequests"
              ? "Det er ingen forespørsler å vise i denne kategorien enda."
              : "Det er ingen prosjekter å vise i denne kategorien enda."
          }}
        </p>
      </div>
    </template>
    <TenderList
      v-else
      :loading="loading"
      :tenders-list="tendersList"
      :supplier-id="supplierId"
      @reload-tender-list="handleReloadTenderList"
      @agreement-click="handleAgreementClick"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useRoute } from "vue-router";
import TenderList from "@/components/MyTenders/TenderList.vue";
import SkeletonTenderList from "@/components/MyTenders/SkeletonTenderList.vue";
import type { Tender } from "@/stores/tender/types";
import BaseButton from "@/components/Base/BaseButton/BaseButton.vue";

// Get the current route to determine which page we're on
const route = useRoute();
const currentPage = computed(() => route.name as string);

interface Props {
  loading: boolean;
  error?: string;
  items: Tender[];
  title: string;
  supplierId: number;
}

const props = withDefaults(defineProps<Props>(), {
  loading: false,
  error: "",
  items: () => [],
  supplierUsers: () => [],
});

const emit = defineEmits(["retry", "reload-tender-list", "agreement-click"]);

const handleReloadTenderList = () => {
  emit("reload-tender-list");
};

const handleAgreementClick = (priceRequestId: number | string) => {
  emit("agreement-click", priceRequestId);
};

const tendersList = computed(() => {
  if (!props.items.length) return [];

  return [
    {
      title: props.title,
      tenders: props.items,
    },
  ];
});
</script>

<style lang="scss" scoped>
.request-list {
  position: relative;
  margin-top: 1rem;
}

.error-state,
.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem;
  text-align: center;

  i {
    font-size: 3rem;
    margin-bottom: 1rem;
  }

  h3 {
    margin: 1rem 0;
    font-weight: 500;
  }

  p {
    margin-bottom: 1rem;
    font-weight: 400;
  }
}

.error-state {
  i {
    color: $color-error;
  }
}
</style>
