import {
  SupplierApi,
  SupplierApiEndpoints,
} from "@/config/api/supplier/supplierApi";
import { executeApiRequest } from "@/services/api/apiService";
import { useToastErrorHandler } from "@/composables/useToastErrorHandler";
import {
  Supplier,
  ServiceCategory,
  SustainabilityGoal,
  Certification,
  UpdateSupplierProfileDto,
  SupplierAddress,
} from "@/stores/supplier/types";

const getEndpointFactory = (endpointKey: symbol) => ({
  endpointKey,
  config: SupplierApi,
});

const useSupplierApiService = () => {
  const { withToastErrorHandling } = useToastErrorHandler();

  const getSupplierByUserId = async (userId: string) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<Supplier | void>(
          getEndpointFactory(
            Symbol.for(SupplierApiEndpoints.GET_SUPPLIER_BY_USER_ID),
          ),
          {
            pathParams: [userId],
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Leverandørfeil",
          detail: "Kunne ikke hente leverandør for bruker",
        },
      },
    );
  };

  const fetchCategoriesBySupplierType = async (params: {
    supplierId: number;
    supplierTypeId: number;
  }) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<ServiceCategory[]>(
          getEndpointFactory(
            Symbol.for(SupplierApiEndpoints.FETCH_CATEGORIES_BY_SUPPLIER_TYPE),
          ),
          {
            pathParams: [
              params.supplierId,
              "supplierType",
              params.supplierTypeId,
              "categories",
            ],
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Kategorifeil",
          detail: "Kunne ikke hente kategorier for leverandørtype",
        },
      },
    );
  };

  const getSupplierSustainabilityGoals = async (params: {
    supplierId: number;
  }) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<SustainabilityGoal[]>(
          getEndpointFactory(
            Symbol.for(SupplierApiEndpoints.GET_SUPPLIER_SUSTAINABILITY_GOALS),
          ),
          {
            pathParams: [params.supplierId, "sustainability"],
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Bærekraftfeil",
          detail: "Kunne ikke hente bærekraftsmål for leverandør",
        },
      },
    );
  };

  const getSupplierCertifications = async (params: { supplierId: number }) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<Certification[]>(
          getEndpointFactory(
            Symbol.for(SupplierApiEndpoints.GET_SUPPLIER_CERTIFICATIONS),
          ),
          {
            pathParams: [params.supplierId, "certifications"],
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Sertifiseringsfeil",
          detail: "Kunne ikke hente sertifiseringer for leverandør",
        },
      },
    );
  };

  const getSupplierServiceCategories = async (params: {
    supplierId: number;
  }) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<ServiceCategory[]>(
          getEndpointFactory(
            Symbol.for(SupplierApiEndpoints.GET_SUPPLIER_SERVICE_CATEGORIES),
          ),
          {
            pathParams: [params.supplierId, "categories"],
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Kategorifeil",
          detail: "Kunne ikke hente tjenestekategorier for leverandør",
        },
      },
    );
  };

  const getSupplierAddresses = async (params: { supplierId: number }) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<SupplierAddress[]>(
          getEndpointFactory(
            Symbol.for(SupplierApiEndpoints.GET_SUPPLIER_ADDRESSES),
          ),
          {
            pathParams: [params.supplierId, "Addresses"],
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Adressefeil",
          detail: "Kunne ikke hente adresser for leverandør",
        },
      },
    );
  };

  const updateSupplierProfile = async (params: {
    supplierId: number;
    data: UpdateSupplierProfileDto;
  }) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<Supplier>(
          getEndpointFactory(
            Symbol.for(SupplierApiEndpoints.UPDATE_SUPPLIER_PROFILE),
          ),
          {
            data: params.data,
            pathParams: [params.supplierId],
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Leverandørfeil",
          detail: "Kunne ikke oppdatere leverandørprofil",
        },
        successOptions: {
          severity: "success",
          summary: "Vellykket",
          detail: "Leverandørprofil oppdatert",
        },
      },
    );
  };

  const createSupplier = async (data: UpdateSupplierProfileDto) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<Supplier>(
          getEndpointFactory(
            Symbol.for(SupplierApiEndpoints.CREATE_SUPPLIER_PROFILE),
          ),
          {
            data: data,
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Leverandørfeil",
          detail: "Kunne ikke opprette leverandør",
        },
        successOptions: {
          severity: "success",
          summary: "Vellykket",
          detail: "Leverandør opprettet",
        },
      },
    );
  };

  const createSupplierUser = async (params: {
    supplierId: number;
    userId: string;
  }) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<string>(
          getEndpointFactory(
            Symbol.for(SupplierApiEndpoints.CREATE_SUPPLIER_USER),
          ),
          {
            pathParams: [params.supplierId, "User", params.userId],
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Brukerfeil",
          detail: "Kunne ikke knytte bruker til leverandør",
        },
        successOptions: {
          severity: "success",
          summary: "Vellykket",
          detail: "Bruker knyttet til leverandør",
        },
      },
    );
  };

  const getSupplierByOrgNumber = async (orgNumber: string) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<Supplier>(
          getEndpointFactory(
            Symbol.for(SupplierApiEndpoints.GET_SUPPLIER_BY_ORG_NUMBER),
          ),
          {
            pathParams: [orgNumber],
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Leverandørfeil",
          detail: "Kunne ikke finne leverandør med dette organisasjonsnummeret",
        },
      },
    );
  };

  const editSupplierLogo = async (params: {
    supplierId: number;
    logo: string;
  }) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<void>(
          getEndpointFactory(
            Symbol.for(SupplierApiEndpoints.EDIT_SUPPLIER_LOGO),
          ),
          {
            data: { logo: params.logo },
            pathParams: [params.supplierId, "logo"],
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Logofeil",
          detail: "Kunne ikke oppdatere leverandørlogo",
        },
        successOptions: {
          severity: "success",
          summary: "Vellykket",
          detail: "Leverandørlogo oppdatert",
        },
      },
    );
  };

  const getSupplierLogo = async (supplierId: number) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<{ logo: string }>(
          getEndpointFactory(
            Symbol.for(SupplierApiEndpoints.GET_SUPPLIER_LOGO),
          ),
          {
            pathParams: [supplierId, "logo"],
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Logofeil",
          detail: "Kunne ikke hente leverandørlogo",
        },
      },
    );
  };

  return {
    getSupplierByUserId,
    fetchCategoriesBySupplierType,
    getSupplierSustainabilityGoals,
    getSupplierCertifications,
    getSupplierServiceCategories,
    getSupplierAddresses,
    updateSupplierProfile,
    createSupplierUser,
    createSupplier,
    getSupplierByOrgNumber,
    editSupplierLogo,
    getSupplierLogo,
  };
};

export const useSupplierApi = useSupplierApiService;
