import { loginRequest, msalInstance } from "@/config/auth/authConfig";
import { useAuthenticationCookie } from "@/composables/useAuthenticationCookie";
import { useUserStore } from "@/stores/user";
import { AccountInfo, SilentRequest } from "@azure/msal-browser";

const API_SCOPES = loginRequest.scopes;
interface UseMsalAuthenticationReturn {
  initializeMsal: () => Promise<void>;
  signOut: () => Promise<void>;
  signIn: () => Promise<void>;
  acquireApiAccessToken: (
    account: AccountInfo,
    triggerLoginIfNecessary?: boolean,
  ) => Promise<string>;
}

export function useMsalAuthentication(): UseMsalAuthenticationReturn {
  const { getAuthenticationCookieData, setAuthenticationCookieData } =
    useAuthenticationCookie();

  const initializeMsal = async () => {
    try {
      await msalInstance.initialize();
    } catch (error) {
      console.error("Error initializing MSAL:", error);
      // TODO: Add more specific error handling
    }
  };

  const loginUserLocally = async (account: AccountInfo) => {
    try {
      const userStore = useUserStore();
      userStore.setMsalAccount(account);
      setAuthenticationCookieData(account);
    } catch (error) {
      console.error("Error logging in user:", error);
      // TODO: Add more specific error handling
    }
  };

  const getAuthenticatedCookieAccount = async () => {
    const account = getAuthenticationCookieData();
    if (account) {
      try {
        await acquireApiAccessToken(account, false);
        return account;
      } catch (error) {
        console.log("Error getting token: ", error);
      }
    }
    return undefined;
  };

  const signIn = async () => {
    const tokenResponse = await msalInstance.handleRedirectPromise();
    const account =
      tokenResponse?.account || (await getAuthenticatedCookieAccount());
    if (account) {
      await loginUserLocally(account);
    } else {
      await msalInstance.loginRedirect(loginRequest);
    }
  };

  const acquireApiAccessToken = async (
    account: AccountInfo,
    triggerLoginIfNecessary = true,
  ) => {
    const accessTokenRequest: SilentRequest = {
      scopes: API_SCOPES,
      account: account,
    };
    try {
      const response =
        await msalInstance.acquireTokenSilent(accessTokenRequest);
      return response.accessToken;
    } catch (error) {
      console.log("Error acquiring token", error);
      if (triggerLoginIfNecessary) {
        await signIn();
      }
      throw error;
    }
  };

  const signOut = async () => {
    try {
      const authenticationData = getAuthenticationCookieData();
      setAuthenticationCookieData(null);
      const userStore = useUserStore();
      userStore.clearUserData();
      await msalInstance.logoutRedirect({
        account: authenticationData,
      });
    } catch (error) {
      console.error("Error signing out:", error);
      // TODO: Add more specific error handling
    }
  };

  return {
    initializeMsal,
    signOut,
    signIn,
    acquireApiAccessToken,
  };
}
