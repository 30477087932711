import {
  CommunicationApi,
  CommunicationApiEndpoints,
} from "@/config/api/communication/communicationApi";
import { executeApiRequest } from "@/services/api/apiService";
import {
  Message,
  MessageThread,
  MessageThreadArray,
  SendMessageDto,
  UnreadMessageStatistics,
} from "@/stores/communication/types";
import { useToastErrorHandler } from "@/composables/useToastErrorHandler";

const useCommunicationApiService = () => {
  const { withToastErrorHandling } = useToastErrorHandler();

  const sendMessage = async (data: SendMessageDto) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<Message>(
          {
            endpointKey: Symbol.for(CommunicationApiEndpoints.SEND_MESSAGE),
            config: CommunicationApi,
          },
          {
            data: data,
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Sendefeil",
          detail: "Kunne ikke sende meldingen",
        },
      },
    );
  };

  const fetchSupplierConversations = async (supplierRequestId: number) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<MessageThreadArray>(
          {
            endpointKey: Symbol.for(
              CommunicationApiEndpoints.GET_SUPPLIER_CONVERSATIONS,
            ),
            config: CommunicationApi,
          },
          {
            pathParams: [supplierRequestId],
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Hentefeil",
          detail: "Kunne ikke hente leverandørens samtaler",
        },
      },
    );
  };

  const fetchConversationMessages = async (conversationId: string) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<MessageThread>(
          {
            endpointKey: Symbol.for(
              CommunicationApiEndpoints.GET_CONVERSATION_MESSAGES,
            ),
            config: CommunicationApi,
          },
          {
            pathParams: [conversationId],
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Hentefeil",
          detail: "Kunne ikke hente meldingene i samtalen",
        },
      },
    );
  };

  const fetchSupplierUnreadMessagesCount = async (query: {
    addressId: string;
    supplierId: string;
  }) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<number>(
          {
            endpointKey: Symbol.for(
              CommunicationApiEndpoints.GET_SUPPLIER_UNREAD_MESSAGES_COUNT,
            ),
            config: CommunicationApi,
          },
          {
            pathParams: [
              "address",
              query.addressId,
              "supplier",
              query.supplierId,
            ],
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Hentefeil",
          detail: "Kunne ikke hente antall uleste meldinger",
        },
      },
    );
  };

  const fetchAllSupplierUnreadMessagesCount = async (supplierId: string) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<number>(
          {
            endpointKey: Symbol.for(
              CommunicationApiEndpoints.GET_ALL_SUPPLIER_UNREAD_MESSAGES_COUNT,
            ),
            config: CommunicationApi,
          },
          {
            pathParams: ["Supplier", supplierId],
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Hentefeil",
          detail: "Kunne ikke hente totalt antall uleste meldinger",
        },
      },
    );
  };

  const fetchSupplierUnreadMessageStatistics = async (supplierId: string) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<UnreadMessageStatistics>(
          {
            endpointKey: Symbol.for(
              CommunicationApiEndpoints.GET_SUPPLIER_UNREAD_MESSAGE_STATISTICS,
            ),
            config: CommunicationApi,
          },
          {
            pathParams: [supplierId, "UnreadMessageStatistics"],
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Statistikkfeil",
          detail: "Kunne ikke hente statistikk for uleste meldinger",
        },
      },
    );
  };

  const markMessagesRead = async (messageIds: string[]) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest(
          {
            endpointKey: Symbol.for(
              CommunicationApiEndpoints.MARK_MESSAGES_READ,
            ),
            config: CommunicationApi,
          },
          {
            data: { messageIds },
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Oppdateringsfeil",
          detail: "Kunne ikke markere meldingene som lest",
        },
      },
    );
  };

  return {
    sendMessage,
    fetchSupplierConversations,
    fetchConversationMessages,
    fetchSupplierUnreadMessagesCount,
    fetchAllSupplierUnreadMessagesCount,
    fetchSupplierUnreadMessageStatistics,
    markMessagesRead,
  };
};

export const useCommunicationApi = useCommunicationApiService;
