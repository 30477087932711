import { ref, computed, watch } from "vue";
import { useTenderApi } from "@/services/api/useTenderApi";
import { OfferStateType, ServiceOffer } from "@/custom-types/GeneralTypes";
import { ServiceOffer as ServiceOfferType } from "@/custom-types/serviceOffers";
import { Tender } from "@/stores/tender/types";

interface UseServiceOffersProps {
  supplierId: number;
  tender: Tender;
}

export function useServiceOffers(
  props: UseServiceOffersProps,
  supplierRequestId: number | null,
) {
  const { getServiceOffers, getServiceOffer } = useTenderApi();
  const serviceOffers = ref<ServiceOffer[]>([]);
  const isLoading = ref(true);
  const acceptedServiceOffer = ref<ServiceOfferType | null>(null);

  const selectedServiceOfferId = computed(() => {
    if (!supplierRequestId) return null;
    const acceptedOffer = serviceOffers.value.find(
      (offer) =>
        offer.currentState === OfferStateType.ACCEPTED ||
        offer.currentState === OfferStateType.COMPLETED,
    );

    return acceptedOffer?.serviceOfferId ?? null;
  });

  const acceptedServiceOfferItem = computed(() => {
    return serviceOffers.value.find(
      (offer) =>
        offer.currentState === OfferStateType.ACCEPTED ||
        offer.currentState === OfferStateType.COMPLETED,
    );
  });

  const hasAcceptedServiceOffer = computed(() => {
    return acceptedServiceOfferItem.value !== null;
  });

  const bookingNumber = computed(() => {
    return acceptedServiceOfferItem.value?.bookingNumber;
  });

  const fetchServiceOffers = async () => {
    try {
      if (!props.supplierId || !supplierRequestId) return;

      const response = await getServiceOffers({
        supplierId: props.supplierId,
        supplierRequestId: supplierRequestId,
      });

      serviceOffers.value = response.data;
    } catch (error) {
      console.error("Error fetching service offers:", error);
    } finally {
      isLoading.value = false;
    }
  };

  const fetchServiceOffer = async () => {
    try {
      if (
        !props.supplierId ||
        !supplierRequestId ||
        !selectedServiceOfferId.value
      )
        return;

      const response = await getServiceOffer(
        props.supplierId,
        supplierRequestId,
        selectedServiceOfferId.value,
      );

      acceptedServiceOffer.value = response.data as ServiceOfferType;
    } catch (error) {
      console.error("Error fetching service offer:", error);
    }
  };

  watch(() => supplierRequestId, fetchServiceOffers);

  return {
    serviceOffers,
    isLoading,
    selectedServiceOfferId,
    fetchServiceOffers,
    fetchServiceOffer,
    acceptedServiceOffer,
    acceptedServiceOfferItem,
    hasAcceptedServiceOffer,
    bookingNumber,
  };
}
