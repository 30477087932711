<template>
  <div
    class="attachment"
    :class="attachmentClasses"
    @click="handleAttachmentClick"
  >
    <div class="attachment__left-icon-container">
      <b-spinner
        v-if="state.isFetchingAttachment"
        class="attachment__spinner"
      />
      <slot v-else name="leftIcon">
        <img
          :src="Document32Icon"
          alt="left icon"
          class="attachment__left-icon"
        />
      </slot>
    </div>
    <div class="attachment__title">
      <div class="attachment__name">
        {{ formattedAttachment.nameWithoutExtension }}
      </div>
      <div>
        {{ formattedAttachment.extension }}
      </div>
    </div>
    <button
      v-if="removable"
      type="button"
      class="attachment__remove-button"
      @click.stop="handleRemoveClick"
    >
      <img :src="CloseCircleIcon" alt="" />
    </button>
    <img
      v-else-if="!hideRightIcon"
      :src="NewTabIcon"
      alt="right icon"
      class="attachment__right-icon"
    />
  </div>
</template>

<script setup lang="ts">
import Document32Icon from "@/assets/img/Document_32.svg";
import CloseCircleIcon from "@/assets/img/CloseCircle.svg";
import { computed, reactive } from "vue";
import {
  getFileExtension,
  getFileNameWithoutExtension,
} from "@/utilities/fileUtils";
import { isValidHttpUrl } from "@/utilities/stringUtils";
import NewTabIcon from "@/assets/img/new-tab.svg";

const props = withDefaults(
  defineProps<{
    fileName: string;
    attachment?: Blob | string | (() => Promise<Blob>);
    removable?: boolean;
    hideRightIcon?: boolean;
    fullWidth?: boolean;
  }>(),
  {
    attachment: undefined,
    fullWidth: false,
  },
);

const emit = defineEmits(["attachment-clicked", "remove"]);

const state = reactive({
  isFetchingAttachment: false,
});

const formattedAttachment = computed(() => {
  return {
    nameWithoutExtension: getFileNameWithoutExtension(props.fileName),
    extension: getFileExtension(props.fileName),
  };
});
const attachmentClasses = computed(() => {
  return props.fullWidth ? [`attachment--full-width`] : [];
});

const handleAttachmentClick = async () => {
  emit("attachment-clicked");
  if (!props.attachment) {
    return;
  }
  state.isFetchingAttachment = true;
  try {
    if (typeof props.attachment === "function") {
      const attachmentBlob = await props.attachment();
      console.log("A", attachmentBlob);
      viewBlob(attachmentBlob);
    } else if (props.attachment instanceof Blob) {
      viewBlob(props.attachment);
    } else if (isValidHttpUrl(props.attachment)) {
      openUrlInNewWindow(props.attachment);
    }
  } catch (error) {
    console.log("Error viewing attachment", error);
  } finally {
    state.isFetchingAttachment = false;
  }
};
const viewBlob = (file: Blob) => {
  console.log("viewBlob", file);
  const fileUrl = URL.createObjectURL(file);
  openUrlInNewWindow(fileUrl);
};
const openUrlInNewWindow = (url: string) => {
  window.open(url, "_blank");
};
const handleRemoveClick = () => {
  emit("remove");
};
</script>

<style lang="scss" scoped>
.attachment {
  min-width: 0;
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  border-radius: 8px;
  gap: 0.25rem;
  transition: all ease-out 200ms;
  border: 1px solid #5a5a5a;
  cursor: pointer;

  &:hover {
    background: #f6f6f6;
  }

  &--full-width {
    display: flex;
  }

  &__left-icon-container {
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__spinner {
    width: 1rem;
    height: 1rem;
    border-width: 2px;
  }

  &__left-icon {
    flex-shrink: 0;
  }

  &__right-icon {
    flex-shrink: 0;
    margin-left: 0.5rem;
  }

  &__title {
    min-width: 0;
    flex-grow: 1;
    display: flex;
    align-items: center;
    font-weight: 600;
  }

  &__name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__remove-button {
    border: none;
    background: transparent;
    border-radius: 4px;

    &:hover {
      background: #f6f6f6;
    }
  }
}
</style>
