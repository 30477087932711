<template>
  <b-modal
    v-model="showModal"
    :no-close-on-backdrop="true"
    centered
    hide-footer
    hide-header
    body-class="p-0"
    size="lg"
  >
    <form class="activity-form">
      <div class="activity-form__title">
        <custom-block-content :blocks="resolvedTitle" />
      </div>
      <div class="activity-form__field">
        <div class="activity-form__field-title">
          {{ descriptionInputCms.title }}
        </div>
        <div class="activity-form__field-description">
          {{ descriptionInputCms.description }}
        </div>
      </div>
      <v-field
        v-slot="{ value, errors, handleBlur }"
        name="description"
        :rules="string().required()"
        :validate-on-input="true"
      >
        <div v-if="errors.length" class="activity-form__field-input-error">
          <input-error :text="descriptionInputCms.error" />
        </div>
        <div class="activity-form__field-input">
          <auto-complete
            :model-value="value"
            :placeholder="descriptionInputCms.placeholder"
            :suggestions="state.suggestions"
            :show-empty-message="false"
            :delay="500"
            :complete-on-focus="true"
            option-label="title"
            append-to="self"
            input-class="activity-form__description-input"
            panel-class="activity-form__description-autocomplete-panel-class"
            fluid
            @complete="searchTemplates"
            @option-select="handleSelectedPriceTemplate"
            @blur="handleBlur"
            @keyup="handleAutocompleteInput"
          >
            <template #option="slotProps">
              <div class="activity-form__autocomplete-suggestion-item">
                <div class="activity-form__autocomplete-suggestion-title">
                  {{ slotProps.option.title }}
                </div>
                <div class="activity-form__autocomplete-suggestion-description">
                  {{ slotProps.option.description }}
                </div>
              </div>
            </template>
          </auto-complete>
        </div>
      </v-field>
      <div
        v-if="showFrequencyOptions"
        class="activity-form__frequency-options-wrapper"
      >
        <frequency-options
          :activity-form-cms="activityFormCms"
          :frequency-options="frequencyOptions"
          :service-activities-cms="serviceActivitiesCms"
          :form-errors="state.stabilizedFormErrors"
          :form-values="values"
          @new-form-values="updateFormValues"
        />
      </div>
      <div v-if="showPriceCalculator" class="activity-form__price-calculator">
        <fixed-price-calculator
          v-if="costType === ActivityCostType.Fixed"
          :activity-form-cms="activityFormCms"
          :service-activities-cms="serviceActivitiesCms"
          :form-errors="state.stabilizedFormErrors"
          :form-values="values"
          :supplier-commission="supplierCommission"
          @new-form-values="updateFormValues"
        />
        <variable-price-calculator
          v-else
          :activity-form-cms="activityFormCms"
          :service-activities-cms="serviceActivitiesCms"
          :form-errors="state.stabilizedFormErrors"
          :form-values="values"
          :supplier-commission="supplierCommission"
          @new-form-values="updateFormValues"
        />
      </div>
      <div class="activity-form__action-buttons">
        <tertiary-button @click="closeModal">{{
          getButtonText("cancelButton")
        }}</tertiary-button>
        <primary-button
          :is-submitting="state.isSubmitting && !state.addNewAfterSaving"
          @click="saveActivity(false)"
          >{{ getButtonText("saveButton") }}</primary-button
        >
        <primary-button
          :is-submitting="state.isSubmitting && state.addNewAfterSaving"
          @click="saveActivity(true)"
          >{{ getButtonText("saveAndAddNewButton") }}</primary-button
        >
      </div>
    </form>
  </b-modal>
</template>

<script setup>
import { computed, reactive, watch } from "vue";
import PrimaryButton from "@/components/Buttons/PrimaryButton.vue";
import TertiaryButton from "@/components/Buttons/TertiaryButton.vue";
import { getCmsItemText } from "@/utilities/cmsUtilities";
import CustomBlockContent from "@/components/CustomBlockContent/CustomBlockContent.vue";
import InputError from "@/components/InputError/InputError.vue";
import { Field as VField, useForm } from "vee-validate";
import { uuidv4 } from "@/utilities/uuid";
import { cloneDeep, isEqual } from "lodash";
import { string } from "yup";
import FrequencyOptions from "@/components/ActivityModal/FrequencyOptions.vue";
import {
  ActivityCostType,
  ActivityFrequency,
  ServiceActivityCmsLabelType,
} from "@/custom-types/types";
import FixedPriceCalculator from "@/components/ActivityModal/FixedPriceCalculator.vue";
import VariablePriceCalculator from "@/components/ActivityModal/VariablePriceCalculator.vue";
import { firstLetterUppercase } from "@/utilities/stringUtils";
import { useTenderApi } from "@/services/api/useTenderApi";
import { useSystemApi } from "@/services/api/useSystemApi";
import useSupplierCostItem, {
  SupplierCostFrequencyGroup,
  SupplierCostFrequencyGroupLabel,
} from "@/composables/useSupplierCostItem";
import AutoComplete from "primevue/autocomplete";
import { formatCurrency } from "@/utilities/numberUtils";

const showModal = defineModel({ required: true });
const emit = defineEmits([
  "input",
  "new-activity-added",
  "add-new-activity",
  "cost-item-deleted",
]);

const props = defineProps({
  costType: {
    type: String, //Fixed, Variable
    required: true,
  },
  frameAgreementId: {
    type: [Number, undefined],
    default: undefined,
  },
  supplierCost: {
    type: [Object, null],
    default: null,
  },
  activityFormCms: {
    type: Object,
    required: true,
  },
  serviceActivitiesCms: {
    type: Array,
    required: true,
  },
  frequencyOptions: {
    type: Object,
    required: true,
  },
  supplierInfo: {
    type: Object,
    required: true,
  },
  systemPriceGroups: {
    type: Array,
    required: true,
  },
  supplierRequestId: {
    type: Number,
    required: true,
  },
  serviceOfferId: {
    type: Number,
    required: true,
  },
});

const tenderApis = useTenderApi();
const systemApis = useSystemApi();
const {
  extractFrequencyFromFrequencyLabel,
  extractFrequencyGroupFromFrequencyLabel,
  formatLimitedPeriod,
  getInputItemCost,
} = useSupplierCostItem();

const {
  errors: formErrors,
  resetForm,
  setValues,
  handleSubmit,
  values,
} = useForm({
  keepValuesOnUnmount: true,
  initialValues: getFormDataFromSupplierCost(),
});

const state = reactive({
  stabilizedFormErrors: {},
  isSubmitting: false,
  addNewAfterSaving: false,
  suggestions: null,
  selectedPriceTemplate: undefined,
});

const resolvedTitle = computed(() => {
  return props.activityFormCms.title.content;
});
const descriptionInputCms = computed(() => {
  return props.activityFormCms.inputs.find(
    (input) => input.label === "activityDescription",
  );
});
const showFrequencyOptions = computed(() => {
  return props.costType === ActivityCostType.Fixed;
});
const showPriceCalculator = computed(() => {
  return props.costType === ActivityCostType.Variable || values.frequencyGroup;
});
const hasFrameAgreement = computed(() => {
  return typeof props.frameAgreementId === "number";
});
const frameAgreementCut = computed(() => {
  if (!hasFrameAgreement.value) {
    return 0;
  }
  const frameAgreement = props.supplierInfo.frameAgreements.find(
    (record) => record.id === props.frameAgreementId,
  );
  return frameAgreement?.commission || 0;
});
const supplierCommission = computed(() => {
  return hasFrameAgreement.value
    ? frameAgreementCut.value
    : props.supplierInfo.commission;
});
const availableFrequencyOptions = computed(() =>
  Object.values(props.frequencyOptions).flatMap((record) => record),
);
const availableFrequenciesCms = computed(() =>
  props.serviceActivitiesCms
    .filter((record) =>
      [
        ServiceActivityCmsLabelType.Monthly,
        ServiceActivityCmsLabelType.Yearly,
      ].includes(record.label),
    )
    .flatMap((record) => record.frequencies),
);

watch(
  () => showModal.value,
  (newVal) => {
    if (!newVal) {
      return;
    }
    resetForm();
    if (props.supplierCost) {
      setValues(getFormDataFromSupplierCost(props.supplierCost));
    }
    state.suggestions = null;
    state.selectedPriceTemplate = undefined;
  },
);

watch(
  () => formErrors.value,
  (newVal) => {
    if (!isEqual(cloneDeep(newVal), cloneDeep(state.stabilizedFormErrors))) {
      state.stabilizedFormErrors = cloneDeep(newVal);
    }
  },
  { deep: true, immediate: true },
);
const handleAutocompleteInput = (e) => {
  let newValue = e.target.value;
  if (e.code === "Space") {
    newValue = `${values.description} `;
  }
  updateActivityFormDescription(newValue);
};
const updateActivityFormDescription = (value) => {
  setValues(
    {
      description: value,
    },
    false,
  );
};
const searchTemplates = async (event) => {
  const priceTemplates = await getPriceTemplates(event.query);
  const activityItemsCms = props.serviceActivitiesCms.flatMap(
    (record) => record.activityItems,
  );
  state.suggestions = priceTemplates
    .map((priceTemplate) => {
      const { formattedFrequency, frequencyLabel, frequencyGroup } =
        getFrequencyInformationFromPriceTemplate(priceTemplate);
      const matchingItemCms = activityItemsCms.find(
        (record) => record.label === priceTemplate.unit,
      );
      const formattedItemCost = [
        formatCurrency(priceTemplate.suggestedUnitPrice),
        "kr",
        matchingItemCms ? `per ${matchingItemCms?.unit}` : "",
      ]
        .join(" ")
        .trim();
      return {
        title: priceTemplate.label,
        description: [formattedFrequency, formattedItemCost]
          .filter((record) => !!record)
          .join(" - "),
        frequencyLabel: frequencyLabel,
        frequencyGroup: frequencyGroup,
        priceTemplate: priceTemplate,
      };
    })
    .sort((a, b) => {
      const idLengthA = (a.title || "").toString().length;
      const idLengthB = (b.title || "").toString().length;
      return idLengthA - idLengthB;
    });
};
const handleSelectedPriceTemplate = (event) => {
  updateActivityFormDescription(event.value.title);
  state.selectedPriceTemplate = event.value.priceTemplate;
  setValues(
    {
      frequencyGroup: event.value.frequencyGroup || values.frequencyGroup,
      frequency: event.value.frequencyGroup
        ? event.value.frequencyLabel
        : values.frequency,
      itemsUnit: event.value.priceTemplate.unit,
      pricePerItem: event.value.priceTemplate.suggestedUnitPrice,
    },
    false,
  );
};
const getFrequencyInformationFromPriceTemplate = (priceTemplate) => {
  let formattedFrequency;
  let frequencyLabel = "";
  let frequencyGroup;
  if (typeof priceTemplate.frequencySegmentId === "number") {
    const matchingFrequencyOption = availableFrequencyOptions.value.find(
      (record) => {
        return record.id === priceTemplate.frequencySegmentId;
      },
    );
    const matchingFrequencyCms = matchingFrequencyOption
      ? availableFrequenciesCms.value.find(
          (record) => record.label === matchingFrequencyOption.label,
        )
      : undefined;
    formattedFrequency =
      matchingFrequencyCms?.title || matchingFrequencyOption?.label;
    frequencyLabel = matchingFrequencyCms?.label || "";
    const frequencyGroupCms = frequencyLabel
      ? props.serviceActivitiesCms
          .filter((record) =>
            [
              ServiceActivityCmsLabelType.Monthly,
              ServiceActivityCmsLabelType.Yearly,
            ].includes(record.label),
          )
          .find((record) =>
            record.frequencies.find(
              (record) => record.label === frequencyLabel,
            ),
          )
      : undefined;
    frequencyGroup = firstLetterUppercase(frequencyGroupCms?.label || "");
  } else {
    frequencyLabel = "";
    frequencyGroup = ActivityFrequency.Fixed;
    formattedFrequency = getCmsItemText(
      props.serviceActivitiesCms,
      ServiceActivityCmsLabelType.Fixed,
    );
  }
  const limitedPeriod = formatLimitedPeriod(
    priceTemplate.activePeriodStart,
    priceTemplate.activePeriodEnd,
  );
  return {
    activePeriodStart: priceTemplate.activePeriodStart,
    activePeriodEnd: priceTemplate.activePeriodEnd,
    frequencyGroup: frequencyGroup,
    frequencyLabel: frequencyLabel,
    formattedFrequency: formattedFrequency
      ? [formattedFrequency, limitedPeriod].join(" ").trim()
      : "",
  };
};
const saveActivity = async (addNewAfterSaving) => {
  if (state.isSubmitting) {
    return;
  }
  await handleSubmit(async (values) => {
    state.isSubmitting = true;
    state.addNewAfterSaving = addNewAfterSaving;
    const formData = cloneDeep(values);
    formData.itemsCount = parseFloat(`${formData.itemsCount || 0}`);
    formData.pricePerItem = parseFloat(`${formData.pricePerItem}`);
    try {
      let newSupplierCost;
      if (
        !props.supplierCost ||
        isFrequencyChanged(
          getFormDataFromSupplierCost(props.supplierCost),
          formData,
        )
      ) {
        newSupplierCost = await createSupplierCost(
          formData,
          state.selectedPriceTemplate,
        );
        if (props.supplierCost) {
          await deleteSupplierCost(props.supplierCost);
        }
      } else {
        newSupplierCost = await updateSupplierCost(
          formData,
          props.supplierCost,
        );
      }
      if (newSupplierCost) {
        emit("new-activity-added", newSupplierCost);
      }
      if (addNewAfterSaving) {
        emit("add-new-activity");
      } else {
        closeModal();
      }
      resetForm();
    } catch (error) {
      console.log("Error creating description", error);
    } finally {
      state.isSubmitting = false;
      state.addNewAfterSaving = false;
    }
  })();
};
const isFrequencyChanged = (formDataA, formDataB) => {
  return (
    formDataA.frequencyGroup !== formDataB.frequencyGroup ||
    formDataA.limitedPeriodStartMonth !== formDataB.limitedPeriodStartMonth ||
    formDataA.limitedPeriodEndMonth !== formDataB.limitedPeriodEndMonth
  );
};

const deleteSupplierCost = async (supplierCost) => {
  try {
    await tenderApis.deleteSupplierCostItem(
      props.supplierInfo.id,
      props.supplierRequestId,
      props.serviceOfferId,
      supplierCost.serviceOfferSegmentGroupPriceId,
    );
    emit("cost-item-deleted", supplierCost);
  } catch (error) {
    console.log("Error deleting supplier cost", error);
  }
};
const createSupplierCost = async (formData, priceTemplate = undefined) => {
  let resolvedPriceTemplate;
  if (priceTemplate) {
    const { frequencyLabel, frequencyGroup } =
      getFrequencyInformationFromPriceTemplate(priceTemplate);
    if (
      formData.frequencyGroup?.toLowerCase() ===
        frequencyGroup?.toLowerCase() &&
      formData.frequency?.toLowerCase() === frequencyLabel?.toLowerCase() &&
      formData.limitedPeriodStartMonth?.toLowerCase() ===
        priceTemplate.activePeriodStart?.toLowerCase() &&
      formData.limitedPeriodEndMonth?.toLowerCase() ===
        priceTemplate.activePeriodEnd?.toLowerCase()
    ) {
      resolvedPriceTemplate = priceTemplate;
    }
  }
  if (!resolvedPriceTemplate) {
    resolvedPriceTemplate = await createPriceTemplate(formData);
  }
  const baseSupplierCostDTO = getBaseSupplierCostDTO(formData);
  const createSupplierCostDTO = {
    segmentGroupId: resolvedPriceTemplate.segmentGroupId,
    segmentId: resolvedPriceTemplate.valueSegmentId,
    frequencySegmentId: resolvedPriceTemplate.frequencySegmentId,
    externalGrouping: uuidv4(),
    ...baseSupplierCostDTO,
  };
  const response = await tenderApis.createMandatorySupplierCost(
    props.supplierInfo.id,
    props.supplierRequestId,
    props.serviceOfferId,
    createSupplierCostDTO,
  );
  return response.data;
};
const updateSupplierCost = async (formData, supplierCost) => {
  const baseSupplierCostDTO = getBaseSupplierCostDTO(formData);
  const updateSupplierCostDTO = {
    segmentGroupId: supplierCost.segmentGroupId,
    segmentId: supplierCost.segmentId,
    frequencySegmentId: supplierCost.frequencySegmentId,
    externalGrouping: supplierCost.externalGrouping,
    serviceOfferSegmentGroupPriceId:
      supplierCost.serviceOfferSegmentGroupPriceId,
    serviceOfferSegmentValueId: supplierCost.serviceOfferSegmentValueId,
    serviceOfferSegmentFrequencyValueId:
      supplierCost.serviceOfferSegmentFrequencyValueId,
    ...baseSupplierCostDTO,
  };
  const response = await tenderApis.updateMandatorySupplierCost(
    props.supplierInfo.id,
    props.supplierRequestId,
    props.serviceOfferId,
    updateSupplierCostDTO,
  );
  return response.data;
};
const createPriceTemplate = async (formData) => {
  const matchingPriceGroup = props.systemPriceGroups.find((record) => {
    const priceType =
      props.costType === ActivityCostType.Fixed ? "Aggregated" : "Independent";
    const billingCycle =
      props.costType === ActivityCostType.Fixed
        ? formData.frequencyGroup
        : "Fixed";
    return (
      record.priceType === priceType &&
      record.billingCycle.toLowerCase() === billingCycle.toLowerCase()
    );
  });
  if (!matchingPriceGroup) {
    throw new Error("Error finding price group");
  }
  const matchingFrequencyOption = props.frequencyOptions[
    formData.frequencyGroup.toLowerCase()
  ]?.find((record) => record.label === formData.frequency);
  const createPriceTemplateDTO = {
    priceGroupId: matchingPriceGroup.id,
    billingType:
      props.costType === ActivityCostType.Fixed ? "Fixed" : "Variable",
    label: formData.description,
    unit: formData.itemsUnit,
    frequencySegmentId: matchingFrequencyOption?.id || undefined,
  };
  const createTemplateResponse = await systemApis.createPriceTemplate(
    props.supplierInfo.id,
    createPriceTemplateDTO,
  );
  return createTemplateResponse.data;
};
const getBaseSupplierCostDTO = (formData) => {
  const matchingFrequencyOption = props.frequencyOptions[
    formData.frequencyGroup.toLowerCase()
  ]?.find((record) => record.label === formData.frequency);
  const inputPrice =
    props.costType === ActivityCostType.Fixed
      ? formData.itemsCount * formData.pricePerItem
      : formData.pricePerItem;
  const itemTotalCost = ((100 - supplierCommission.value) / 100) * inputPrice;
  let formattedFrequency = "";
  if (formData.frequencyGroup && formData.frequency) {
    const frequencyGroupLabel =
      formData.frequencyGroup === SupplierCostFrequencyGroup.Yearly
        ? SupplierCostFrequencyGroupLabel.Yearly
        : SupplierCostFrequencyGroupLabel.Monthly;
    formattedFrequency = `${frequencyGroupLabel}-${formData.frequency}`;
  }
  return {
    frequencyLabel: formattedFrequency,
    frequencyInputValue: parseFloat(matchingFrequencyOption?.value || "0"),
    label: formData.description,
    itemTotal: formData.itemsCount,
    itemUnit: formData.itemsUnit,
    itemPriceUnit: "",
    itemCost: formData.pricePerItem,
    inputPrice: inputPrice,
    itemTotalCost: itemTotalCost,
    limitedPeriodStart:
      formData.frequencyGroup === SupplierCostFrequencyGroup.Monthly
        ? formData.limitedPeriodStartMonth || null
        : null,
    limitedPeriodEnd:
      formData.frequencyGroup === SupplierCostFrequencyGroup.Monthly
        ? formData.limitedPeriodEndMonth || null
        : null,
  };
};
function getFormDataFromSupplierCost(supplierCost = undefined) {
  const limitedPeriodStart = supplierCost?.limitedPeriodStart || "";
  const limitedPeriodEnd = supplierCost?.limitedPeriodEnd || "";
  return {
    description: supplierCost?.label || "",
    frequencyGroup: firstLetterUppercase(
      extractFrequencyGroupFromFrequencyLabel(
        supplierCost?.frequencyLabel || "",
      ),
    ),
    frequency: extractFrequencyFromFrequencyLabel(
      supplierCost?.frequencyLabel || "",
    ),
    limitedPeriod: !!(limitedPeriodStart && limitedPeriodEnd),
    limitedPeriodStartMonth: limitedPeriodStart.toLowerCase(),
    limitedPeriodEndMonth: limitedPeriodEnd.toLowerCase(),
    itemsCount: supplierCost?.itemTotal || 0,
    itemsUnit:
      props.costType === ActivityCostType.Fixed
        ? supplierCost?.itemUnit || ""
        : supplierCost?.itemPriceUnit || "",
    pricePerItem:
      props.costType === ActivityCostType.Fixed
        ? getInputItemCost(supplierCost) || 0
        : supplierCost?.inputPrice || 0,
  };
}
const getButtonText = (label) => {
  return getCmsItemText(props.activityFormCms.buttons, label);
};
const closeModal = () => {
  showModal.value = false;
};
const updateFormValues = (formValues) => {
  setValues(formValues, false);
};
const getPriceTemplates = async (searchText) => {
  const priceType =
    props.costType === ActivityCostType.Variable ? "Independent" : "Aggregated";
  const response = await systemApis.getPriceTemplates(
    props.supplierInfo.id,
    priceType,
    {
      filter: searchText,
      take: 5,
    },
  );
  return response.data;
};
</script>

<style lang="scss" scoped>
.activity-form {
  padding: 1.5rem 1.5rem 2rem 1.5rem;
  font-family: "Montserrat", serif;

  &__title :deep(*) {
    font-weight: 600;
    font-size: $font-size-2xl;
  }

  &__field {
    margin-top: 1.5rem;
  }

  &__field-title {
    font-weight: 600;
    font-size: $font-size-base;
  }

  &__field-description {
    font-weight: 400;
    font-size: $font-size-sm;
  }

  &__field-input-error,
  &__field-input {
    margin-top: 0.5rem;
  }

  &__action-buttons {
    margin-top: 1.5rem;
    display: flex;
    justify-content: space-between;

    > button {
      padding: 1rem 2.5rem;
    }
  }

  &__frequency-options-wrapper {
    margin-top: 1.5rem;
  }

  &__price-calculator {
    margin-top: 1.5rem;
  }
}

:deep {
  .activity-form {
    &__description-input {
      width: 100%;
      border: 1px solid $color-black;
      font-weight: 400;
      font-size: $font-size-sm;
      color: $color-black;
      border-radius: 4px;

      &:enabled:focus,
      &:hover {
        border: 1px solid #1d1d1d;
      }
    }

    &__description-autocomplete-panel-class {
      right: 100%;
    }

    &__autocomplete-suggestion-item {
      display: block;
      overflow: hidden;
    }

    &__autocomplete-suggestion-title {
      font-weight: 600;
      font-size: 0.875rem;
      color: $color-black;
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &__autocomplete-suggestion-description {
      font-weight: 400;
      font-size: 0.75rem;
      color: #5b5b5b;
    }
  }

  .modal-lg {
    max-width: 680px !important;
  }
}
</style>
