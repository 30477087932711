export enum MessageTypes {
  PriceRequest = "PriceRequest",
  GeneralMessage = "GeneralMessage",
  Compliment = "Compliment",
  Complaint = "Complaint",
}

export type Conversation = {
  customerMessageCount: number;
  supplierMessageCount: number;
  latestMessage: Message;
  message: Message;
  messageCount: number;
  read: boolean;
  thread: Message[] | null;
};

export type Attachment = {
  fileName: string;
  attachmentUri: string;
  mimeType?: string;
  attachmentId?: string;
};

export type SendMessageDto = {
  conversationId: string | null;
  senderUserId: string;
  senderName: string;
  senderEmail: string;
  supplierId: number;
  customerOrgNumber?: string;
  messageType: string;
  messageDomain: string;
  messageBody: string | null;
  price: number | null;
  rootMessage: boolean;
  supplierRequestId: number;
  tenderId: number;
  attachments: SendAttachmentDto[] | null;
  serviceOfferId?: number;
};

export type SendAttachmentDto = {
  fileName: string;
  fileContent: string;
};

export type Message = {
  id: number;
  createdOn: string;
  conversationId: string | null;
  senderUserId: string;
  senderName: string;
  senderEmail: string;
  messageType: string;
  messageDomain: string;
  messageBody: string;
  messageRead: boolean;
  price: number | null;
  rootMessage: boolean;
  supplierRequestId: number;
  tenderId: number;
  serviceOfferId: null | number;
  attachments: Attachment[];
};

export type MessageThread = {
  message: Message;
  customerMessageCount: number;
  supplierMessageCount: number;
  messageCount: number;
  read: boolean;
  thread: null | Message[];
  latestMessage: Message;
};

export type FormattedThread = {
  conversationId: string;
  recipientName: string;
  latestDate: string;
  latestMessage: string;
  messageType: string;
  latestMessageAttachments: Attachment[];
};

export interface ApiError {
  message: string;
  name: string;
  code: string;
  status?: number;
  config?: {
    method?: string;
    url?: string;
  };
}

export interface SendMessagePayload {
  message: string | null;
  attachments: File[];
  successCallback: () => void;
  errorCallback: (error?: ApiError) => void;
}

export type MessageThreadArray = MessageThread[];

export interface UnreadMessageStatistics {
  // For MyRequestsView
  "InformationRequested-project": number;
  "Offered-project": number;
  "PriceRequested-project": number;
  "Rejected-project": number;
  "Offered-service": number;
  "Rejected-service": number;
  // For MyAgreementsView
  "Accepted-project": number;
  "Completed-project": number;
  "Accepted-service": number;
  "Completed-service": number;
}

export interface ActivityStats {
  requestState: string;
  priceRequestIds: number[];
  isServiceAgreement: boolean;
}
