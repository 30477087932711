<template>
  <base-modal
    v-model="showModal"
    centered
    size="lg"
    hide-header
    modal-class="profile-modal"
    @hide="closeModal"
  >
    <template #default>
      <div class="profile-modal__body-content">
        <div class="profile-modal__title-content">
          <div class="profile-modal__title">{{ title }}</div>
          <div class="profile-modal__subtitle">
            {{ subtitle }}
          </div>
        </div>
        <div v-if="localCertifications" class="profile-modal__info-tag-group">
          <ProfileCheckboxInfoTag
            v-for="(certification, index) in localCertifications"
            :key="index"
            v-model="certification.selected"
            :label="`${certification.title}`"
            :icon-src="iconSrc"
            @@update:model-value="updateCertificationSelected(index, $event)"
          />
        </div>
      </div>
    </template>
    <template #footer>
      <div class="profile-modal__footer-buttons">
        <ProfileButton text="Accept" :loading="isSaving" @click="save"
          >{{ saveButtonText }}
        </ProfileButton>
        <ProfileButton text="Cancel" :is-cancel="true" @click="cancel"
          >{{ cancelButtonText }}
        </ProfileButton>
        <slot name="footer" />
      </div>
    </template>
  </base-modal>
</template>

<script lang="ts" setup>
import { computed, PropType, ref, watch } from "vue";
import ProfileCheckboxInfoTag from "./ProfileCheckboxInfoTag.vue";
import BaseModal from "@/components/Base/BaseModal/BaseModal.vue";
import ProfileButton from "./ProfileButton.vue";

interface MappedCertification {
  id: string;
  title: string;
  certificationLabel: string;
  selected: boolean;
}

const props = defineProps({
  modelValue: {
    type: Boolean as PropType<boolean>,
    required: true,
  },
  title: {
    type: String as PropType<string>,
    required: true,
  },
  subtitle: {
    type: String as PropType<string>,
    required: true,
  },
  iconSrc: {
    type: String as PropType<string>,
    required: true,
  },
  saveButtonText: {
    type: String as PropType<string>,
    required: true,
  },
  cancelButtonText: {
    type: String as PropType<string>,
    required: true,
  },
  certifications: {
    type: Array as PropType<MappedCertification[]>,
    required: true,
  },
  isSaving: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
});

const emit = defineEmits(["update:modelValue", "save"]);

const showModal = computed({
  get: () => props.modelValue,
  set: (newVal) => emit("update:modelValue", newVal),
});

const localCertifications = ref<MappedCertification[]>([]);

const resetLocalCertifications = () => {
  localCertifications.value = props.certifications
    .map((cert) => {
      return { ...cert, selected: cert.selected };
    })
    .sort((a, b) => a.title.localeCompare(b.title));
};

watch(
  () => props.certifications,
  () => {
    resetLocalCertifications();
  },
  { immediate: true, deep: true },
);

watch(showModal, (newVal) => {
  if (!newVal) {
    resetLocalCertifications();
  }
});

const updateCertificationSelected = (index: number, selected: boolean) => {
  localCertifications.value[index].selected = selected;
};

const closeModal = () => {
  showModal.value = false;
};

const save = () => {
  const certificationsToSave = localCertifications.value.filter(
    (cert) => cert.selected,
  );
  emit("save", certificationsToSave);
};

const cancel = () => {
  closeModal();
};
</script>

<style lang="scss">
.profile-modal {
  .modal-dialog {
    display: flex;
    height: calc(100% - 10rem);
    min-width: 1100px;
    border-radius: 8px;
    background: var(--White, #fff);
  }

  .modal-content {
    border-radius: 8px;
    border: none;
    padding: 64px 64px 0px 64px;
  }

  .modal-body {
    background-color: #fff;
    padding: 0;
  }

  .modal-footer {
    border: none;
    padding: 0;
    margin-top: 4rem;
  }
}
</style>

<style lang="scss" scoped>
.profile-modal {
  &__body-content {
    display: flex;
    flex-direction: column;
    gap: 4rem;
  }

  &__close-button-wrapper {
    display: flex;
    justify-content: flex-end;
    padding: 0 2rem;
  }

  &__main-content {
    max-width: 912px;
    margin: 0 auto;
  }

  &__title-content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: flex-start;
    align-self: start;
    align-items: flex-start;
  }

  &__title {
    color: var(--Dark-Purple, #441b49);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  &__subtitle {
    color: var(--Dark-Purple, #441b49);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &__close-button {
    border: none;
    background: transparent;
    text-transform: uppercase;
    display: flex;
    align-items: center;
  }

  &__section {
    margin-top: 2rem;
  }

  &__section-title {
    font-size: 0.75rem;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1;
    padding-bottom: 0.625rem;
    border-bottom: 1px solid #e1e1e1;
    margin-bottom: 1rem;
  }

  &__info-tag-group {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    flex-wrap: wrap;
    gap: 1rem;
  }

  &__footer-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 1rem;
    padding: 1rem;
    border-top: 1px solid var(--Black, #dee2e6);
  }
}
</style>
