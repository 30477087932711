<template>
  <div class="conversation-list">
    <ConversationItem
      v-for="thread in formattedThreads"
      :key="thread.conversationId"
      :thread="thread"
      @click="handleThreadClick"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import dayjs from "dayjs";
import ConversationItem from "./ConversationItem.vue";
import { MessageTypes } from "@/stores/communication/types";
import type {
  MessageThread,
  FormattedThread,
} from "@/stores/communication/types";

interface Props {
  conversations: MessageThread[];
  recipientName: string;
}

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: "conversation-clicked", conversationId: string): void;
}>();

const getTranslatedMessageType = (messageType: MessageTypes): string => {
  switch (messageType) {
    case MessageTypes.GeneralMessage:
      return "Generell";
    case MessageTypes.Complaint:
      return "Klage";
    case MessageTypes.Compliment:
      return "Skryt";
    case MessageTypes.PriceRequest:
      return "Prisforespørsel";
    default:
      return "Generell";
  }
};

const formattedThreads = computed<FormattedThread[]>(() => {
  return props.conversations
    .slice()
    .sort((a, b) => {
      const dateA = new Date(a.latestMessage.createdOn);
      const dateB = new Date(b.latestMessage.createdOn);
      return dateB.getTime() - dateA.getTime();
    })
    .map(
      (thread): FormattedThread => ({
        conversationId: thread.latestMessage.conversationId,
        recipientName: props.recipientName,
        latestDate: dayjs(thread.latestMessage.createdOn).format("DD.MM.YYYY"),
        latestMessage: thread.latestMessage.messageBody,
        messageType: getTranslatedMessageType(
          thread.latestMessage.messageType as MessageTypes,
        ),
        latestMessageAttachments: thread.latestMessage.attachments,
      }),
    );
});

const handleThreadClick = (conversationId: string) => {
  emit("conversation-clicked", conversationId);
};
</script>

<style lang="scss" scoped>
.conversation-list {
  display: flex;
  flex-direction: column;
}
</style>
