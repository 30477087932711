<template>
  <b-modal
    v-model="showModal"
    :no-close-on-backdrop="true"
    centered
    hide-footer
    hide-header
    body-class="p-0"
  >
    <div class="app-modal">
      <div class="app-modal__header">
        <div class="app-modal__title">{{ title }}</div>
      </div>
      <div class="app-modal__body">
        <slot />
      </div>
      <div class="app-modal__footer">
        <div v-if="submissionError" class="app-modal__submission-error">
          <input-error :text="submissionError" />
        </div>
        <div
          v-if="secondaryButtonText || primaryButtonText"
          class="app-modal__action-buttons"
        >
          <tertiary-button
            v-if="secondaryButtonText"
            @click="handleSecondaryButtonClick"
            >{{ secondaryButtonText }}</tertiary-button
          >
          <primary-button
            v-if="primaryButtonText"
            :is-submitting="isSubmitting"
            @click="handlePrimaryButtonClick"
            >{{ primaryButtonText }}</primary-button
          >
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script setup>
import TertiaryButton from "@/components/Buttons/TertiaryButton.vue";
import PrimaryButton from "@/components/Buttons/PrimaryButton.vue";
import InputError from "@/components/InputError/InputError.vue";

const emit = defineEmits(["secondary-button-click", "primary-button-click"]);
const showModal = defineModel({ required: true });
defineProps({
  title: {
    type: String,
    required: true,
  },
  secondaryButtonText: {
    type: String,
    default: "",
  },
  primaryButtonText: {
    type: String,
    default: "",
  },
  isSubmitting: {
    type: Boolean,
    default: false,
  },
  submissionError: {
    type: String,
    default: "",
  },
});

const handleSecondaryButtonClick = () => {
  emit("secondary-button-click");
};

const handlePrimaryButtonClick = () => {
  emit("primary-button-click");
};
</script>

<style lang="scss" scoped>
.app-modal {
  font-family: "Montserrat", sans-serif;
  padding: 1.5rem 2rem;
  border-radius: 4px;
  text-align: left;

  &__field-input,
  &__field-input-error {
    margin-top: 0.5rem;
  }

  &__title {
    font-weight: 600;
    font-size: $font-size-2xl;
  }

  &__body {
    margin-top: 1rem;
  }

  &__footer {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__action-buttons {
    display: flex;
    gap: 1rem;
  }
}

:deep(.modal-sm) {
  max-width: 498px !important;
}

:deep(.app-modal) {
  border: none;
  border-radius: 4px;
}
</style>
