<template>
  <div class="message-composer">
    <div class="message-composer__error-container">
      <div v-if="error" class="message-composer__error" role="alert">
        <img :src="AlertIcon" alt="" aria-hidden="true" />
        <span>{{ getErrorMessage(error) }}</span>
      </div>
    </div>

    <div v-if="attachments.length" class="message-composer__attachments">
      <div
        v-for="attachment in attachments"
        :key="attachment.id"
        class="message-composer__attachment"
        role="listitem"
      >
        <img
          :src="DocumentIcon"
          alt="Document icon"
          class="message-composer__attachment-icon"
        />
        <span class="message-composer__attachment-title">
          {{ attachment.file.name }}
        </span>
        <button
          class="message-composer__attachment-remove"
          :aria-label="`Remove attachment ${attachment.file.name}`"
          @click="removeAttachment(attachment.id)"
        >
          <img :src="CloseIcon" alt="" aria-hidden="true" />
        </button>
      </div>
    </div>

    <div class="message-composer__content">
      <div
        class="message-composer__input-wrapper"
        :class="{ 'message-composer__input-wrapper--error': error }"
      >
        <textarea
          ref="textareaRef"
          v-model="message"
          class="message-composer__input"
          :placeholder="placeholder"
          rows="1"
          @input="handleInput"
        />

        <file-input
          v-slot="{ openFileInput }"
          :valid-file-extensions="VALID_FILE_EXTENSIONS"
          @selected-files="handleSelectedFiles"
        >
          <button
            class="message-composer__attach"
            :disabled="isMaxAttachmentsReached"
            :aria-label="
              isMaxAttachmentsReached
                ? 'Maximum attachments reached'
                : 'Add attachment'
            "
            @click="openFileInput"
          >
            <img :src="AttachmentIcon" alt="" aria-hidden="true" />
          </button>
        </file-input>
      </div>

      <button
        class="message-composer__send"
        :disabled="!canSend"
        :aria-label="sendButtonAriaLabel"
        @click="handleSend"
      >
        <b-spinner v-if="isSending" type="grow" small />
        <template v-else>
          <img :src="SendIcon" alt="" aria-hidden="true" />
          <span>Send</span>
        </template>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, nextTick } from "vue";
import { useTextareaAutosize } from "@vueuse/core";
import {
  getFilesWithValidExtension,
  getFilesWithValidSize,
} from "@/utilities/fileUtils";
import { uuidv4 } from "@/utilities/uuid";
import FileInput from "@/components/FileInput/FileInput.vue";
import AlertIcon from "@/assets/img/alert.svg";
import DocumentIcon from "@/assets/img/document.svg";
import CloseIcon from "@/assets/img/close.svg";
import AttachmentIcon from "@/assets/img/attachment.svg";
import SendIcon from "@/assets/img/send-black.svg";

const VALID_FILE_EXTENSIONS = [".pdf", ".jpg", ".jpeg", ".png"] as string[];
const MAX_FILE_SIZE_MB = 20;
const MAX_FILES = 20;
const ERROR_TIMEOUT = 12000;

interface FileAttachment {
  id: string;
  file: File;
}

interface Props {
  conversation?: {
    latestMessage: {
      conversationId: string;
    };
  } | null;
  placeholder?: string;
}

const props = withDefaults(defineProps<Props>(), {
  conversation: null,
  placeholder: "Send melding til kunde...",
});

interface ApiError {
  message: string;
  name: string;
  code: string;
  status?: number;
  config?: {
    method?: string;
    url?: string;
  };
}

const emit = defineEmits<{
  (
    e: "send-message",
    payload: {
      message: string | null;
      attachments: File[];
      conversationId: string | null;
      successCallback: () => void;
      errorCallback: (error?: ApiError) => void;
    },
  ): void;
}>();

// Refs
const { textarea: textareaRef } = useTextareaAutosize();
const message = ref("");
const error = ref("");
const isSending = ref(false);
const attachments = ref<FileAttachment[]>([]);

// Computed
const isMaxAttachmentsReached = computed(
  () => attachments.value.length >= MAX_FILES,
);

const canSend = computed(
  () =>
    (message.value.trim().length > 0 || attachments.value.length > 0) &&
    !isSending.value,
);

const sendButtonAriaLabel = computed(() =>
  isSending.value ? "Sending message..." : "Send message",
);

// Methods
const handleInput = () => {
  error.value = "";
  if (textareaRef.value) {
    textareaRef.value.style.height = "auto";
    textareaRef.value.style.height = textareaRef.value.scrollHeight + "px";
  }
};

const removeAttachment = (id: string) => {
  attachments.value = attachments.value.filter((att) => att.id !== id);
};

const errorMessages = {
  required: "Dette feltet er påkrevd",
  maxFiles: "Du kan ikke laste opp flere enn 20 filer",
  fileSize: `Filen er for stor. Maksimal størrelse er ${MAX_FILE_SIZE_MB}MB`,
  fileType: "Ugyldig filtype. Tillatte formater er PDF, JPG og PNG",
  generic: "Noe gikk galt. Vennligst prøv igjen",
  // Network errors
  400: "Ugyldig forespørsel. Vennligst sjekk informasjonen og prøv igjen",
  401: "Ikke autorisert. Vennligst logg inn på nytt",
  403: "Ingen tilgang. Du har ikke tillatelse til å utføre denne handlingen",
  404: "Ressursen ble ikke funnet. Vennligst oppdater siden og prøv igjen",
  408: "Forespørselen tok for lang tid. Sjekk internettforbindelsen din",
  500: "Serverfeil. Vennligst prøv igjen senere",
  502: "Tjenesten er utilgjengelig. Vennligst prøv igjen senere",
  503: "Tjenesten er midlertidig utilgjengelig. Vennligst prøv igjen senere",
  504: "Tidsavbrudd fra serveren. Vennligst prøv igjen",
  networkError:
    "Kunne ikke koble til serveren. Sjekk internettforbindelsen din",
} as const;

type ErrorType = keyof typeof errorMessages;

const getErrorMessage = (errorType: ErrorType | string) => {
  return errorMessages[errorType as ErrorType] || errorType;
};

const handleSelectedFiles = (files: FileList) => {
  if (isMaxAttachmentsReached.value) {
    error.value = "maxFiles";
    return;
  }

  const selectedFiles = Array.from(files);
  let validFiles = getFilesWithValidExtension(
    selectedFiles,
    VALID_FILE_EXTENSIONS,
  );
  validFiles = getFilesWithValidSize(validFiles, MAX_FILE_SIZE_MB);

  const remainingSlots = MAX_FILES - attachments.value.length;
  validFiles = validFiles.slice(0, remainingSlots);

  const newAttachments = validFiles.map((file) => ({
    id: uuidv4(),
    file,
  }));

  attachments.value.push(...newAttachments);
};

const resetForm = async () => {
  message.value = "";
  attachments.value = [];
  if (textareaRef.value) {
    await nextTick();
    textareaRef.value.style.height = "auto";
    textareaRef.value.style.height = textareaRef.value.scrollHeight + "px";
  }
};

const ERROR_CODES = {
  ERR_NETWORK: "networkError",
  ERR_TIMEOUT: "408",
  ERR_BAD_REQUEST: "400",
  ERR_UNAUTHORIZED: "401",
  ERR_FORBIDDEN: "403",
  ERR_NOT_FOUND: "404",
  ERR_SERVER_ERROR: "500",
  ERR_BAD_GATEWAY: "502",
  ERR_SERVICE_UNAVAILABLE: "503",
  ERR_GATEWAY_TIMEOUT: "504",
} as const;

type ErrorCode = keyof typeof ERROR_CODES;

const handleApiError = (err?: ApiError): string => {
  if (!err) return "generic";

  // If we have a specific HTTP status, use it directly
  if (err.status && err.status in errorMessages) {
    return String(err.status);
  }

  // Map error codes to error messages
  return ERROR_CODES[err.code as ErrorCode] || "generic";
};

const handleSend = async () => {
  if (!canSend.value) return;

  const trimmedMessage = message.value.trim();

  // If no message text but has attachments, that's valid
  if (!trimmedMessage && attachments.value.length === 0) {
    error.value = "required";
    return;
  }

  isSending.value = true;

  emit("send-message", {
    message: trimmedMessage || null,
    attachments: attachments.value.map((att) => att.file),
    conversationId: props.conversation?.latestMessage.conversationId ?? null,
    successCallback: async () => {
      await resetForm();
      isSending.value = false;
    },
    errorCallback: (err?: ApiError) => {
      isSending.value = false;
      error.value = handleApiError(err);

      setTimeout(() => {
        error.value = "";
      }, ERROR_TIMEOUT);
    },
  });
};

// Lifecycle
onMounted(() => {
  if (textareaRef.value) {
    textareaRef.value.style.height = "auto";
    textareaRef.value.style.height = textareaRef.value.scrollHeight + "px";
  }
});
</script>

<style lang="scss" scoped>
.message-composer {
  margin: 1rem;

  &__error-container {
    text-align: end;
    padding: 0.75rem 1rem;
  }

  &__content {
    display: flex;
    align-items: flex-end;
    gap: 0.5rem;
  }

  &__input-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    background: $color-grey-lighter;
    border-radius: 4px;
    padding: 0.25rem 0.25rem 0.25rem 1rem;
    transition: border-color 0.2s ease;
    width: 100%;

    &--error {
      border: 1px solid $color-error;
    }
  }

  &__input {
    flex: 1;
    background-color: transparent;
    border: none;
    outline: none;
    font-weight: $font-weight-regular;
    font-size: $font-size-sm;
    resize: none;
    max-height: 10.625rem;

    &::placeholder {
      color: $color-grey-dark;
    }
  }

  &__attach {
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;
    transition: background-color 0.2s ease;

    &:hover:not(:disabled) {
      background-color: $color-grey-light;
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  &__send {
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.625rem;
    background-color: $color-yellow-dark;
    color: $color-black;
    font-weight: 500;
    font-size: $font-size-base;
    width: 6.75rem;
    border: none;
    border-radius: 4px;
    transition: opacity 0.2s ease;

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }

    &:not(:disabled):hover {
      opacity: 0.9;
    }
  }

  &__error {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1rem;
    background-color: rgba($color-error, 0.1);
    border-radius: 4px;
    color: $color-error;
    font-size: $font-size-sm;
    font-weight: 500;

    img {
      width: 1rem;
      height: 1rem;
    }
  }

  &__attachments {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
  }

  &__attachment {
    border: 1px solid $color-grey-lighter;
    border-radius: 4px;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    width: 14.375rem;
    gap: 0.5rem;
  }

  &__attachment-title {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: $font-size-sm;
  }

  &__attachment-remove {
    flex-shrink: 0;
    border: none;
    border-radius: 4px;
    padding: 0;
    background: transparent;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.2s ease;

    &:hover {
      background: $color-grey-lighter;
    }
  }
}
</style>
