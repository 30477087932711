<template>
  <div ref="agreementResponsibleRef" class="agreement-responsible">
    <div
      class="agreement-responsible__header"
      :class="agreementResponsibleHeaderClasses"
      @click="toggleCollapse"
    >
      <div class="agreement-responsible__header-icon-wrapper">
        <img :src="UserIcon" alt="User" />
      </div>
      <div class="agreement-responsible__header-title">
        <template v-if="selectedUserName">{{ selectedUserName }}</template>
        <b-spinner v-else type="grow" small />
      </div>
      <img :src="dropdownIconUrl" alt="Dropdown arrow" />
    </div>
    <div
      v-if="dropdownCollapsed"
      v-click-outside="closeDropdown"
      class="agreement-responsible__dropdown-content"
    >
      <template v-if="supplierUsers">
        <div
          v-for="user in augmentedSupplierUsers"
          :key="user.id"
          class="agreement-responsible__user"
          @click="handleUserClick(user.id)"
        >
          <div class="agreement-responsible__user-icon-wrapper">
            <img
              v-if="checkIfUserIsSelected(user.id)"
              :src="CheckMarkIcon"
              alt="Check"
            />
          </div>
          <div class="agreement-responsible__user-info">
            <div>{{ user.name }}</div>
            <div class="agreement-responsible__user-contact-info">
              {{ user.description }}
            </div>
          </div>
        </div>
      </template>
      <div v-else class="agreement-responsible__loader">
        <b-spinner type="grow" small />
      </div>
    </div>
    <new-agreement-responsible-modal
      v-model="showAgreementResponsibleModal"
      :user="userToSetAsResponsible"
      :tender="tender"
      :price-request="priceRequest"
      :supplier-id="supplierId"
      :tender-id="tenderId ?? null"
      @success="handleModalSuccess"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue";
import { vClickOutside } from "v-click-outside";
import UserIcon from "@/assets/img/user.svg";
import CheckMarkIcon from "@/assets/img/check-mark.svg";
import NewAgreementResponsibleModal from "./NewAgreementResponsibleModal.vue";
import { useUsersApi } from "@/services/api/useUserApi";
import { Tender, PriceRequest } from "@/stores/tender/types";
import { useSupplierUsersStore } from "@/stores/supplierUsers";

interface User {
  userId: string;
  displayName: string;
  mobilePhone?: string;
  email?: string;
}

interface AugmentedUser {
  id: string;
  name: string;
  description: string;
}

interface Props {
  supplierId: number;
  tender: Tender;
  tenderId: number | null;
}

const props = defineProps<Props>();
const { fetchSupplierUsers } = useUsersApi();
const supplierUsersStore = useSupplierUsersStore();

// Refs
const agreementResponsibleRef = ref<HTMLElement | null>(null);
const dropdownCollapsed = ref(false);
const showAgreementResponsibleModal = ref(false);
const userIdToSetAsResponsible = ref<string | null>(null);
const localSelectedUserId = ref<string | null>(null);

// Computed
const supplierUsers = computed(() => supplierUsersStore.getSupplierUsers);

const supplierRequest = computed(() =>
  props.tender.priceRequests
    .flatMap((priceRequest) => priceRequest.supplierRequests)
    .find((supplierRequest) => supplierRequest.supplierId === props.supplierId),
);

const priceRequest = computed<PriceRequest>(
  () => props.tender.priceRequests[0] as PriceRequest,
);

const selectedUserId = computed(
  () =>
    localSelectedUserId.value ?? supplierRequest.value?.agreementOwnerUserId,
);

const dropdownIconUrl = computed(
  () => `/images/24/${dropdownCollapsed.value ? "arrow-up" : "arrow-down"}.svg`,
);

const agreementResponsibleHeaderClasses = computed(() =>
  dropdownCollapsed.value ? ["agreement-responsible__header--collapsed"] : [],
);

const augmentedSupplierUsers = computed<AugmentedUser[]>(() => {
  const users = [
    {
      id: "",
      name: "Alle brukere ansvarlig",
      description: "Alle brukere mottar varslinger",
    },
  ];

  supplierUsers.value?.forEach((user: User) => {
    users.push({
      id: user.userId,
      name: user.displayName,
      description: getUserDescription(user),
    });
  });

  return users;
});

const selectedUserName = computed(() => {
  const selectedUser = augmentedSupplierUsers.value.find((user) =>
    checkIfUserIsSelected(user.id),
  );
  if (selectedUser) return selectedUser.name;
  if (selectedUserId.value) return "Alle brukere ansvarlig";
  return "";
});

const userToSetAsResponsible = computed(() => {
  if (!userIdToSetAsResponsible.value) return null;
  return (
    supplierUsers.value?.find(
      (user: User) => user.userId === userIdToSetAsResponsible.value,
    ) ?? null
  );
});

// Methods
const checkIfUserIsSelected = (userId: string): boolean =>
  (!selectedUserId.value && !userId) || selectedUserId.value === userId;

const toggleCollapse = () => {
  dropdownCollapsed.value = !dropdownCollapsed.value;
};

const getUserDescription = (user: User): string => {
  const records: string[] = [];
  const mobilePhone = user.mobilePhone?.trim();
  const email = user.email?.trim();

  if (mobilePhone) records.push(mobilePhone);
  if (email) records.push(email);

  return records.join(" - ");
};

const getSupplierUsers = async () => {
  const usersResponse = await fetchSupplierUsers(props.supplierId);
  supplierUsersStore.setSupplierUsers(usersResponse.data);
};

const handleUserClick = (userId: string) => {
  if (!checkIfUserIsSelected(userId)) {
    userIdToSetAsResponsible.value = userId;
    showAgreementResponsibleModal.value = true;
  }
  dropdownCollapsed.value = false;
};

const closeDropdown = () => {
  dropdownCollapsed.value = false;
};

const handleModalSuccess = async () => {
  localSelectedUserId.value = userIdToSetAsResponsible.value;
};

// Lifecycle
onMounted(() => {
  if (!supplierUsers.value) {
    getSupplierUsers();
  }
});
</script>

<style lang="scss" scoped>
.agreement-responsible {
  position: relative;
  max-width: 28rem;
  font-weight: 400;

  &__header {
    background-color: #ffffff;
    padding: 0.75rem 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    border-radius: 6px;
    border: 1px solid #1d1d1d;

    &:hover {
      background-color: #f5f5f5;
    }

    &--collapsed {
      border-radius: 6px 6px 0 0;
    }
  }

  &__header-icon-wrapper {
    width: 2rem;
  }

  &__header-title {
    flex-grow: 1;
    font-size: $font-size-base;
  }

  &__dropdown-content {
    background-color: #ffffff;
    border-radius: 0 0 6px 6px;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  &__user {
    background-color: #ffffff;
    padding: 0.75rem 1rem;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: #f5f5f5;
    }

    &:last-child {
      border-radius: 0 0 6px 6px;
    }
  }

  &__user-icon-wrapper {
    width: 2rem;
  }

  &__user-contact-info {
    font-size: $font-size-sm;
  }

  &__loader {
    padding: 4rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
