import Cookies from "js-cookie";
import { ref } from "vue";

const COOKIE_EXPIRATION_DAYS = 3;
const STORAGE_PREFIX = "feedback_";
const NEW_REQUEST_PREFIX = "new_request_";
const UNREAD_MESSAGES_PREFIX = "unread_messages_";
const MESSAGE_BADGE_PREFIX = "message_badge_";
const LOST_REQUEST_PREFIX = "lost_request_";
const NEW_AGREEMENT_PREFIX = "new_agreement_";
const ARCHIVED_REQUEST_PREFIX = "archived_request_";
const ACTIVE_SERVICE_PREFIX = "active_service_";
const COMPLETED_SERVICE_PREFIX = "completed_service_";
const ACTIVE_PROJECT_PREFIX = "active_project_";
const COMPLETED_PROJECT_PREFIX = "completed_project_";

const viewedMessagesState = ref<Set<number>>(new Set());
const hiddenMessageBadgesState = ref<Set<number>>(new Set());

export function useFeedbackCookie() {
  const setFeedbackViewedCookie = (key: string, value: boolean) => {
    const cookieKey = `${STORAGE_PREFIX}${key}`;
    Cookies.set(cookieKey, value.toString(), {
      expires: COOKIE_EXPIRATION_DAYS,
    });
  };

  const getFeedbackViewedCookie = (key: string): boolean => {
    const cookieKey = `${STORAGE_PREFIX}${key}`;
    const value = Cookies.get(cookieKey);
    return value === "true";
  };

  const setNewRequestViewed = (priceRequestId: number | string) => {
    const key = `${NEW_REQUEST_PREFIX}_${priceRequestId}`;
    Cookies.set(key, "true", { expires: COOKIE_EXPIRATION_DAYS });
  };

  const hasNewRequestBeenViewed = (
    priceRequestId: number | string,
  ): boolean => {
    const key = `${NEW_REQUEST_PREFIX}_${priceRequestId}`;
    const value = Cookies.get(key);
    return value === "true";
  };

  const setMessagesViewed = (priceRequestId: number) => {
    const key = `${UNREAD_MESSAGES_PREFIX}_${priceRequestId}`;
    Cookies.set(key, "true", { expires: COOKIE_EXPIRATION_DAYS });
    viewedMessagesState.value.add(priceRequestId);
  };

  const hasMessagesBeenViewed = (priceRequestId: number): boolean => {
    const key = `${UNREAD_MESSAGES_PREFIX}_${priceRequestId}`;
    const value = Cookies.get(key);
    return viewedMessagesState.value.has(priceRequestId) || value === "true";
  };

  // New functions for message badge visibility
  const hideMessageBadge = (priceRequestId: number) => {
    const key = `${MESSAGE_BADGE_PREFIX}_${priceRequestId}`;
    Cookies.set(key, "true", { expires: COOKIE_EXPIRATION_DAYS });
    hiddenMessageBadgesState.value.add(priceRequestId);
  };

  const isMessageBadgeHidden = (priceRequestId: number): boolean => {
    const key = `${MESSAGE_BADGE_PREFIX}_${priceRequestId}`;
    const value = Cookies.get(key);
    return (
      hiddenMessageBadgesState.value.has(priceRequestId) || value === "true"
    );
  };

  const setLostRequestViewed = (priceRequestId: number) => {
    const key = `${LOST_REQUEST_PREFIX}_${priceRequestId}`;
    Cookies.set(key, "true", { expires: COOKIE_EXPIRATION_DAYS });
  };

  const hasLostRequestBeenViewed = (priceRequestId: number): boolean => {
    const key = `${LOST_REQUEST_PREFIX}_${priceRequestId}`;
    const value = Cookies.get(key);
    return value === "true";
  };

  const setNewAgreementViewed = (priceRequestId: number | string) => {
    const key = `${NEW_AGREEMENT_PREFIX}_${priceRequestId}`;
    Cookies.set(key, "true", { expires: COOKIE_EXPIRATION_DAYS });

    // Also mark as viewed in the specific agreement type cookies
    // This ensures compatibility with the TenderList component
    setActiveServiceViewed(priceRequestId);
    setActiveProjectViewed(priceRequestId);
  };

  const hasNewAgreementBeenViewed = (
    priceRequestId: number | string,
  ): boolean => {
    // Check the generic agreement cookie first
    const key = `${NEW_AGREEMENT_PREFIX}_${priceRequestId}`;
    const value = Cookies.get(key);
    if (value === "true") return true;

    // Then check specific agreement types
    return (
      hasActiveServiceBeenViewed(priceRequestId) ||
      hasActiveProjectBeenViewed(priceRequestId)
    );
  };

  const setArchivedRequestViewed = (priceRequestId: number | string) => {
    const key = `${ARCHIVED_REQUEST_PREFIX}_${priceRequestId}`;
    Cookies.set(key, "true", { expires: COOKIE_EXPIRATION_DAYS });
  };

  const hasArchivedRequestBeenViewed = (
    priceRequestId: number | string,
  ): boolean => {
    const key = `${ARCHIVED_REQUEST_PREFIX}_${priceRequestId}`;
    const value = Cookies.get(key);
    return value === "true";
  };

  // Add functions for active service agreements
  const setActiveServiceViewed = (priceRequestId: number | string) => {
    const key = `${ACTIVE_SERVICE_PREFIX}_${priceRequestId}`;
    Cookies.set(key, "true", { expires: COOKIE_EXPIRATION_DAYS });
  };

  const hasActiveServiceBeenViewed = (
    priceRequestId: number | string,
  ): boolean => {
    const key = `${ACTIVE_SERVICE_PREFIX}_${priceRequestId}`;
    const value = Cookies.get(key);
    return value === "true";
  };

  // Add functions for completed service agreements
  const setCompletedServiceViewed = (priceRequestId: number | string) => {
    const key = `${COMPLETED_SERVICE_PREFIX}_${priceRequestId}`;
    Cookies.set(key, "true", { expires: COOKIE_EXPIRATION_DAYS });
  };

  const hasCompletedServiceBeenViewed = (
    priceRequestId: number | string,
  ): boolean => {
    const key = `${COMPLETED_SERVICE_PREFIX}_${priceRequestId}`;
    const value = Cookies.get(key);
    return value === "true";
  };

  // Add functions for active projects
  const setActiveProjectViewed = (priceRequestId: number | string) => {
    const key = `${ACTIVE_PROJECT_PREFIX}_${priceRequestId}`;
    Cookies.set(key, "true", { expires: COOKIE_EXPIRATION_DAYS });
  };

  const hasActiveProjectBeenViewed = (
    priceRequestId: number | string,
  ): boolean => {
    const key = `${ACTIVE_PROJECT_PREFIX}_${priceRequestId}`;
    const value = Cookies.get(key);
    return value === "true";
  };

  // Add functions for completed projects
  const setCompletedProjectViewed = (priceRequestId: number | string) => {
    const key = `${COMPLETED_PROJECT_PREFIX}_${priceRequestId}`;
    Cookies.set(key, "true", { expires: COOKIE_EXPIRATION_DAYS });
  };

  const hasCompletedProjectBeenViewed = (
    priceRequestId: number | string,
  ): boolean => {
    const key = `${COMPLETED_PROJECT_PREFIX}_${priceRequestId}`;
    const value = Cookies.get(key);
    return value === "true";
  };

  return {
    setFeedbackViewedCookie,
    getFeedbackViewedCookie,
    setNewRequestViewed,
    hasNewRequestBeenViewed,
    setMessagesViewed,
    hasMessagesBeenViewed,
    hideMessageBadge,
    isMessageBadgeHidden,
    setLostRequestViewed,
    hasLostRequestBeenViewed,
    setNewAgreementViewed,
    hasNewAgreementBeenViewed,
    setArchivedRequestViewed,
    hasArchivedRequestBeenViewed,
    setActiveServiceViewed,
    hasActiveServiceBeenViewed,
    setCompletedServiceViewed,
    hasCompletedServiceBeenViewed,
    setActiveProjectViewed,
    hasActiveProjectBeenViewed,
    setCompletedProjectViewed,
    hasCompletedProjectBeenViewed,
  };
}
