import {
  TenderApi,
  SystemApi,
  TenderApiEndpoints,
  SystemApiEndpoints,
  CustomerServiceEndpoints,
  CustomerServiceApi,
} from "@/config/api/tender/tenderApi";
import { executeApiRequest } from "@/services/api/apiService";
import { useToastErrorHandler } from "@/composables/useToastErrorHandler";
import {
  Tender,
  ServiceOffer,
  InvoiceRecipient,
  ChangeOfferStateDto,
  CompleteTenderDto,
  InformationRequestedDto,
  AgreementResponsibleDto,
  RejectPriceRequestDto,
  sendOfferDto,
  FrameAgreement,
  PublicNote,
  TenderArchiveDto,
  CloneableOffer,
  ActiveRequest,
  AgreementState,
  AgreementType,
  Agreement,
  PriceRequestDetails,
  AgreementAcceptance,
  PagedResult,
} from "@/stores/tender/types";
import type { ActivityStats } from "@/stores/communication/types";
import {
  CloneServiceOfferDTO,
  CreateServiceOfferDTO,
  FileAttachment,
  FileAttachmentDto,
  ServiceOffer as ServiceOfferType,
} from "@/custom-types/GeneralTypes";
import cachingDecorator from "@/utilities/cachingDecorator";

const cachedExecuteApiRequest = cachingDecorator(executeApiRequest);

const useTenderApiService = () => {
  const { withToastErrorHandling } = useToastErrorHandler();

  const fetchSupplierOffers = async (params: {
    tenderId: number;
    supplierId: number;
  }) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<ServiceOffer[]>(
          {
            endpointKey: Symbol.for(TenderApiEndpoints.FETCH_SUPPLIER_OFFERS),
            config: TenderApi,
          },
          {
            pathParams: [
              params.tenderId,
              "Supplier",
              params.supplierId,
              "Offers",
            ],
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Hentefeil",
          detail: "Kunne ikke hente leverandørens tilbud",
        },
      },
    );
  };

  const fetchTenderOffersBySupplier = async (supplierId: number) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<Tender[]>(
          {
            endpointKey: Symbol.for(
              TenderApiEndpoints.FETCH_TENDER_OFFERS_BY_SUPPLIER,
            ),
            config: TenderApi,
          },
          {
            pathParams: [supplierId],
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Hentefeil",
          detail: "Kunne ikke hente tilbudene for leverandøren",
        },
      },
    );
  };

  const completeTenderOfferBySupplier = async (params: {
    supplierId: number;
    supplierRequestId: number;
    serviceOfferId: number;
    data: CompleteTenderDto;
  }) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<void>(
          {
            endpointKey: Symbol.for(
              TenderApiEndpoints.COMPLETE_TENDER_OFFER_BY_SUPPLIER,
            ),
            config: TenderApi,
          },
          {
            data: params.data,
            pathParams: [
              params.supplierId,
              "SupplierRequest",
              params.supplierRequestId,
              "Offers",
              params.serviceOfferId,
              "OfferState",
              "Completed",
            ],
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Fullføringsfeil",
          detail: "Kunne ikke fullføre anbudsprosessen",
        },
        successOptions: {
          severity: "success",
          summary: "Fullført",
          detail: "Anbudsprosessen er fullført",
        },
      },
    );
  };

  const getTenderConfirmationUri = async (params: {
    tenderId: number;
    offerId: number;
    audience: "Customer" | "Supplier";
  }) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<Blob>(
          {
            endpointKey: Symbol.for(
              TenderApiEndpoints.GET_TENDER_CONFIRMATION_URI,
            ),
            config: TenderApi,
          },
          {
            pathParams: [
              params.tenderId,
              "offer",
              params.offerId,
              "OrderConfirmation",
              params.audience,
            ],
            responseType: "blob",
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Dokumenthentingsfeil",
          detail: "Kunne ikke laste ned ordrebekreftelsen",
        },
      },
    );
  };

  const sendRejectPriceRequest = async (params: {
    supplierId: number;
    supplierRequestId: number;
    data: RejectPriceRequestDto;
  }) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<void>(
          {
            endpointKey: Symbol.for(
              TenderApiEndpoints.SEND_REJECT_PRICE_REQUEST,
            ),
            config: TenderApi,
          },
          {
            data: params.data,
            pathParams: [
              "Supplier",
              params.supplierId,
              "requests",
              params.supplierRequestId,
              "requestState",
              "Rejected",
            ],
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Avvisningsfeil",
          detail: "Kunne ikke avvise prisforespørselen",
        },
        successOptions: {
          severity: "success",
          summary: "Avvist",
          detail: "Prisforespørselen er avvist",
        },
      },
    );
  };

  const setInformationRequested = async (params: {
    supplierId: number;
    supplierRequestId: number;
    data: InformationRequestedDto;
  }) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<void>(
          {
            endpointKey: Symbol.for(
              TenderApiEndpoints.SET_INFORMATION_REQUESTED,
            ),
            config: TenderApi,
          },
          {
            data: params.data,
            pathParams: [
              "Supplier",
              params.supplierId,
              "requests",
              params.supplierRequestId,
              "requestState",
              "InformationRequested",
            ],
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Forespørselsfeil",
          detail: "Kunne ikke be om tilleggsinformasjon",
        },
        successOptions: {
          severity: "success",
          summary: "Sendt",
          detail: "Forespørsel om tilleggsinformasjon er sendt",
        },
      },
    );
  };

  const getTenderOffer = async (params: {
    tenderId: number;
    offerId: number;
  }) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<ServiceOffer>(
          {
            endpointKey: Symbol.for(TenderApiEndpoints.GET_TENDER_OFFER),
            config: TenderApi,
          },
          {
            pathParams: [params.tenderId, "offer", params.offerId],
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Hentefeil",
          detail: "Kunne ikke hente anbudstilbudet",
        },
      },
    );
  };

  const getTender = async (
    params: { supplierId: number; tenderId: number },
    options?: { signal?: AbortSignal },
  ) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<Tender>(
          {
            endpointKey: Symbol.for(TenderApiEndpoints.GET_TENDER),
            config: TenderApi,
          },
          {
            pathParams: [params.tenderId, "Supplier", params.supplierId],
            signal: options?.signal,
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Hentefeil",
          detail: "Kunne ikke hente anbudet",
        },
      },
    );
  };

  const sendOffer = async (params: {
    tenderId: number;
    offerId: number;
    data: sendOfferDto;
  }) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<void>(
          {
            endpointKey: Symbol.for(TenderApiEndpoints.SEND_OFFER),
            config: TenderApi,
          },
          {
            data: params.data,
            pathParams: [params.tenderId, "offer", params.offerId, "change"],
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Sendefeil",
          detail: "Kunne ikke sende tilbudet",
        },
        successOptions: {
          severity: "success",
          summary: "Sendt",
          detail: "Tilbudet er sendt",
        },
      },
    );
  };

  const deleteSupplierOffer = async (params: {
    supplierId: number;
    supplierRequestId: number;
    offerId: number;
  }) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<void>(
          {
            endpointKey: Symbol.for(TenderApiEndpoints.DELETE_SUPPLIER_OFFER),
            config: TenderApi,
          },
          {
            pathParams: [
              params.supplierId,
              "SupplierRequest",
              params.supplierRequestId,
              "Offers",
              params.offerId,
            ],
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Slettefeil",
          detail: "Kunne ikke slette leverandørtilbudet",
        },
        successOptions: {
          severity: "success",
          summary: "Slettet",
          detail: "Leverandørtilbudet er slettet",
        },
      },
    );
  };

  const withdrawSupplierOffer = async (params: {
    tenderId: number;
    offerId: number;
    data: ChangeOfferStateDto;
  }) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<void>(
          {
            endpointKey: Symbol.for(TenderApiEndpoints.WITHDRAW_SUPPLIER_OFFER),
            config: TenderApi,
          },
          {
            data: params.data,
            pathParams: [params.tenderId, "offer", params.offerId, "change"],
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Tilbaketrekkingsfeil",
          detail: "Kunne ikke trekke tilbake leverandørtilbudet",
        },
        successOptions: {
          severity: "success",
          summary: "Tilbaketrukket",
          detail: "Leverandørtilbudet er trukket tilbake",
        },
      },
    );
  };

  const setAgreementResponsible = async (params: {
    supplierId: number;
    supplierRequestId: number;
    data: AgreementResponsibleDto;
  }) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<void>(
          {
            endpointKey: Symbol.for(
              TenderApiEndpoints.SET_AGREEMENT_RESPONSIBLE,
            ),
            config: TenderApi,
          },
          {
            data: params.data,
            pathParams: [
              "Supplier",
              params.supplierId,
              "SupplierRequest",
              params.supplierRequestId,
              "Responsible",
            ],
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Avtaleoppdateringsfeil",
          detail: "Kunne ikke oppdatere avtaleansvarlig",
        },
        successOptions: {
          severity: "success",
          summary: "Oppdatert",
          detail: "Avtaleansvarlig er oppdatert",
        },
      },
    );
  };

  const getInvoiceRecipient = async (tenderId: number, offerId: number) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<InvoiceRecipient>(
          {
            endpointKey: Symbol.for(TenderApiEndpoints.GET_INVOICE_RECIPIENT),
            config: TenderApi,
          },
          {
            pathParams: [tenderId, "offer", offerId, "InvoiceRecipient"],
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Fakturadatafeil",
          detail: "Kunne ikke hente informasjon om fakturamottaker",
        },
      },
    );
  };

  const submitTender = async (data: unknown) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<Tender>(
          {
            endpointKey: Symbol.for(TenderApiEndpoints.SUBMIT_TENDER),
            config: TenderApi,
          },
          { data: data },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Innsendingsfeil",
          detail: "Kunne ikke sende inn anbudet",
        },
        successOptions: {
          severity: "success",
          summary: "Sendt inn",
          detail: "Anbudet er sendt inn",
        },
      },
    );
  };

  const createSupplierTenderOffer = async (data: ServiceOffer) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<ServiceOffer>(
          {
            endpointKey: Symbol.for(
              SystemApiEndpoints.CREATE_SUPPLIER_TENDER_OFFER,
            ),
            config: SystemApi,
          },
          { data: data },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Opprettelsesfeil",
          detail: "Kunne ikke opprette anbudstilbud",
        },
        successOptions: {
          severity: "success",
          summary: "Opprettet",
          detail: "Nytt anbudstilbud er opprettet",
        },
      },
    );
  };

  const updateSupplierTenderOffer = async (params: {
    serviceOfferId: number;
    data: ServiceOffer;
  }) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<ServiceOffer>(
          {
            endpointKey: Symbol.for(
              SystemApiEndpoints.UPDATE_SUPPLIER_TENDER_OFFER,
            ),
            config: SystemApi,
          },
          {
            data: params.data,
            pathParams: [params.serviceOfferId],
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Oppdateringsfeil",
          detail: "Kunne ikke oppdatere anbudstilbudet",
        },
        successOptions: {
          severity: "success",
          summary: "Oppdatert",
          detail: "Anbudstilbudet er oppdatert",
        },
      },
    );
  };

  const getActivityFormFrequencyOptions = async () => {
    return withToastErrorHandling(
      async () => {
        return cachedExecuteApiRequest<string[]>({
          endpointKey: Symbol.for(
            SystemApiEndpoints.GET_ACTIVITY_FORM_FREQUENCY_OPTIONS,
          ),
          config: SystemApi,
        });
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Konfigureringsfeil",
          detail: "Kunne ikke laste frekvensalternativene",
        },
      },
    );
  };

  const getFrameAgreement = async (params: {
    organizationId: number;
    frameAgreementId: number;
  }) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<FrameAgreement>(
          {
            endpointKey: Symbol.for(
              CustomerServiceEndpoints.GET_FRAME_AGREEMENT,
            ),
            config: CustomerServiceApi,
          },
          {
            pathParams: [
              params.organizationId,
              "frameAgreement",
              params.frameAgreementId,
            ],
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Hentefeil",
          detail: "Kunne ikke hente rammeavtalen",
        },
      },
    );
  };

  const getPublicNotes = async (params: {
    priceRequestId: number;
    supplierId: number;
  }) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<PublicNote[]>(
          {
            endpointKey: Symbol.for(TenderApiEndpoints.GET_PUBLIC_NOTES),
            config: TenderApi,
          },
          {
            pathParams: [
              "PriceRequest",
              params.priceRequestId,
              "Supplier",
              params.supplierId,
              "Note",
            ],
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Hentefeil",
          detail: "Kunne ikke hente offentlige notater",
        },
      },
    );
  };

  const updateTenderArchive = async (params: {
    tenderId: number;
    requestId: number;
    flag: string;
    data: TenderArchiveDto;
  }) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<Tender>(
          {
            endpointKey: Symbol.for(TenderApiEndpoints.UPDATE_TENDER_ARCHIVE),
            config: TenderApi,
          },
          {
            data: params.data,
            pathParams: [
              params.tenderId,
              "supplierRequest",
              params.requestId,
              "archive",
              params.flag,
            ],
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Arkivoppdateringsfeil",
          detail: "Kunne ikke oppdatere anbudsarkivet",
        },
        successOptions: {
          severity: "success",
          summary: "Oppdatert",
          detail: "Anbudsarkivet er oppdatert",
        },
      },
    );
  };

  const getCloneableOffers = async (params: {
    supplierId: number;
    serviceCategoryId: number;
  }) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<CloneableOffer[]>(
          {
            endpointKey: Symbol.for(TenderApiEndpoints.GET_CLONEABLE_OFFERS),
            config: TenderApi,
          },
          {
            pathParams: [
              "Supplier",
              params.supplierId,
              "ServiceCategory",
              params.serviceCategoryId,
              "Offers",
              "Cloneable",
            ],
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Hentefeil",
          detail: "Kunne ikke hente klonerbare tilbud",
        },
      },
    );
  };

  const getActiveRequests = async (
    supplierId: number,
    skip?: number,
    take?: number,
  ) => {
    const queryParams: Record<string, string> = {};

    if (skip !== undefined) {
      queryParams.skip = skip.toString();
    }

    if (take !== undefined) {
      queryParams.take = take.toString();
    }

    return withToastErrorHandling(
      async () => {
        return executeApiRequest<PagedResult<ActiveRequest>>(
          {
            endpointKey: Symbol.for(TenderApiEndpoints.GET_ACTIVE_REQUESTS),
            config: TenderApi,
          },
          {
            pathParams: [supplierId, "requests", "active"],
            queryParams,
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Hentefeil",
          detail: "Kunne ikke laste aktive forespørsler",
        },
      },
    );
  };

  const getLostRequests = async (
    supplierId: number,
    skip?: number,
    take?: number,
  ) => {
    const queryParams: Record<string, string> = {};

    if (skip !== undefined) {
      queryParams.skip = skip.toString();
    }

    if (take !== undefined) {
      queryParams.take = take.toString();
    }

    return withToastErrorHandling(
      async () => {
        return executeApiRequest<PagedResult<ActiveRequest>>(
          {
            endpointKey: Symbol.for(TenderApiEndpoints.GET_LOST_REQUESTS),
            config: TenderApi,
          },
          {
            pathParams: [supplierId, "requests", "lost"],
            queryParams,
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Hentefeil",
          detail: "Kunne ikke laste tapte forespørsler",
        },
      },
    );
  };

  const getArchivedRequests = async (
    supplierId: number,
    skip?: number,
    take?: number,
  ) => {
    const queryParams: Record<string, string> = {};

    if (skip !== undefined) {
      queryParams.skip = skip.toString();
    }

    if (take !== undefined) {
      queryParams.take = take.toString();
    }

    return withToastErrorHandling(
      async () => {
        return executeApiRequest<PagedResult<ActiveRequest>>(
          {
            endpointKey: Symbol.for(TenderApiEndpoints.GET_ARCHIVED_REQUESTS),
            config: TenderApi,
          },
          {
            pathParams: [supplierId, "requests", "archived"],
            queryParams,
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Hentefeil",
          detail: "Kunne ikke laste arkiverte forespørsler",
        },
      },
    );
  };

  const getAgreements = async (
    supplierId: number,
    state: AgreementState,
    type: AgreementType,
    skip?: number,
    take?: number,
  ) => {
    const queryParams: Record<string, string> = {};

    if (skip !== undefined) {
      queryParams.skip = skip.toString();
    }

    if (take !== undefined) {
      queryParams.take = take.toString();
    }

    return withToastErrorHandling(
      async () => {
        return executeApiRequest<PagedResult<Agreement>>(
          {
            endpointKey: Symbol.for(TenderApiEndpoints.GET_AGREEMENTS),
            config: TenderApi,
          },
          {
            pathParams: [supplierId, "Agreement", state, type],
            queryParams,
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Hentefeil",
          detail: "Kunne ikke laste avtalene",
        },
      },
    );
  };

  const getPriceRequestDetails = async (params: {
    supplierId: number;
    priceRequestId: number;
  }) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<PriceRequestDetails>(
          {
            endpointKey: Symbol.for(
              TenderApiEndpoints.GET_PRICE_REQUEST_DETAILS,
            ),
            config: TenderApi,
          },
          {
            pathParams: [
              "Supplier",
              params.supplierId,
              "Requests",
              params.priceRequestId,
            ],
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Prisforespørselsfeil",
          detail: "Kunne ikke hente detaljer for prisforespørsel",
        },
      },
    );
  };

  const getServiceOffers = async (params: {
    supplierId: number;
    supplierRequestId: number;
  }) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<ServiceOfferType[]>(
          {
            endpointKey: Symbol.for(TenderApiEndpoints.GET_SERVICE_OFFERS),
            config: TenderApi,
          },
          {
            pathParams: [
              params.supplierId,
              "SupplierRequest",
              params.supplierRequestId,
              "Offers",
            ],
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Tilbudsfeil",
          detail: "Kunne ikke hente tjenestetilbud",
        },
      },
    );
  };

  const getServiceOfferDescriptions = async (
    supplierId: number,
    supplierRequestId: number,
    offerId: number,
  ) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<unknown>(
          {
            endpointKey: Symbol.for(
              TenderApiEndpoints.GET_SERVICE_OFFER_DESCRIPTIONS,
            ),
            config: TenderApi,
          },
          {
            pathParams: [
              supplierId,
              "supplierRequest",
              supplierRequestId,
              "offers",
              offerId,
              "descriptions",
            ],
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Tilbudsfeil",
          detail: "Kunne ikke hente tjenestetilbudsbeskrivelser",
        },
      },
    );
  };

  const createServiceOfferDescription = async (
    supplierId: number,
    supplierRequestId: number,
    offerId: number,
    payload: {
      title: string;
      body: string;
    },
  ) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<unknown>(
          {
            endpointKey: Symbol.for(
              TenderApiEndpoints.CREATE_SERVICE_OFFER_DESCRIPTION,
            ),
            config: TenderApi,
          },
          {
            pathParams: [
              supplierId,
              "supplierRequest",
              supplierRequestId,
              "offers",
              offerId,
              "description",
            ],
            data: payload,
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Opprettelsesfeil",
          detail: "Kunne ikke opprette beskrivelsen for tjenestetilbudet",
        },
        successOptions: {
          severity: "success",
          summary: "Opprettet",
          detail: "Beskrivelsen for tjenestetilbudet er opprettet",
        },
      },
    );
  };

  const updateServiceOfferDescription = async (
    supplierId: number,
    supplierRequestId: number,
    offerId: number,
    payload: {
      id: number;
      title: string;
      body: string;
    },
  ) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<unknown>(
          {
            endpointKey: Symbol.for(
              TenderApiEndpoints.UPDATE_SERVICE_OFFER_DESCRIPTION,
            ),
            config: TenderApi,
          },
          {
            pathParams: [
              supplierId,
              "supplierRequest",
              supplierRequestId,
              "offers",
              offerId,
              "description",
            ],
            data: payload,
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Tilbudsfeil",
          detail: "Kunne ikke oppdatere tjenestetilbudsbeskrivelse",
        },
        successOptions: {
          severity: "success",
          summary: "Vellykket",
          detail: "Tjenestetilbudsbeskrivelse oppdatert",
        },
      },
    );
  };

  const deleteServiceOfferDescription = async (
    supplierId: number,
    supplierRequestId: number,
    offerId: number,
    descriptionId: number,
  ) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<unknown>(
          {
            endpointKey: Symbol.for(
              TenderApiEndpoints.DELETE_SERVICE_OFFER_DESCRIPTION,
            ),
            config: TenderApi,
          },
          {
            pathParams: [
              supplierId,
              "supplierRequest",
              supplierRequestId,
              "offers",
              offerId,
              "description",
              descriptionId,
            ],
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Tilbudsfeil",
          detail: "Kunne ikke slette tjenestetilbudsbeskrivelse",
        },
        successOptions: {
          severity: "success",
          summary: "Vellykket",
          detail: "Tjenestetilbudsbeskrivelse slettet",
        },
      },
    );
  };

  const createMandatorySupplierCost = async (
    supplierId: number,
    supplierRequestId: number,
    offerId: number,
    payload: unknown,
  ) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<unknown>(
          {
            endpointKey: Symbol.for(
              TenderApiEndpoints.CREATE_MANDATORY_SUPPLIER_COST,
            ),
            config: TenderApi,
          },
          {
            pathParams: [
              supplierId,
              "supplierRequest",
              supplierRequestId,
              "offers",
              offerId,
              "ModiyCostAction",
            ],
            data: payload,
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Kostnadsfeil",
          detail: "Kunne ikke opprette obligatorisk leverandørkostnad",
        },
        successOptions: {
          severity: "success",
          summary: "Vellykket",
          detail: "Obligatorisk leverandørkostnad opprettet",
        },
      },
    );
  };

  const updateMandatorySupplierCost = async (
    supplierId: number,
    supplierRequestId: number,
    offerId: number,
    payload: unknown,
  ) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<unknown>(
          {
            endpointKey: Symbol.for(
              TenderApiEndpoints.UPDATE_MANDATORY_SUPPLIER_COST,
            ),
            config: TenderApi,
          },
          {
            pathParams: [
              supplierId,
              "supplierRequest",
              supplierRequestId,
              "offers",
              offerId,
              "ModiyCostAction",
            ],
            data: payload,
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Kostnadsfeil",
          detail: "Kunne ikke oppdatere obligatorisk leverandørkostnad",
        },
        successOptions: {
          severity: "success",
          summary: "Vellykket",
          detail: "Obligatorisk leverandørkostnad oppdatert",
        },
      },
    );
  };

  const deleteSupplierCostItem = async (
    supplierId: number,
    supplierRequestId: number,
    offerId: number,
    segmentGroupPriceId: number,
  ) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<unknown>(
          {
            endpointKey: Symbol.for(
              TenderApiEndpoints.DELETE_SUPPLIER_COST_ITEM,
            ),
            config: TenderApi,
          },
          {
            pathParams: [
              supplierId,
              "supplierRequest",
              supplierRequestId,
              "offers",
              offerId,
              "costItem",
              segmentGroupPriceId,
            ],
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Kostnadsfeil",
          detail: "Kunne ikke slette leverandørkostnadselement",
        },
        successOptions: {
          severity: "success",
          summary: "Vellykket",
          detail: "Leverandørkostnadselement slettet",
        },
      },
    );
  };

  const getServiceOffer = async (
    supplierId: number,
    supplierRequestId: number,
    offerId: number,
  ) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<unknown>(
          {
            endpointKey: Symbol.for(TenderApiEndpoints.GET_SERVICE_OFFER),
            config: TenderApi,
          },
          {
            pathParams: [
              supplierId,
              "supplierRequest",
              supplierRequestId,
              "offers",
              offerId,
            ],
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Tilbudsfeil",
          detail: "Kunne ikke hente tjenestetilbud",
        },
      },
    );
  };

  const createServiceOffer = async (
    supplierId: number,
    supplierRequestId: number,
    payload: CreateServiceOfferDTO,
  ) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<unknown>(
          {
            endpointKey: Symbol.for(TenderApiEndpoints.CREATE_SERVICE_OFFER),
            config: TenderApi,
          },
          {
            pathParams: [
              supplierId,
              "supplierRequest",
              supplierRequestId,
              "offers",
              "createAction",
            ],
            data: payload,
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Tilbudsfeil",
          detail: "Kunne ikke opprette tjenestetilbud",
        },
        successOptions: {
          severity: "success",
          summary: "Vellykket",
          detail: "Tjenestetilbud opprettet",
        },
      },
    );
  };

  const cloneServiceOffer = async (
    supplierId: number,
    supplierRequestId: number,
    payload: CloneServiceOfferDTO,
  ) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<unknown>(
          {
            endpointKey: Symbol.for(TenderApiEndpoints.CLONE_SERVICE_OFFER),
            config: TenderApi,
          },
          {
            pathParams: [
              supplierId,
              "supplierRequest",
              supplierRequestId,
              "offers",
              "cloneAction",
            ],
            data: payload,
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Kloneringsfeil",
          detail: "Kunne ikke klone tjenestetilbudet",
        },
        successOptions: {
          severity: "success",
          summary: "Klonet",
          detail: "Tjenestetilbudet er klonet",
        },
      },
    );
  };

  const addServiceOfferAttachments = async (
    supplierId: number,
    supplierRequestId: number,
    offerId: number,
    payload: Array<FileAttachmentDto>,
  ) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<FileAttachment>(
          {
            endpointKey: Symbol.for(
              TenderApiEndpoints.ADD_SERVICE_OFFER_ATTACHMENTS,
            ),
            config: TenderApi,
          },
          {
            pathParams: [
              supplierId,
              "supplierRequest",
              supplierRequestId,
              "offers",
              offerId,
              "attachments",
            ],
            data: payload,
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Vedleggsfeil",
          detail: "Kunne ikke legge til vedlegg i tjenestetilbudet",
        },
        successOptions: {
          severity: "success",
          summary: "Lagt til",
          detail: "Vedlegg er lagt til i tjenestetilbudet",
        },
      },
    );
  };

  const removeServiceOfferAttachments = async (
    supplierId: number,
    supplierRequestId: number,
    offerId: number,
    attachmentId: string,
  ) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<unknown>(
          {
            endpointKey: Symbol.for(
              TenderApiEndpoints.REMOVE_SERVICE_OFFER_ATTACHMENTS,
            ),
            config: TenderApi,
          },
          {
            pathParams: [
              supplierId,
              "supplierRequest",
              supplierRequestId,
              "offers",
              offerId,
              "attachments",
              attachmentId,
            ],
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Vedleggsfeil",
          detail: "Kunne ikke fjerne vedlegget fra tjenestetilbudet",
        },
        successOptions: {
          severity: "success",
          summary: "Fjernet",
          detail: "Vedlegget er fjernet fra tjenestetilbudet",
        },
      },
    );
  };

  const getRequestActivityStats = async (supplierId: number, from: string) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<ActivityStats[]>(
          {
            endpointKey: Symbol.for(
              TenderApiEndpoints.GET_REQUEST_ACTIVITY_STATS,
            ),
            config: TenderApi,
          },
          {
            pathParams: [supplierId, "ActivityStats", "From", from],
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Statistikkfeil",
          detail: "Kunne ikke hente aktivitetsstatistikk",
        },
      },
    );
  };

  const updateServiceOfferNickname = async (
    supplierId: number,
    supplierRequestId: number,
    offerId: number,
    payload: { newNickname: string },
  ) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<unknown>(
          {
            endpointKey: Symbol.for(
              TenderApiEndpoints.UPDATE_SERVICE_OFFER_NICKNAME,
            ),
            config: TenderApi,
          },
          {
            pathParams: [
              supplierId,
              "supplierRequest",
              supplierRequestId,
              "offers",
              offerId,
              "nickname",
            ],
            data: payload,
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Tilbudsfeil",
          detail: "Kunne ikke oppdatere kallenavn for tjenestetilbud",
        },
        successOptions: {
          severity: "success",
          summary: "Vellykket",
          detail: "Kallenavn for tjenestetilbud oppdatert",
        },
      },
    );
  };

  const updateServiceOfferStartDate = async (
    supplierId: number,
    supplierRequestId: number,
    offerId: number,
    payload: { newStartDate: string },
  ) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<unknown>(
          {
            endpointKey: Symbol.for(
              TenderApiEndpoints.UPDATE_SERVICE_OFFER_START_DATE,
            ),
            config: TenderApi,
          },
          {
            pathParams: [
              supplierId,
              "supplierRequest",
              supplierRequestId,
              "offers",
              offerId,
              "startDate",
            ],
            data: payload,
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Tilbudsfeil",
          detail: "Kunne ikke oppdatere startdato for tjenestetilbud",
        },
        successOptions: {
          severity: "success",
          summary: "Vellykket",
          detail: "Startdato for tjenestetilbud oppdatert",
        },
      },
    );
  };

  const updateServiceOfferTerminationPeriod = async (
    supplierId: number,
    supplierRequestId: number,
    offerId: number,
    payload: { newTerminationPeriodInMonths: number },
  ) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<unknown>(
          {
            endpointKey: Symbol.for(
              TenderApiEndpoints.UPDATE_SERVICE_OFFER_START_DATE,
            ),
            config: TenderApi,
          },
          {
            pathParams: [
              supplierId,
              "supplierRequest",
              supplierRequestId,
              "offers",
              offerId,
              "terminationPeriod",
            ],
            data: payload,
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Tilbudsfeil",
          detail: "Kunne ikke oppdatere oppsigelsesperiode for tjenestetilbud",
        },
        successOptions: {
          severity: "success",
          summary: "Vellykket",
          detail: "Oppsigelsesperiode for tjenestetilbud oppdatert",
        },
      },
    );
  };

  const changeServiceOfferState = async (
    supplierId: number,
    supplierRequestId: number,
    offerId: number,
    stateTo: string,
    payload: { userId: string; withdrawOtherOffers: boolean },
  ) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<unknown>(
          {
            endpointKey: Symbol.for(
              TenderApiEndpoints.CHANGE_SERVICE_OFFER_STATE,
            ),
            config: TenderApi,
          },
          {
            pathParams: [
              supplierId,
              "supplierRequest",
              supplierRequestId,
              "offers",
              offerId,
              "offerState",
              stateTo,
            ],
            data: payload,
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Tilbudsfeil",
          detail: "Kunne ikke endre tilbudsstatus",
        },
        successOptions: {
          severity: "success",
          summary: "Vellykket",
          detail: "Tilbudsstatus endret",
        },
      },
    );
  };

  const getAgreementAcceptance = async (params: {
    supplierId: number;
    priceRequestId: number;
  }) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<AgreementAcceptance>(
          {
            endpointKey: Symbol.for(
              TenderApiEndpoints.GET_AGREEMENT_ACCEPTANCE,
            ),
            config: TenderApi,
          },
          {
            pathParams: [
              params.supplierId,
              "Agreement",
              params.priceRequestId,
              "Acceptance",
            ],
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Avtalefeil",
          detail: "Kunne ikke hente avtaleaksept",
        },
      },
    );
  };

  return {
    fetchSupplierOffers,
    fetchTenderOffersBySupplier,
    completeTenderOfferBySupplier,
    getTenderConfirmationUri,
    sendRejectPriceRequest,
    setInformationRequested,
    getTenderOffer,
    getTender,
    sendOffer,
    deleteSupplierOffer,
    withdrawSupplierOffer,
    setAgreementResponsible,
    getInvoiceRecipient,
    submitTender,
    createSupplierTenderOffer,
    updateSupplierTenderOffer,
    getActivityFormFrequencyOptions,
    getFrameAgreement,
    getPublicNotes,
    updateTenderArchive,
    getCloneableOffers,
    getActiveRequests,
    getLostRequests,
    getArchivedRequests,
    getAgreements,
    getPriceRequestDetails,
    getServiceOffers,
    getServiceOfferDescriptions,
    createServiceOfferDescription,
    updateServiceOfferDescription,
    deleteServiceOfferDescription,
    createMandatorySupplierCost,
    updateMandatorySupplierCost,
    deleteSupplierCostItem,
    getServiceOffer,
    createServiceOffer,
    cloneServiceOffer,
    addServiceOfferAttachments,
    removeServiceOfferAttachments,
    getRequestActivityStats,
    updateServiceOfferNickname,
    updateServiceOfferStartDate,
    updateServiceOfferTerminationPeriod,
    changeServiceOfferState,
    getAgreementAcceptance,
  };
};

export const useTenderApi = useTenderApiService;
