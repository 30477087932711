<template>
  <div class="upload-documentation">
    <div class="upload-documentation__title">Last opp dokumentasjon</div>
    <div class="upload-documentation__description">
      Her kan du laste opp siste servicerapporter, restanselister eller annen
      dokumentasjon på utført arbeid. Denne dokumentasjonen vil kunden få enkel
      tilgang til på sin side.
    </div>
    <b-button
      v-if="!showServiceReportForm"
      class="upload-documentation__btn"
      size="lg"
      @click="showServiceReportForm = true"
    >
      Legg til ny dokumentasjon
    </b-button>
    <service-report-form
      v-else
      :service-offer-id="serviceOfferId"
      :tender-id="tender.tenderId"
      :customer-org-number="tender.customerOrganization.orgNumber"
      :is-single-job="isSingleJob"
      @hide="hideServiceReportForm"
      @new-service-report="handleNewServiceReport"
    />
  </div>
</template>

<script>
import ServiceReportForm from "@/components/ServiceReport/ServiceReportForm.vue";
import { isTenderSingleJob } from "@/utilities/offerUtilities";

export default {
  components: { ServiceReportForm },
  props: {
    tender: {
      type: Object,
      required: true,
    },
    supplierId: {
      type: Number,
      required: true,
    },
    serviceOfferId: {
      type: Number,
      required: true,
    },
  },
  emits: ["new-service-report"],
  data() {
    return {
      showServiceReportForm: false,
    };
  },
  computed: {
    isSingleJob() {
      return isTenderSingleJob(this.tender, this.supplierId);
    },
  },
  methods: {
    hideServiceReportForm() {
      this.showServiceReportForm = false;
    },
    handleNewServiceReport() {
      this.$emit("new-service-report");
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-documentation {
  text-align: start;

  &__title {
    font-size: $font-size-base;
    font-weight: bold;
    color: $color-primary-darker;
  }

  &__description {
    font-weight: 400;
    font-size: $font-size-sm;
    color: $color-primary-darker;
    margin-bottom: 2.5rem;
  }

  &__btn {
    color: white;
    background-color: #611f69;
    border-radius: 0.25rem;
    padding: 1.125rem 2.5rem;
    margin-bottom: 5rem;
    text-transform: uppercase;
    font-weight: 600;
    font-size: $font-size-base;

    &:hover {
      background-color: $color-primary-darker;
    }
  }
}
</style>
