<template>
  <div class="service-offer-list">
    <div v-for="state in offerStates" :key="state" class="state-section">
      <service-offer-item
        v-for="(offer, i) in getFilteredOffersByState(state)"
        :key="offer.serviceOfferId"
        :offer="offer"
        :tender="tender"
        :supplier-id="supplierId"
        :frequency-options="frequencyOptions"
        :is-first="i === 0"
        :is-last="isLastInState(state, i)"
        @item-action="handleItemAction"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import ServiceOfferItem from "./ServiceOfferItem.vue";
import type { ServiceOffer } from "@/custom-types/GeneralTypes";
import { OfferStateType } from "@/custom-types/GeneralTypes";
import type { Tender } from "@/stores/tender/types";

type OfferState =
  | typeof OfferStateType.OFFERED
  | typeof OfferStateType.DRAFT
  | "WithdrawnOrRejected";

interface Props {
  tender: Tender;
  supplierId: number;
  serviceOffers: ServiceOffer[];
  frequencyOptions?: Record<string, unknown>;
}

const props = withDefaults(defineProps<Props>(), {
  frequencyOptions: () => ({}),
});

const emit = defineEmits<{
  (e: "send-to-customer", offer: ServiceOffer): void;
  (e: "delete-draft", offer: ServiceOffer): void;
  (e: "copy", offer: ServiceOffer): void;
  (e: "edit", offer: ServiceOffer): void;
  (e: "view-offer", offer: ServiceOffer): void;
  (e: "edit-sent-withdrawn", offer: ServiceOffer): void;
  (e: "revoke", offer: ServiceOffer): void;
  (e: "see-agreement", offer: ServiceOffer): void;
  (e: "download-terms"): void;
}>();

const offers = ref<ServiceOffer[]>([]);
const filteredOffersByState = ref<Record<OfferState, ServiceOffer[]>>({
  [OfferStateType.OFFERED]: [],
  [OfferStateType.DRAFT]: [],
  WithdrawnOrRejected: [],
});

const offerStates: readonly OfferState[] = [
  OfferStateType.OFFERED,
  OfferStateType.DRAFT,
  "WithdrawnOrRejected",
] as const;

watch(
  () => props.serviceOffers,
  (newOffers) => {
    offers.value = newOffers;

    offerStates.forEach((state) => {
      const withdrawnStates = [
        OfferStateType.WITHDRAWN,
        OfferStateType.REJECTED,
        OfferStateType.LOST,
      ];

      filteredOffersByState.value[state] = offers.value
        .filter((offer) =>
          state === "WithdrawnOrRejected"
            ? withdrawnStates.includes(offer.currentState)
            : offer.currentState === state,
        )
        .sort(
          (a, b) =>
            new Date(b.lastStateChange).getTime() -
            new Date(a.lastStateChange).getTime(),
        );
    });
  },
  { immediate: true },
);

const getFilteredOffersByState = (state: OfferState) =>
  filteredOffersByState.value[state];

const isLastInState = (state: OfferState, index: number): boolean =>
  index === filteredOffersByState.value[state].length - 1;

const handleItemAction = (action: string, offer: ServiceOffer) => {
  switch (action) {
    case "send-to-customer":
      emit("send-to-customer", offer);
      break;
    case "delete-draft":
      emit("delete-draft", offer);
      break;
    case "copy":
      emit("copy", offer);
      break;
    case "edit":
      offer.currentState === OfferStateType.DRAFT
        ? emit("edit", offer)
        : emit("edit-sent-withdrawn", offer);
      break;
    case "view-offer":
      emit("view-offer", offer);
      break;
    case "revoke-offer":
      emit("revoke", offer);
      break;
    case "see-agreement":
      emit("see-agreement", offer);
      break;
    case "download-terms":
      emit("download-terms");
      break;
    default:
      console.warn(`Unknown action: ${action}`);
  }
};
</script>

<style lang="scss" scoped>
.service-offer-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.state-section {
  display: flex;
  flex-direction: column;
}
</style>
