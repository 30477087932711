import { UserApi, UserApiEndpoints } from "@/config/api/user/userApi";
import { executeApiRequest } from "@/services/api/apiService";
import { SupplierUser } from "@/stores/supplierUsers/types";
import {
  EditUserDTO,
  UserDTO,
  CreateUserDTO,
} from "@/custom-types/GeneralTypes";
import { useToastErrorHandler } from "@/composables/useToastErrorHandler";

const useUserApiService = () => {
  const { withToastErrorHandling } = useToastErrorHandler();

  const fetchSupplierUsers = async (supplierId: number) => {
    return withToastErrorHandling(
      async () => {
        const response = await executeApiRequest<SupplierUser[]>(
          {
            endpointKey: Symbol.for(UserApiEndpoints.FETCH_SUPPLIER_USERS),
            config: UserApi,
          },
          {
            pathParams: [supplierId],
          },
        );
        return response;
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Brukerfeil",
          detail: "Kunne ikke hente leverandørbrukere",
        },
      },
    );
  };

  const fetchCustomerByEmail = async (email: string) => {
    return withToastErrorHandling(
      async () => {
        const response = await executeApiRequest<SupplierUser>(
          {
            endpointKey: Symbol.for(UserApiEndpoints.GET_USER_BY_EMAIL),
            config: UserApi,
          },
          {
            pathParams: [email],
          },
        );
        return response;
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Brukerfeil",
          detail: "Kunne ikke hente bruker via e-post",
        },
      },
    );
  };

  const getUserInfo = async (userId: string) => {
    return withToastErrorHandling(
      async () => {
        const response = await executeApiRequest<UserDTO>(
          {
            endpointKey: Symbol.for(UserApiEndpoints.GET_USER_INFO),
            config: UserApi,
          },
          {
            pathParams: [userId],
          },
        );
        return response;
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Brukerfeil",
          detail: "Kunne ikke hente brukerinformasjon",
        },
      },
    );
  };

  const editUserInfo = async (data: EditUserDTO) => {
    return withToastErrorHandling(
      async () => {
        const response = await executeApiRequest<string>(
          {
            endpointKey: Symbol.for(UserApiEndpoints.EDIT_USER_INFO),
            config: UserApi,
          },
          {
            data: data,
          },
        );
        return response;
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Brukerfeil",
          detail: "Kunne ikke oppdatere brukerinformasjon",
        },
        successOptions: {
          severity: "success",
          summary: "Vellykket",
          detail: "Brukerinformasjon ble oppdatert",
        },
      },
    );
  };

  const createUser = async (data: CreateUserDTO) => {
    return withToastErrorHandling(
      async () => {
        const response = await executeApiRequest<string>(
          {
            endpointKey: Symbol.for(UserApiEndpoints.CREATE_USER),
            config: UserApi,
          },
          {
            data: data,
          },
        );
        return response;
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Brukerfeil",
          detail: "Kunne ikke opprette bruker",
        },
        successOptions: {
          severity: "success",
          summary: "Vellykket",
          detail: "Bruker ble opprettet",
        },
      },
    );
  };

  return {
    fetchSupplierUsers,
    fetchCustomerByEmail,
    getUserInfo,
    editUserInfo,
    createUser,
  };
};

export const useUsersApi = useUserApiService;
