import { ref, computed } from "vue";
import type {
  Tender,
  PriceRequestDetails,
  AgreementAcceptance,
} from "@/stores/tender/types";
import FeedbackPanel from "@/components/FeedbackPanel/FeedbackPanel.vue";
import { useSidePanelStore } from "@/stores/sidePanel/sidePanel";
import { useTenderApi } from "@/services/api/useTenderApi";
import { useCms } from "@/composables/useCms";
import { ContentType } from "@/custom-types/CmsContentTypes";
import { createCookieCache } from "@/utilities/cookieUtils";

interface Props {
  tender: Tender;
  priceRequestDetails: PriceRequestDetails | null;
  supplierId: number;
}

interface AcceptanceReasonCms {
  id: number;
  reason: string;
  text: string;
}

export const usePriceRequestFeedback = (props: Props) => {
  const { getAgreementAcceptance } = useTenderApi();
  const { fetchCmsContent } = useCms();

  const feedback = ref<AgreementAcceptance | null>(null);
  const acceptanceReasons = ref<AcceptanceReasonCms[]>([]);

  // Initialize the cookie-based cache for agreement acceptance data
  const agreementCache = createCookieCache(
    `agreement_acc_${props.supplierId}_${props.tender.tenderState}`,
    0.0833,
  );

  const fetchAcceptanceReasonsCms = async () => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: any = await fetchCmsContent(
        ContentType.OFFER_ACCEPTANCE_REASONS_CMS,
      );

      if (response) {
        const reasonsList = response.pageSections?.find(
          (section: { label: string }) =>
            section.label === "acceptanceReasonsList",
        )?.otherText;

        acceptanceReasons.value =
          reasonsList?.map(
            (item: { label: string; title: string }, index: number) => ({
              id: index,
              reason: item.label,
              text: item.title,
            }),
          ) || [];
      }
    } catch (error) {
      console.error("Error fetching offer acceptance reasons:", error);
    }
  };

  const fetchAcceptanceData = async () => {
    if (!props.priceRequestDetails?.priceRequestId) return;

    const priceRequestId = props.priceRequestDetails.priceRequestId;

    // Check cache first to avoid duplicate API calls
    if (agreementCache.isDataCached(priceRequestId)) {
      feedback.value =
        agreementCache.getFromCache<AgreementAcceptance>(priceRequestId);
      // Still fetch the reasons from CMS as they might have changed
      await fetchAcceptanceReasonsCms();
      return;
    }

    try {
      // Fetch agreement acceptance data and CMS reasons in parallel
      const acceptancePromise = getAgreementAcceptance({
        supplierId: props.supplierId,
        priceRequestId,
      });

      await Promise.all([
        acceptancePromise.then((response) => {
          if (response) {
            feedback.value = response.data;
            // Cache the response for future requests
            agreementCache.saveToCache(priceRequestId, response.data);
          }
        }),
        fetchAcceptanceReasonsCms(),
      ]);
    } catch (error) {
      console.error("Failed to fetch acceptance data:", error);
    }
  };

  const selectedReasons = computed(() =>
    acceptanceReasons.value.filter((reason) =>
      feedback.value?.acceptanceReason.includes(reason.reason),
    ),
  );

  // Determine if the feedback notification should be shown
  const shouldShowFeedbackNotification = computed(() => {
    if (!feedback.value) return false;

    // Show notification if there's a comment
    if (feedback.value.acceptanceComment) return true;

    // Show notification if there are any acceptance reasons
    if (feedback.value.acceptanceReason) {
      if (Array.isArray(feedback.value.acceptanceReason)) {
        return feedback.value.acceptanceReason.length > 0;
      }
      return !!feedback.value.acceptanceReason;
    }

    return false;
  });

  const publicActorName = computed(() => props.tender.customerOrganizationName);

  // Open the feedback panel with the agreement acceptance feedback
  const openFeedbackPanel = (isAccepted: boolean) => {
    const sidePanelStore = useSidePanelStore();
    sidePanelStore.openPanel("Tilbakemelding", FeedbackPanel, {
      title: "Tilbakemelding",
      publicActorName: publicActorName.value,
      feedback: {
        reasons: selectedReasons.value,
        comment: feedback.value?.acceptanceComment || "",
        competingBids: feedback.value?.competingBids || [],
      },
      isPublicActor: feedback.value?.customerIsPublicActor,
      isAccepted: isAccepted,
    });
  };

  return {
    feedback,
    acceptanceReasons,
    shouldShowFeedbackNotification,
    fetchAcceptanceData,
    openFeedbackPanel,
  };
};
