<template>
  <div ref="menuContainer" class="service-offer-menu">
    <button
      class="service-offer-menu__btn"
      aria-label="Open menu"
      :aria-expanded="showMenu"
      @click="toggleShowMenu"
    >
      <img src="/images/24/alternatives.svg" alt="Menu icon" />
    </button>
    <div v-if="showMenu" class="service-offer-menu__dropdown">
      <base-menu
        :items="availableMenuOptions"
        @item-clicked="handleMenuItemClick"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { onClickOutside } from "@vueuse/core";
import BaseMenu from "@/components/Base/BaseMenu/BaseMenu.vue";
import { uuidv4 } from "@/utilities/uuid";
import { OfferStateType } from "@/custom-types/GeneralTypes";
import type { ServiceOffer } from "@/custom-types/GeneralTypes";
import type { Tender } from "@/stores/tender/types";
import { useTenderState } from "@/composables/useTenderState";

interface MenuItem {
  id: string;
  text: string;
  eventName: string;
}

interface MenuAction {
  text: string;
  eventName: string;
  visibleIn: OfferStateType[];
}

type ModificationType = "remove" | "replace";

interface BaseModification {
  action: ModificationType;
  text: string;
}

interface RemoveModification extends BaseModification {
  action: "remove";
}

interface ReplaceModification extends BaseModification {
  action: "replace";
  replaceWith: string;
  eventName: string;
}

type Modification = RemoveModification | ReplaceModification;

interface Props {
  offer: ServiceOffer;
  tender: Tender;
  supplierId: number;
}

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: "item-action", action: string): void;
}>();

const { isTenderCompleted, isTenderLost } = useTenderState({
  tender: props.tender,
  supplierId: props.supplierId,
});

const showMenu = ref(false);
const menuContainer = ref<HTMLElement | null>(null);

// Setup click outside handler
onClickOutside(menuContainer, () => {
  showMenu.value = false;
});

// Base menu configuration
const menuConfig: MenuAction[] = [
  {
    text: "Se tilbud",
    eventName: "view-offer",
    visibleIn: [
      OfferStateType.OFFERED,
      OfferStateType.WITHDRAWN,
      OfferStateType.REJECTED,
      OfferStateType.LOST,
    ],
  },
  {
    text: "Send til kunde",
    eventName: "send-to-customer",
    visibleIn: [OfferStateType.DRAFT, OfferStateType.WITHDRAWN],
  },
  {
    text: "Endre",
    eventName: "edit",
    visibleIn: [OfferStateType.DRAFT],
  },
  {
    text: "Lag kopi",
    eventName: "copy",
    visibleIn: [
      OfferStateType.DRAFT,
      OfferStateType.OFFERED,
      OfferStateType.WITHDRAWN,
      OfferStateType.REJECTED,
    ],
  },
  {
    text: "Slett",
    eventName: "delete-draft",
    visibleIn: [OfferStateType.DRAFT],
  },
  {
    text: "Trekk tilbake",
    eventName: "revoke-offer",
    visibleIn: [OfferStateType.OFFERED],
  },
  {
    text: "Se avtale",
    eventName: "view-offer",
    visibleIn: [OfferStateType.ACCEPTED, OfferStateType.COMPLETED],
  },
  {
    text: "Last ned avtale",
    eventName: "see-agreement",
    visibleIn: [OfferStateType.ACCEPTED, OfferStateType.COMPLETED],
  },
];

const stateHandlers: Record<OfferStateType, (options: MenuItem[]) => void> = {
  [OfferStateType.DRAFT]: (options) =>
    handleStateModifications(options, [
      { action: "remove", text: "Se tilbud" },
      { action: "remove", text: "Trekk tilbake" },
      { action: "remove", text: "Se avtale" },
    ]),
  [OfferStateType.OFFERED]: (options) =>
    handleStateModifications(options, [
      { action: "remove", text: "Send til kunde" },
      { action: "remove", text: "Slett" },
    ]),
  [OfferStateType.WITHDRAWN]: (options) =>
    handleStateModifications(options, [{ action: "remove", text: "Slett" }]),
  [OfferStateType.ACCEPTED]: (options) =>
    handleStateModifications(options, [
      { action: "remove", text: "Send til kunde" },
      { action: "remove", text: "Endre" },
      { action: "remove", text: "Lag kopi" },
    ]),
  [OfferStateType.COMPLETED]: (options) =>
    handleStateModifications(options, [
      { action: "remove", text: "Send til kunde" },
      { action: "remove", text: "Endre" },
      { action: "remove", text: "Lag kopi" },
    ]),
  [OfferStateType.REJECTED]: (options) =>
    handleStateModifications(options, [
      { action: "remove", text: "Slett" },
      {
        action: "replace",
        text: "Send til kunde",
        replaceWith: "Se tilbud",
        eventName: "view-offer",
      },
    ]),
  [OfferStateType.LOST]: (options) => {
    // Keep only "Se tilbud" option for LOST state
    const seTilbudOption = options.find((item) => item.text === "Se tilbud");
    options.length = 0;
    if (seTilbudOption) {
      options.push(seTilbudOption);
    } else {
      options.push(createMenuItem("Se tilbud", "view-offer"));
    }
  },
};

const handleStateModifications = (
  options: MenuItem[],
  modifications: Modification[],
) => {
  modifications.forEach((mod) => {
    const index = options.findIndex((item) => item.text === mod.text);
    if (index === -1) return;

    if (mod.action === "remove") {
      options.splice(index, 1);
    } else if (
      mod.action === "replace" &&
      "replaceWith" in mod &&
      "eventName" in mod
    ) {
      options[index] = createMenuItem(mod.replaceWith, mod.eventName);
    }
  });
};

const isOfferReadyForCustomer = computed(() => {
  return props.offer.readyForCustomer;
});

const availableMenuOptions = computed(() => {
  const currentState = props.offer.currentState.toLowerCase() as OfferStateType;

  // Special case: If the tender is completed or lost, only show "Se tilbud"
  if (
    isTenderCompleted.value ||
    isTenderLost.value ||
    currentState === OfferStateType.LOST
  ) {
    return [createMenuItem("Se tilbud", "view-offer")];
  }

  const options = menuConfig
    .filter((item) => {
      if (item.text === "Send til kunde" && !isOfferReadyForCustomer.value) {
        return false;
      }
      return item.visibleIn.includes(currentState);
    })
    .map((item) => createMenuItem(item.text, item.eventName));

  const handler = stateHandlers[currentState];
  if (handler) {
    handler(options);
  }

  return options;
});

const createMenuItem = (text: string, eventName: string): MenuItem => ({
  id: uuidv4(),
  text,
  eventName,
});

// Event handlers
const toggleShowMenu = () => {
  showMenu.value = !showMenu.value;
};

const handleMenuItemClick = (item: MenuItem) => {
  emit("item-action", item.eventName);
  showMenu.value = false;
};
</script>

<style lang="scss" scoped>
.service-offer-menu {
  display: inline-block;
  position: relative;

  &__btn {
    background: #fff;
    border: 1px solid $color-primary-pink-darker;
    border-radius: 4px;
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition:
      box-shadow 0.3s ease,
      transform 0.3s ease;
    cursor: pointer;

    &:hover {
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      transform: translateY(-2px);
    }

    &:focus {
      outline: 2px solid $color-primary-pink-darker;
      outline-offset: 2px;
    }
  }

  &__dropdown {
    position: absolute;
    right: 0;
    width: 140px;
    top: 100%;
    z-index: 999;
    text-align: left;
    background: #fff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin-top: 4px;
  }
}
</style>
