<template>
  <div>
    <div
      v-for="(listSection, index) in tendersList"
      :key="index"
      class="tenders-list__section"
    >
      <template v-if="!loading">
        <base-list v-if="listSection.tenders.length">
          <supplier-tender-list-item
            v-for="tender in listSection.tenders"
            :key="tender.tenderId"
            :service-types-cms="serviceTypesCms"
            :tender="tender"
            :price-request-id="tender.priceRequests[0]?.id ?? 0"
            :collapsed="isTenderCollapsed(tender)"
            :list-item-id="'row-' + (tender.priceRequests[0]?.id ?? 0)"
            :supplier-users="supplierUsers"
            :supplier-id="supplierId"
            :show-new-request-widget="shouldShowNewRequestWidget(tender)"
            :show-unread-messages-widget="
              shouldShowUnreadMessagesWidget(tender)
            "
            :show-unread-messages-badge="shouldShowMessageBadge(tender)"
            :show-customer-chose-another-supplier-widget="
              shouldShowCustomerChoseAnotherSupplierWidget(tender)
            "
            :show-new-agreement-widget="shouldShowNewAgreementWidget(tender)"
            @clicked="toggleShowTenderDetails(tender)"
          >
            <template #collapseContent>
              <tender-detail
                v-if="!isTenderCollapsed(tender)"
                :tender="tender"
                :supplier-id="supplierId"
                :supplier-users="supplierUsers"
                @scroll-to-row="handleScrollToRow"
                @reload-tender-list="handleReloadTenderList"
              />
            </template>
          </supplier-tender-list-item>
        </base-list>
        <div v-else class="tenders-list__no-tenders">
          {{ noOffersText }}
        </div>
      </template>
      <div v-else class="tenders-list__loader">
        <b-spinner class="table-spinner align-middle" type="grow" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, watch, inject } from "vue";
import { useRoute, useRouter } from "vue-router";
import TenderDetail from "./TenderDetail.vue";
import {
  SupplierTenderListItem,
  BaseList,
} from "@really/really-tender-components";
import { useTenderScroll } from "@/composables/useTenderScroll";
import type { Tender } from "@/stores/tender/types";
import { useFeedbackCookie } from "@/utilities/useFeedbackCookie";
import {
  useTenderWidgets,
  ACTIVITY_STATS_KEY,
} from "@/composables/useTenderWidgets";
import type { ActivityStats } from "@/stores/communication/types";
import { useSupplierUsersStore } from "@/stores/supplierUsers";
import { useCmsStore } from "@/stores/cms";

interface TenderListSection {
  title: string;
  tenders: Tender[];
}

interface Props {
  tendersList: TenderListSection[];
  loading: boolean;
  supplierId: number;
}

const props = defineProps<Props>();

const emit = defineEmits(["reload-tender-list", "agreement-click"]);

const cmsStore = useCmsStore();
const route = useRoute();
const router = useRouter();

const supplierUsersStore = useSupplierUsersStore();
const supplierUsers = computed(() => supplierUsersStore.getSupplierUsers);

const { scrollToRow, handleScrollToRow } = useTenderScroll(route);
const {
  shouldShowNewRequestWidget,
  shouldShowUnreadMessagesWidget,
  shouldShowMessageBadge,
  shouldShowCustomerChoseAnotherSupplierWidget,
  shouldShowNewAgreementWidget,
} = useTenderWidgets();

const { setNewRequestViewed, setLostRequestViewed, setNewAgreementViewed } =
  useFeedbackCookie();

const showTenderDetails = ref<number[]>([]);
const noOffersText = ref("Ingen tilbud funnet");

const serviceTypesCms = computed(() => cmsStore.serviceTypesCms);

// Inject the activity stats from the parent component
inject(ACTIVITY_STATS_KEY, ref<ActivityStats[]>([]));

onMounted(() => {
  const priceRequestId = parseInt(route.query.priceRequestId as string);
  if (priceRequestId) {
    showTenderDetails.value.push(priceRequestId);
  }
  initializeRowScroll();
});

const initializeRowScroll = (): void => {
  watch(
    () => props.loading,
    (newVal) => {
      if (!newVal) {
        setupSelectedTenderWatcher();
      }
    },
    { immediate: true },
  );
};

const setupSelectedTenderWatcher = (): void => {
  watch(
    route,
    (newRoute) => {
      const priceRequestId = parseInt(newRoute.query.priceRequestId as string);
      if (!priceRequestId) return;

      if (!showTenderDetails.value.includes(priceRequestId)) {
        showTenderDetails.value.push(priceRequestId);
      }
      scrollToRow(priceRequestId);
    },
    { immediate: true, deep: true },
  );
};

const toggleShowTenderDetails = (tender: Tender): void => {
  const priceRequest = tender.priceRequests[0];
  if (!priceRequest) return;
  const supplierRequestId = priceRequest.supplierRequests[0]?.id;
  if (!supplierRequestId) return;

  const matchingIndex = showTenderDetails.value.findIndex(
    (priceRequestId) => priceRequestId === priceRequest.id,
  );

  if (matchingIndex >= 0) {
    showTenderDetails.value.splice(matchingIndex, 1);
    router.replace({
      query: {
        tabList: route.query.tabList,
      },
    });
  } else {
    showTenderDetails.value.push(priceRequest.id);
    router.replace({
      query: {
        ...route.query,
        priceRequestId: priceRequest.id.toString(),
      },
    });

    // Mark as viewed when opening the tender details
    const widgetStates = getWidgetStates(tender);

    if (widgetStates.newRequest) {
      setNewRequestViewed(priceRequest.id);
    }
    if (widgetStates.customerChoseAnother) {
      setLostRequestViewed(priceRequest.id);
    }
    if (widgetStates.newAgreement) {
      setNewAgreementViewed(priceRequest.id);
      // Emit agreement-click event for active services and projects
      emit("agreement-click", priceRequest.id);
    }
  }
};

const getWidgetStates = (tender: Tender) => ({
  newRequest: shouldShowNewRequestWidget(tender),
  customerChoseAnother: shouldShowCustomerChoseAnotherSupplierWidget(tender),
  newAgreement: shouldShowNewAgreementWidget(tender),
});

const isTenderCollapsed = (tender: Tender): boolean => {
  return !showTenderDetails.value.includes(tender.priceRequests[0]?.id ?? 0);
};

const handleReloadTenderList = () => {
  emit("reload-tender-list");
};
</script>

<style lang="scss" scoped>
.tenders-list {
  &__title {
    color: $color-primary-dark;
    text-transform: uppercase;
    text-align: left;
    font-weight: 800;
    margin-bottom: 1rem;
    font-size: 18px;
  }

  &__no-tenders {
    text-align: center;
    color: $color-primary-darker;
    font-weight: 700;
    border-bottom: 2px solid $color-primary;
    padding: 0.75rem;
  }

  &__loader {
    display: flex;
    justify-content: center;
    border-bottom: 2px solid $color-primary;
    padding: 0.75rem;
    color: $color-primary;
  }
}
</style>
