import {
  ActivityFrequency,
  ServiceActivityCmsLabelType,
  BillingCycle,
} from "@/custom-types/GeneralTypes";

interface FrequencyCms {
  label: string;
  title: string;
}

interface ServiceActivityCms {
  title: string;
  label: string;
  frequencies?: FrequencyCms[];
}

interface Frequency {
  label: string;
  value?: string;
}

const billingCycleToActivityFrequency = (
  billingCycle: BillingCycle,
): ActivityFrequency => {
  const mapping: Record<BillingCycle, ActivityFrequency> = {
    [BillingCycle.MONTHLY]: ActivityFrequency.MONTHLY,
    [BillingCycle.YEARLY]: ActivityFrequency.YEARLY,
    [BillingCycle.FIXED]: ActivityFrequency.FIXED,
  };
  return mapping[billingCycle];
};

const resolveFrequency = (
  serviceActivityCms: ServiceActivityCms | undefined,
  frequencyGroup: ActivityFrequency | BillingCycle | null,
  frequency: string,
): string => {
  if (!serviceActivityCms || !frequencyGroup) return "";

  const activityFrequency =
    typeof frequencyGroup === "string"
      ? (frequencyGroup as ActivityFrequency)
      : billingCycleToActivityFrequency(frequencyGroup);

  if (activityFrequency === ActivityFrequency.FIXED) {
    return serviceActivityCms.title;
  }

  const frequencyCms = serviceActivityCms.frequencies?.find(
    (cms) => cms.label.toLowerCase() === frequency.toLowerCase(),
  );
  return frequencyCms?.title ?? frequency;
};

const serviceActivityCms = (
  serviceActivitiesCms: ServiceActivityCms[] | null | undefined,
  frequencyGroup: ActivityFrequency | BillingCycle,
): ServiceActivityCms | undefined => {
  if (!serviceActivitiesCms) return undefined;

  const activityFrequency =
    typeof frequencyGroup === "string"
      ? (frequencyGroup as ActivityFrequency)
      : billingCycleToActivityFrequency(frequencyGroup);

  const cmsLabelMapping: Record<
    ActivityFrequency,
    ServiceActivityCmsLabelType
  > = {
    [ActivityFrequency.MONTHLY]: ServiceActivityCmsLabelType.MONTHLY,
    [ActivityFrequency.YEARLY]: ServiceActivityCmsLabelType.YEARLY,
    [ActivityFrequency.FIXED]: ServiceActivityCmsLabelType.FIXED,
  };

  const cmsLabel = cmsLabelMapping[activityFrequency];

  // First try exact match
  let match = serviceActivitiesCms.find((item) => item.label === cmsLabel);

  // If no exact match, try case-insensitive match
  if (!match) {
    match = serviceActivitiesCms.find(
      (item) => item.title.toLowerCase() === cmsLabel.toLowerCase(),
    );
  }

  return match;
};

const getAugmentedActivityFrequencies = (
  frequencies: Frequency[],
  frequenciesCms: FrequencyCms[],
): Frequency[] => {
  const augmentedFrequencies: Frequency[] = [];

  frequenciesCms.forEach((frequencyCms) => {
    const frequency = frequencies.find(
      (frequency) =>
        frequency.label.toLowerCase() === frequencyCms.label.toLowerCase(),
    );
    if (frequency) {
      augmentedFrequencies.push({
        label: frequencyCms.title,
        value: frequency.label,
      });
    }
  });

  frequencies.forEach((frequency) => {
    const frequencyCms = frequenciesCms.find(
      (frequencyCms) =>
        frequencyCms.label.toLowerCase() === frequency.label.toLowerCase(),
    );
    if (!frequencyCms) {
      augmentedFrequencies.push({
        label: frequency.label,
        value: frequency.label,
      });
    }
  });

  return augmentedFrequencies;
};

export {
  resolveFrequency,
  serviceActivityCms,
  getAugmentedActivityFrequencies,
};
