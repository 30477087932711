import { ref } from "vue";

export const usePriceRequestHandling = () => {
  const showRejectionModal = ref(false);
  const showRejectionSuccessModal = ref(false);
  const showCreateRequestModal = ref(false);
  const showCompleteOfferModal = ref(false);

  const handleCreatePriceRequest = () => {
    showCreateRequestModal.value = true;
  };

  const handleRejectPriceRequest = () => {
    showRejectionModal.value = true;
  };

  const handleMarkAsDone = () => {
    showCompleteOfferModal.value = true;
  };

  const handlePriceRejected = () => {
    showRejectionModal.value = false;
    showRejectionSuccessModal.value = true;
  };

  const handleOfferCompleted = () => {
    showCompleteOfferModal.value = false;
  };

  return {
    showRejectionModal,
    showRejectionSuccessModal,
    showCreateRequestModal,
    showCompleteOfferModal,
    handleCreatePriceRequest,
    handleRejectPriceRequest,
    handleMarkAsDone,
    handlePriceRejected,
    handleOfferCompleted,
  };
};
