<template>
  <div
    v-if="state.pageIsReady && supplierProfileCmsContent"
    class="supplier-profile-grid"
  >
    <header class="supplier-profile-grid__header">
      {{ supplierProfileCmsContent.title }}
    </header>

    <main class="supplier-profile-grid__main-content">
      <profile-section
        v-for="(section, index) in mainSections"
        :key="sectionUniqueIds[index]"
        v-bind="getProfileSectionProps(section)"
        :editable="isSectionEditable(section)"
        @edit="handleSectionEdit(section?.label ?? '')"
      >
        <component
          :is="getSectionComponentToRender(section)"
          :is-saving="isSaving(section?.label ?? '')"
          :is-editing="isSectionEditable(section)"
          v-bind="getProfileSectionComponentProps(section)"
          @edit="handleSectionEdit(section?.label ?? '')"
          @add="handleSectionAdd(section?.label ?? '')"
          @save="handleSectionSave(section?.label ?? '')"
          @cancel="handleSectionCancel(section?.label ?? '')"
          @update:modelValue="
            handleUpdateProfileSectionFormData(
              section?.label ?? '',
              $event as string,
            )
          "
          @save-supplier-address="handleSaveSupplierAddress"
        />
      </profile-section>
    </main>

    <aside class="supplier-profile-grid__sidebar">
      <company-logo-upload
        v-bind="getCompanyLogoUploadProps"
        :supplier-id="supplierProfileData.id"
        @update:logo="handleLogoUpdate"
      />

      <company-profile-section
        v-for="(input, index) in sidebarInputs"
        :key="index"
        v-bind="getCompanyProfileSectionProps(input)"
        :editable="isInputEditable(input)"
        @edit="handleInputEdit(input?.label ?? '')"
      >
        <component
          :is="getCompanyProfileComponentToRender(input)"
          :is-saving="isSaving(input?.label ?? '')"
          v-bind="getCompanyProfileComponentProps(input)"
          @save="handleInputSave(input?.label ?? '')"
          @cancel="handleInputCancel(input?.label ?? '')"
          @add="handleInputAdd(input?.label ?? '')"
          @update:modelValue="
            handleUpdateCompanyProfileFormData(
              input?.label ?? '',
              $event as string,
            )
          "
        />
      </company-profile-section>
    </aside>

    <service-categories-modal
      v-model="state.showServiceCategoriesModal"
      :is-saving="isSaving('myServicesSection')"
      v-bind="getServiceCategoriesProps()"
      @save="handleServiceCategoriesModalSave"
      @cancel="handleServiceCategoriesCancel()"
    />
    <sustainability-goals-modal
      v-model="state.showSustainabilityGoalsModal"
      :is-saving="isSaving('sustainabilityGoalsSection')"
      v-bind="getSustainabilityGoalsProps()"
      @save="handleSustainabilityGoalsSave"
      @cancel="handleSustainabilityGoalsCancel()"
    />
    <certifications-approvals-modal
      v-model="state.showCertificationsApprovalsModal"
      :is-saving="isSaving('certificationsApprovalsSection')"
      v-bind="getCertificationsApprovalsProps()"
      @save="handleCertificationsApprovalsSave"
      @cancel="handleCertificationsApprovalsCancel()"
    />
  </div>
  <div v-else>
    <supplier-profile-skeleton />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive } from "vue";
import SupplierProfileSkeleton from "@/components/SupplierProfile/SupplierProfileSkeleton.vue";

import CompanyProfileSection from "@/components/SupplierProfile/CompanyProfileSection.vue";
import ProfileSection from "@/components/SupplierProfile/ProfileSection.vue";
import CompanyLogoUpload from "@/components/SupplierProfile/CompanyLogoUpload.vue";
import ProfileInput from "@/components/SupplierProfile/ProfileInput.vue";
import DisplayValue from "@/components/SupplierProfile/DisplayValue.vue";
import DefaultCompanyProfileSection from "@/components/SupplierProfile/DefaultCompanyProfileSection.vue";
import DefaultProfileSection from "@/components/SupplierProfile/DefaultProfileSection.vue";

import CompanyDescriptionEditableSection from "@/components/SupplierProfile/CompanyDescriptionEditableSection.vue";
import ServiceCategoriesDisplaySection from "@/components/SupplierProfile/ServiceCategoriesDisplaySection.vue";
import LocationDeliveryAreaDisplaySection from "@/components/SupplierProfile/LocationDeliveryAreaDisplaySection.vue";
import CompanyDescriptionDisplaySection from "@/components/SupplierProfile/CompanyDescriptionDisplaySection.vue";
import CertificationsApprovalsDisplaySection from "@/components/SupplierProfile/CertificationsApprovalsDisplaySection.vue";
import SustainabilityGoalsDisplaySection from "@/components/SupplierProfile/SustainabilityGoalsDisplaySection.vue";

import SustainabilityGoalsModal from "@/components/SupplierProfile/SustainabilityGoalsModal.vue";
import CertificationsApprovalsModal from "@/components/SupplierProfile/CertificationsApprovalsModal.vue";
import ServiceCategoriesModal from "@/components/SupplierProfile/ServiceCategoriesModal.vue";
import LocationDeliveryAreaEditableSection from "@/components/SupplierProfile/LocationDeliveryAreaEditableSection.vue";

import { ContentType } from "@/custom-types/CmsContentTypes";
import {
  Button,
  Form,
  ExtraText,
  Icon,
  Input,
  PageSection,
  SustainabilityGoals,
  Certifications,
} from "@/stores/cms/types";
import cmsService from "@/services/cms/cmsService";
import { useCmsStore } from "@/stores/cms";
import { useUserStore } from "@/stores/user";
import { useSupplierStore } from "@/stores/supplier";

import { useSupplierProfileActions } from "@/composables/useSupplierProfileActions";
import { useSupplierProfileData } from "@/composables/useSupplierProfileData";
import { useCompanyProfileFormData } from "@/composables/useCompanyProfileFormData";
import { useProfileSectionData } from "@/composables/useProfileSectionData";

import { useSupplierProfileSavingState } from "@/composables/useSupplierProfileSavingState";
import {
  Certification,
  ServiceCategory,
  SupplierAddress,
  SustainabilityGoal,
} from "@/stores/supplier/types";

import { useSupplierApi } from "@/services/api/useSupplierApi";
import { useSystemApi } from "@/services/api/useSystemApi";

interface ServiceCategoryWithoutSomeProps
  extends Omit<ServiceCategory, "supplierId" | "selected"> {}

const { getServiceCategories, getCertifications, getSustainabilityGoals } =
  useSystemApi();
//getServiceTypeCategories,
const {
  getSupplierCertifications,
  getSupplierSustainabilityGoals,
  getSupplierAddresses,
  getSupplierServiceCategories,
} = useSupplierApi();

const { isSaving, startSaving, finishSaving } = useSupplierProfileSavingState();

const { saveSupplierProfile } = useSupplierProfileActions();

const { supplierProfileData, updateSupplierProfileData } =
  useSupplierProfileData();
const { updateCompanyProfileFormData, getCompanyProfileFormData } =
  useCompanyProfileFormData();
const { profileSectionData, updateProfileSectionData, getProfileSectionData } =
  useProfileSectionData();

const cmsStore = useCmsStore();
const supplierStore = useSupplierStore();
const userStore = useUserStore();

const editableCompanyProfileInputFlags = reactive(new Map<string, boolean>());
const editableProfileSectionFlags = reactive(new Map<string, boolean>());

const state = reactive({
  showSustainabilityGoalsModal: false,
  showCertificationsApprovalsModal: false,
  showServiceCategoriesModal: false,
  pageIsReady: false,
  serviceCategories: [] as ServiceCategoryWithoutSomeProps[],
  allCertifications: [] as Certification[],
  allSustainabilityGoals: [] as SustainabilityGoal[],
});

const handleServiceCategoriesCancel = () => {
  state.showServiceCategoriesModal = false;
};

const handleSustainabilityGoalsCancel = () => {
  state.showSustainabilityGoalsModal = false;
};

const handleCertificationsApprovalsCancel = () => {
  state.showCertificationsApprovalsModal = false;
};

const handleUpdateProfileSectionFormData = (key: string, value: string) => {
  updateProfileSectionData(key, value);
};

const handleUpdateCompanyProfileFormData = (key: string, value: string) => {
  updateCompanyProfileFormData(key, value);
};

const updateSupplierProfileDataByInputLabel = (inputLabel: string) => {
  const inputValue = getCompanyProfileFormData(inputLabel);
  switch (inputLabel) {
    case "orgNumberInput":
      updateSupplierProfileData({
        orgNumber: inputValue ?? "",
      });
      break;
    case "contactPersonInput":
      updateSupplierProfileData({
        contactPerson: inputValue,
      });
      break;
    case "emailInput":
      updateSupplierProfileData({
        email: inputValue,
      });
      break;
    case "companyTurnoverInput":
      updateSupplierProfileData({
        revenue: Number(inputValue),
      });
      break;
    case "companyEmployeeNumberInput":
      updateSupplierProfileData({
        numEmployees: Number(inputValue),
      });
      break;
    default:
      break;
  }
};

const updateSupplierProfileDataBySectionLabel = (sectionLabel: string) => {
  const sectionValue = getProfileSectionData(sectionLabel);
  switch (sectionLabel) {
    case "companyNameSection":
      updateSupplierProfileData({
        sysName: sectionValue,
      });
      break;
    case "companyDescriptionSection":
      updateSupplierProfileData({
        description: sectionValue,
      });
      break;
    default:
      break;
  }
};

const handleLogoUpdate = async (logo: string) => {
  try {
    await updateSupplierProfileData({ logo: logo });
    // Update the supplier store directly to ensure reactivity across components
    supplierStore.updateSupplier({ logo: logo });
  } catch (error) {
    console.error("Failed to update supplier logo:", error);
    // TODO: Handle the error, show an error message, or perform any necessary actions
  }
};

const handleInputSave = async (inputLabel: string) => {
  try {
    startSaving(inputLabel);
    updateSupplierProfileDataByInputLabel(inputLabel);
    await saveSupplierProfile(supplierProfileData.value);
    toggleEditState(inputLabel);
  } catch (error) {
    console.error("Failed to save supplier profile:", error);
    // TODO: Handle the error, show an error message, or perform any necessary actions
  } finally {
    finishSaving(inputLabel);
  }
};

const handleSectionSave = async (sectionLabel: string) => {
  try {
    startSaving(sectionLabel);
    updateSupplierProfileDataBySectionLabel(sectionLabel);
    const response = await saveSupplierProfile(supplierProfileData.value);

    // Update supplier organization name when company name is changed
    if (sectionLabel === "companyNameSection" && response) {
      const newName = getProfileSectionData(sectionLabel);
      if (newName) {
        supplierStore.updateSupplier({ sysName: newName });
      }
    }

    rerenderSection(sectionLabel);
    toggleSectionEditState(sectionLabel);
  } catch (error) {
    console.error("Failed to save supplier profile:", error);
  } finally {
    finishSaving(sectionLabel);
  }
};

const handleServiceCategoriesModalSave = async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  serviceCategories: any,
) => {
  const sectionLabel = "myServicesSection";
  try {
    startSaving(sectionLabel);
    updateSupplierProfileData({
      serviceCategories: serviceCategories,
    });
    updateProfileSectionData(sectionLabel, serviceCategories);
    await saveSupplierProfile(supplierProfileData.value);
    rerenderSection(sectionLabel);
    state.showServiceCategoriesModal = false;
  } catch (error) {
    console.error("Failed to save supplier profile:", error);
  } finally {
    finishSaving(sectionLabel);
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleSustainabilityGoalsSave = async (sustainabilityGoals: any) => {
  const sectionLabel = "sustainabilityGoalsSection";
  try {
    startSaving(sectionLabel);
    updateSupplierProfileData({
      sustainabilityGoals: sustainabilityGoals,
    });
    updateProfileSectionData(sectionLabel, sustainabilityGoals);
    await saveSupplierProfile(supplierProfileData.value);
    rerenderSection(sectionLabel);
    state.showSustainabilityGoalsModal = false;
  } catch (error) {
    console.error("Failed to save supplier profile:", error);
  } finally {
    finishSaving(sectionLabel);
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleCertificationsApprovalsSave = async (certifications: any) => {
  const sectionLabel = "certificationsApprovalsSection";
  try {
    startSaving(sectionLabel);
    updateSupplierProfileData({
      certifications: certifications,
    });
    updateProfileSectionData(sectionLabel, certifications);
    await saveSupplierProfile(supplierProfileData.value);
    rerenderSection(sectionLabel);
    state.showCertificationsApprovalsModal = false;
  } catch (error) {
    console.error("Failed to save supplier profile:", error);
  } finally {
    finishSaving(sectionLabel);
  }
};

const handleSaveSupplierAddress = async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ...args: any[]
) => {
  const sectionLabel = "locationDeliveryAreaSection";

  if (args.length === 1 && typeof args[0] === "object") {
    const { supplierAddresses, stillEditing } = args[0] as {
      supplierAddresses: SupplierAddress[];
      stillEditing: boolean;
    };

    try {
      startSaving(sectionLabel);
      updateProfileSectionData(sectionLabel, supplierAddresses);
      updateSupplierProfileData({
        offices: supplierAddresses,
      });
      await saveSupplierProfile(supplierProfileData.value);
      if (!stillEditing) {
        toggleSectionEditState(sectionLabel);
        rerenderSection(sectionLabel);
      }
    } catch (error) {
      console.error("Failed to save supplier profile:", error);
    } finally {
      finishSaving(sectionLabel);
    }
  }
};

const handleSectionCancel = (sectionLabel: string) => {
  toggleSectionEditState(sectionLabel);
};

const handleInputCancel = (inputLabel: string) => {
  toggleEditState(inputLabel);
};

const handleInputEdit = (inputLabel: string) => {
  toggleEditState(inputLabel);
};

const handleInputAdd = (inputLabel: string) => {
  toggleEditState(inputLabel);
};

const handleSectionEdit = (sectionLabel: string) => {
  switch (sectionLabel) {
    case "companyNameSection":
      toggleSectionEditState(sectionLabel);
      break;
    case "companyDescriptionSection":
      toggleSectionEditState(sectionLabel);
      break;
    case "sustainabilityGoalsSection":
      state.showSustainabilityGoalsModal = !state.showSustainabilityGoalsModal;
      break;
    case "certificationsApprovalsSection":
      state.showCertificationsApprovalsModal =
        !state.showCertificationsApprovalsModal;
      break;
    case "myServicesSection":
      state.showServiceCategoriesModal = !state.showServiceCategoriesModal;
      break;
    default:
      toggleSectionEditState(sectionLabel);
  }
};

const handleSectionAdd = (sectionLabel: string) => {
  switch (sectionLabel) {
    case "companyNameSection":
      toggleSectionEditState(sectionLabel);
      break;
    case "companyDescriptionSection":
      toggleSectionEditState(sectionLabel);
      break;
    case "sustainabilityGoalsSection":
      state.showSustainabilityGoalsModal = !state.showSustainabilityGoalsModal;
      break;
    case "certificationsApprovalsSection":
      state.showCertificationsApprovalsModal =
        !state.showCertificationsApprovalsModal;
      break;
    case "myServicesSection":
      state.showServiceCategoriesModal = !state.showServiceCategoriesModal;
      break;
    default:
      toggleSectionEditState(sectionLabel);
  }
};

const toggleSectionEditState = (sectionLabel: string) => {
  const currentState = editableProfileSectionFlags.get(sectionLabel);
  editableProfileSectionFlags.set(sectionLabel, !currentState);
};

const toggleEditState = (inputLabel: string) => {
  const currentState = editableCompanyProfileInputFlags.get(inputLabel);
  editableCompanyProfileInputFlags.set(inputLabel, !currentState);
};

const initializeEditCompanyProfileInputFlags = (inputs: Input[]) => {
  inputs.forEach((input) => {
    if (input && !editableCompanyProfileInputFlags.has(input?.label ?? "")) {
      editableCompanyProfileInputFlags.set(input?.label ?? "", false);
    }
  });
};

const initializeEditProfileSectionFlags = (sections: PageSection[]) => {
  sections.forEach((section) => {
    if (section && !editableProfileSectionFlags.has(section?.label ?? "")) {
      editableProfileSectionFlags.set(section?.label ?? "", false);
    }
  });
};

const isInputEditable = (input: Input) => {
  return editableCompanyProfileInputFlags.get(input?.label ?? "");
};

const getCompanyProfileInputRightText = (input: Input) => {
  return input?.unit || "";
};

const getCompanyProfileComponentToRender = (input: Input) => {
  const modelValue = getCompanyProfileFormData(input?.label ?? "");
  const isEditable = isInputEditable(input);

  if (isEditable) {
    return ProfileInput;
  } else if (modelValue.length > 0) {
    return DisplayValue;
  } else {
    return DefaultCompanyProfileSection;
  }
};

const getCompanyProfileComponentProps = (input: Input) => {
  const props = {
    modelValue:
      input?.label === "OrgNumberInput"
        ? {
            inputText: getCompanyProfileFormData(input?.label ?? "") as string,
            selectedCompany: null,
          }
        : getCompanyProfileFormData(input?.label ?? ""),
    name: input?.label ?? "",
    placeholder: input?.placeholder ?? "",
    disabled: false,
    type:
      input?.label === "companyTurnoverInput" ||
      input?.label === "companyEmployeeNumberInput"
        ? "number"
        : "text",
    saveButtonText: supplierProfileCmsContent.value?.buttons?.saveButton,
    cancelButtonText: supplierProfileCmsContent.value?.buttons?.cancelButton,
    rightText: getCompanyProfileInputRightText(input),
    editable: isInputEditable(input),
    addButtonText: supplierProfileCmsContent.value?.buttons.addButton,
  };

  return props;
};

const isSectionEditable = (section: PageSection) => {
  return editableProfileSectionFlags.get(section?.label ?? "");
};

const hasSectionData = (section: PageSection) => {
  const profileSectionData = getProfileSectionData(section?.label ?? "");
  if (
    section?.label === "certificationsApprovalsSection" ||
    section?.label === "sustainabilityGoalsSection"
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return profileSectionData.some((item: any) => item.selected);
  }
  if (section?.label === "myServicesSection") {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return profileSectionData.some((item: any) => item);
  }
  return profileSectionData.length > 0;
};

const getEditableComponent = (sectionLabel: string) => {
  switch (sectionLabel) {
    case "companyNameSection":
      return CompanyDescriptionEditableSection;
    case "companyDescriptionSection":
      return CompanyDescriptionEditableSection;
    case "myServicesSection":
      return ServiceCategoriesDisplaySection;
    case "locationDeliveryAreaSection":
      return LocationDeliveryAreaEditableSection;
    case "certificationsApprovalsSection":
      return CertificationsApprovalsDisplaySection;
    case "sustainabilityGoalsSection":
      return SustainabilityGoalsDisplaySection;
    default:
      return DefaultProfileSection;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getSectionComponentToRender = (section: any) => {
  const sectionLabel = section?.label ?? "";
  const isEditable = isSectionEditable(section);
  const hasData = hasSectionData(section);

  if (isEditable && sectionLabel === "companyNameSection") {
    return CompanyDescriptionEditableSection;
  } else if (isEditable && sectionLabel === "companyDescriptionSection") {
    return CompanyDescriptionEditableSection;
  } else if (isEditable && hasData) {
    return getEditableComponent(sectionLabel);
  } else if (isEditable && !hasData) {
    return getEditableComponent(sectionLabel);
  } else if (!isEditable && hasData) {
    return getDisplayComponent(sectionLabel);
  } else {
    return DefaultProfileSection;
  }
};

const getButtonIcon = (buttons: Button[]) => {
  return findIconResolveIconUrl(buttons?.[0]?.icons || [], "addMoreIcon", "");
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getProfileSectionComponentProps = (section: any) => {
  const props = {
    modelValue:
      section?.label === "companyNameSection"
        ? profileSectionData.companyNameSection
        : section?.label === "companyDescriptionSection"
          ? profileSectionData.companyDescriptionSection
          : section?.label,
    name: section?.label ?? "",
    placeholder: section?.placeholder ?? "",
    saveButtonText: supplierProfileCmsContent.value?.buttons.saveButton,
    cancelButtonText: supplierProfileCmsContent.value?.buttons.cancelButton,
    editable: isSectionEditable(section),
    defaultText: getExtraTextTitle(
      section?.otherText || [],
      "completionPromptText",
      "",
    ),
    addButtonText: supplierProfileCmsContent.value?.buttons.addButton,
    certifications: supplierProfileData.value?.certifications || [],
    sustainabilityGoals: supplierProfileData.value?.sustainabilityGoals || [],
    certificationsIcon: findIconResolveIconUrl(
      supplierProfileCmsContent.value?.pageSections
        .certificationsApprovalsSection?.icons || [],
      "categoryIcon",
      "/images/sertifisering.svg",
    ),
    sustainabilityGoalsIcon: findIconResolveIconUrl(
      supplierProfileCmsContent.value?.pageSections.sustainabilityGoalsSection
        ?.icons || [],
      "categoryIcon",
      "/images/goal.svg",
    ),
    serviceCategories: supplierProfileData.value?.serviceCategories || [],
    supplierAddresses: supplierProfileData.value.offices || [],
    locationPlaceholder: "Søk etter adresse",
    deliveryAreaPlaceholder: "0 km",
    locationLeftIconSrc: findIconResolveIconUrl(
      supplierProfileCmsContent.value?.pageSections?.locationDeliveryAreaSection
        ?.icons || [],
      "searchIcon",
      "",
    ),
    deliveryAreaLeftIconSrc: findIconResolveIconUrl(
      supplierProfileCmsContent.value?.pageSections?.locationDeliveryAreaSection
        ?.icons || [],
      "areaIcon",
      "",
    ),
    deleteIconSrc: findIconResolveIconUrl(
      supplierProfileCmsContent.value?.pageSections?.locationDeliveryAreaSection
        ?.icons || [],
      "deleteIcon",
      "",
    ),
    addLocationButtonText: getButtonTitle(
      supplierProfileCmsContent.value?.pageSections?.locationDeliveryAreaSection
        ?.buttons || [],
      "addMoreButton",
      "Legg til flere",
    ),
    addLocationButtonIconSrc: getButtonIcon(
      supplierProfileCmsContent.value?.pageSections?.locationDeliveryAreaSection
        ?.buttons || [],
    ),
    exitIconSrc: findIconResolveIconUrl(
      supplierProfileCmsContent.value?.pageSections?.locationDeliveryAreaSection
        ?.icons || [],
      "exitIcon",
      "",
    ),
    locationIcon: findIconResolveIconUrl(
      supplierProfileCmsContent.value?.pageSections?.locationDeliveryAreaSection
        ?.icons || [],
      "locationIcon",
      "",
    ),
    areaIcon: findIconResolveIconUrl(
      supplierProfileCmsContent.value?.pageSections?.locationDeliveryAreaSection
        ?.icons || [],
      "areaIcon",
      "",
    ),
    locationSubtext: getExtraTextTitle(
      supplierProfileCmsContent.value?.pageSections?.locationDeliveryAreaSection
        ?.otherText || [],
      "locationSubtext",
      "",
    ),
    deliveryAreaSubtext: getExtraTextTitle(
      supplierProfileCmsContent.value?.pageSections?.locationDeliveryAreaSection
        ?.otherText || [],
      "deliveryAreaSubtext",
      "",
    ),
  };

  return props;
};

const getDisplayComponent = (sectionLabel: string) => {
  switch (sectionLabel) {
    case "companyNameSection":
      return CompanyDescriptionDisplaySection;
    case "companyDescriptionSection":
      return CompanyDescriptionDisplaySection;
    case "myServicesSection":
      return ServiceCategoriesDisplaySection;
    case "locationDeliveryAreaSection":
      return LocationDeliveryAreaDisplaySection;
    case "certificationsApprovalsSection":
      return CertificationsApprovalsDisplaySection;
    case "sustainabilityGoalsSection":
      return SustainabilityGoalsDisplaySection;
    default:
      return ProfileSection;
  }
};

const findIconResolveIconUrl = (
  icons: Icon[],
  label: string,
  defaultImage: string,
) => {
  const icon = icons.find((icon) => icon.label === label)?.icon;
  return icon ? cmsService.resolveImage(icon).url() : defaultImage;
};

const getButtonTitle = (
  buttons: Button[],
  label: string,
  defaultTitle: string,
) => {
  const button = buttons.find((btn: Button) => btn.label === label);
  return button?.title || defaultTitle;
};

const getExtraTextTitle = (
  otherText: ExtraText[],
  label: string,
  defaultTitle: string,
) => {
  const text = otherText.find((txt: ExtraText) => txt.label === label);
  return text?.title || defaultTitle;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getProfileSectionProps = (section: any) => ({
  title: section.title,
  infoText: getExtraTextTitle(
    section?.otherText || [],
    "completionPromptText",
    "",
  ),
  showEditButton: false,
  headerText: section.title,
  defaultText: getExtraTextTitle(
    section?.otherText || [],
    "completionPromptText",
    "",
  ),
  infoIconSrc: supplierProfileCmsContent.value?.icons?.infoIcon || "",
  editIconSrc: supplierProfileCmsContent.value?.icons?.editIcon || "",
  addButtonText: supplierProfileCmsContent.value?.buttons?.addButton || "",
  editButtonText: supplierProfileCmsContent.value?.buttons?.editButton || "",
  closeIconSrc: supplierProfileCmsContent.value?.icons?.closeIcon || "",
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getCompanyProfileSectionProps = (input: any) => ({
  title: input.title,
  infoText: input?.description,
  showEditButton: false,
  headerText: input.title,
  defaultText: input?.description,
  infoIconSrc: supplierProfileCmsContent.value?.icons?.infoIcon || "",
  editIconSrc: supplierProfileCmsContent.value?.icons?.editIcon || "",
  addButtonText: supplierProfileCmsContent.value?.buttons?.addButton || "",
  editButtonText: supplierProfileCmsContent.value?.buttons?.editButton || "",
  closeIconSrc: supplierProfileCmsContent.value?.icons?.closeIcon || "",
});

const getCompanyLogoUploadProps = computed(() => ({
  buttonText: getButtonTitle(
    supplierProfileCmsContent.value?.forms?.companyProfileForm?.buttons || [],
    "uploadLogoButton",
    "Last opp logo",
  ),
  uploadIcon: findIconResolveIconUrl(
    getLogoInput("uploadLogoInput")?.icons || [],
    "uploadIcon",
    "/images/upload.svg",
  ),
  closeIcon: findIconResolveIconUrl(
    getLogoInput("uploadLogoInput")?.icons || [],
    "closeIcon",
    "/images/close.svg",
  ),
  logo: supplierProfileData.value?.logo || "",
}));

const serviceTypesCms = computed(() => {
  return cmsStore[ContentType.SERVICE_TYPES_CMS];
});

const validatedServiceTypes = computed(() => {
  return serviceTypesCms.value
    ?.map((serviceCms) => {
      return serviceCms;
    })
    .filter((serviceCms) => !!serviceCms?.serviceCategories?.length)
    .slice()
    .sort((a, b) => {
      if (typeof a.order !== "number") {
        return 1;
      }
      if (typeof b.order !== "number") {
        return -1;
      }
      return a.order - b.order;
    });
});

const resolveIconUrl = (icons: Icon[], defaultImage: string) => {
  const icon = icons?.[0]?.icon;
  return icon ? cmsService.resolveImage(icon).url() : defaultImage;
};

const getServiceCategoriesProps = () => ({
  title: getExtraTextTitle(
    supplierProfileCmsContent.value?.pageSections.myServicesSection
      ?.otherText || [],
    "modalTitle",
    "",
  ),
  subtitle: getExtraTextTitle(
    supplierProfileCmsContent.value?.pageSections.myServicesSection
      ?.otherText || [],
    "modalSubtitle",
    "",
  ),
  iconSrc: findIconResolveIconUrl(
    supplierProfileCmsContent.value?.pageSections.myServicesSection?.icons ||
      [],
    "categoryIcon",
    "/images/Add.svg",
  ),
  saveButtonText: supplierProfileCmsContent.value?.buttons.saveButton || "",
  cancelButtonText: supplierProfileCmsContent.value?.buttons.cancelButton || "",
  closeIconSrc: supplierProfileCmsContent.value?.icons.closeIcon || "",
  serviceTypes: formattedServiceTypesCategories.value,
});

const getSustainabilityGoalsProps = () => ({
  title: getExtraTextTitle(
    supplierProfileCmsContent.value?.pageSections.sustainabilityGoalsSection
      ?.otherText || [],
    "modalTitle",
    "",
  ),
  subtitle: getExtraTextTitle(
    supplierProfileCmsContent.value?.pageSections.sustainabilityGoalsSection
      ?.otherText || [],
    "modalSubtitle",
    "",
  ),
  iconSrc: findIconResolveIconUrl(
    supplierProfileCmsContent.value?.pageSections.sustainabilityGoalsSection
      ?.icons || [],
    "categoryIcon",
    "/images/goal.svg",
  ),
  saveButtonText: supplierProfileCmsContent.value?.buttons.saveButton || "",
  cancelButtonText: supplierProfileCmsContent.value?.buttons.cancelButton || "",
  sustainabilityGoals: mappedSustainabilityGoals.value ?? [],
});

const getCertificationsApprovalsProps = () => ({
  title: getExtraTextTitle(
    supplierProfileCmsContent.value?.pageSections.certificationsApprovalsSection
      ?.otherText || [],
    "modalTitle",
    "",
  ),
  subtitle: getExtraTextTitle(
    supplierProfileCmsContent.value?.pageSections.certificationsApprovalsSection
      ?.otherText || [],
    "modalSubtitle",
    "",
  ),
  iconSrc: findIconResolveIconUrl(
    supplierProfileCmsContent.value?.pageSections.certificationsApprovalsSection
      ?.icons || [],
    "categoryIcon",
    "/images/sertifisering.svg",
  ),
  saveButtonText: supplierProfileCmsContent.value?.buttons.saveButton || "",
  cancelButtonText: supplierProfileCmsContent.value?.buttons.cancelButton || "",
  certifications: mappedCertifications.value ?? [],
});

const supplierProfileCmsContent = computed(() => {
  const cms = cmsStore[ContentType.SUPPLIER_PROFILE_PAGE_CMS];

  if (!cms) {
    return null;
  }

  const { title, forms, buttons, icons, pageSections } = cms;

  if (!forms || !buttons || !icons || !pageSections) {
    return null;
  }

  const getFormByLabel = (label: string) => {
    return forms.find((form: Form) => form.label === label);
  };

  const getPageSectionByLabel = (label: string) => {
    return pageSections.find((section: PageSection) => section.label === label);
  };

  return {
    title,
    forms: {
      companyProfileForm: getFormByLabel("companyProfileForm"),
      companyDescriptionForm: getFormByLabel("companyDescriptionForm"),
      locationDeliveryAreaForm: getFormByLabel("locationDeliveryAreaForm"),
    },
    buttons: {
      editButton: getButtonTitle(buttons, "editButton", "Rediger"),
      addButton: getButtonTitle(buttons, "addButton", "Leg til"),
      saveButton: getButtonTitle(buttons, "saveButton", "Lagre"),
      cancelButton: getButtonTitle(buttons, "cancelButton", "Avbryt"),
    },
    icons: {
      infoIcon: findIconResolveIconUrl(icons, "infoIcon", "/images/info.svg"),
      alertIcon: findIconResolveIconUrl(
        icons,
        "alertIcon",
        "/images/alert.svg",
      ),
      closeIcon: findIconResolveIconUrl(
        icons,
        "closeIcon",
        "/images/close.svg",
      ),
      editIcon: findIconResolveIconUrl(
        icons,
        "editIcon",
        "/images/rediger.svg",
      ),
    },
    pageSections: {
      companyNameSection: {
        label: "companyNameSection",
        title: "Bedriftsnavn",
        description: "Endre bedriftsnavn",
        otherText: [
          {
            label: "completionPromptText",
            title: "Legg til bedriftsnavn",
          },
        ],
      },
      companyDescriptionSection: getPageSectionByLabel(
        "companyDescriptionSection",
      ),
      myServicesSection: getPageSectionByLabel("myServicesSection"),
      locationDeliveryAreaSection: getPageSectionByLabel(
        "locationDeliveryAreaSection",
      ),
      certificationsApprovalsSection: getPageSectionByLabel(
        "certificationsApprovalsSection",
      ),
      sustainabilityGoalsSection: getPageSectionByLabel(
        "sustainabilityGoalsSection",
      ),
    },
  };
});

const mainSections = computed(() => {
  const pageSections = supplierProfileCmsContent.value?.pageSections || {};
  return Object.values(pageSections).filter(
    (section): section is PageSection => !!section,
  );
});

const sectionUniqueIds = reactive(
  mainSections.value.map(() => Math.random().toString()),
);

const rerenderSection = (sectionLabel: string) => {
  const sectionIndex = mainSections.value?.findIndex(
    (section) => section.label === sectionLabel,
  );
  if (sectionIndex !== -1 && sectionUniqueIds[sectionIndex] !== undefined) {
    sectionUniqueIds[sectionIndex] = Math.random().toString();
  }
};

const getLogoInput = (label: string) => {
  return supplierProfileCmsContent.value?.forms?.companyProfileForm?.inputs?.find(
    (input: Input) => input.label === label,
  );
};

const sidebarInputs = computed(() => {
  return supplierProfileCmsContent.value?.forms?.companyProfileForm?.inputs?.filter(
    (input: Input) =>
      [
        "orgNumberInput",
        "contactPersonInput",
        "emailInput",
        "companyTurnoverInput",
        "companyEmployeeNumberInput",
      ].includes(input?.label || ""),
  );
});

const initializeSupplierProfileData = async () => {
  state.pageIsReady = false;
  try {
    const supplier = await supplierStore.supplier;
    const supplierId = supplier?.id ?? 0;
    const certificationResponse = await getSupplierCertifications({
      supplierId: supplierId,
    });
    const allCertificationsResponse = await getCertifications();
    const sustainabilityGoalsResponse = await getSupplierSustainabilityGoals({
      supplierId: supplierId,
    });
    const allSustainabilityGoalsResponse = await getSustainabilityGoals();
    const supplierAddressesResponse = await getSupplierAddresses({
      supplierId: supplierId,
    });
    const supplierServiceCategoriesResponse =
      await getSupplierServiceCategories({
        supplierId: supplierId,
      });

    const serviceCategoriesResponse = await getServiceCategories();

    state.serviceCategories = serviceCategoriesResponse?.data || [];
    state.allCertifications = allCertificationsResponse?.data || [];
    state.allSustainabilityGoals = allSustainabilityGoalsResponse?.data || [];

    const certifications = certificationResponse?.data || [];
    const sustainabilityGoals = sustainabilityGoalsResponse?.data || [];
    const supplierServiceCategories =
      supplierServiceCategoriesResponse?.data || [];
    const supplierAddresses = supplierAddressesResponse?.data || [];

    state.pageIsReady = true;

    const storedUserId = userStore.userAccount?.idTokenClaims?.sub ?? "";

    if (supplier) {
      const {
        id,
        contactPerson,
        description,
        email,
        logo,
        numEmployees,
        revenue,
        orgNumber,
        sysName,
        reservationHigher,
        reservationLower,
      } = supplier;

      updateSupplierProfileData({
        id: id ?? null,
        contactPerson: contactPerson ?? "",
        description: description ?? "",
        email: email ?? "",
        logo: logo ?? null,
        numEmployees: numEmployees ?? 0,
        revenue: revenue ?? null,
        userId: storedUserId,
        orgNumber: orgNumber ?? "",
        sysName: sysName ?? "",
        serviceCategories: supplierServiceCategories ?? [],
        reservationHigher: reservationHigher ?? 0,
        reservationLower: reservationLower ?? 0,
        offices:
          supplierAddresses?.map((supplierAddress: SupplierAddress) => ({
            address: {
              id: supplierAddress.address?.id ?? null,
              address: supplierAddress.address?.address ?? "",
              city: supplierAddress.address?.city ?? "",
              postalCode: supplierAddress.address?.postalCode ?? "",
              county: supplierAddress.address?.county ?? "",
            },
            reservationKilometers: supplierAddress?.reservationKilometers ?? 0,
          })) ?? [],
        certifications:
          certifications?.map((certification: Certification) => ({
            id: certification.id ?? 0,
            certificationLabel: certification.certificationLabel ?? "",
            selected: certification.selected ?? false,
          })) ?? [],
        sustainabilityGoals:
          sustainabilityGoals?.map((goal: SustainabilityGoal) => ({
            id: goal.id ?? 0,
            goalLabel: goal.goalLabel ?? "",
            selected: goal.selected ?? false,
          })) ?? [],
      });

      updateCompanyProfileFormData("orgNumberInput", orgNumber ?? "");
      updateCompanyProfileFormData("contactPersonInput", contactPerson ?? "");
      updateCompanyProfileFormData("emailInput", email ?? "");
      updateCompanyProfileFormData(
        "companyTurnoverInput",
        revenue?.toString() ?? "",
      );
      updateCompanyProfileFormData(
        "companyEmployeeNumberInput",
        numEmployees?.toString() ?? "",
      );

      updateProfileSectionData("companyNameSection", sysName ?? "");
      updateProfileSectionData("companyDescriptionSection", description ?? "");

      const locationDeliveryArea = supplierProfileData.value.offices || [];
      updateProfileSectionData(
        "locationDeliveryAreaSection",
        locationDeliveryArea,
      );

      const selectedServiceCategories =
        supplierProfileData.value.serviceCategories || [];
      updateProfileSectionData("myServicesSection", selectedServiceCategories);

      const selectedCertifications = mappedCertifications.value?.filter(
        (certification) => certification.selected,
      );

      updateProfileSectionData(
        "certificationsApprovalsSection",
        selectedCertifications,
      );

      const selectedGoals = mappedSustainabilityGoals.value?.filter(
        (goal) => goal.selected,
      );

      updateProfileSectionData("sustainabilityGoalsSection", selectedGoals);
    }
  } catch (error) {
    console.error("Failed to fetch supplier profile data:", error);
    // Handle the error, show an error message, or redirect to an error page
  }
};

const certificationsCMS = computed(
  () => cmsStore[ContentType.SUPPLIER_CERTIFICATIONS_CMS],
);
const sustainabilityGoalsCMS = computed(
  () => cmsStore[ContentType.SUPPLIER_SUSTAINABILITY_GOALS_CMS],
);

const mappedCertifications = computed(() => {
  const supplierCertifications = supplierProfileData.value.certifications || [];
  const systemCertifications = state.allCertifications || [];

  return systemCertifications.map((sysCert) => {
    const supplierCert = supplierCertifications.find(
      (cert) => cert.certificationLabel === sysCert.certificationLabel,
    );

    const supplierCertId = systemCertifications.find(
      (cert) => cert.certificationLabel === sysCert.certificationLabel,
    )?.id;

    const supplierCertTitle = certificationsCMS.value?.find(
      (certCms: Certifications) => certCms.label === sysCert.certificationLabel,
    )?.title;

    return {
      id: supplierCertId,
      certificationLabel: sysCert.certificationLabel,
      title: supplierCertTitle ?? "",
      selected: supplierCert?.selected ?? false,
    };
  });
});

const mappedSustainabilityGoals = computed(() => {
  const supplierGoals = supplierProfileData.value.sustainabilityGoals || [];
  const systemGoals = state.allSustainabilityGoals || [];

  return systemGoals.map((sysGoal) => {
    const supplierGoal = supplierGoals.find(
      (goal) => goal.goalLabel === sysGoal.goalLabel,
    );

    const supplierGoalId = systemGoals.find(
      (goal) => goal.goalLabel === sysGoal.goalLabel,
    )?.id;

    const supplierGoalTitle = sustainabilityGoalsCMS.value?.find(
      (goalCms: SustainabilityGoals) => goalCms.label === sysGoal.goalLabel,
    )?.title;

    return {
      id: supplierGoalId,
      goalLabel: sysGoal.goalLabel,
      title: supplierGoalTitle ?? "",
      selected: supplierGoal?.selected ?? false,
    };
  });
});

const formattedServiceTypesCategories = computed(() => {
  const supplierServiceCategories =
    supplierProfileData.value.serviceCategories || [];

  return (
    validatedServiceTypes.value?.map((serviceCms) => {
      const serviceCategories = serviceCms.serviceCategories
        ?.map((category) => {
          const mappedCategory = supplierServiceCategories.find(
            (mappedCat) =>
              typeof mappedCat !== "number" &&
              mappedCat.categoryLabel === category.label,
          );

          if (mappedCategory) {
            return {
              ...mappedCategory,
              title: category.title,
              icon: resolveIconUrl(category?.icons || [], "/images/Add.svg"),
              description: category.description || "",
              selected: true,
            };
          }

          const stateCategory = state.serviceCategories.find(
            (stateCat: ServiceCategoryWithoutSomeProps) =>
              stateCat.supplierTypeLabel === serviceCms.label &&
              stateCat.categoryLabel === category.label,
          );

          console.log("stateCategory", stateCategory);

          if (stateCategory) {
            return {
              ...stateCategory,
              title: category.title,
              icon: resolveIconUrl(category?.icons || [], "/images/Add.svg"),
              description: category.description || "",
              selected: false,
            };
          }

          return null;
        })
        .filter(Boolean);

      return {
        title: serviceCms.name,
        categories: serviceCategories || [],
      };
    }) || []
  );
});

const init = async () => {
  await initializeEditCompanyProfileInputFlags(sidebarInputs.value || []);
  await initializeEditProfileSectionFlags(mainSections.value || []);
  await initializeSupplierProfileData();
};

// Add smooth scroll to top function
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

onMounted(() => {
  // Scroll to top smoothly
  scrollToTop();

  init();
});
</script>

<style lang="scss" scoped>
.supplier-profile-grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 2rem;
  grid-template-areas:
    "header header"
    "main sidebar";
  padding: 0 2rem;
  background: inherit;
  padding-bottom: 12rem;

  &__header {
    grid-area: header;
    font-size: 2.5rem;
    text-align: left;
    margin-top: 1rem;
    font-weight: 500;
    color: $color-black;
    display: flex;
    gap: 0.5rem;
    align-items: baseline;
    text-transform: uppercase;
  }

  &__main-content {
    grid-area: main;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  &__sidebar {
    grid-area: sidebar;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    border-radius: 8px;
    background: #fff;
    padding: 1.5rem 1.5rem 4rem 1.5rem;
    height: fit-content;

    &--company-profile-inputs {
      display: flex;
      flex-direction: column;
      gap: 3rem;
    }
  }

  &__loader {
    padding: 4rem 0;
    display: flex;
    justify-content: center;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
