<template>
  <b-modal
    v-model="showModal"
    :no-close-on-backdrop="true"
    centered
    hide-footer
    hide-header
    body-class="p-0"
    size="md"
  >
    <div
      class="base-service-offer-modal"
      :class="{ 'text-align-center': alignItems === 'center' }"
    >
      <img
        v-if="icon"
        :src="icon"
        alt="icon"
        class="base-service-offer-modal__icon"
      />
      <div class="base-service-offer-modal__title">{{ title }}</div>
      <div
        v-if="description.length"
        class="base-service-offer-modal__description"
      >
        {{ description[0]?.content }}
      </div>
      <div>
        <!-- Form slot -->
        <slot name="form" />
      </div>
      <div class="base-service-offer-modal__actions">
        <!-- Buttons slot -->
        <slot name="buttons" />
      </div>
    </div>
  </b-modal>
</template>

<script setup lang="ts">
import { computed } from "vue";

interface Props {
  modelValue: boolean;
  icon?: string | null;
  title: string;
  description: Array<Record<string, unknown>>;
  alignItems?: "left" | "center";
}

const props = withDefaults(defineProps<Props>(), {
  icon: null,
  alignItems: "center",
});

const emit = defineEmits<{
  (e: "update:modelValue", value: boolean): void;
}>();

const showModal = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
});
</script>

<style lang="scss" scoped>
// Define Variables
$font-family: "Montserrat", sans-serif;
$background-color: #ffffff;
$font-600: 600;
$font-400: 400;
$font-color: #000000;
$border-color: white;

.base-service-offer-modal {
  font-family: $font-family;
  background: $background-color;
  border: 1px solid $border-color;
  padding: 48px;
  border-radius: 4px;
  text-align: left;
  min-width: 530px;

  &__icon {
    margin-bottom: 34px;
    width: 48px;
    height: 48px;
  }

  &__title {
    font-weight: $font-600;
    font-size: $font-size-2xl;
    line-height: 29px;
    color: $font-color;
    padding-bottom: 34px;
  }

  &__description {
    font-weight: $font-400;
    font-size: $font-size-base;
    line-height: 20px;
    color: $font-color;
  }
}

.text-align-center {
  text-align: center;
}

:deep(.modal-dialog) {
  min-width: 530px !important;
}

.modal-dialog {
  min-width: 530px !important;
}

:deep(.modal-content) {
  border-radius: 4px;
}
</style>
