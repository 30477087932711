<template>
  <div class="offer-view">
    <offer-view-base-header
      :offer-recipient="customerOrganizationName"
      :last-saved="lastSaveDate"
      @go-back="goBack"
    >
      <template v-if="showPageActionButtons" #right-content>
        <div class="offer-view__header-buttons">
          <primary-button variant="inverted" @click="sendOffer">
            {{ getButtonText("sendOfferButton") }}
          </primary-button>
        </div>
      </template>
    </offer-view-base-header>
    <div v-if="feedbackMessages.length" class="offer-view__feedback-messages">
      <div
        v-for="feedbackMessage in feedbackMessages"
        :key="feedbackMessage.id"
        class="offer-view__feedback-message"
      >
        <feedback-overlay
          :feedback-type="feedbackMessage.type"
          :message-title="feedbackMessage.messageTitle"
          :message-info="feedbackMessage.messageInfo"
          @close="removeFeedbackMessage(feedbackMessage)"
        />
      </div>
    </div>
    <div v-if="pageIsReady" class="offer-view__container">
      <template v-if="serviceOffer">
        <div class="offer-view__title-wrapper">
          <h1 class="offer-view__title">{{ pageTitle }}</h1>
          <button
            class="offer-view__new-tab-button"
            @click="handleOnNewTabClick"
          >
            <img :src="`/images/new_tab.svg`" alt="new-tab" />
            Hvordan lage tilbud?
          </button>
        </div>
        <div
          v-if="!pageIsReadOnly"
          class="offer-view__change-offer-name-button"
        >
          <secondary-button
            :icon-src="editOfferNameButtonIcon"
            :text="editOfferNameButtonText"
            @click="openEditOfferNameModal"
          />
        </div>
        <div class="offer-view__agreement-partners">
          <agreement-partners
            :service-offer="serviceOffer"
            :supplier-org-number="supplierOrgNumber"
            :collapsible="!pageIsReadOnly"
            :supplier-id="supplierId"
            :price-request-id="priceRequestId"
          />
        </div>
        <div class="offer-view__activities-and-cost">
          <activities-costs-section
            :service-offer="serviceOffer"
            :page-cms="pageCms"
            :frequency-options="frequencyOptions"
            :service-activities-cms="serviceActivitiesCms"
            :read-only="pageIsReadOnly"
            :supplier-info="supplier"
            :mandatory-supplier-costs="serviceOffer.mandatorySupplierCosts"
            :independent-supplier-costs="serviceOffer.independentSupplierCosts"
            @new-mandatory-cost="handleNewMandatoryCost"
            @remove-mandatory-cost="handleRemoveMandatoryCost"
            @new-independent-cost="handleNewIndependentCost"
            @remove-independent-cost="handleRemoveIndependentCost"
          />
        </div>
        <div class="offer-view__work-description">
          <description-of-work
            :page-cms="pageCms"
            :supplier-id="supplierId"
            :service-offer-id="serviceOffer.serviceOfferId"
            :supplier-request-id="serviceOffer.supplierRequestId"
            :work-descriptions="serviceOffer.descriptions"
            :read-only="pageIsReadOnly"
            @new-description="handleNewDescription"
            @remove-description="handleRemoveDescription"
          />
        </div>
        <div class="offer-view__terms">
          <terms
            v-if="supplier"
            :page-cms="pageCms"
            :service-offer="serviceOffer"
            :supplier="supplier"
            :supplier-id="supplierId"
            :read-only="pageIsReadOnly"
            @start-date-updated="handleUpdatedStartDate"
            @termination-period-updated="handleUpdatedTerminationPeriod"
          />
        </div>
        <div class="offer-view__product-description-uploads">
          <upload-product-description
            :page-cms="pageCms"
            :service-offer="serviceOffer"
            :supplier-id="supplierId"
            :read-only="pageIsReadOnly"
            @new-attachments="handleNewAttachments"
            @remove-attachment="handleRemoveAttachment"
          />
        </div>
      </template>
      <change-name-on-offer-modal
        v-model="showEditOfferNameModal"
        :title="editOfferNameButtonText"
        :service-offer="serviceOffer"
        :change-name-form-cms="changeNameFormCms"
        :initial-offer-name="initialOfferName"
        :is-submitting="isEditingOfferName"
        :submission-error="submissionError"
        @new-offer-name="handleNewServiceOfferName"
        @cancel="cancelEditingOfferName"
      />
      <late-offer-modal
        v-if="showLateOfferModal"
        v-model="showLateOfferModal"
        :tender-id="serviceOffer.tenderId"
        @ok="handleLateOfferOk"
      />
      <send-offer-modal
        v-if="serviceOffer"
        v-model="showSendOfferModal"
        :service-offer="serviceOffer"
        :supplier-id="supplierId"
        :price-request-id="priceRequestId"
        @offer-sent-acknowledged="handleOfferSentAcknowledgement"
        @offer-sent="handleOfferSent"
      />
    </div>
    <div v-if="!pageIsReady" class="offer-view__loader">
      <b-spinner label="Large Spinner" type="grow" />
    </div>
  </div>
</template>

<script>
import Terms from "@/views/TenderOffer/EditOfferView/Terms/Terms.vue";
import LateOfferModal from "@/components/LateOfferModal/LateOfferModal.vue";
import OfferViewBaseHeader from "@/components/OfferViewBaseHeader/OfferViewBaseHeader.vue";
import PrimaryButton from "@/components/Buttons/PrimaryButton.vue";
import SecondaryButton from "@/components/Buttons/SecondaryButton.vue";
import AgreementPartners from "@/components/AgreementPartners/AgreementPartners.vue";
import cmsService from "@/services/cmsService";
import UploadProductDescription from "@/views/TenderOffer/EditOfferView/UploadProductDescription/UploadProductDescription.vue";
import DescriptionOfWork from "@/components/SupplierOfferWorkDescription/DescriptionOfWork.vue";
import ActivitiesCostsSection from "@/components/ActivitiesCosts/ActivitiesCostsSection.vue";
import ChangeNameOnOfferModal from "@/components/ChangeNameOnOfferModal/ChangeNameOnOfferModal.vue";
import {
  getCmsItem,
  getCmsItemText,
  getIconUrl,
} from "@/utilities/cmsUtilities";
import dayjs from "dayjs";
import { uuidv4 } from "@/utilities/uuid";
import {
  FeedbackType,
  OfferStatus,
  RequestState,
  RequestStateType,
} from "@/custom-types/GeneralTypes";
import FeedbackOverlay from "@/components/FeedbackOverlay/FeedbackOverlay.vue";
import SendOfferModal from "@/components/SendOfferModal/SendOfferModal.vue";
import { RouteNames } from "@/router/types";
import { useTenderApi } from "@/services/api/useTenderApi";
import utc from "dayjs/plugin/utc";
import { compareStatuses } from "@/utilities/stringUtils";
import { addBusinessDays } from "@/utilities/dateUtils";
import { useUserStore } from "@/stores/user";
import { useCommunicationApi } from "@/services/api/useCommunicationApi";
import { useCmsStore } from "@/stores/cms";
import { usePriceRequestCache } from "@/composables/usePriceRequestCache";
dayjs.extend(utc);

const tenderApis = useTenderApi();
const communicationApis = useCommunicationApi();

export default {
  components: {
    SendOfferModal,
    FeedbackOverlay,
    DescriptionOfWork,
    UploadProductDescription,
    AgreementPartners,
    PrimaryButton,
    OfferViewBaseHeader,
    ActivitiesCostsSection,
    ChangeNameOnOfferModal,
    SecondaryButton,
    LateOfferModal,
    Terms,
  },
  props: {
    supplier: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const userStore = useUserStore();
    const cmsStore = useCmsStore();
    const priceRequestCache = usePriceRequestCache();
    return { userStore, cmsStore, priceRequestCache };
  },
  data() {
    return {
      pageIsReady: false,
      pageCms: null,
      showEditOfferNameModal: false,
      serviceOfferConfirmed: false,
      showSendOfferModal: false,
      frequencyOptions: null,
      isSavingOffer: false,
      feedbackMessages: [],
      serviceActivitiesCms: null,
      conversations: null,
      queueSave: false,
      initialOfferName: "",
      showLateOfferModal: false,
      serviceOffer: undefined,
      priceRequest: undefined,
      isEditingOfferName: false,
      submissionError: "",
    };
  },
  computed: {
    serviceTypesCms() {
      return this.cmsStore.serviceTypesCms;
    },
    pageIsReadOnly() {
      if (!this.serviceOffer || !this.priceRequest) {
        return true;
      }
      return (
        ![
          RequestState.PRICE_REQUESTED,
          RequestState.INFORMATION_REQUESTED,
          RequestState.OFFERED,
        ].some((record) =>
          compareStatuses(this.priceRequest.requestState, record),
        ) || !compareStatuses(this.serviceOffer.currentState, OfferStatus.DRAFT)
      );
    },
    supplierId() {
      return this.supplier.id;
    },
    supplierOrgNumber() {
      return this.supplier?.orgNumber ?? "";
    },
    customerOrganizationName() {
      return this.serviceOffer?.customerOrgName;
    },
    defaultTitle() {
      return this.pageCms ? this.pageCms.title : "";
    },
    pageTitle() {
      return this.serviceOffer?.nickname?.trim() || this.defaultTitle;
    },
    editOfferNameButtonIcon() {
      const button = getCmsItem(this.pageCms?.buttons, "changeNameButton");
      const iconUrl = getIconUrl(button.icons, "editIcon");
      return iconUrl || "";
    },
    editOfferNameButtonText() {
      return getCmsItemText(this.pageCms?.buttons, "changeNameButton");
    },
    changeNameFormCms() {
      if (this.pageCms && this.pageCms.pageSections) {
        const cmsItem = getCmsItem(this.pageCms.forms, "changeNameForm");
        return cmsItem ? cmsItem : {};
      }
      return {};
    },
    showPageActionButtons() {
      return this.serviceOffer && this.pageIsReady && !this.pageIsReadOnly;
    },
    serviceOfferMeetsMinimumSendRequirements() {
      return (
        this.serviceOffer &&
        (this.serviceOffer.mandatorySupplierCosts.length ||
          this.serviceOffer.independentSupplierCosts.length) &&
        this.serviceOffer.descriptions.length
      );
    },
    lastSaveDate() {
      const lastUpdate = this.serviceOffer?.lastStateChange;
      return lastUpdate ? dayjs(lastUpdate).format("HH:mm - DD.MM.YYYY") : "";
    },
    isWantedStartDateInThePast() {
      return dayjs(this.serviceOffer.suggestedStartDate).isBefore(
        dayjs(),
        "day",
      );
    },
    priceRequestId() {
      return parseInt(this.$route.params.priceRequestId || -1);
    },
    supplierRequestId() {
      return parseInt(this.$route.params.supplierRequestId || -1);
    },
    offerId() {
      return parseInt(this.$route.params.offerId || -1);
    },
    userId() {
      if (!this.userStore.isUserLoaded) {
        this.userStore.fetchLoggedInUser();
      }
      return this.userStore.user?.userId;
    },
  },
  async created() {
    if (this.priceRequestId < 0 || this.supplierRequestId < 0) {
      return this.$router.push({ name: RouteNames.SUPPLIER_ADMIN });
    }

    // Ensure user data is loaded
    if (!this.userStore.isUserLoaded) {
      await this.userStore.fetchLoggedInUser();
    }

    const [
      priceRequest,
      serviceOffer,
      frequencyOptionsResponse,
      editOfferPageCmsResponse,
      serviceActivitiesCms,
    ] = await Promise.all([
      this.fetchPriceRequest(),
      this.fetchServiceOffer(this.offerId),
      tenderApis.getActivityFormFrequencyOptions(),
      cmsService.getEditSupplierOfferPage(),
      cmsService.getServiceActivities(),
    ]);
    this.priceRequest = priceRequest;
    this.serviceActivitiesCms = serviceActivitiesCms;
    this.pageCms = editOfferPageCmsResponse[0];
    this.frequencyOptions = frequencyOptionsResponse.data;
    if (!serviceOffer) {
      if (this.offerId >= 0) {
        return this.$router.push({ name: RouteNames.SUPPLIER_ADMIN });
      } else {
        this.initialOfferName = this.$route.query.suggestedName || "";
        this.showEditOfferNameModal = true;
      }
    } else {
      this.serviceOffer = serviceOffer;
    }
    this.pageIsReady = true;
  },
  methods: {
    async fetchPriceRequest() {
      if (this.priceRequestId < 0) {
        return undefined;
      }

      const data = await this.priceRequestCache.getPriceRequestDetailsWithCache(
        this.supplierId,
        this.priceRequestId,
      );

      return data;
    },
    async fetchServiceOffer(offerId) {
      if (offerId < 0) {
        return undefined;
      }
      const serviceOfferResponse = await tenderApis.getServiceOffer(
        this.supplierId,
        this.supplierRequestId,
        offerId,
      );
      return serviceOfferResponse.data;
    },
    handleNewMandatoryCost(supplierCost) {
      const matchingCost = this.serviceOffer?.mandatorySupplierCosts.find(
        (record) =>
          record.serviceOfferSegmentGroupPriceId ===
          supplierCost.serviceOfferSegmentGroupPriceId,
      );
      if (matchingCost) {
        Object.assign(matchingCost, supplierCost);
        return;
      }
      this.serviceOffer?.mandatorySupplierCosts.push(supplierCost);
    },
    handleNewIndependentCost(supplierCost) {
      const matchingCost = this.serviceOffer?.independentSupplierCosts.find(
        (record) =>
          record.serviceOfferSegmentGroupPriceId ===
          supplierCost.serviceOfferSegmentGroupPriceId,
      );
      if (matchingCost) {
        Object.assign(matchingCost, supplierCost);
        return;
      }
      this.serviceOffer?.independentSupplierCosts.push(supplierCost);
    },
    handleRemoveMandatoryCost(record) {
      for (
        let i = this.serviceOffer?.mandatorySupplierCosts.length - 1;
        i >= 0;
        i--
      ) {
        if (
          this.serviceOffer?.mandatorySupplierCosts[i]
            .serviceOfferSegmentGroupPriceId ===
          record.serviceOfferSegmentGroupPriceId
        ) {
          this.serviceOffer?.mandatorySupplierCosts.splice(i, 1);
        }
      }
    },
    handleRemoveIndependentCost(record) {
      for (
        let i = this.serviceOffer?.independentSupplierCosts.length - 1;
        i >= 0;
        i--
      ) {
        if (
          this.serviceOffer?.independentSupplierCosts[i]
            .serviceOfferSegmentGroupPriceId ===
          record.serviceOfferSegmentGroupPriceId
        ) {
          this.serviceOffer?.independentSupplierCosts.splice(i, 1);
        }
      }
    },
    handleNewDescription(record) {
      const matchingDescription = this.serviceOffer?.descriptions.find(
        (workDescription) => workDescription.id === record.id,
      );
      if (matchingDescription) {
        Object.assign(matchingDescription, record);
      } else {
        this.serviceOffer?.descriptions.push(record);
      }
    },
    handleRemoveDescription(record) {
      for (let i = this.serviceOffer?.descriptions.length - 1; i >= 0; i--) {
        if (this.serviceOffer?.descriptions[i].id === record.id) {
          this.serviceOffer?.descriptions.splice(i, 1);
        }
      }
    },
    handleUpdatedStartDate(newStartDate) {
      if (this.serviceOffer) {
        this.serviceOffer.suggestedStartDate = newStartDate;
      }
    },
    handleUpdatedTerminationPeriod(newPeriod) {
      if (this.serviceOffer) {
        this.serviceOffer.terminationPeriodInMonths = newPeriod;
      }
    },
    handleNewAttachments(records) {
      this.serviceOffer?.serviceOfferAttachments.push(...records);
    },
    handleRemoveAttachment(record) {
      for (
        let i = this.serviceOffer?.serviceOfferAttachments.length - 1;
        i >= 0;
        i--
      ) {
        if (
          this.serviceOffer?.serviceOfferAttachments[i].attachmentId ===
          record.attachmentId
        ) {
          this.serviceOffer?.serviceOfferAttachments.splice(i, 1);
        }
      }
    },
    showFeedbackMessage(feedbackType, messageText) {
      if (this.pageIsReadOnly) return;
      this.feedbackMessages.push({
        id: uuidv4(),
        type: feedbackType,
        messageTitle: messageText,
      });
    },
    removeFeedbackMessage(message) {
      for (let i = this.feedbackMessages.length - 1; i >= 0; i--) {
        if (this.feedbackMessages[i].id === message.id) {
          this.feedbackMessages.splice(i, 1);
        }
      }
    },
    clearFeedbackMessages() {
      this.feedbackMessages = [];
    },
    sendOffer() {
      this.clearFeedbackMessages();
      if (this.offerHasInvalidContent()) {
        this.showInvalidContentFeedbackMessage();
        return;
      }
      if (!this.priceRequest) {
        return;
      }
      const daysRemainingToDeadline = this.getDaysRemainingToResponseDeadline(
        this.priceRequest.latestSupplierRequestDate,
        this.priceRequest.responsePeriodInDays,
      );
      if (daysRemainingToDeadline && daysRemainingToDeadline < 0) {
        this.showLateOfferModal = true;
      } else {
        this.showSendOfferModal = true;
      }
    },
    getDaysRemainingToResponseDeadline(startDate, responsePeriodInDays) {
      if (!startDate || !responsePeriodInDays) {
        return undefined;
      }
      const deadlineDate = dayjs(
        addBusinessDays(startDate, responsePeriodInDays),
      );
      return deadlineDate.diff(dayjs(), "days") + 1;
    },
    handleLateOfferOk() {
      this.showLateOfferModal = false;
      this.showSendOfferModal = true;
    },
    offerHasInvalidContent() {
      return (
        !this.serviceOfferMeetsMinimumSendRequirements ||
        this.isWantedStartDateInThePast
      );
    },
    showInvalidContentFeedbackMessage() {
      if (!this.serviceOfferMeetsMinimumSendRequirements) {
        this.showFeedbackMessage(
          FeedbackType.Error,
          "Minimum data før sending: 1) Minst én fast kostnadslinje 2) Minst én beskrivelseslinje",
        );
      } else if (this.isWantedStartDateInThePast) {
        this.showFeedbackMessage(
          FeedbackType.Error,
          "Ønsket startdato er i fortiden. Vennligst velg en fremtidig dato.",
        );
      }
    },
    handleNewServiceOfferName(newName) {
      if (this.serviceOffer) {
        this.updateServiceOfferNickname(newName);
      } else {
        this.createServiceOffer(newName);
      }
    },
    async updateServiceOfferNickname(newName) {
      if (this.isEditingOfferName || !this.serviceOffer) {
        return;
      }
      this.isEditingOfferName = true;
      this.submissionError = "";
      try {
        await tenderApis.updateServiceOfferNickname(
          this.supplier.id,
          this.supplierRequestId,
          this.serviceOffer.serviceOfferId,
          { newNickname: newName },
        );
        this.serviceOffer.nickname = newName;
        this.closeEditOfferNameModal();
      } catch (error) {
        console.log("Error updating nickname ", error);
        this.submissionError = error.toString();
      } finally {
        this.isEditingOfferName = false;
      }
    },
    async createServiceOffer(serviceOfferName) {
      if (this.isEditingOfferName) {
        return;
      }
      this.isEditingOfferName = true;
      this.submissionError = "";
      try {
        const reuseOfferId = parseInt(this.$route.query.reuseOfferId || -1);
        const userId = this.userId;
        console.log("userId", userId);
        let response;
        if (reuseOfferId >= 0) {
          response = await tenderApis.cloneServiceOffer(
            this.supplier.id,
            this.supplierRequestId,
            {
              userIdCloningOffer: userId,
              serviceOfferIdToClone: reuseOfferId,
              newOfferNickname: serviceOfferName,
            },
          );
        } else {
          response = await tenderApis.createServiceOffer(
            this.supplier.id,
            this.supplierRequestId,
            {
              userIdGivingOffer: userId,
              nickname: serviceOfferName,
            },
          );
        }
        this.serviceOffer = response.data;
        this.$router.replace({
          name: RouteNames.EDIT_OFFER,
          params: {
            offerId: this.serviceOffer.serviceOfferId,
          },
        });
        this.closeEditOfferNameModal();
      } catch (error) {
        console.log("Error creating service offer ", error);
        this.submissionError = error.toString();
      } finally {
        this.isEditingOfferName = false;
      }
    },
    cancelEditingOfferName() {
      if (this.serviceOffer) {
        this.closeEditOfferNameModal();
      } else {
        this.goBack();
      }
    },
    closeEditOfferNameModal() {
      this.showEditOfferNameModal = false;
      this.submissionError = "";
    },
    openEditOfferNameModal() {
      this.showEditOfferNameModal = true;
    },
    getButtonText(label) {
      const buttons = this.pageCms?.buttons;
      return buttons ? getCmsItemText(buttons, label) : "";
    },
    async handleOfferSent() {
      this.serviceOffer.currentState = OfferStatus.Offered;
    },
    async handleOfferSentAcknowledgement() {
      console.log("Navigate to requests list");
    },
    goBack() {
      if (!this.serviceOffer || !this.priceRequest) {
        this.$router.back();
        return;
      }

      if (window.history.length > 2) {
        this.$router.back();
        return;
      }

      const requestState = this.serviceOffer.currentState;
      const isServiceAgreement = this.serviceOffer.isServiceAgreement === true;
      let route = "my-requests";
      let tabList = "";
      let includeRequestId = true;

      // Determine route and tabList based on the request state
      if (
        requestState === RequestStateType.OFFERED ||
        requestState === RequestStateType.INFORMATION_REQUESTED
      ) {
        route = RouteNames.MY_REQUESTS;
        tabList = "active";
      } else if (requestState === RequestStateType.PRICE_REQUESTED) {
        route = RouteNames.MY_REQUESTS;
        tabList = "new";
      } else if (requestState === RequestStateType.LOST) {
        route = RouteNames.MY_REQUESTS;
        tabList = "lost";
        includeRequestId = false;
      } else if (
        requestState === RequestStateType.UNANSWERED ||
        requestState === RequestStateType.REJECTED ||
        requestState === RequestStateType.ARCHIVED
      ) {
        route = RouteNames.MY_REQUESTS;
        tabList = "archived";
      } else if (requestState === RequestStateType.ACCEPTED) {
        route = RouteNames.MY_AGREEMENTS;
        tabList = isServiceAgreement ? "active-service" : "active-projects";
      } else if (requestState === RequestStateType.COMPLETED) {
        route = RouteNames.MY_AGREEMENTS;
        tabList = isServiceAgreement
          ? "completed-service"
          : "completed-projects";
      }

      // Construct the query parameters
      const query = {
        tabList,
      };

      // Only include priceRequestId for appropriate states
      if (
        includeRequestId &&
        this.priceRequest &&
        this.priceRequest.priceRequestId
      ) {
        query.priceRequestId = this.priceRequest.priceRequestId.toString();
      }

      // Navigate to the constructed URL
      this.$router.push({
        name: route,
        query,
      });
    },
    async fetchConversationMessages(conversationId) {
      try {
        const response =
          await communicationApis.fetchConversationMessages(conversationId);
        console.log("response", response);
        if (!this.conversations) {
          this.conversations = [];
        }
        const matchingConversation = this.conversations.find(
          (conversation) =>
            conversation.message.conversationId === conversationId,
        );
        if (matchingConversation) {
          Object.assign(matchingConversation, response.data);
        } else {
          this.conversations.push(response.data);
        }
      } catch (warn) {
        console.warn("Error fetching conversation messages:", warn);
      }
    },
    handleOnNewTabClick() {
      window.open(import.meta.env.VITE_APP_NEED_HELP_OFFER_URL, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.offer-view {
  color: $color-black;
  margin-bottom: 8rem;

  &__header-buttons {
    display: flex;
    gap: 1.5rem;

    > button {
      padding: 0.625rem 1rem;
    }
  }

  &__title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  &__title {
    font-weight: 600;
    font-size: $font-size-2xl;
  }

  &__new-tab-button {
    display: flex;
    height: 40px;
    padding: 0 16px;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    background: var(--Yellow-Dark, #ecba4e);
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
    color: var(--Black, #1d1d1d);
    font-weight: 700;

    img {
      width: 24px;
      height: 24px;
    }

    &:hover {
      background: rgba(236, 186, 78, 0.8);
    }

    &:active {
      background: rgba(236, 186, 78, 0.6);
    }
  }

  &__container {
    max-width: 61.25rem;
    margin: 0 auto;
    padding-top: 4.75rem;
    padding-bottom: 8rem;
  }

  &__feedback-messages {
    position: fixed;
    top: 4.5rem;
    left: 50%;
    transform: translateX(-50%);
    width: 556px;
    margin: 0 auto;
  }

  &__feedback-message {
    padding-top: 0.5rem;
  }

  &__change-offer-name-button {
    margin-top: 0.5rem;
  }

  &__agreement-partners {
    margin-top: 2rem;
  }

  &__start-date {
    margin-top: 2.5rem;
  }

  &__activities-and-cost {
    margin-top: 2.5rem;
  }

  &__work-description {
    margin-top: 2.5rem;
  }

  &__terms {
    margin-top: 2.5rem;
  }

  &__product-description-uploads {
    margin-top: 2.5rem;
  }

  &__terms-and-conditions {
    margin-top: 2.5rem;
  }

  &__loader {
    padding: 4rem 0;
    display: flex;
    justify-content: center;
  }
}

:deep(*) {
  font-family: "Montserrat", sans-serif;
}
</style>
