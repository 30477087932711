import {
  ServiceReportApi,
  ServiceReportApiEndpoints,
} from "@/config/api/serviceReport/serviceReportApi";
import { executeApiRequest } from "@/services/api/apiService";
import { ServiceReport } from "@/stores/serviceReport/types";
import { useToastErrorHandler } from "@/composables/useToastErrorHandler";

const useServiceReportApiService = () => {
  const { withToastErrorHandling } = useToastErrorHandler();

  const fetchServiceReports = async (params: {
    customerOrgNumber: string;
    tenderId: number;
    offerId: number;
  }) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<ServiceReport[]>(
          {
            endpointKey: Symbol.for(
              ServiceReportApiEndpoints.FETCH_SERVICE_REPORTS,
            ),
            config: ServiceReportApi,
          },
          {
            pathParams: [
              params.customerOrgNumber,
              "Tender",
              params.tenderId,
              "Offer",
              params.offerId,
            ],
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Servicerapportfeil",
          detail: "Kunne ikke hente servicerapporter",
        },
      },
    );
  };

  const createServiceReport = async (
    data: Omit<ServiceReport, "id" | "deleted">,
  ) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<ServiceReport>(
          {
            endpointKey: Symbol.for(
              ServiceReportApiEndpoints.CREATE_SERVICE_REPORT,
            ),
            config: ServiceReportApi,
          },
          {
            data: data,
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Servicerapportfeil",
          detail: "Kunne ikke opprette servicerapport",
        },
        successOptions: {
          severity: "success",
          summary: "Vellykket",
          detail: "Servicerapport opprettet",
        },
      },
    );
  };

  const deleteServiceReport = async (serviceReportId: number) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<void>(
          {
            endpointKey: Symbol.for(
              ServiceReportApiEndpoints.DELETE_SERVICE_REPORT,
            ),
            config: ServiceReportApi,
          },
          {
            pathParams: [serviceReportId],
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Servicerapportfeil",
          detail: "Kunne ikke slette servicerapport",
        },
        successOptions: {
          severity: "success",
          summary: "Vellykket",
          detail: "Servicerapport slettet",
        },
      },
    );
  };

  return {
    fetchServiceReports,
    createServiceReport,
    deleteServiceReport,
  };
};

export const useServiceReportApi = useServiceReportApiService;
