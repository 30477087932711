<template>
  <div
    class="attachment"
    role="button"
    tabindex="0"
    :aria-label="`Open attachment: ${attachment.fileName}`"
    @click="handleAttachmentClick"
    @keydown.enter="handleAttachmentClick"
  >
    <img :src="DocumentIcon" alt="Document icon" class="attachment__icon" />
    <div class="attachment__file-name">
      {{ attachment.fileName }}
    </div>
  </div>
</template>

<script setup lang="ts">
import useFileDownload from "@/composables/useFileDownload";
import type { Attachment } from "@/stores/communication/types";
import DocumentIcon from "@/assets/img/document.svg";

import { useRouter } from "vue-router";

interface AttachmentProps {
  attachment: Attachment;
  supplierId: number;
}

const props = defineProps<AttachmentProps>();
const router = useRouter();
const { viewAttachmentFile } = useFileDownload();

const handleAttachmentClick = async () => {
  const { attachment, supplierId } = props;

  if (!attachment) return;

  try {
    if (attachment.attachmentId) {
      await viewAttachmentFile(
        {
          attachmentId: attachment.attachmentId.toString(),
          supplierId: supplierId,
        },
        router,
      );
      return;
    }

    if (attachment.attachmentUri) {
      window.open(attachment.attachmentUri, "_blank", "noopener,noreferrer");
    }
  } catch (error) {
    console.error("Error viewing attachment:", error);
    // TODO: Show error message
  }
};
</script>

<style lang="scss" scoped>
.attachment {
  border: 1px solid gray;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &__icon {
    flex-shrink: 0;
    margin-right: 0.5rem;
    width: 24px;
    height: 24px;
  }

  &__file-name {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover,
  &:focus {
    background: #f6f6f6;
    outline: none;
  }

  &:focus-visible {
    outline: 2px solid #000;
    outline-offset: 2px;
  }
}
</style>
