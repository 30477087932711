<template>
  <div class="price-request-details">
    <FeedbackNotification
      v-if="shouldShowFeedbackNotification"
      :is-accepted="isAccepted"
      @read-feedback="openFeedbackPanel"
    />

    <div class="price-request-details__grid">
      <DetailsItem
        v-for="item in detailItems"
        :key="item.label"
        :label="item.label"
        :value="item.value"
        :is-loading="item.loading"
      />
    </div>

    <CollapsibleSection
      :is-truncated="isWorkDescriptionTruncated"
      :content="effectiveSharedMessage"
      label="Arbeidsbeskrivelse"
      @toggle="toggleWorkDescription"
    />

    <div class="price-request-details__full-width">
      <div class="price-request-details__label">Vedlegg</div>
      <div class="price-request-details__divider" />
      <div
        v-if="mappedPriceRequestAttachments.length"
        class="price-request-details__attachments"
      >
        <Attachment
          v-for="(attachment, index) in mappedPriceRequestAttachments"
          :key="index"
          :download-handler="attachment.downloadHandler"
          :file-name="attachment.fileName"
          class="price-request-details__attachment"
        />
      </div>
      <div v-else class="price-request-details__no-attachment">
        <p class="price-request-details__no-attachment-text">Ingen vedlegg</p>
      </div>
    </div>

    <div class="price-request-details__full-width">
      <div class="price-request-details__label">Fellesmeldinger</div>
      <div class="price-request-details__divider" />
      <PublicNotes
        :tender="tender"
        :price-request="priceRequest"
        :supplier-id="supplierId"
      />
    </div>

    <div class="price-request-details__full-width">
      <div class="price-request-details__label">Vilkår</div>
      <div class="price-request-details__divider" />
      <template v-if="!isLoadingFrameAgreement">
        <Attachment
          v-for="attachment in displayedAttachments"
          :key="attachment.fileName"
          :file-name="attachment.fileName"
          :download-handler="attachment.downloadHandler"
          class="price-request-details__attachment"
        />
        <p
          v-if="displayedAttachments?.length === 0"
          class="price-request-details__no-attachment-text"
        >
          Ingen vedlegg
        </p>
      </template>
      <div v-else class="price-request-details__skeleton">
        <Skeleton
          v-for="n in 2"
          :key="n"
          width="100%"
          height="40px"
          class="mb-2"
          border-radius="8px"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, toRefs } from "vue";
import Skeleton from "primevue/skeleton";
import { usePriceRequestFrameAgreement } from "@/composables/usePriceRequestFrameAgreement";
import { usePriceRequestFeedback } from "@/composables/usePriceRequestFeedback";
import { usePriceRequestDetails } from "@/composables/usePriceRequestDetails";
import DetailsItem from "@/components/PriceRequestDetails/DetailsItem.vue";
import CollapsibleSection from "@/components/PriceRequestDetails/CollapsibleSection.vue";
import Attachment from "@/components/Attachment/Attachment.vue";
import FeedbackNotification from "@/components/FeedbackNotification/FeedbackNotification.vue";
import PublicNotes from "@/components/PublicNotes/PublicNotes.vue";
import { Tender, PriceRequestDetails } from "@/stores/tender/types";
import { useFileAttachmentApi } from "@/services/api/useFileAttachmentApi";
import useFileDownload from "@/composables/useFileDownload";
import { useRouter } from "vue-router";
import { RequestStateType } from "@/custom-types/GeneralTypes";
import { useTenderState } from "@/composables/useTenderState";

interface Props {
  tender: Tender;
  supplierId: number;
  priceRequestDetails?: PriceRequestDetails | null;
  serviceCategory: string;
  serviceOfferId: number;
  deadlineDate: string | object;
}

interface FrameAgreementAttachment {
  fileName: string;
  attachmentId: string;
}

interface DisplayAttachment {
  fileName: string;
  downloadHandler: () => Promise<void>;
}

// Add these after the existing imports
enum AgreementAndTermsTitles {
  Agreement = "Avtale",
  Terms = "Avtalevilkår",
}

const agreementAndTermsCms = [
  {
    title: AgreementAndTermsTitles.Terms,
    link: "https://cdn.sanity.io/files/0ls0csm4/production/4fbf969e8e608afe6d1452e7d3640b40d32a6cc1.pdf",
  },
  {
    title: AgreementAndTermsTitles.Agreement,
    link: "https://cdn.sanity.io/files/0ls0csm4/production/4fbf969e8e608afe6d1452e7d3640b40d32a6cc1.pdf",
  },
];

const props = withDefaults(defineProps<Props>(), {
  priceRequestDetails: null,
});

const { isAccepted } = useTenderState(props);

const hasFrameAgreement = computed(
  () => props.priceRequestDetails?.hasFrameAgreement ?? false,
);

const customerOrganizationId = computed(
  () => props.priceRequestDetails?.customerOrgId ?? null,
);

const {
  effectiveReferenceCode,
  effectiveServiceCategory,
  effectiveRequestSent,
  effectiveDesiredStartDate,
  effectiveJointInspection,
  effectiveSharedMessage,
} = usePriceRequestDetails(toRefs(props));

const { frameAgreement, isLoadingFrameAgreement, fetchFrameAgreement } =
  usePriceRequestFrameAgreement(
    props.tender,
    props.supplierId,
    hasFrameAgreement,
    customerOrganizationId,
  );

const {
  shouldShowFeedbackNotification,
  openFeedbackPanel,
  fetchAcceptanceData,
} = usePriceRequestFeedback(props);

const { getAttachment } = useFileAttachmentApi();
const { viewFileInNewWindow, viewOrderConfirmationDocument } =
  useFileDownload();
const router = useRouter();
const isWorkDescriptionTruncated = ref(true);

const priceRequest = computed(() => props.tender.priceRequests?.[0]);

const mappedPriceRequestAttachments = computed(() =>
  (props.priceRequestDetails?.priceRequestAttachmentDtos || []).map(
    (attachment) => ({
      fileName: attachment.fileName,
      downloadHandler: async () => {
        try {
          const response = await getAttachment({
            attachmentId: attachment.attachmentId,
            tenderId: props.tender.tenderId,
            fileName: attachment.fileName,
            supplierId: props.supplierId,
          });

          if (!response?.data) {
            throw new Error("No response data");
          }

          await viewFileInNewWindow(response.data, router);
        } catch (error) {
          console.error("Error downloading attachment:", error);
        }
      },
    }),
  ),
);

const getFrameAgreementAttachments = (
  attachment: FrameAgreementAttachment,
): DisplayAttachment => ({
  fileName: attachment.fileName,
  downloadHandler: async () => {
    try {
      const response = await getAttachment({
        attachmentId: attachment.attachmentId,
        tenderId: props.tender.tenderId,
        fileName: attachment.fileName,
        supplierId: props.supplierId,
      });

      if (!response?.data) {
        throw new Error("No response data");
      }

      await viewFileInNewWindow(response.data, router);
    } catch (error) {
      console.error("Error downloading attachment:", error);
    }
  },
});

const isAgreementRequestState = (state?: RequestStateType) => {
  const validStates = [
    RequestStateType.ACCEPTED,
    RequestStateType.REJECTED,
    RequestStateType.COMPLETED,
  ];
  return state ? validStates.includes(state) : false;
};

const getDocumentHandler = (
  document: (typeof agreementAndTermsCms)[0],
): (() => Promise<void>) => {
  const isAgreementDocument =
    document.title === AgreementAndTermsTitles.Agreement;

  if (isAgreementDocument) {
    return async () => {
      try {
        await viewOrderConfirmationDocument(
          props.tender?.tenderId,
          props.serviceOfferId,
          router,
        );
      } catch (error) {
        console.error("Error viewing order confirmation:", error);
      }
    };
  }

  return async () => {
    if (document.link) {
      window.open(document.link, "_blank");
    }
  };
};

const getDefaultAttachments = computed(() => {
  const requestState = props.priceRequestDetails
    ?.requestState as RequestStateType;

  // Show Agreement for valid states, Terms for others
  const documentToShow = agreementAndTermsCms.find(
    (doc) =>
      doc.title ===
      (isAgreementRequestState(requestState)
        ? AgreementAndTermsTitles.Agreement
        : AgreementAndTermsTitles.Terms),
  );

  return documentToShow
    ? [
        {
          fileName: documentToShow.title,
          downloadHandler: getDocumentHandler(documentToShow),
        },
      ]
    : [];
});

const displayedAttachments = computed(() => {
  const hasFrameAgreements =
    props.priceRequestDetails?.hasFrameAgreement &&
    frameAgreement.value?.attachments &&
    frameAgreement.value?.attachments?.length > 0;

  if (hasFrameAgreements) {
    return frameAgreement.value?.attachments.map(getFrameAgreementAttachments);
  }

  return getDefaultAttachments.value;
});

const detailItems = computed(() => [
  {
    label: "Referansekode",
    value: effectiveReferenceCode.value,
    loading: false,
  },
  {
    label: "Tjenestekategori",
    value: effectiveServiceCategory.value,
    loading: false,
  },
  {
    label: "Forespørsel sendt",
    value: effectiveRequestSent.value,
    loading: false,
  },
  {
    label: "Svarfrist",
    value: props.deadlineDate,
    loading: false,
  },
  {
    label: "Ønsket oppstartsdato",
    value: effectiveDesiredStartDate.value,
    loading: false,
  },
  {
    label: "Felles befaring",
    value: effectiveJointInspection.value,
    loading: false,
  },
]);

const toggleWorkDescription = () => {
  isWorkDescriptionTruncated.value = !isWorkDescriptionTruncated.value;
};

onMounted(async () => {
  await fetchFrameAgreement();
  await fetchAcceptanceData();
});
</script>

<style lang="scss" scoped>
.price-request-details {
  &__label {
    font-size: 16px;
    font-weight: 600;
    color: #1d1d1d;
    margin: 0;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px 40px;
    margin-bottom: 40px;
  }

  &__full-width {
    margin-bottom: 40px;
  }

  &__divider {
    height: 1px;
    background: #dfcfe5;
    margin-bottom: 8px;
  }

  &__skeleton {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__attachment {
    margin-bottom: 8px;
  }

  &__no-attachment {
    display: flex;
    justify-content: start;
    align-items: start;
    height: 100%;
  }

  &__no-attachment-text {
    font-size: 14px;
    font-weight: 400;
    color: #1d1d1d;
  }

  @media (max-width: 768px) {
    padding: 24px;

    &__grid {
      grid-template-columns: 1fr;
    }
  }
}
</style>
