<template>
  <base-file-attachment v-bind="$attrs" :attachment="attachmentDownloader">
    <template v-for="(_, slot) of $slots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </base-file-attachment>
</template>

<script setup lang="ts">
import BaseFileAttachment from "@/components/Attachment/BaseFileAttachment.vue";
import { useFileAttachmentApi } from "@/services/api/useFileAttachmentApi";

defineOptions({
  inheritAttrs: false,
});

const props = defineProps<{
  attachmentId: string;
  supplierId: number;
}>();

const fileAttachmentApis = useFileAttachmentApi();

const attachmentDownloader = async () => {
  const response = await fileAttachmentApis.fetchFileAttachment({
    attachmentId: props.attachmentId,
    supplierId: props.supplierId,
  });
  return response.data;
};
</script>
