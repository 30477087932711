<template>
  <div class="agreement-details-shell">
    <div class="agreement-content">
      <div class="agreement-content__left">
        <template
          v-if="
            !state.isLoadingAgreement &&
            props.acceptedServiceOffer &&
            state.serviceActivitiesCms
          "
        >
          <AgreementDetail
            :agreement-details="agreementDetails"
            :action-buttons="actionButtons"
            :attachments="attachments"
            :tender="tender"
            :supplier-id="supplierId"
            :service-activities-cms="state.serviceActivitiesCms"
          />
        </template>
        <template v-else>
          <AgreementDetailSkeleton />
        </template>
      </div>
      <div class="agreement-content__right">
        <InvoiceRecipient :booking-number="bookingNumber" />
        <CustomerContactInfo
          :customer-details="state.customerDetails"
          :is-loading="state.isCustomerLoading"
        />
      </div>
    </div>
    <div class="agreement-details-shell__bottom">
      <ServiceOffersShell
        :tender="tender"
        :supplier-id="supplierId"
        :supplier-request-id="supplierRequestId"
        :service-offers="serviceOffers"
        :frequency-options="frequencyOptions"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, watch, reactive } from "vue";
import InvoiceRecipient from "./InvoiceRecipient.vue";
import AgreementDetail from "./AgreementDetail.vue";
import CustomerContactInfo from "./CustomerContactInfo.vue";
import AgreementDetailSkeleton from "./AgreementDetailSkeleton.vue";
import ServiceOffersShell from "@/components/ServiceOffers/ServiceOffersShell.vue";
import { FeedbackType } from "@/custom-types/GeneralTypes";
// import type { ServiceOffer } from "@/custom-types/serviceOffers";
import { useTenderApi } from "@/services/api/useTenderApi";
import { useUsersApi } from "@/services/api/useUserApi";
import cmsService from "@/services/cmsService";
import type {
  Tender,
  ServiceOffer as ServiceOfferType,
} from "@/stores/tender/types";
import type { InvoiceRecipient as InvoiceRecipientType } from "@/stores/tender/types";
import { useExtendedBillable } from "@/composables/useExtendedBillable";
import type { SignatureUsers } from "@/composables/useExtendedBillable";
import type { ServiceOffer } from "@/custom-types/GeneralTypes";
import { agreementCache } from "@/services/agreementCache";
import { useCmsStore } from "@/stores/cms";

interface Props {
  tender: Tender;
  supplierId: number;
  frequencyOptions: {
    label: string;
    value: string;
  };
  serviceOfferId: number;
  supplierRequestId: number;
  bookingNumber: string;
  acceptedServiceOffer?: ServiceOfferType | null;
  serviceOffers: ServiceOffer[];
  priceRequestId?: number | undefined;
}

interface CustomerDetails {
  displayName: string | null;
  email: string | null;
  mobilePhone: string | null;
}

interface ServiceActivityCms {
  title: string;
  [key: string]: unknown;
}

interface AgreementDetails {
  nickname: string;
  supplierCost: string;
  supplierCostSubtitle: string;
  serviceCategoryTitle: string;
  suggestedStartDate: string;
  signatureUsers: SignatureUsers[];
  averageCost: string | null;
  acceptedServiceOffer: ServiceOfferType | null;
}

interface State {
  isLoading: boolean;
  isCustomerLoading: boolean;
  errorMessage: {
    type: FeedbackType;
    messageTitle: string;
    messageInfo: string;
  } | null;
  serviceActivitiesCms: ServiceActivityCms[] | null;
  company: InvoiceRecipientType | null;
  customerDetails: CustomerDetails | null;
  isLoadingAgreement: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  frequencyOptions: () => ({ label: "", value: "" }),
  acceptedServiceOffer: null,
  serviceOffers: () => [],
  priceRequestId: undefined,
});

const { getInvoiceRecipient } = useTenderApi();
const { fetchCustomerByEmail } = useUsersApi();

const cmsStore = useCmsStore();
const serviceTypesCms = computed(() => cmsStore.serviceTypesCms);

const state = reactive<State>({
  isLoading: false,
  isCustomerLoading: true,
  errorMessage: null,
  serviceActivitiesCms: null,
  company: null,
  customerDetails: null,
  isLoadingAgreement: false,
});

const {
  actionButtons,
  initialize,
  state: extendedBillableState,
  signatureUsers,
} = useExtendedBillable({
  tender: props.tender,
  supplierId: props.supplierId,
  serviceTypesCms: serviceTypesCms.value,
});

// Check if we have cached data and use it
const checkCacheAndInitialize = async (serviceOffer: ServiceOfferType) => {
  if (!serviceOffer) return;

  const tenderId = props.tender?.tenderId;
  if (!tenderId) return;

  state.isLoadingAgreement = true;

  try {
    // Check if we have cached data
    const cachedData = agreementCache.get(
      tenderId,
      props.supplierId,
      props.serviceOfferId,
    );

    if (cachedData) {
      // Apply cached data directly
      Object.assign(extendedBillableState, cachedData);
    } else {
      // Initialize from API and cache the result
      await initialize(serviceOffer);

      // Store the processed data in cache
      agreementCache.set(tenderId, props.supplierId, props.serviceOfferId, {
        ...extendedBillableState,
      });
    }
  } finally {
    state.isLoadingAgreement = false;
  }
};

// Watch for changes in acceptedServiceOffer and reinitialize when it changes
watch(
  () => props.acceptedServiceOffer,
  async (newOffer) => {
    if (newOffer) {
      await checkCacheAndInitialize(newOffer);
    }
  },
  { immediate: true },
);

const agreementDetails = computed<AgreementDetails>(() => ({
  nickname: extendedBillableState.nickname,
  supplierCost: extendedBillableState.supplierCost,
  supplierCostSubtitle: extendedBillableState.supplierCostSubtitle,
  serviceCategoryTitle: extendedBillableState.serviceCategoryTitle,
  suggestedStartDate: extendedBillableState.suggestedStartDate,
  signatureUsers: signatureUsers.value,
  averageCost: extendedBillableState.averageCost,
  acceptedServiceOffer: extendedBillableState.acceptedServiceOffer,
}));

const attachments = computed(
  () =>
    extendedBillableState.acceptedServiceOffer?.serviceOfferAttachments ?? [],
);
const bookingNumber = computed(() => props.bookingNumber);

const resolveCustomerInvoiceRecipient = async () => {
  const tenderId = props.tender?.tenderId;
  const offerId = props.serviceOfferId;
  if (!tenderId || !offerId) {
    console.warn("Tender or offer ID is missing");
    return null;
  }

  try {
    const result = await getInvoiceRecipient(tenderId, offerId);
    return result?.data ?? null;
  } catch (e) {
    console.error("Error fetching company data:", e);
    return null;
  }
};

const resolveCustomer = async () => {
  const email = state.company?.email;
  if (!email) return;

  state.isCustomerLoading = true;

  // Check cache for customer details first
  const customerCacheKey = `customer_${email}`;
  const cachedCustomer = sessionStorage.getItem(customerCacheKey);

  if (cachedCustomer) {
    state.customerDetails = JSON.parse(cachedCustomer);
    state.isCustomerLoading = false;
    return;
  }

  try {
    const result = await fetchCustomerByEmail(email);
    state.customerDetails = result?.data ?? null;

    // Cache the customer details
    if (state.customerDetails) {
      sessionStorage.setItem(
        customerCacheKey,
        JSON.stringify(state.customerDetails),
      );
    }
  } catch (e) {
    console.error("Error fetching customer data:", e);
  } finally {
    state.isCustomerLoading = false;
  }
};

watch(() => state.company, resolveCustomer, { deep: true, immediate: true });

onMounted(async () => {
  state.isLoading = true;
  const ERROR_TITLE = "Feil ved lasting av data";

  try {
    // Check if we have service activities in cache
    const tenderId = props.tender?.tenderId;
    const cacheKey = `service_activities_${tenderId}`;
    const cachedActivities = sessionStorage.getItem(cacheKey);

    if (cachedActivities) {
      state.serviceActivitiesCms = JSON.parse(cachedActivities);
    } else {
      const serviceActivitiesCms =
        (await cmsService.getServiceActivities()) as ServiceActivityCms[];
      state.serviceActivitiesCms = serviceActivitiesCms;

      // Store in session storage for quicker access next time
      sessionStorage.setItem(cacheKey, JSON.stringify(serviceActivitiesCms));
    }

    // Company data
    const companyCacheKey = `company_${tenderId}`;
    const cachedCompany = sessionStorage.getItem(companyCacheKey);

    if (cachedCompany) {
      state.company = JSON.parse(cachedCompany);
    } else {
      const data = await resolveCustomerInvoiceRecipient();
      state.company = data;
      sessionStorage.setItem(companyCacheKey, JSON.stringify(state.company));
    }
  } catch (error) {
    const errorData = error instanceof Error ? error.message : String(error);
    state.errorMessage = {
      type: FeedbackType.ERROR,
      messageTitle: ERROR_TITLE,
      messageInfo: errorData,
    };
  } finally {
    state.isLoading = false;
  }
});
</script>

<style lang="scss" scoped>
.agreement-details-shell {
  min-height: 22rem;
  padding: 0 1rem;
  width: 100%;

  &__bottom {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

.agreement-content {
  display: flex;
  gap: 1rem;
  width: 100%;

  &__left {
    flex: 3;
    min-width: 38rem;
    background-color: $color-primary-pink;
    border-radius: 4px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  }

  &__right {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    > * {
      flex: 1;
      width: 100%;
      background-color: $color-primary-pink;
      border-radius: 4px;
    }
  }
}
</style>
