import { computed } from "vue";
import { RequestStateType } from "@/custom-types/GeneralTypes";
import { Tender, BillingCycle } from "@/stores/tender/types";

type TenderStateProps = {
  tender: Tender;
  supplierId: number;
};

export const useTenderState = (props: TenderStateProps) => {
  const supplierRequest = computed(() =>
    props.tender?.priceRequests?.[0]?.supplierRequests.find(
      (request) => request.supplierId === props.supplierId,
    ),
  );

  const requestState = computed(
    () =>
      supplierRequest.value?.requestState?.toLowerCase() as
        | RequestStateType
        | undefined,
  );

  const isInState = (state: RequestStateType): boolean =>
    Boolean(requestState.value === state.toLowerCase());

  const isInAnyState = (states: RequestStateType[]): boolean =>
    Boolean(
      requestState.value &&
        states.includes(requestState.value as RequestStateType),
    );

  const REJECTED_STATES = [
    RequestStateType.REJECTED,
    RequestStateType.ARCHIVED,
    RequestStateType.UNANSWERED,
  ] as const;

  const COMPLETED_OR_ACCEPTED_STATES = [
    RequestStateType.COMPLETED,
    RequestStateType.ACCEPTED,
  ] as const;

  const isRejected = computed(() =>
    isInAnyState(REJECTED_STATES as unknown as RequestStateType[]),
  );

  const isCompletedOrAccepted = computed(() =>
    isInAnyState(COMPLETED_OR_ACCEPTED_STATES as unknown as RequestStateType[]),
  );

  const hasActiveAgreement = computed(() =>
    isInState(RequestStateType.ACCEPTED),
  );

  const isTenderCompleted = computed(() =>
    isInState(RequestStateType.COMPLETED),
  );

  const isAccepted = computed(() => isInState(RequestStateType.ACCEPTED));

  const isTenderLost = computed(() => isInState(RequestStateType.LOST));

  const isSingleJob = computed(() => {
    const cycles = props.tender?.billingCycles?.flat() || [];
    return (
      cycles.length === 1 &&
      cycles[0]?.toLowerCase() === BillingCycle.Fixed.toLowerCase()
    );
  });

  const showOfferButton = computed((): boolean => {
    if (!props.tender) return false;
    if (isTenderLost.value) return false;
    if (hasActiveAgreement.value) return false;
    if (isRejected.value) return false;
    if (isTenderCompleted.value) return false;

    return true;
  });

  const showMarkAsDoneButton = computed(
    (): boolean => isSingleJob.value && isAccepted.value,
  );

  const showRejectPriceRequestButton = computed((): boolean => {
    if (!requestState.value) return false;
    if (isTenderCompleted.value) return false;
    if (isTenderLost.value) return false;

    return !isRejected.value && !isCompletedOrAccepted.value;
  });

  const showCreatePriceRequestButton = computed((): boolean =>
    Boolean(props.tender && hasActiveAgreement.value),
  );

  return {
    showRejectPriceRequestButton,
    showOfferButton,
    showMarkAsDoneButton,
    showCreatePriceRequestButton,
    hasActiveAgreement,
    isTenderCompleted,
    isAccepted,
    isTenderLost,
    supplierRequest,
  };
};
