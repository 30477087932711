import { executeApiRequest } from "@/services/api/apiService";
import {
  AttachmentApi,
  AttachmentApiEndpoints,
} from "@/config/api/attachment/attachmentApi";
import { useToastErrorHandler } from "@/composables/useToastErrorHandler";

interface FileAttachmentRequest {
  attachmentId: string;
  supplierId: number;
}

interface AttachmentRequest {
  attachmentId: string;
  tenderId: number;
  fileName: string;
  supplierId: number;
}

const useFileAttachmentApiService = () => {
  const { withToastErrorHandling } = useToastErrorHandler();

  const fetchFileAttachment = async (req: FileAttachmentRequest) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<Blob>(
          {
            endpointKey: Symbol.for(AttachmentApiEndpoints.GET_FILE_ATTACHMENT),
            config: AttachmentApi,
          },
          {
            pathParams: [req.attachmentId, "supplier", req.supplierId],
            responseType: "blob",
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Vedleggsfeil",
          detail: "Kunne ikke hente filvedlegg",
        },
      },
    );
  };

  const getPublicNoteAttachment = async (req: AttachmentRequest) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<Blob>(
          {
            endpointKey: Symbol.for(AttachmentApiEndpoints.GET_ATTACHMENT),
            config: AttachmentApi,
          },
          {
            pathParams: [req?.attachmentId, "supplier", req?.supplierId],
            responseType: "blob",
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Vedleggsfeil",
          detail: "Kunne ikke hente offentlig notatvedlegg",
        },
      },
    );
  };

  const getAttachment = async (req: AttachmentRequest) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<Blob>(
          {
            endpointKey: Symbol.for(AttachmentApiEndpoints.GET_ATTACHMENT),
            config: AttachmentApi,
          },
          {
            pathParams: [req?.attachmentId, "supplier", req?.supplierId],
            responseType: "blob",
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Vedleggsfeil",
          detail: "Kunne ikke hente vedlegg",
        },
      },
    );
  };

  return {
    fetchFileAttachment,
    getPublicNoteAttachment,
    getAttachment,
  };
};

export const useFileAttachmentApi = useFileAttachmentApiService;
