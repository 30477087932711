import { computed } from "vue";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { PriceRequestDetails } from "@/stores/tender/types";

dayjs.extend(utc);

const calculateDeadline = (
  startDateTime: string,
  daysToAdd: number,
): Date | undefined => {
  try {
    const startDate = dayjs.utc(startDateTime).toDate();
    if (isNaN(startDate.getTime())) return undefined;

    return addBusinessDays(startDate, daysToAdd);
  } catch {
    return undefined;
  }
};

const addBusinessDays = (startDate: Date, daysToAdd: number): Date => {
  const result = new Date(startDate.getTime());
  let daysRemaining = daysToAdd;

  while (daysRemaining > 0) {
    result.setUTCDate(result.getUTCDate() + 1);
    const dayOfWeek = result.getUTCDay();
    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
      daysRemaining--;
    }
  }
  return result;
};

const calculateDaysToDeadline = (deadlineDate: Date): number | undefined => {
  if (!deadlineDate) return undefined;

  const now = dayjs.utc();
  const deadline = dayjs.utc(deadlineDate);

  if (!now.isValid() || !deadline.isValid()) {
    console.warn(`Invalid deadline date for tender`);
    return undefined;
  }

  return Math.ceil(deadline.diff(now, "day", true));
};

export const useTenderDeadline = (priceRequestDetails: PriceRequestDetails) => {
  const getResponseDeadlineDate = computed(() => {
    try {
      const startDateTime = priceRequestDetails?.createdOn;
      if (!startDateTime) return undefined;

      return calculateDeadline(
        startDateTime,
        priceRequestDetails.responsePeriodInDays,
      );
    } catch {
      console.warn(
        `Error calculating deadline for tender ${priceRequestDetails?.tenderId}`,
      );
      return undefined;
    }
  });

  const daysToDeadline = computed(() => {
    const deadlineDate = getResponseDeadlineDate.value;
    return deadlineDate ? calculateDaysToDeadline(deadlineDate) : undefined;
  });

  const isPastDeadline = computed(() => {
    const days = daysToDeadline.value;
    return typeof days === "number" && days <= 0;
  });

  const formattedDeadlineDate = computed(() => {
    const deadlineDate = getResponseDeadlineDate.value;
    if (!deadlineDate) return "Ikke satt";

    try {
      const formatted = dayjs(deadlineDate).format("DD. MMMM YYYY");
      return formatted || "Ikke satt";
    } catch {
      return "Ikke satt";
    }
  });

  return {
    isPastDeadline,
    daysToDeadline,
    formattedDeadlineDate,
    getResponseDeadlineDate,
  };
};
