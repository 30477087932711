<template>
  <div class="terms">
    <h2>{{ title }}</h2>
    <div class="terms__description">
      <custom-block-content :blocks="description" />
    </div>
    <div class="terms__section">
      <service-start-date
        :page-cms="pageCms"
        :service-offer="serviceOffer"
        :supplier-id="supplierId"
        :read-only="readOnly"
        @start-date-updated="handleUpdatedStartDate"
        @termination-period-updated="handleUpdatedTerminationPeriod"
      />
    </div>
    <div v-if="hasRepeatingCosts" class="terms__section">
      <termination-notice-period
        :cms="noticePeriodCms"
        :service-offer="serviceOffer"
        :supplier-id="supplierId"
        :read-only="readOnly"
      />
    </div>
    <div
      v-if="state.frameAgreement?.attachments.length"
      class="terms__section frame-agreement"
    >
      <div class="frame-agreement__icon">
        <img :src="ContractIcon" alt="" />
      </div>
      <div class="frame-agreement__content">
        <div class="frame-agreement__text">
          <h4>{{ frameAgreementTitle }}</h4>
          <div class="frame-agreement-description">
            <custom-block-content :blocks="frameAgreementDescription" />
          </div>
        </div>
        <div class="frame-agreement__attachments">
          <supplier-attachment
            v-for="attachment in state.frameAgreement.attachments"
            :key="attachment.attachmentId"
            :file-name="attachment.fileName"
            :removable="false"
            :supplier-id="supplierId"
            :attachment-id="attachment.attachmentId"
          >
            <template #leftIcon>
              <img
                :src="ContractIcon"
                alt=""
                class="terms__frame-agreement-attachment-icon"
              />
            </template>
          </supplier-attachment>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, reactive } from "vue";
import ContractIcon from "@/assets/img/contract.svg";
import ServiceStartDate from "@/views/TenderOffer/EditOfferView/ServiceStartDate.vue";
import CustomBlockContent from "@/components/CustomBlockContent/CustomBlockContent.vue";
import { BillingCycle } from "@/custom-types/types.js";
import { useTenderApi } from "@/services/api/useTenderApi";
import SupplierAttachment from "@/components/Attachment/SupplierAttachment.vue";
import TerminationNoticePeriod from "@/views/TenderOffer/EditOfferView/Terms/TerminationNoticePeriod.vue";

const props = defineProps({
  pageCms: {
    type: Object,
    required: true,
  },
  serviceOffer: {
    type: Object,
    required: true,
  },
  supplier: {
    type: Object,
    required: true,
  },
  supplierId: {
    type: Number,
    required: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["start-date-updated", "termination-period-updated"]);

const tenderApis = useTenderApi();

const state = reactive({
  loadingAttachmentId: null,
  frameAgreement: undefined,
});

const termsCms = computed(() =>
  props.pageCms.pageSections.find((section) => section.label === "terms"),
);
const noticePeriodCms = computed(() =>
  props.pageCms.pageSections.find(
    (section) => section.label === "noticePeriod",
  ),
);
const frameAgreementCms = computed(() =>
  props.pageCms.pageSections.find(
    (section) => section.label === "frameAgreement",
  ),
);
const title = computed(() => termsCms.value?.title);
const description = computed(() => termsCms.value?.content);
const frameAgreementTitle = computed(() => frameAgreementCms.value?.title);
const frameAgreementDescription = computed(
  () => frameAgreementCms.value?.content,
);
const hasRepeatingCosts = computed(() => {
  return props.serviceOffer.mandatorySupplierCosts.some(
    (activity) =>
      activity.billingCycle.toLowerCase() !== BillingCycle.Fixed.toLowerCase(),
  );
});

const handleUpdatedStartDate = (data) => {
  emit("start-date-updated", data);
};
const handleUpdatedTerminationPeriod = (data) => {
  emit("termination-period-updated", data);
};
const fetchFrameAgreement = async () => {
  if (!props.serviceOffer.frameAgreementId) {
    return;
  }
  const supplierFrameAgreement = props.supplier.frameAgreements.find(
    (record) => record.id === props.serviceOffer.frameAgreementId,
  );
  if (!supplierFrameAgreement) {
    return;
  }
  const response = await tenderApis.getFrameAgreement({
    frameAgreementId: supplierFrameAgreement.id,
    organizationId: supplierFrameAgreement.customerOrganizationId,
  });
  state.frameAgreement = response.data;
};
const initialize = () => {
  fetchFrameAgreement();
};
initialize();
</script>

<style lang="scss" scoped>
.terms {
  font-family: "Montserrat", sans-serif;
  color: #1d1d1d;

  &__description {
    margin-top: 0.5rem;
  }

  .notice-period-description,
  .frame-agreement-description {
    :deep(p) {
      margin: 0;
      font-size: 14px;
      font-weight: 400;
      color: #5a5a5a;
    }
  }

  &__frame-agreement-attachment-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.terms h2 {
  font-size: 24px;
  font-weight: 600;
}

.terms p {
  font-size: 14px;
  font-weight: 400;
  color: black;
}

.terms__section h3 {
  font-size: 16px;
  font-weight: 600;
}

.terms__section p {
  font-size: 14px;
  font-weight: 400;
  color: #5a5a5a;
}

.terms__section {
  margin-top: 24px;
}

.frame-agreement {
  display: inline-flex;
  gap: 8px;
  padding: 16px;
  background: #f1e6f5;
  border: 1px solid #dfcfe5;
  border-radius: 8px;
  width: 100%;
}

.frame-agreement__icon {
  width: 40px;
  height: 40px;
  position: relative;
}

.frame-agreement__content {
  flex: 1;
  display: inline-flex;
  flex-direction: column;
  gap: 8px;
}

.frame-agreement__text {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.frame-agreement__text h4 {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}

.frame-agreement__text p {
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

.frame-agreement__attachments {
  display: flex;
  flex-wrap: wrap;
  gap: 0.375rem;
}
</style>
