import { defineStore } from "pinia";
import { SupplierUser } from "./types";
import { useUsersApi } from "@/services/api/useUserApi";

export const useSupplierUsersStore = defineStore("supplierUsers", {
  state: () => ({
    supplierUsers: [] as SupplierUser[],
    loading: false,
    error: null as string | null,
  }),
  getters: {
    getSupplierUsers: (state) => state.supplierUsers,
  },
  actions: {
    setSupplierUsers(supplierUsers: SupplierUser[]) {
      this.supplierUsers = supplierUsers;
    },
    setLoading(loading: boolean) {
      this.loading = loading;
    },
    setError(error: string | null) {
      this.error = error;
    },
    async fetchSupplierUsers(supplierId: number) {
      this.setLoading(true);
      this.setError(null);
      try {
        const { fetchSupplierUsers } = useUsersApi();
        const response = await fetchSupplierUsers(supplierId);
        this.setSupplierUsers(response.data);
      } catch (error) {
        this.setError(error instanceof Error ? error.message : "Unknown error");
      } finally {
        this.setLoading(false);
      }
    },
  },
});
