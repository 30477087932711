<template>
  <div id="app">
    <Toast />
    <router-view v-slot="{ Component }">
      <component :is="Component" v-if="!isLoading" class="router-view" />
      <loading-view v-else />
    </router-view>
    <BaseSidePanel />
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useMsalAuthentication } from "@/composables/useMsalAuthentication";
import LoadingView from "@/views/LoadingView/LoadingView.vue";
import { useSupplierStore } from "@/stores/supplier";
import BaseSidePanel from "@/components/BaseSidePanel/BaseSidePanel.vue";
import { useTermsGuard } from "@/composables/useTermsGuard";
import { useUserStore } from "@/stores/user";
import { useToastErrorHandler } from "@/composables/useToastErrorHandler";

const isLoading = ref(true);
const { handleError } = useToastErrorHandler();

const supplierStore = useSupplierStore();
const userStore = useUserStore();

const { initializeMsal, signIn } = useMsalAuthentication();

const loadNonCriticalData = async () => {
  try {
    await Promise.all([supplierStore.fetchServiceTypes()]);
  } catch (error) {
    // Use toast error handler for non-critical errors
    handleError(error, {
      summary: "Warning",
      detail:
        "Some non-critical data could not be loaded. You can continue using the application.",
    });
    console.error("Error loading non-critical data:", error);
  }
};

const initialize = async () => {
  try {
    isLoading.value = true;

    // Run critical initialization tasks in parallel
    await Promise.all([
      // Authentication
      initializeMsal().then(() => signIn()),
    ]);

    // Get user ID from token claims
    const storedUserId = userStore.userAccount?.idTokenClaims?.sub ?? "";

    // Fetch supplier data (critical for UI)
    await supplierStore.fetchSupplierByUserId(storedUserId);

    // After critical resources are loaded, show UI
    isLoading.value = false;

    // Then load non-critical data in the background
    loadNonCriticalData();
  } catch (error) {
    console.error("Initialization error:", error);
    isLoading.value = false;
  }
};

initialize();

useTermsGuard();
</script>

<style lang="scss">
.p-toast {
  top: 80px !important;
}

.p-toast-summary {
  margin-top: -6px;
}

.p-toast-message-text {
  gap: 0 !important;
}

.p-toast-close-button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.p-toast-close-icon {
  border: none !important;
}

.p-tooltip {
  &.tooltip-custom {
    z-index: 999 !important;
  }
}

.p-tooltip {
  &.tooltip-custom {
    &:not([data-p-position="right"]) .p-tooltip-arrow {
      width: 0 !important;
      height: 0 !important;
      border-style: solid !important;
      border-width: 0 8px 8px 8px !important;
      border-color: transparent transparent #fff transparent !important;
      position: absolute !important;
      top: -4px !important;
      left: 50% !important;
      transform: translateX(-50%) !important;
    }

    .p-tooltip-text {
      background-color: #fff !important;
      color: #000 !important;
      font-size: 0.75rem !important;
      line-height: 1.2rem !important;
      padding: 8px 12px !important;
      border-radius: 4px !important;

      width: auto !important;
      min-width: 120px !important;
      max-width: 300px !important;
      white-space: pre-line !important;
      text-align: left !important;

      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15) !important;
    }

    &.p-tooltip-show {
      .p-tooltip-text {
        opacity: 1 !important;
        visibility: visible !important;
      }
    }
  }
}

.p-menu {
  border: none !important;
  border-radius: 0 !important;
  width: 100% !important;
  background: #f1e6f5 !important;
  min-width: 14.5rem !important;
  position: sticky !important;
  top: 0 !important;
  height: 100vh !important;
  overflow-y: auto !important;

  .p-menuitem {
    &:hover {
      background: #dfcfe5 !important;
    }

    &:active {
      background: #d1bdd7 !important;
    }
  }

  .p-menuitem-link {
    &.p-menuitem-link-active {
      background: #dfcfe5 !important;
      border-left: 2px solid #441b49 !important;
    }
  }

  .menu-item--active {
    border-left: 0px solid #441b49 !important;
  }
}
.p-badge-success {
  background: #008000 !important;
  background-color: #008000 !important;
  color: #fff !important;
}

.p-badge-dot {
  background-color: #ff0000 !important;
}

.p-badge-sm {
  min-width: 10px !important;
  height: 10px !important;
  font-size: 8px !important;
  background-color: #ff0000 !important;
  color: #fff !important;
}

.p-badge-circle {
  min-width: 15px !important;
  height: 15px !important;
  font-size: 10px !important;
  background-color: #ff0000 !important;
  color: #fff !important;
}

@media (min-width: 768px) {
  .p-menu {
    &.menu-scroll-up,
    &.menu-scroll-down {
      top: 4rem !important;
    }
  }
}
</style>
