<template>
  <b-modal
    v-model="showModal"
    :no-close-on-backdrop="true"
    centered
    hide-footer
    hide-header
    body-class="p-0"
  >
    <div v-if="sendOfferPageCms" class="send-offer-modal__content">
      <SendOfferForm
        v-if="!offerSent"
        :service-offer="serviceOffer"
        :supplier-id="supplierId"
        :price-request-id="priceRequestId"
        :send-offer-page-cms="sendOfferPageCms"
        @offer-sent="handlerOfferSent"
        @done="showOfferSentAcknowledgement"
        @cancel="closeModal"
      />
      <OfferSent
        v-else
        :send-offer-page-cms="sendOfferPageCms"
        :customer-org-name="serviceOffer.customerOrgName"
        @close="handleOfferSentAcknowledgment"
      />
    </div>
    <div v-else class="send-offer-modal__loader">
      <b-spinner />
    </div>
  </b-modal>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue";
import SendOfferForm from "./SendOfferForm.vue";
import OfferSent from "./OfferSent.vue";
import cmsService from "@/services/cmsService";
import {
  type ServiceOffer,
  type SendOfferCmsContent,
  TenderViewTab,
} from "@/stores/tender/types";
import { useRouter } from "vue-router";
import { OfferStateType } from "@/custom-types/GeneralTypes";
interface Props {
  modelValue: boolean;
  serviceOffer: ServiceOffer;
  supplierId: number;
  priceRequestId: number;
}

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: "update:modelValue", value: boolean): void;
  (e: "offer-sent-acknowledged"): void;
  (e: "reload-offer-list"): void;
}>();

const router = useRouter();

const offerSent = ref(false);
const sendOfferPageCms = ref<SendOfferCmsContent | null>(null);

const showModal = computed({
  get: () => props.modelValue,
  set: (newVal: boolean) => emit("update:modelValue", newVal),
});

const closeModal = () => {
  showModal.value = false;
};

const handlerOfferSent = () => {
  console.log("handlerOfferSent");
};

const showOfferSentAcknowledgement = () => {
  console.log("showOfferSentAcknowledgement -", props.serviceOffer);
  if (props.serviceOffer.currentState !== OfferStateType.WITHDRAWN) {
    offerSent.value = true;
  }
};

const handleOfferSentAcknowledgment = async () => {
  const { priceRequestId } = props;
  const targetQuery = {
    tabList: "active",
    priceRequestId: priceRequestId.toString(),
    tab: TenderViewTab.OFFERS,
  };

  emit("offer-sent-acknowledged");
  closeModal();

  const currentRoute = router.currentRoute.value;
  if (
    currentRoute.path === "/my-requests" &&
    currentRoute.query.tabList === targetQuery.tabList &&
    currentRoute.query.priceRequestId === targetQuery.priceRequestId &&
    currentRoute.query.tab === targetQuery.tab
  ) {
    emit("reload-offer-list");
  } else {
    await router.replace({
      path: "/my-requests",
      query: targetQuery,
    });
  }
};

onMounted(async () => {
  sendOfferPageCms.value = await cmsService.getSendOfferModalPage();
});
</script>

<style lang="scss" scoped>
.send-offer-modal {
  &__content {
    display: block;
    padding: 2.5rem;
    font-family: "Montserrat", sans-serif;
  }

  &__loader {
    padding: 8rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
