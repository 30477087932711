<template>
  <div v-if="showComponent" class="product-description">
    <div class="product-description__title">{{ title }}</div>
    <div class="product-description__description">
      <custom-block-content :blocks="descriptionContent" />
    </div>
    <div
      v-if="offerAttachments.length"
      class="product-description__attachments"
    >
      <div
        v-for="attachment in offerAttachments"
        :key="attachment.attachmentId"
        class="product-description__attachment"
      >
        <supplier-attachment
          :file-name="attachment.fileName"
          :removable="!readOnly"
          :supplier-id="supplierId"
          :attachment-id="attachment.attachmentId"
          :full-width="true"
          @remove="removeAttachment(attachment)"
        />
      </div>
    </div>
    <file-input
      v-if="!readOnly"
      v-slot="{ openFileInput }"
      :valid-file-extensions="validFileExtensions"
      @selected-files="handleSelectedFiles"
    >
      <div class="product-description__input-section">
        <secondary-button
          :icon-src="addAttachmentsButtonIcon"
          :text="addAttachmentsButtonText"
          @click="openFileInput"
        />
        <div>({{ formattedValidFileExtensions }})</div>
      </div>
    </file-input>
    <upload-attachments-modal
      v-model="showAddAttachmentsModal"
      :supplier-id="supplierId"
      :supplier-request-id="serviceOffer.supplierRequestId"
      :offer-id="serviceOffer.serviceOfferId"
      :attachments="attachmentsToUpload"
      @new-attachments="handleNewAttachments"
    />
    <remove-service-offer-attachment-modal
      v-if="attachmentToRemove"
      v-model="showRemoveAttachmentModal"
      :supplier-id="supplierId"
      :supplier-request-id="serviceOffer.supplierRequestId"
      :offer-id="serviceOffer.serviceOfferId"
      :attachment="attachmentToRemove"
      @attachment-removed="handleAttachmentRemoved"
    />
  </div>
</template>

<script>
import FileInput from "@/components/FileInput/FileInput.vue";
import { uuidv4 } from "@/utilities/uuid";
import {
  getFilesWithValidExtension,
  getFilesWithValidSize,
} from "@/utilities/fileUtils";
import cmsService from "@/services/cmsService";
import CustomBlockContent from "@/components/CustomBlockContent/CustomBlockContent.vue";
import SecondaryButton from "@/components/Buttons/SecondaryButton.vue";
import UploadServiceOfferAttachmentsModal from "@/views/TenderOffer/EditOfferView/UploadProductDescription/UploadServiceOfferAttachmentsModal.vue";
import RemoveServiceOfferAttachmentModal from "@/views/TenderOffer/EditOfferView/UploadProductDescription/RemoveServiceOfferAttachmentModal.vue";
import SupplierAttachment from "@/components/Attachment/SupplierAttachment.vue";

export default {
  components: {
    SupplierAttachment,
    RemoveServiceOfferAttachmentModal,
    UploadAttachmentsModal: UploadServiceOfferAttachmentsModal,
    SecondaryButton,
    CustomBlockContent,
    FileInput,
  },
  props: {
    pageCms: {
      type: Object,
      required: true,
    },
    serviceOffer: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    supplierId: {
      type: Number,
      required: true,
    },
  },
  emits: ["new-attachments", "remove-attachment"],
  data() {
    return {
      initialized: false,
      maxFileSizeInMB: 20,
      attachmentsToUpload: [],
      attachmentToRemove: undefined,
      validFileExtensions: [".pdf", ".jpg", ".jpeg", ".png"],
      showAddAttachmentsModal: false,
      showRemoveAttachmentModal: false,
    };
  },
  computed: {
    offerAttachments() {
      return this.serviceOffer.serviceOfferAttachments;
    },
    showComponent() {
      return !this.readOnly || this.offerAttachments.length;
    },
    productDescriptionUploadCms() {
      return this.pageCms.pageSections.find(
        (section) => section.label === "uploadProductDescription",
      );
    },
    title() {
      return this.productDescriptionUploadCms.title;
    },
    descriptionContent() {
      return this.productDescriptionUploadCms.content;
    },
    addAttachmentsButton() {
      return this.productDescriptionUploadCms.buttons.find(
        (button) => button.label === "addAttachmentsButton",
      );
    },
    addAttachmentsButtonText() {
      return this.addAttachmentsButton.title;
    },
    addAttachmentsButtonIcon() {
      const icon = this.addAttachmentsButton.icons[0].icon;
      return cmsService.resolveImage(icon).url();
    },
    formattedValidFileExtensions() {
      return this.validFileExtensions.join(", ");
    },
  },
  methods: {
    handleNewAttachments(attachments) {
      console.log("Attachments: ", attachments);
      this.$emit("new-attachments", attachments);
    },
    handleAttachmentRemoved(attachment) {
      this.$emit("remove-attachment", attachment);
    },
    handleSelectedFiles(files) {
      let validFiles = getFilesWithValidExtension(
        files,
        this.validFileExtensions,
      );
      validFiles = getFilesWithValidSize(validFiles, this.maxFileSizeInMB);
      this.attachmentsToUpload = validFiles.map((file) => {
        return {
          id: uuidv4(),
          file: file,
        };
      });
      if (this.attachmentsToUpload.length) {
        this.showAddAttachmentsModal = true;
      }
    },
    removeAttachment(attachment) {
      this.attachmentToRemove = attachment;
      this.$nextTick(() => {
        this.showRemoveAttachmentModal = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.product-description {
  &__title {
    font-weight: 600;
    font-size: $font-size-lg;
  }

  &__description {
    margin: 0;
    font-weight: 400;
    font-size: $font-size-sm;
  }

  &__input-section {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &__input-button {
    border: none;
    padding: 0.625rem 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border-radius: 8px;
    background-color: $color-primary-pink-dark;

    &:hover {
      background: $color-primary-pink-darker;
    }
  }

  &__attachments {
    max-width: 500px;
    margin-top: 1rem;
  }

  &__attachment {
    &:not(:first-child) {
      margin-top: 0.5rem;
    }
  }

  &__loader {
    padding: 2rem 0 3rem;
    display: flex;
    justify-content: center;
  }
}
</style>
