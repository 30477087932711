<template>
  <div class="tender-details-view">
    <template v-if="!isLoading && !error">
      <TenderDetailsHeader
        :supplier-id="supplierId"
        :tender="tenderDetails"
        :show-give-offer-button="showOfferButton"
        :show-reject-price-request-button="showRejectPriceRequestButton"
        :show-mark-as-done-button="showMarkAsDoneButton"
        :show-create-price-request-button="showCreatePriceRequestButton"
        @on-create-price-request-click="handleCreatePriceRequest"
        @on-price-request-rejected-click="handleRejectPriceRequest"
        @on-mark-as-done-click="handleMarkAsDone"
        @on-new-offer-click="handleNewOffer"
      >
        <template #agreementResponsible>
          <AgreementResponsible
            :supplier-id="supplierId"
            :tender="tenderDetails"
            :tender-id="priceRequestDetails?.tenderId ?? null"
          />
        </template>
      </TenderDetailsHeader>

      <Tabs
        :value="activeTabValue"
        class="tender-details-tabs"
        :scrollable="getAvailableTabs.length > 3"
        :lazy="true"
        @update:value="(val) => handleTabChange(val.toString())"
      >
        <TabList>
          <Tab
            v-for="tab in getAvailableTabs"
            :key="tab.value"
            :value="tab.value"
            :label="tab.label"
            :disabled="tab.disabled"
          >
            {{ tab.label }}
            <Badge
              v-if="
                (tab.value === TenderViewTab.OFFERS &&
                  serviceOffersCount > 0) ||
                (tab.value === TenderViewTab.MESSAGES &&
                  (hasUnreadMessages || unreadMessagesCount > 0))
              "
              :value="
                tab.value === TenderViewTab.OFFERS
                  ? serviceOffersCount.toString()
                  : unreadMessagesCount > 0
                    ? unreadMessagesCount.toString()
                    : '1'
              "
              severity="success"
              class="ml-2"
            />
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel
            v-for="tab in getAvailableTabs"
            :key="`panel-${tab.value}`"
            :value="tab.value"
          >
            <keep-alive>
              <component
                :is="tab.component"
                v-if="tab.component"
                v-bind="getTabComponentProps(tab)"
                @scroll-to-row="$emit('scroll-to-row', $event)"
                @fetch-service-offers="fetchServiceOffers"
                @create-offer="handleNewOffer"
              />
            </keep-alive>
          </TabPanel>
        </TabPanels>
      </Tabs>

      <PriceRequestRejectionModal
        v-model="showRejectionModal"
        :tender-id="priceRequestDetails?.tenderId"
        :price-request-id="priceRequestDetails?.priceRequestId"
        :supplier-request-id="supplierRequestId"
        :user-id="currentUserId ?? 0"
        :supplier-id="supplierId"
        @price-request-rejected="handlePriceRejected"
      />

      <PriceRequestRejectionSuccessModal
        v-model="showRejectionSuccessModal"
        @primary-click="handleRejectionSuccessConfirmWithReload"
      />

      <CompleteOfferModal
        v-model="showCompleteOfferModal"
        :tender-id="priceRequestDetails?.tenderId ?? null"
        :supplier-id="supplierId"
        :supplier-request-id="supplierRequestId ?? null"
        :service-offer-id="selectedServiceOfferId ?? 0"
        @completed="handleOfferCompleted"
      />

      <CreatePriceRequestModal
        v-model="showCreateRequestModal"
        :supplier-service-types="validatedServiceTypes"
        :supplier-users="supplierUsers"
        :tender="tenderDetails"
        :supplier-id="supplierId"
      />

      <LateOfferWarningModal
        ref="lateOfferModal"
        :tender-id="tenderDetails?.tenderId"
        @ok="handleLateOfferConfirmation"
      />
    </template>

    <div v-else-if="error" class="tender-details-view__error">
      <AlertMessage severity="error" icon="pi pi-exclamation-triangle">
        {{ error }}
        <Button label="Prøv igjen" @click="handleRetry" />
      </AlertMessage>
    </div>

    <div v-else class="tender-details-view__spinner-wrapper">
      <TenderDetailSkeletonLoader />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, watch, defineProps, defineEmits, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import Tabs from "primevue/tabs";
import TabList from "primevue/tablist";
import Tab from "primevue/tab";
import TabPanels from "primevue/tabpanels";
import TabPanel from "primevue/tabpanel";
import { storeToRefs } from "pinia";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import AlertMessage from "primevue/message";
import Button from "primevue/button";
import Badge from "primevue/badge";
import { OfferStateType } from "@/custom-types/GeneralTypes";

// Components
import TenderDetailsHeader from "./TenderDetailsHeader.vue";
import AgreementResponsible from "@/components/AgreementResponsible/AgreementResponsible.vue";
import PriceRequestRejectionModal from "@/components/RejectPriceRequestModal/RejectPriceRequestModal.vue";
import PriceRequestRejectionSuccessModal from "@/components/RejectPriceRequestSuccessModal/RejectPriceRequestSuccessModal.vue";
import CreatePriceRequestModal from "@/components/CreatePriceRequestModal/CreatePriceRequestModal.vue";
import CompleteOfferModal from "@/components/CompleteTenderOfferModal/CompleteTenderOfferModal.vue";
import LateOfferWarningModal from "@/components/LateOfferModal/LateOfferModal.vue";
import TenderDetailSkeletonLoader from "./TenderDetailSkeletonLoader.vue";

// Composables and Utilities
import { useTenderNavigation } from "@/composables/useTenderNavigation";
import { useTenderState } from "@/composables/useTenderState";
import { usePriceRequestHandling } from "@/composables/usePriceRequestHandling";
import { useServiceOffers } from "@/composables/useServiceOffers";
import { useServiceCategory } from "@/composables/useServiceCategory";
import { Tender, TenderViewTab } from "@/stores/tender/types";
import { SupplierUser } from "@/stores/supplierUsers/types";
import { useUserStore } from "@/stores/user";
import { useTenderTabs, TabDefinition } from "@/composables/useTenderTabs";
import { useTenderDeadline } from "@/composables/useTenderDeadline";
import { useFrequencyOptions } from "@/composables/useFrequencyOptions";
import { useFeedbackCookie } from "@/utilities/useFeedbackCookie";
dayjs.extend(utc);

const props = defineProps<{
  tender: Tender;
  supplierId: number;
  supplierUsers: SupplierUser[];
}>();

const emit = defineEmits(["scroll-to-row", "reload-tender-list"]);

// Router and State Management
const router = useRouter();
const route = useRoute();
const userStore = useUserStore();
const { user } = storeToRefs(userStore);

const currentUserId = computed(() => user.value?.userId);

// Composable Initialization
const {
  tenderDetails,
  isLoading,
  // activeTabValue,
  handleTabChange,
  fetchPriceRequestDetails,
  priceRequestDetails,
  navigateToOfferCreation,
  supplierRequestId,
} = useTenderNavigation(props, route, router);

const {
  showRejectPriceRequestButton,
  showOfferButton,
  showMarkAsDoneButton,
  showCreatePriceRequestButton,
  hasActiveAgreement,
  isTenderCompleted,
} = useTenderState(props);

const {
  showRejectionModal,
  showRejectionSuccessModal,
  showCreateRequestModal,
  showCompleteOfferModal,
  handleCreatePriceRequest,
  handleRejectPriceRequest,
  handleMarkAsDone,
  handlePriceRejected,
  handleOfferCompleted,
} = usePriceRequestHandling();

const {
  selectedServiceOfferId,
  serviceOffers,
  fetchServiceOffers,
  fetchServiceOffer,
  hasAcceptedServiceOffer,
  acceptedServiceOfferItem,
  acceptedServiceOffer,
  bookingNumber,
} = useServiceOffers(props, supplierRequestId.value ?? null);

const {
  getServiceTypeAndCategory,
  supplierServiceTypes,
  fetchServiceCategories,
} = useServiceCategory(props.supplierId);

const validatedServiceTypes = computed(() => {
  return supplierServiceTypes.value.map((serviceType) => ({
    ...serviceType,
    id: serviceType.id ?? 0,
    categories: serviceType.categories.map((category) => ({
      ...category,
      id: category.id ?? 0,
    })),
  }));
});

const serviceCategory = computed(() => {
  const categoryLabel = priceRequestDetails.value?.serviceCategoryLabel;
  if (!categoryLabel) return "";

  const { cmsServiceType, cmsServiceCategory } =
    getServiceTypeAndCategory(categoryLabel);
  if (!cmsServiceType || !cmsServiceCategory) return "";

  return `${cmsServiceType.name || cmsServiceType.label} - ${cmsServiceCategory.title}`;
});

const { getAvailableTabs } = useTenderTabs(
  hasActiveAgreement.value,
  isTenderCompleted.value,
);

const activeTabValue = ref(getAvailableTabs.value[0]?.value || "");

const deadlineInfo = computed(() => {
  if (!priceRequestDetails.value)
    return {
      isPastDeadline: false,
      formattedDeadlineDate: "Ikke satt",
      daysToDeadline: undefined,
    };

  const { isPastDeadline, formattedDeadlineDate, daysToDeadline } =
    useTenderDeadline(priceRequestDetails.value);

  return {
    isPastDeadline: isPastDeadline.value ?? false,
    formattedDeadlineDate: formattedDeadlineDate.value,
    daysToDeadline: daysToDeadline.value,
  };
});

const isPastDeadline = computed(() => deadlineInfo.value.isPastDeadline);

const formattedDeadlineDate = computed(
  () => deadlineInfo.value.formattedDeadlineDate,
);

const { frequencyOptions, fetchActivityFormFrequencyOptions } =
  useFrequencyOptions();

const getTabComponentProps = (tab: TabDefinition) => {
  const baseProps = {
    tender: tenderDetails.value,
    supplierId: props.supplierId,
    priceRequestDetails: priceRequestDetails.value,
  };

  switch (tab.value) {
    case TenderViewTab.PRICE_REQUEST:
      return {
        ...baseProps,
        serviceCategory: serviceCategory.value,
        serviceOfferId: selectedServiceOfferId.value ?? 0,
        deadlineDate: formattedDeadlineDate.value,
      };
    case TenderViewTab.MESSAGES:
      return {
        ...baseProps,
        hasUnreadMessages: hasUnreadMessages.value,
        unreadMessagesCount: unreadMessagesCount.value,
        onMessagesViewed: (priceRequestId: number) => {
          const { setMessagesViewed } = useFeedbackCookie();
          setMessagesViewed(priceRequestId);
        },
      };
    case TenderViewTab.DOCUMENTATION:
      return {
        ...baseProps,
        serviceOfferId: selectedServiceOfferId.value ?? null,
        acceptedServiceOffer: acceptedServiceOfferItem.value,
      };
    case TenderViewTab.AGREEMENT:
      return {
        ...baseProps,
        hasActiveAgreement: hasActiveAgreement.value,
        serviceOfferId: selectedServiceOfferId.value ?? 0,
        supplierRequestId: supplierRequestId.value ?? null,
        frequencyOptions: frequencyOptions.value,
        hasAcceptedServiceOffer: hasAcceptedServiceOffer.value,
        acceptedServiceOffer: acceptedServiceOffer.value ?? null,
        bookingNumber: bookingNumber.value,
        serviceOffers: serviceOffers.value,
        priceRequestId: priceRequestDetails.value?.priceRequestId,
      };
    case TenderViewTab.OFFERS:
      return {
        ...baseProps,
        supplierRequestId: supplierRequestId.value ?? 0,
        showNewOfferButton: showOfferButton.value,
        canCreateOffer: showOfferButton.value,
        frequencyOptions: frequencyOptions.value,
        serviceOffers: serviceOffers.value,
      };
    case TenderViewTab.ECONOMY:
      return { ...baseProps, bookingNumber: bookingNumber.value ?? null };
    default:
      return baseProps;
  }
};

// Event Handlers
const handleNewOffer = () => {
  if (isPastDeadline.value) {
    lateOfferModal.value?.open();
  } else {
    navigateToOfferCreation();
  }
};

const handleLateOfferConfirmation = () => {
  navigateToOfferCreation();
};

const error = ref<string | null>(null);
const loadData = async () => {
  try {
    error.value = null;
    // First fetch all required data in parallel
    await Promise.all([
      fetchServiceOffers(),
      fetchPriceRequestDetails(),
      fetchActivityFormFrequencyOptions(),
      fetchServiceCategories(),
    ]);

    // Then fetch service offer which depends on service offers being loaded first
    if (selectedServiceOfferId.value) {
      await fetchServiceOffer();
    }
  } catch (e) {
    error.value = "Kunne ikke laste inn anbudsdetaljer. Vennligst prøv igjen.";
    console.error("Error loading tender details:", e);
  }
};

const handleRetry = () => {
  loadData();
};

// Lifecycle Hooks
onMounted(() => {
  // Initialize based on URL parameters
  const initialTab = route.query.tab?.toString();
  if (
    initialTab &&
    getAvailableTabs.value.some((t) => t.value === initialTab)
  ) {
    activeTabValue.value = initialTab;
  }

  loadData();
});

watch(
  () => route.query.tab,
  (newTab) => {
    if (newTab && getAvailableTabs.value.some((t) => t.value === newTab)) {
      activeTabValue.value = newTab as string;
    }
  },
);

// Watch for tab changes to load service offer when switching to agreement tab
watch(
  () => activeTabValue.value,
  async (newTab) => {
    if (newTab === TenderViewTab.AGREEMENT && !acceptedServiceOffer.value) {
      try {
        // Clear any existing service offer to trigger loading state
        acceptedServiceOffer.value = null;
        await fetchServiceOffer();
      } catch (e) {
        console.error("Error fetching service offer:", e);
        error.value =
          "Kunne ikke laste inn avtaleinformasjon. Vennligst prøv igjen.";
      }
    }
  },
);

const lateOfferModal = ref<InstanceType<typeof LateOfferWarningModal> | null>(
  null,
);

const serviceOffersCount = computed(
  () =>
    serviceOffers.value.filter(
      (offer) =>
        ![OfferStateType.ACCEPTED, OfferStateType.COMPLETED].includes(
          offer.currentState,
        ),
    ).length,
);

interface UnreadState {
  hasUnread: boolean;
  count: number;
}

const { hasMessagesBeenViewed } = useFeedbackCookie();

const unreadMessagesState = computed(() => {
  if (!props.tender?.priceRequests?.length) {
    return { hasUnread: false, count: 0 } as UnreadState;
  }

  return props.tender.priceRequests.reduce<UnreadState>(
    (acc, request) => {
      // Check if messages for this price request have been viewed
      const priceRequestId = request.id;
      const hasBeenViewed = hasMessagesBeenViewed(priceRequestId);

      // If messages have been viewed, don't count them as unread
      const hasUnread = hasBeenViewed ? false : !!request.hasUnreadMessages;
      const unreadCount = hasBeenViewed ? 0 : request.unreadMessagesCount || 0;

      return {
        hasUnread: acc.hasUnread || hasUnread,
        count: acc.count + unreadCount,
      };
    },
    { hasUnread: false, count: 0 },
  );
});

const hasUnreadMessages = computed(() => unreadMessagesState.value.hasUnread);
const unreadMessagesCount = computed(() => unreadMessagesState.value.count);

// Update the rejection success handler
const handleRejectionSuccessConfirmWithReload = () => {
  showRejectionSuccessModal.value = false;
  emit("reload-tender-list");
};
</script>

<style lang="scss">
.tender-details-view {
  min-height: 30rem;
  background: #fff;

  .tender-details-tabs {
    background-color: #fff;
    padding: 1rem 4rem;
    cursor: default;

    .p-tablist {
      background: white;
      padding: 0 0 2rem;
    }
  }

  .tender-content-panels {
    padding: 2rem 4rem;
    background: white;
  }

  &__error {
    padding: 2rem 4rem;
    display: flex;
    justify-content: center;

    :deep(.p-message) {
      width: 100%;
      max-width: 600px;
    }
  }
}
</style>
