<template>
  <div class="termination-notice">
    <div class="notice-period-header">
      <h3>{{ noticePeriodTitle }}</h3>
      <div class="notice-period-description">
        <custom-block-content :blocks="noticePeriodDescription" />
      </div>
    </div>
    <div class="termination-notice__input-section">
      <div class="notice-period-control">
        <button
          class="control-button"
          :disabled="readOnly"
          @click="decreaseNoticePeriod"
        >
          <img :src="Subtract" alt="Subtract" />
        </button>
        <div class="notice-period-value">
          <span class="number">{{ state.noticePeriod }}</span>
          <span class="unit"> måneder</span>
        </div>
        <button
          class="control-button"
          :disabled="readOnly"
          @click="increaseNoticePeriod"
        >
          <img :src="AddIcon" alt="" />
        </button>
      </div>
      <div v-if="state.isSubmitting" class="termination-notice__saving-loader">
        <b-spinner class="termination-notice__saving-loader-spinner" />
      </div>
    </div>
    <div
      v-if="state.submissionError"
      class="termination-notice__submit-section"
    >
      <input-error v-if="state.submissionError" :text="state.submissionError" />
    </div>
  </div>
</template>
<script setup>
import Subtract from "@/assets/img/subtract.svg";
import AddIcon from "@/assets/img/add.svg";
import CustomBlockContent from "@/components/CustomBlockContent/CustomBlockContent.vue";
import { computed, reactive, watch } from "vue";
import InputError from "@/components/InputError/InputError.vue";
import { useTenderApi } from "@/services/api/useTenderApi";
import { uuidv4 } from "@/utilities/uuid";
import { debounce } from "lodash";

const props = defineProps({
  cms: {
    type: Object,
    required: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  serviceOffer: {
    type: Object,
    required: true,
  },
  supplierId: {
    type: Number,
    required: true,
  },
});
const emit = defineEmits(["termination-period-updated"]);

const tenderApis = useTenderApi();

const state = reactive({
  noticePeriod: 0,
  defaultNoticePeriod: 3,
  isSubmitting: false,
  submissionError: "",
  currentRequestToken: "",
});

const noticePeriodTitle = computed(() => props.cms.title);
const noticePeriodDescription = computed(() => props.cms.content);

watch(
  () => props.serviceOffer.terminationPeriodInMonths,
  () => {
    initializeNoticePeriod();
  },
  { immediate: true },
);

const makeSaveRequest = debounce(async () => {
  const requestToken = uuidv4();
  state.currentRequestToken = requestToken;
  state.submissionError = "";
  state.isSubmitting = true;
  try {
    const noticePeriodCopy = state.noticePeriod;
    await tenderApis.updateServiceOfferTerminationPeriod(
      props.supplierId,
      props.serviceOffer.supplierRequestId,
      props.serviceOffer.serviceOfferId,
      {
        newTerminationPeriodInMonths: noticePeriodCopy,
      },
    );
    if (requestToken !== state.currentRequestToken) {
      return;
    }
    emit("termination-period-updated", noticePeriodCopy);
  } catch (error) {
    if (requestToken === state.currentRequestToken) {
      state.submissionError = error.toString();
      console.log("Error updating start date ", error);
    }
  } finally {
    if (requestToken === state.currentRequestToken) {
      state.isSubmitting = false;
    }
  }
}, 400);

const updateTerminationNoticePeriod = async () => {
  state.isSubmitting = true;
  makeSaveRequest();
};
const decreaseNoticePeriod = () => {
  if (props.readOnly || state.noticePeriod < 1) {
    return;
  }
  state.noticePeriod = state.noticePeriod - 1;
  updateTerminationNoticePeriod();
};
const increaseNoticePeriod = () => {
  if (props.readOnly) return;
  state.noticePeriod = state.noticePeriod + 1;
  updateTerminationNoticePeriod();
};
function initializeNoticePeriod() {
  state.noticePeriod =
    typeof props.serviceOffer.terminationPeriodInMonths === "number"
      ? props.serviceOffer.terminationPeriodInMonths
      : state.defaultNoticePeriod;
}
</script>

<style lang="scss" scoped>
.termination-notice {
  &__input-section {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &__saving-loader {
    line-height: 1;
  }

  &__saving-loader-spinner {
    font-size: $font-size-xs;
    width: 1rem;
    height: 1rem;
  }

  &__submit-section {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
}

.notice-period-header {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.notice-period-header h3 {
  color: #1d1d1d;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.notice-period-header p {
  color: #5a5a5a;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

.notice-period-control {
  width: 189px;
  height: 2.5rem;
  padding: 0.25rem;
  background: white;
  border-radius: 9999px;
  border: 1px solid #2a2a2a;
  display: inline-flex;
  align-items: center;
}

.control-button {
  width: 32px;
  height: 32px;
  background: #441b49;
  border-radius: 100px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    background: #cccccc;
    cursor: not-allowed;
  }
}

.minus-icon,
.plus-icon {
  width: 16px;
  height: 2px;
  background: white;
  position: relative;
}

.plus-icon::after {
  content: "";
  position: absolute;
  width: 2px;
  height: 16px;
  background: white;
  left: 7px;
  top: -7px;
}

.notice-period-value {
  flex: 1;
  padding: 0 0.625rem;
}

.number {
  color: #1d1d1d;
  font-size: 0.875rem;
  font-weight: 400;
}

.unit {
  color: #1d1d1d;
  font-size: 12px;
  font-weight: 600;
}
</style>
