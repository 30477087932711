<template>
  <div class="start-date">
    <div class="start-date__title">{{ title }}</div>
    <div v-if="readOnly" class="start-date__read-only-value">
      {{ formattedStartDate }}
    </div>
    <template v-else>
      <div class="start-date__description">
        <custom-block-content :blocks="description" />
      </div>
      <div class="start-date__date-picker">
        <date-picker
          v-model="state.localStartDate"
          placeholder="Velg dato"
          date-format="dd.mm.yy"
          :disabled-date="disableDateInDatePicker"
          :manual-input="false"
          show-icon
          fluid
          icon-display="input"
        >
          <template #inputicon="slotProps">
            <img
              src="/images/calendar-dark.svg"
              alt=""
              @click="slotProps.clickCallback"
            />
          </template>
        </date-picker>
        <div v-if="state.isSubmitting" class="start-date__saving-loader">
          <b-spinner class="start-date__saving-loader-spinner" />
        </div>
      </div>
      <div v-if="state.submissionError" class="start-date__submit-section">
        <input-error :text="state.submissionError" />
      </div>
    </template>
  </div>
</template>

<script setup>
import { computed, reactive, watch } from "vue";
import dayjs from "dayjs";
import CustomBlockContent from "@/components/CustomBlockContent/CustomBlockContent.vue";
import { formatToLongDateString } from "@/utilities/dateUtils";
import InputError from "@/components/InputError/InputError.vue";
import { useTenderApi } from "@/services/api/useTenderApi";
import utc from "dayjs/plugin/utc";
import { cloneDeep } from "lodash";
import DatePicker from "primevue/datepicker";
import { uuidv4 } from "@/utilities/uuid";

dayjs.extend(utc);

const props = defineProps({
  pageCms: {
    type: Object,
    default: null,
  },
  serviceOffer: {
    type: Object,
    required: true,
  },
  supplierId: {
    type: Number,
    required: true,
  },
  readOnly: {
    type: Boolean,
    required: true,
  },
});
const emit = defineEmits(["start-date-updated"]);

const tenderApis = useTenderApi();

const state = reactive({
  localStartDate: null,
  isSubmitting: false,
  submissionError: "",
  minimumDaysToStartDate: 1,
  currentRequestToken: "",
});

const startDateCms = computed(() =>
  props.pageCms.pageSections.find(
    (section) => section.label === "startDateSection",
  ),
);
const title = computed(() => startDateCms.value?.title);
const description = computed(() => startDateCms.value?.content);
const formattedStartDate = computed(() => {
  return formatToLongDateString(props.serviceOffer.suggestedStartDate) || "--";
});

watch(
  () => props.serviceOffer.suggestedStartDate,
  () => {
    initializeStartDate();
  },
  { immediate: true },
);

watch(
  () => state.localStartDate,
  () => {
    if (
      !isDatesEqual(state.localStartDate, props.serviceOffer.suggestedStartDate)
    ) {
      updateStartDate();
    }
  },
  { deep: true },
);

function isDatesEqual(date1, date2) {
  if ((!date1 || !date2) && date1 === date2) {
    return true;
  }
  return dayjs(date1).isSame(dayjs(date2), "d");
}
const updateStartDate = async () => {
  const requestToken = uuidv4();
  state.currentRequestToken = requestToken;
  state.isSubmitting = true;
  state.submissionError = "";
  try {
    const dateCopy = cloneDeep(state.localStartDate);
    await tenderApis.updateServiceOfferStartDate(
      props.supplierId,
      props.serviceOffer.supplierRequestId,
      props.serviceOffer.serviceOfferId,
      {
        newStartDate: dateCopy ? dayjs(dateCopy).format("MM.DD.YYYY") : "",
      },
    );
    if (requestToken !== state.currentRequestToken) {
      return;
    }
    const newOfferDate = dateCopy
      ? dayjs(dateCopy).format("YYYY-MM-DD") + "T00:00:00Z"
      : null;
    emit("start-date-updated", newOfferDate);
  } catch (error) {
    if (requestToken === state.currentRequestToken) {
      state.submissionError = error.toString();
      console.log("Error updating start date ", error);
    }
  } finally {
    if (requestToken === state.currentRequestToken) {
      state.isSubmitting = false;
    }
  }
};
function initializeStartDate() {
  const offerDate = props.serviceOffer.suggestedStartDate;
  if (!offerDate) {
    state.localStartDate = null;
    return;
  }
  const dateStringParts = offerDate.split("T").shift().split("-");
  state.localStartDate = new Date(
    dateStringParts[0],
    dateStringParts[1] - 1,
    dateStringParts[2],
  );
}
const disableDateInDatePicker = (date) => {
  const minimumStartDate = dayjs().add(state.minimumDaysToStartDate, "day");
  return dayjs(date).isBefore(minimumStartDate, "day");
};
</script>

<style lang="scss">
:root {
  --p-inputtext-border-color: #{$color-black};
  --p-inputtext-hover-border-color: #{$color-black};
  --p-inputtext-border-radius: 4px;
}
</style>

<style lang="scss" scoped>
.start-date {
  &__title {
    font-weight: 600;
    font-size: $font-size-base;
  }

  &__description {
    margin-top: 0.375rem;
    font-weight: 400;
    font-size: 14px;
  }

  &__date-picker {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &__saving-loader {
    line-height: 1;
  }

  &__saving-loader-spinner {
    font-size: $font-size-xs;
    width: 1rem;
    height: 1rem;
  }

  &__read-only-value {
    margin-top: 0.25rem;
    font-weight: 400;
    font-size: 0.875rem;
  }

  &__submit-section {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  &__buttons {
    display: flex;
    align-items: center;
    gap: 0.625rem;
  }

  &__cancel-button,
  &__submit-button {
    padding: 0.625rem 1rem;
    font-size: 0.875rem;
  }
}
</style>
