<template>
  <div class="chat-view">
    <template v-if="isReady">
      <ActiveConversation
        v-if="shouldShowActiveConversation"
        :message-thread="activeThread ?? null"
        :tender="tender"
        :supplier-id="supplierId"
        :price-request-id="priceRequest?.id"
        :supplier-request-id="supplierRequest?.id"
        :show-back-button="!!messageThreads?.length"
        @back-button-clicked="handleThreadClose"
        @send-message="handleSendMessage"
      >
        <template #error>
          <div v-if="errorMessage" class="chat-view__error">
            {{ errorMessage }}
          </div>
        </template>
      </ActiveConversation>

      <template v-else>
        <button
          v-if="showNewThreadButton"
          class="chat-view__new-thread-btn"
          @click="createThread"
        >
          Ny meldingstråd
        </button>

        <ConversationList
          v-if="messageThreads?.length"
          :conversations="messageThreads"
          :recipient-name="customerName"
          @conversation-clicked="handleThreadSelect"
        />
        <NoConversations v-else-if="!isLoading" />
      </template>
    </template>

    <div v-else class="chat-view__skeleton">
      <MessageListSkeleton />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue";
import ActiveConversation from "./ActiveConversation.vue";
import ConversationList from "./ConversationList.vue";
import NoConversations from "./NoConversations.vue";
import MessageListSkeleton from "./MessageListSkeleton.vue";
import { useUserStore } from "@/stores/user";
import { useConversations } from "@/composables/useConversations";
import { findSupplierRequest } from "@/utilities/tenderUtils";
import { RequestState } from "@/custom-types/GeneralTypes";
import type { Tender } from "@/stores/tender/types";
import type { PriceRequest } from "@/stores/tender/types";
import type {
  ApiError,
  SendMessagePayload,
} from "@/stores/communication/types";

interface Props {
  tender: Tender;
  supplierId: number;
  isRequestStateAccepted: boolean;
  priceRequest?: PriceRequest;
}

const props = withDefaults(defineProps<Props>(), {
  isPriceRequest: false,
  priceRequest: undefined,
});

const userStore = useUserStore();
const isReady = ref(false);
const isLoading = ref(true);

// Computed properties
const customerName = computed(() => props.tender.customerOrganization.name);

const supplierRequest = computed(() => {
  if (props.priceRequest?.supplierRequests?.length) {
    return props.priceRequest.supplierRequests[0];
  }
  return findSupplierRequest(props.tender, props.supplierId);
});

const showNewThreadButton = computed(
  () => supplierRequest.value?.requestState === RequestState.ACCEPTED,
);

// Initialize conversations
const {
  messageThreads,
  activeThread,
  isThreadVisible,
  errorMessage,
  createThread,
  handleThreadSelect,
  handleThreadClose,
  sendMessage,
  fetchMessageThreads,
} = useConversations({
  tender: props.tender,
  supplierId: props.supplierId,
  supplierRequestId: supplierRequest.value?.id || 0,
  user: userStore.user!,
});

// Modify the shouldShowActiveConversation computed property
const shouldShowActiveConversation = computed(
  () =>
    isThreadVisible.value ||
    (activeThread.value != null && isThreadVisible.value) ||
    (!messageThreads.value?.length && !props.isRequestStateAccepted),
);

// Methods
const handleSendMessage = async (payload: SendMessagePayload) => {
  await sendMessage({
    conversationId: activeThread.value?.latestMessage.conversationId ?? null,
    content: payload.message,
    files: payload.attachments,
    onSuccess: payload.successCallback,
    onError: (error) => {
      payload.errorCallback(error as ApiError);
    },
  });
};

// Lifecycle
onMounted(async () => {
  try {
    isLoading.value = true;
    await fetchMessageThreads();
  } finally {
    isLoading.value = false;
    isReady.value = true;
  }
});
</script>

<style lang="scss" scoped>
.chat-view {
  &__error {
    color: $color-error;
    margin: 1rem 0;
    padding: 0.5rem;
    border: 1px solid $color-error;
    border-radius: 4px;
  }

  &__new-thread-btn {
    background: $color-yellow-dark;
    border-radius: 6px;
    color: $color-black;
    font-weight: $font-weight-semi-bold;
    font-size: $font-size-base;
    border: none;
    padding: 1rem 2.5rem;
    text-transform: uppercase;
    margin-bottom: 1rem;
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 0.9;
    }
  }

  &__skeleton {
    padding: 1rem;
  }
}
</style>
