<template>
  <div class="billing-summary">
    <div class="billing-summary__title">{{ title }}</div>
    <div
      v-for="(record, index) in summaryRecords"
      :key="index"
      class="billing-summary__record"
    >
      <div class="billing-summary__record-label">
        {{ record.label }}
      </div>
      <div class="billing-summary__record-value">{{ record.cost }} kr</div>
    </div>
  </div>
</template>

<script>
import { formatCurrency } from "@/utilities/numberUtils";
import {
  ActivityFrequency,
  ServiceActivityCmsLabelType,
} from "@/custom-types/types";
import useSupplierCostItem from "@/composables/useSupplierCostItem";
import { groupBy } from "lodash";
import { compareStatuses } from "@/utilities/stringUtils";
import { getCmsItem, getCmsItemText } from "@/utilities/cmsUtilities";

const {
  extractFrequencyFromFrequencyLabel,
  extractFrequencyGroupFromFrequencyLabel,
} = useSupplierCostItem();

export default {
  components: {},
  props: {
    pageCms: {
      type: Object,
      required: true,
    },
    mandatorySupplierCosts: {
      type: Array,
      required: true,
    },
    frequencyOptions: {
      type: Object,
      required: true,
    },
    serviceActivitiesCms: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    summaryRecords() {
      const serviceOfferPriceGroups = groupBy(
        this.mandatorySupplierCosts,
        (record) => record.serviceOfferPriceId,
      );
      return Object.values(serviceOfferPriceGroups).map((costs) => {
        const totalCost = costs.reduce((total, cost) => {
          return total + cost.itemTotalCost;
        }, 0);
        const frequencyGroup = extractFrequencyGroupFromFrequencyLabel(
          costs[0].frequencyLabel,
        );
        let label = "";
        if (compareStatuses(frequencyGroup, ActivityFrequency.Monthly)) {
          const limitedStart = costs[0].limitedPeriodStart;
          const limitedEnd = costs[0].limitedPeriodEnd;
          const limitedPeriodText =
            limitedStart && limitedEnd
              ? ` (${limitedStart.substring(0, 3)} - ${limitedEnd.substring(0, 3)})`
              : "";
          label = `Fakturerbart per måned ${limitedPeriodText}`;
        } else if (compareStatuses(frequencyGroup, ActivityFrequency.Fixed)) {
          label = "Enkeltkostnader";
        } else if (compareStatuses(frequencyGroup, ActivityFrequency.Yearly)) {
          const serviceActivityCms = getCmsItem(
            this.serviceActivitiesCms,
            ServiceActivityCmsLabelType.Yearly,
          );
          const frequency = extractFrequencyFromFrequencyLabel(
            costs[0].frequencyLabel,
          );
          const frequencyText = getCmsItemText(
            serviceActivityCms?.frequencies,
            frequency,
          );
          label = `Fakturerbart ${(frequencyText || frequency || "").toLowerCase()}`;
        }
        return {
          cost: formatCurrency(totalCost),
          label: label.trim(),
        };
      });
    },
    totalBillablePriceCms() {
      return getCmsItem(this.pageCms.pageSections, "totalBillablePrice");
    },
    title() {
      return this.totalBillablePriceCms.title || "";
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.billing-summary {
  max-width: 480px;
  background: $color-primary-pink-dark;
  border: 1px solid $color-primary-pink-darker;
  border-radius: 8px;
  padding: 1.25rem;
  color: $color-black;

  &__title {
    font-weight: 600;
    font-size: 1rem;
    text-align: left;
  }

  &__record {
    margin-top: 0.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__record-label {
    font-weight: 400;
    font-size: 0.875rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__record-label {
    font-weight: 400;
    font-size: 0.875rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__record-value {
    font-weight: 600;
    font-size: 0.875rem;
  }
}
</style>
