<template>
  <div class="customer-contact-information-wrapper">
    <div
      v-if="isLoading"
      class="customer-contact-information__skeleton-wrapper"
    >
      <div class="customer-contact-information">
        <Skeleton height="1.5rem" width="12rem" class="mb-3" />
        <div class="customer-contact-information__details">
          <div v-for="i in 3" :key="i" class="item__item">
            <Skeleton height="1rem" width="5rem" />
            <Skeleton height="1rem" width="10rem" />
          </div>
        </div>
      </div>
    </div>
    <div v-else class="customer-contact-information">
      <h2 class="customer-contact-information__title">
        Kontaktinformasjon til kunde
      </h2>
      <div class="customer-contact-information__details">
        <div v-for="(detail, index) in details" :key="index" class="item__item">
          <span class="item__label">{{ detail.label }}</span>
          <span>{{ detail.value }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, toRefs } from "vue";
import Skeleton from "primevue/skeleton";

interface CustomerDetails {
  displayName: string | null;
  email: string | null;
  mobilePhone: string | null;
}

// Define and set default props
const props = withDefaults(
  defineProps<{
    customerDetails: CustomerDetails | null;
    isLoading?: boolean;
  }>(),
  {
    customerDetails: () => ({
      displayName: null,
      email: null,
      mobilePhone: null,
    }),
    isLoading: false,
  },
);

// Destructure props (template automatically unwraps refs)
const { customerDetails, isLoading } = toRefs(props);

// Compute the details array for contact information
const details = computed(() => [
  { label: "Navn", value: customerDetails.value?.displayName || "" },
  { label: "Epost", value: customerDetails.value?.email || "" },
  { label: "Telefon", value: customerDetails.value?.mobilePhone || "" },
]);
</script>

<style scoped lang="scss">
$background: $color-primary-pink;
$padding-vertical: 24px;
$padding-horizontal: 32px;
$title-color: #000;
$title-font-size: $font-size-base;
$description-color: #000;
$description-font-size: $font-size-sm;
$description-margin: 16px;
$details-padding-bottom: 10px;

.customer-contact-information-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px;
  background: $background;
  background-color: $background;
  margin-left: 10px;
  width: 100%;
  min-height: 180px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
}

.customer-contact-information {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 4px;
  background: $background;
  padding: $padding-vertical $padding-horizontal;
  width: 100%;
}

.customer-contact-information__skeleton-wrapper {
  width: 100%;
  padding: $padding-vertical $padding-horizontal;
}

.customer-contact-information__title {
  color: $title-color;
  font-size: $title-font-size;
  font-weight: 600;
  text-align: left;
}

.customer-contact-information__description {
  color: $description-color;
  font-size: $description-font-size;
  margin: $description-margin 0;
  font-weight: 400;
  text-align: left;
  font-style: italic;
}

.customer-contact-information__details {
  font-size: $description-font-size;
  width: 100%;
}

.item__item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: $details-padding-bottom;
  font-weight: 600;
  font-size: $font-size-sm;
}

.item__label {
  font-weight: 400 !important;
}
</style>
