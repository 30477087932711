<template>
  <b-modal v-bind="$attrs">
    <template v-for="(_, slot) in $slots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </b-modal>
</template>

<script lang="ts" setup></script>

<style lang="scss">
.modal-backdrop {
  z-index: 60000 !important;
}

.modal {
  z-index: 60001 !important;
}
</style>
