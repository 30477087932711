import { useToast } from "primevue/usetoast";
import { ref, getCurrentInstance } from "vue";

export interface ToastErrorOptions {
  severity?: "success" | "info" | "warn" | "error";
  summary?: string;
  detail?: string;
  life?: number;
  sticky?: boolean;
  group?: string;
  position?:
    | "top-left"
    | "top-center"
    | "top-right"
    | "bottom-left"
    | "bottom-center"
    | "bottom-right"
    | "center";
  closable?: boolean;
}

export class AlertableError extends Error {
  toastOptions?: ToastErrorOptions;

  constructor(message: string, toastOptions?: ToastErrorOptions) {
    super(message);
    this.name = "AlertableError";
    this.toastOptions = toastOptions;
  }
}

interface ToastService {
  add: (options: ToastErrorOptions) => void;
}

// Global toast service for non-component contexts
let globalToastService: ToastService | null = null;

export const setGlobalToastService = (toastService: ToastService) => {
  globalToastService = toastService;
  console.log("Global toast service registered");
};

export const useToastErrorHandler = () => {
  const isLoading = ref(false);
  const hasError = ref(false);

  // Default toast options
  const DEFAULT_TOAST_OPTIONS: ToastErrorOptions = {
    severity: "error",
    summary: "Feil",
    detail: "En uventet feil har oppstått",
    closable: true,
    life: 60000,
  };

  const DEFAULT_SUCCESS_OPTIONS: ToastErrorOptions = {
    severity: "success",
    summary: "Success",
    life: 3000,
  };

  // Helper function to safely get toast service
  const getToastService = (): ToastService => {
    const instance = getCurrentInstance();

    // Try component context first
    if (instance) {
      try {
        return useToast();
      } catch (error) {
        // Fall back to global instance if available
        const globalToast = instance.appContext.config.globalProperties.$toast;
        if (globalToast) return globalToast;
      }
    }

    // Use registered global service or fallback
    return (
      globalToastService || {
        add: (options: ToastErrorOptions) => {
          console.warn("Toast not available, would have shown:", options);
        },
      }
    );
  };

  /**
   * Extract error message from different error types
   */
  const extractErrorMessage = (error: unknown): string => {
    if (error instanceof Error) {
      return error.message;
    }

    if (error && typeof error === "object") {
      // Handle API errors (from axios or similar)
      if ("response" in error) {
        const apiError = error as {
          response?: {
            data?: {
              message?: string;
            };
          };
        };
        return apiError.response?.data?.message || "";
      }

      // Handle objects with message property
      if ("message" in error && typeof error.message === "string") {
        return error.message;
      }
    }

    return "";
  };

  /**
   * Handles an error and shows a toast notification
   */
  const handleError = (error: unknown, defaultOptions?: ToastErrorOptions) => {
    hasError.value = true;
    const toast = getToastService();

    // Start with default options
    let toastOptions: ToastErrorOptions = { ...DEFAULT_TOAST_OPTIONS };

    // Get error message
    const errorMessage = extractErrorMessage(error);

    // Handle AlertableError specially
    if (error instanceof AlertableError) {
      // Merge options from AlertableError
      if (error.toastOptions) {
        toastOptions = { ...toastOptions, ...error.toastOptions };
      }
      console.error("AlertableError:", error);
    } else {
      // For other error types, use the extracted message if available
      if (errorMessage && !toastOptions.detail) {
        toastOptions.detail = errorMessage;
      }
      console.error("Error:", error);
    }

    // Apply provided default options with highest priority
    if (defaultOptions) {
      toastOptions = { ...toastOptions, ...defaultOptions };
    }

    // Show the toast notification
    toast.add(toastOptions);
  };

  /**
   * Async wrapper that handles loading state and error handling with toast
   */
  const withToastErrorHandling = async <T>(
    asyncFn: () => Promise<T>,
    options?: {
      loadingState?: boolean;
      errorOptions?: ToastErrorOptions;
      successOptions?: ToastErrorOptions;
    },
  ): Promise<T | undefined> => {
    if (options?.loadingState !== false) {
      isLoading.value = true;
    }
    hasError.value = false;

    try {
      const result = await asyncFn();

      // Show success toast if options provided
      if (options?.successOptions) {
        const toast = getToastService();
        toast.add({
          ...DEFAULT_SUCCESS_OPTIONS,
          ...options.successOptions,
        });
      }

      return result;
    } catch (error) {
      handleError(error, options?.errorOptions);
      return undefined;
    } finally {
      if (options?.loadingState !== false) {
        isLoading.value = false;
      }
    }
  };

  /**
   * Creates standardized error options for API calls
   */
  const createApiErrorOptions = (
    operation: string,
    customDetail?: string,
  ): ToastErrorOptions => {
    return {
      severity: "error",
      summary: "API Error",
      detail: customDetail || `Failed to ${operation}`,
      life: 5000,
    };
  };

  return {
    isLoading,
    hasError,
    handleError,
    withToastErrorHandling,
    createApiErrorOptions,
    AlertableError,
  };
};
