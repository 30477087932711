import SupplierAdmin from "@/views/SupplierAdmin/SupplierAdmin.vue";
import SupplierProfileView from "@/views/SupplierAdmin/SupplierProfileView.vue";
import MyRegistrationsView from "@/views/SupplierAdmin/MyRegistrationsView.vue";
import FileAttachmentView from "@/views/FileAttachmentView/FileAttachmentView.vue";
import CreateNewOfferView from "@/views/TenderOffer/CreateNewOffer/CreateNewOfferView.vue";
import EditOfferView from "@/views/TenderOffer/EditOfferView/EditOfferView.vue";
import TenderOffer from "@/views/TenderOffer/TenderOffer.vue";
import LoadingView from "@/views/LoadingView/LoadingView.vue";
import { RouteNames } from "@/router/types";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import ErrorView from "@/views/ErrorView/ErrorView.vue";
import MyRequestsView from "@/views/SupplierAdmin/MyRequestsView.vue";
import MyAgreementsView from "@/views/SupplierAdmin/MyAgreementsView.vue";
import UserWelcomeView from "@/views/UserWelcome/UserWelcomeView.vue";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: RouteNames.SUPPLIER_ADMIN,
    component: SupplierAdmin,
    meta: {
      requiresAuth: true,
    },
    redirect: { name: RouteNames.MY_REQUESTS },
    children: [
      {
        path: "my-requests",
        name: RouteNames.MY_REQUESTS,
        component: MyRequestsView,
      },
      {
        path: "my-agreements",
        name: RouteNames.MY_AGREEMENTS,
        component: MyAgreementsView,
      },
      {
        path: "supplier-profile",
        name: RouteNames.SUPPLIER_PROFILE,
        component: SupplierProfileView,
      },
      {
        path: "my-registrations",
        name: RouteNames.MY_REGISTRATIONS,
        component: MyRegistrationsView,
      },
    ],
  },
  {
    path: "/priceRequest/:priceRequestId/supplierRequest/:supplierRequestId/offers",
    name: RouteNames.TENDER_OFFER,
    component: TenderOffer,
    meta: {
      requiresAuth: true,
    },
    redirect: { name: RouteNames.CREATE_OFFER },
    children: [
      {
        path: "create",
        name: RouteNames.CREATE_OFFER,
        component: CreateNewOfferView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "offer/:offerId?",
        name: RouteNames.EDIT_OFFER,
        component: EditOfferView,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "/error",
    name: RouteNames.ERROR,
    component: ErrorView,
  },
  {
    path: "/loading",
    name: RouteNames.LOADING,
    component: LoadingView,
    meta: { requiresAuth: false },
  },
  {
    path: "/file-viewer",
    name: RouteNames.FILE_VIEWER,
    component: FileAttachmentView,
  },
  {
    path: "/welcome",
    name: RouteNames.USER_WELCOME,
    component: UserWelcomeView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/:catchAll(.*)*",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;

export { routes };
