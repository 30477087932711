<template>
  <div class="service-documentation">
    <b-container>
      <b-row>
        <b-col cols="12" md="8">
          <upload-documentation
            :tender="tender"
            :service-offer-id="serviceOfferId"
            :supplier-id="supplierId"
            @new-service-report="handleNewServiceReport"
          />
        </b-col>
      </b-row>
      <div class="service-documentation__reports">
        <div class="service-documentation__reports-title">
          <span>Mine servicerapporter</span>
          <span class="service-documentation__reports-title-subtext">
            (Disse er også synlig for kunden)
          </span>
        </div>
        <div class="service-documentation__reports-list-wrapper">
          <service-reports-list
            :service-offer-id="serviceOfferId"
            :tender-id="tender?.tenderId"
            :customer-org-number="tender?.customerOrganization.orgNumber"
            :service-reports="serviceReports"
            @delete-service-report="deleteServiceReport"
          />
        </div>
      </div>
    </b-container>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import UploadDocumentation from "./UploadDocumentation.vue";
import ServiceReportsList from "@/components/ServiceReport/ServiceReportsList.vue";
import { useServiceReportApi } from "@/services/api/useServiceReportApi";
import type { ServiceReport } from "@/stores/serviceReport/types";
import type { Tender } from "@/stores/tender/types";

interface Props {
  tender: Tender | null;
  supplierId: number | null;
  serviceOfferId: number | null;
}

const props = defineProps<Props>();
const serviceReports = ref<ServiceReport[] | null>(null);
const { fetchServiceReports } = useServiceReportApi();

onMounted(() => {
  fetchServiceReportsData();
});

const fetchServiceReportsData = async () => {
  serviceReports.value = null;
  if (!props.serviceOfferId || !props.tender) return;

  const response = await fetchServiceReports({
    customerOrgNumber: props.tender?.customerOrganization.orgNumber,
    tenderId: props.tender?.tenderId,
    offerId: props.serviceOfferId,
  });

  serviceReports.value = response.data ? response.data : [];
};

const handleNewServiceReport = () => {
  fetchServiceReportsData();
};

const deleteServiceReport = (serviceReportId: number) => {
  if (!serviceReports.value) return;

  for (let i = serviceReports.value.length - 1; i >= 0; i--) {
    if (serviceReports.value[i]?.id === serviceReportId) {
      serviceReports.value.splice(i, 1);
    }
  }
};
</script>

<style lang="scss" scoped>
.service-documentation {
  padding: 3rem 2rem;
  background-color: #fff;
  text-align: start;

  .container {
    padding-left: 0;
    padding-right: 0;
    max-width: 1400px;
  }

  &__reports-title {
    font-weight: 700;
    font-size: $font-size-base;
    color: $color-primary-darker;
    border-bottom: 0.125rem solid #aea993;
  }

  &__reports-title-subtext {
    color: $color-primary-darker;
    font-weight: 400;
    font-size: $font-size-sm;
    margin-left: 0.5rem;
  }
}
</style>
