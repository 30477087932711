<template>
  <app-modal
    v-model="showModal"
    title="Remove document from offer"
    secondary-button-text="Cancel"
    primary-button-text="Remove"
    :is-submitting="state.isSubmitting"
    :submission-error="state.submissionError"
    @secondary-button-click="closeModal"
    @primary-button-click="removeAttachment"
  >
    <div class="modal-content__attachment">
      <supplier-attachment
        :key="attachment.id"
        :file-name="attachment.fileName"
        :attachment-id="attachment.attachmentId"
        :supplier-id="supplierId"
        :removable="false"
        :hide-right-icon="true"
      />
    </div>
  </app-modal>
</template>

<script setup>
import { reactive } from "vue";
import { useTenderApi } from "@/services/api/useTenderApi";
import AppModal from "@/components/Base/BaseModal/AppModal.vue";
import SupplierAttachment from "@/components/Attachment/SupplierAttachment.vue";

const emit = defineEmits(["attachment-removed"]);
const showModal = defineModel({ required: true });
const props = defineProps({
  supplierId: {
    type: Number,
    required: true,
  },
  supplierRequestId: {
    type: Number,
    required: true,
  },
  offerId: {
    type: Number,
    required: true,
  },
  attachment: {
    type: Object,
    required: true,
  },
});

const tenderApis = useTenderApi();

const state = reactive({
  isSubmitting: false,
  submissionError: "",
});

const closeModal = () => {
  showModal.value = false;
};
const removeAttachment = async () => {
  if (state.isSubmitting) {
    return;
  }
  state.isSubmitting = true;
  state.submissionError = "";
  try {
    await tenderApis.removeServiceOfferAttachments(
      props.supplierId,
      props.supplierRequestId,
      props.offerId,
      props.attachment.attachmentId,
    );
    emit("attachment-removed", props.attachment);
    closeModal();
  } catch (error) {
    state.submissionError = error.toString();
    console.log("Error uploading attachments", error);
  } finally {
    state.isSubmitting = false;
  }
};
</script>

<style lang="scss" scoped>
.modal-content {
  &__attachment {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
</style>
