<template>
  <div class="service-offers-wrapper">
    <!-- Skeleton Loader -->
    <template v-if="isServiceOffersLoading">
      <div class="service-offers-wrapper__skeleton">
        <div v-for="n in 6" :key="n" class="skeleton-container">
          <Skeleton shape="circle" size="40px" class="skeleton-icon" />
          <div class="skeleton-text">
            <Skeleton width="200px" height="24px" class="mb-2" />
            <Skeleton width="150px" height="18px" />
          </div>
          <div class="skeleton-right">
            <Skeleton width="100px" height="24px" class="mr-2" />
            <Skeleton width="60px" height="24px" class="mr-2" />
            <Skeleton width="120px" height="18px" class="mr-2" />
            <Skeleton width="24px" height="24px" />
          </div>
        </div>
      </div>
    </template>

    <!-- Main Content -->
    <template v-else>
      <!-- No Offers State -->
      <no-service-offers
        v-if="hasNoOffers"
        :tender="tender"
        :can-create-offer="canCreateOffer ?? false"
        :no-supplier-offers-cms="noSupplierOffersCms"
        @create-offer="handleCreateOffer"
      />

      <!-- Offer Lists -->
      <div v-else class="service-offers-wrapper__offer-lists">
        <service-offers-list
          v-if="!acceptedOffer || toggleState"
          :tender="tender"
          :supplier-id="supplierId"
          :service-offers="currentOffers"
          :frequency-options="frequencyOptions"
          @send-to-customer="handleSendToCustomer"
          @delete-draft="handleDeleteDraft"
          @copy="handleCopyOffer"
          @edit-sent-withdrawn="handleEditSentWithdrawn"
          @edit="handleEdit"
          @revoke="handleRevoke"
          @see-agreement="handleSeeAgreement"
          @download-terms="handleDownloadTerms"
          @view-offer="handleViewOffer"
        />
      </div>

      <!-- Modals -->
      <late-offer-modal
        v-if="showLateOfferModal"
        v-model="showLateOfferModal"
        :tender-id="tender.tenderId"
        @ok="handleLateOfferOk"
        @cancel="handleLateOfferCancel"
      />

      <send-offer-modal
        v-if="offerToSendToCustomer && showSendOfferModal"
        v-model="showSendOfferModal"
        :service-offer="offerToSendToCustomer"
        :supplier-id="supplierId"
        :price-request-id="tender.priceRequests[0]?.id"
        @offer-sent-acknowledged="handleOfferSentAcknowledgement"
        @reload-offer-list="handleReloadOfferList"
      />

      <delete-draft-offer-modal
        v-model="showDeleteDraftModal"
        :message-deleted-text="messageResponseText"
        @close="handleCloseModal"
        @delete-draft-offer="handleDeleteOffer"
      />

      <withdraw-offer-modal
        v-model="showWithdrawOfferModal"
        :message-withdrawn-text="messageResponseText"
        @close="handleCloseModal"
        @withdraw-offer="handleWithdrawOffer"
      />

      <!-- Toggle Previous Offers -->
      <div
        v-if="shouldShowToggle"
        class="service-offers-wrapper__toggle-text"
        role="button"
        tabindex="0"
        :aria-label="toggleAriaLabel"
        @click="handleToggle"
        @keydown.enter="handleToggle"
        @keydown.space.prevent="handleToggle"
      >
        {{ toggleText }}
      </div>

      <!-- Error Feedback -->
      <feedback-overlay
        v-if="error"
        :feedback-type="error.type"
        :message-title="error.messageTitle"
        :message-info="error.messageInfo"
        @close="clearError"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import Skeleton from "primevue/skeleton";

import NoServiceOffers from "./NoServiceOffers.vue";
import ServiceOffersList from "./ServiceOffersList.vue";
import { useRouter } from "vue-router";
import { RouteNames } from "@/router/types";

import WithdrawOfferModal from "@/components/ServiceOffersModals/WithdrawOfferModal.vue";
import DeleteDraftOfferModal from "@/components/ServiceOffersModals/DeleteDraftOfferModal.vue";

import { useTenderApi } from "@/services/api/useTenderApi";
import UseFileDownload from "@/composables/useFileDownload";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import {
  FeedbackType,
  RequestStateType,
  Audience,
  OfferStateType,
  ServiceOffer,
} from "@/custom-types/GeneralTypes";
import { PriceRequest, SupplierRequest } from "@/stores/tender/types";
import type { Tender } from "@/stores/tender/types";

import { addBusinessDays } from "@/utilities/dateUtils";
import { useUserStore } from "@/stores/user";

dayjs.extend(utc);

// Props
interface Props {
  tender: Tender;
  supplierId: number;
  supplierRequestId: number;
  showNewOfferButton?: boolean;
  canCreateOffer?: boolean;
  frequencyOptions?: Record<string, unknown>;
  serviceOffers: ServiceOffer[];
}

interface ErrorResponse {
  type: FeedbackType;
  messageTitle: string;
  messageInfo: string;
}

const props = withDefaults(defineProps<Props>(), {
  showNewOfferButton: true,
  isGiveOfferButtonVisible: true,
  frequencyOptions: () => ({}),
});

const router = useRouter();
const {
  deleteSupplierOffer,
  getTenderConfirmationUri,
  changeServiceOfferState,
} = useTenderApi();
const { viewBlobFile } = UseFileDownload();

const userStore = useUserStore();
const userId = computed(() => userStore.user?.userId);

// State
const isServiceOffersLoading = ref(true);
const toggleState = ref(false);
const error = ref<ErrorResponse | null>(null);
const isComponentMounted = ref(false);
const showLateOfferModal = ref(false);
const showSendOfferModal = ref(false);
const showSuccessOfferModal = ref(false);
const showDeleteDraftModal = ref(false);
const showEditSentOrWithdrawnModal = ref(false);
const showWithdrawOfferModal = ref(false);
const offerToSendToCustomer = ref<ServiceOffer | null>(null);
const offerToDelete = ref<ServiceOffer | null>(null);
const offerToEdit = ref<ServiceOffer | null>(null);
const offerToWithdraw = ref<ServiceOffer | null>(null);
const messageResponseText = ref("");

const serviceOffers = computed(() => props.serviceOffers);

// Computed
const acceptedOffer = computed(() =>
  serviceOffers.value.find((offer) =>
    [OfferStateType.ACCEPTED, OfferStateType.COMPLETED].includes(
      offer.currentState,
    ),
  ),
);

const nonAcceptedOffers = computed(() =>
  serviceOffers.value.filter(
    (offer) =>
      ![OfferStateType.ACCEPTED, OfferStateType.COMPLETED].includes(
        offer.currentState,
      ),
  ),
);

const currentOffers = computed(() => {
  const hasAcceptedOffer = acceptedOffer.value !== undefined;

  if (!hasAcceptedOffer) {
    return serviceOffers.value;
  }

  return toggleState.value
    ? nonAcceptedOffers.value
    : [acceptedOffer.value].filter(Boolean);
});

const hasNoOffers = computed(() => serviceOffers.value.length === 0);

const shouldShowToggle = computed(
  () => acceptedOffer.value !== undefined && nonAcceptedOffers.value.length > 0,
);

const toggleText = computed(() =>
  toggleState.value
    ? `Skjul tidligere sendte tilbud (${nonAcceptedOffers.value.length})`
    : `Se tidligere sendte tilbud (${nonAcceptedOffers.value.length})`,
);

const toggleAriaLabel = computed(
  () => `${toggleState.value ? "Skjul" : "Vis"} tidligere sendte tilbud`,
);

// CMS computed properties
const noSupplierOffersCms = computed(() => ({}));

// Methods
const handleCreateOffer = () => {
  // emit create offer event
  emit("create-offer");
};

const handleToggle = () => {
  toggleState.value = !toggleState.value;
};

const handleSendToCustomer = async (offer: ServiceOffer) => {
  console.log("handleSendToCustomer", offer);

  offerToSendToCustomer.value = offer;
  const daysToDeadline = getDaysToDeadline(props.tender);

  if (daysToDeadline !== undefined && daysToDeadline < 0) {
    showLateOfferModal.value = true;
  } else {
    showSendOfferModal.value = true;
  }
};

const handleLateOfferOk = () => {
  showLateOfferModal.value = false;
  showSendOfferModal.value = true;
};

const handleLateOfferCancel = () => {
  showLateOfferModal.value = false;
  offerToSendToCustomer.value = null;
};

const handleOfferSentAcknowledgement = () => {
  console.log("Navigate to requests list");
};

const handleCloseModal = () => {
  showSuccessOfferModal.value = false;
};

const handleDeleteDraft = async (offer: ServiceOffer) => {
  offerToDelete.value = offer;
  showDeleteDraftModal.value = true;
};

const handleDeleteOffer = async () => {
  console.log("handleDeleteOffer", offerToDelete.value);
  if (!offerToDelete.value?.serviceOfferId) return;

  try {
    await deleteSupplierOffer({
      supplierId: props.supplierId,
      supplierRequestId: props.supplierRequestId,
      offerId: offerToDelete.value.serviceOfferId,
    });
    await fetchServiceOffers();
    showDeleteDraftModal.value = false;
  } catch (error) {
    console.error("Error deleting offer:", error);
    handleError(error, "Error deleting offer");
  }
};

const handleCopyOffer = (offer: ServiceOffer) => {
  router.push({
    name: RouteNames.EDIT_OFFER,
    params: {
      priceRequestId: (props.tender.priceRequests[0]?.id || "").toString(),
      supplierRequestId: props.supplierRequestId.toString(),
    },
    query: {
      reuseOfferId: offer.serviceOfferId.toString(),
      suggestedName: offer.nickname
        ? `${offer.nickname} (Kopi)`
        : `Tilbud ${offer.serviceOfferId} (Kopi)`,
    },
  });
};

const handleEditSentWithdrawn = (offer: ServiceOffer) => {
  offerToEdit.value = offer;
  showEditSentOrWithdrawnModal.value = true;
};

const handleEdit = (offer: ServiceOffer) => {
  if (offer.currentState === OfferStateType.DRAFT) {
    router.push({
      name: RouteNames.EDIT_OFFER,
      params: {
        priceRequestId: (props.tender.priceRequests[0]?.id || "").toString(),
        supplierRequestId: props.supplierRequestId.toString(),
        offerId: offer.serviceOfferId.toString(),
      },
    });
  } else {
    offerToEdit.value = offer;
    showEditSentOrWithdrawnModal.value = true;
  }
};

const handleViewOffer = (offer: ServiceOffer) => {
  router.push({
    name: RouteNames.EDIT_OFFER,
    params: {
      priceRequestId: (props.tender.priceRequests[0]?.id || "").toString(),
      supplierRequestId: props.supplierRequestId.toString(),
      offerId: offer.serviceOfferId,
    },
  });
};

const handleRevoke = (offer: ServiceOffer) => {
  offerToWithdraw.value = offer;
  showWithdrawOfferModal.value = true;
};

const handleSeeAgreement = async (offer: ServiceOffer) => {
  if (!offer.serviceOfferId) return;

  try {
    await viewBlobFile(async () => {
      const { data } = await getTenderConfirmationUri({
        tenderId: props.tender.tenderId,
        offerId: offer.serviceOfferId,
        audience: Audience.SUPPLIER,
      });
      return data;
    }, router);
  } catch (error) {
    handleError(error, "Error viewing agreement");
  }
};

const handleDownloadTerms = () => {
  try {
    // TODO: Implement direct file download without CMS
    console.warn("Terms download not implemented");
  } catch (error) {
    handleError(error, "Error downloading terms");
  }
};

const handleReloadOfferList = () => {
  fetchServiceOffers();
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleError = (error: any, title: string) => {
  const errorResponse: ErrorResponse = {
    type: FeedbackType.ERROR,
    messageTitle: title,
    messageInfo:
      error instanceof Error
        ? error.message
        : typeof error === "object" && error !== null && "response" in error
          ? JSON.stringify(error)
          : "An unknown error occurred",
  };

  error.value = errorResponse;

  setTimeout(clearError, 4500);
};

const clearError = () => {
  error.value = null;
};

const getDaysToDeadline = (tender: Tender): number | undefined => {
  const deadlineDate = dayjs.utc(getTenderResponseDeadlineDate(tender));
  const now = dayjs.utc();

  if (!deadlineDate.isValid()) {
    console.warn(`Invalid deadline date for tender ${tender.tenderId}`);
    return undefined;
  }

  return Math.ceil(deadlineDate.diff(now, "day", true));
};

const isValidPriceRequest = (pr: PriceRequest): boolean => {
  return (
    !!pr &&
    typeof pr.offerResponsePeriodInDays === "number" &&
    pr.offerResponsePeriodInDays > 0
  );
};

const getValidRequestDates = (
  supplierRequests: SupplierRequest[],
): dayjs.Dayjs[] => {
  return supplierRequests
    .flatMap((request) => request.requestStateChanges)
    .filter((change) => change.stateTo === RequestStateType.PRICE_REQUESTED)
    .map((change) => dayjs(change.createdOn))
    .filter((date) => date.isValid());
};

const getTenderResponseDeadlineDate = (tender: Tender): Date | undefined => {
  if (!tender?.priceRequests?.length) return undefined;

  const priceRequest = tender.priceRequests[0];
  if (!priceRequest) return undefined;
  if (!isValidPriceRequest(priceRequest)) return undefined;

  const allValidDates = [
    ...getValidRequestDates(priceRequest.supplierRequests),
    dayjs(tender.createdOn),
  ].filter((date) => date.isValid());

  if (!allValidDates.length) return undefined;

  const latestDate = allValidDates.reduce((latest, current) =>
    current.isAfter(latest) ? current : latest,
  );

  return addBusinessDays(
    latestDate.toDate(),
    priceRequest.offerResponsePeriodInDays,
  );
};

const constructChangeOfferDto = () => {
  if (!userId.value) {
    throw new Error("User ID is required");
  }

  return {
    userId: userId.value,
    withdrawOtherOffers: false,
  };
};

const handleWithdrawOffer = async () => {
  if (!offerToWithdraw.value?.serviceOfferId) return;

  const changeOfferDto = constructChangeOfferDto();

  try {
    await changeServiceOfferState(
      props.supplierId,
      props.supplierRequestId,
      offerToWithdraw.value.serviceOfferId,
      "Withdrawn",
      changeOfferDto,
    );
    await fetchServiceOffers();
    showWithdrawOfferModal.value = false;
  } catch (error) {
    console.error("Error withdrawing offer:", error);
    handleError(error, "Error withdrawing offer");
  }
};

// Initialize
const initialize = async () => {
  if (!isComponentMounted.value) return;
  try {
    isServiceOffersLoading.value = true;
    isServiceOffersLoading.value = false;
  } catch (error) {
    console.error("Error initializing:", error);
  }
};

const fetchServiceOffers = async () => {
  if (!props.supplierId || !props.supplierRequestId) return;

  try {
    isServiceOffersLoading.value = true;
    emit("fetch-service-offers");
  } catch (error) {
    console.error("Error fetching service offers:", error);
  } finally {
    isServiceOffersLoading.value = false;
  }
};

// Lifecycle
onMounted(() => {
  isComponentMounted.value = true;
  initialize();
});

onBeforeUnmount(() => {
  isComponentMounted.value = false;
});

// Add emit definition
const emit = defineEmits<{
  "fetch-service-offers": [];
  "create-offer": [];
}>();
</script>

<style lang="scss" scoped>
.service-offers-wrapper {
  $color-primary: #611f69;
  $white-color: #ffffff;

  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;

  &__skeleton {
    width: 100%;
    padding: 16px;
  }

  .skeleton-container {
    display: flex;
    align-items: center;
    padding: 16px;
    margin-bottom: 16px;
    background-color: #fcfcfc;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .skeleton-icon {
    margin-right: 16px;
  }

  .skeleton-text {
    flex: 1;
    margin-right: 16px;
  }

  .skeleton-right {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
    width: 3rem;
    height: 3rem;
    color: $color-primary;
  }

  &__offer-lists {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__toggle-text {
    font-size: 1rem;
    margin: 20px auto 0 0;
    font-weight: bold;
    line-height: 20px;
    color: #000000;
    cursor: pointer;
    text-decoration: underline;

    &:focus {
      outline: 2px solid $color-primary;
      outline-offset: 2px;
    }
  }
}
</style>
