import { computed } from "vue";
import { formatCurrency } from "@/utilities/numberUtils";
import { BillingCycle } from "@/custom-types/GeneralTypes";
import { serviceActivityCms } from "@/utilities/activityUtils";
import { updateBillablePriceSummaryDetails } from "@/utilities/billablePriceUtils";
import type { PriceDetail } from "@/utilities/billablePriceUtils";

interface MandatorySupplierCost {
  itemTotalCost: number;
  billingCycle: BillingCycle;
  frequencyLabel: string | null;
  label: string;
  frequencyInputValue?: number;
  limitedPeriodStart?: string | null;
  limitedPeriodEnd?: string | null;
  priceType?: string;
}

interface ServiceActivityCms {
  title: string;
  label: string;
  frequencies?: {
    label: string;
    title: string;
  }[];
}

interface CostSummaryItem {
  name: string;
  amount: string;
}

interface UseAgreementCostsProps {
  mandatorySupplierCosts: MandatorySupplierCost[];
  serviceActivitiesCms: ServiceActivityCms[];
}

const TEXT_CONSTANTS = {
  averagePricePerYearText: "Gjenomsnittlig kostnad per år",
  totalBillableText: " Fakturerbart",
  totalMonthlyText: " Fakturerbart per måned",
  singleCostText: "Enkeltkostnader",
  perMonthText: " per måned",
} as const;

export const useAgreementCosts = ({
  mandatorySupplierCosts,
  serviceActivitiesCms,
}: UseAgreementCostsProps) => {
  const getFrequencyTitle = (
    billingCycle: BillingCycle,
    frequencyLabel: string | null,
  ): string => {
    if (!frequencyLabel) return "";

    // Split the label and take the second part after the dash
    const actualFrequencyLabel = frequencyLabel.split("-")[1] || frequencyLabel;

    const activityCms = serviceActivityCms(serviceActivitiesCms, billingCycle);

    if (!activityCms?.frequencies?.length) {
      console.warn(
        `No frequency options found for billing cycle: ${billingCycle}`,
      );
      return actualFrequencyLabel;
    }

    const frequency = activityCms.frequencies.find(
      (f) => f.label.toLowerCase() === actualFrequencyLabel.toLowerCase(),
    );

    if (!frequency) {
      console.warn(
        `No frequency match found for label: ${actualFrequencyLabel}`,
      );
      return actualFrequencyLabel;
    }

    return frequency.title;
  };

  const formatBillableText = (
    billingCycle: BillingCycle,
    frequencyLabel: string | null,
    isActivePeriod: boolean,
    activePeriodStart: string | null,
    activePeriodEnd: string | null,
  ): string => {
    const formattedText = TEXT_CONSTANTS.totalBillableText.trim();
    const limitedPeriodText = isActivePeriod
      ? ` (${activePeriodStart?.substring(0, 3)} - ${activePeriodEnd?.substring(0, 3)})`
      : "";

    const activityCms = serviceActivityCms(serviceActivitiesCms, billingCycle);

    const frequencyTitle = getFrequencyTitle(billingCycle, frequencyLabel);
    const activityTitle = frequencyTitle ?? formattedText;

    switch (billingCycle) {
      case BillingCycle.YEARLY:
        return activityTitle;
      case BillingCycle.FIXED:
        return activityCms?.title ?? TEXT_CONSTANTS.singleCostText;
      case BillingCycle.MONTHLY:
        return limitedPeriodText
          ? `${activityTitle}${limitedPeriodText}`
          : activityTitle;
      default:
        return activityTitle;
    }
  };

  const calculatePrice = (cost: MandatorySupplierCost): number => {
    return (cost.frequencyInputValue || 1) * cost.itemTotalCost;
  };

  const extractAggregatedPrices = (
    costs: MandatorySupplierCost[],
  ): MandatorySupplierCost[] => {
    const validCosts = costs.filter(
      (cost) =>
        cost.priceType === "Aggregated" &&
        Object.values(BillingCycle).includes(cost.billingCycle),
    );

    const yearlyTotal = validCosts.reduce((sum, cost) => {
      if (cost.billingCycle !== BillingCycle.FIXED) {
        return sum + calculatePrice(cost);
      }
      return sum;
    }, 0);

    const averageCost: MandatorySupplierCost = {
      itemTotalCost: yearlyTotal,
      billingCycle: BillingCycle.YEARLY,
      frequencyLabel: null,
      label: TEXT_CONSTANTS.averagePricePerYearText,
      frequencyInputValue: 1,
    };

    return [averageCost, ...validCosts];
  };

  const costSummaryItems = computed<CostSummaryItem[]>(() => {
    if (!mandatorySupplierCosts?.length) {
      console.log("No mandatory supplier costs found");
      return [];
    }

    const aggregatedPrices = extractAggregatedPrices(mandatorySupplierCosts);

    const items = aggregatedPrices.map((cost) => ({
      text: formatBillableText(
        cost.billingCycle,
        cost.frequencyLabel,
        !!cost.limitedPeriodStart,
        cost.limitedPeriodStart ?? null,
        cost.limitedPeriodEnd ?? null,
      ),
      price: formatCurrency(cost.itemTotalCost),
    })) as PriceDetail[];

    // Use the billablePriceUtils to properly order the items
    const orderedItems = updateBillablePriceSummaryDetails(
      items,
      TEXT_CONSTANTS.averagePricePerYearText,
      TEXT_CONSTANTS.singleCostText,
      TEXT_CONSTANTS.totalMonthlyText,
    );
    // Convert to CostSummaryItem format
    return orderedItems.map((item) => ({
      name: item.text,
      amount: item.price,
    }));
  });

  const totalCost = computed(() => {
    if (!mandatorySupplierCosts?.length) return formatCurrency(0);

    const total = mandatorySupplierCosts.reduce(
      (sum, cost) => sum + calculatePrice(cost),
      0,
    );
    return formatCurrency(total);
  });

  return {
    costSummaryItems,
    totalCost,
  };
};
