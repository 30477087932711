/**
 * Converts a File object to a base64 string
 * @param file - The file to convert
 * @returns Promise resolving to the base64 string without data URI prefix
 */
const convertFileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      if (!reader.result) {
        resolve("");
        return;
      }

      const base64String = reader.result as string;
      const base64Content = base64String.split(",")[1] ?? "";
      resolve(base64Content);
    };

    reader.onerror = (error) => reject(error);
  });
};

interface MessageAttachment {
  fileName: string;
  fileContent: string;
}

/**
 * Creates a message attachment DTO from a file
 * @param file - The file to create an attachment from
 * @returns Promise resolving to the message attachment DTO
 */
const getMessageAttachmentDto = async (
  file: File,
): Promise<MessageAttachment> => {
  const fileContent = await convertFileToBase64(file);
  return {
    fileName: file.name,
    fileContent,
  };
};

/**
 * Gets the file extension from a filename
 * @param fileName - The filename to extract extension from
 * @returns The file extension including the dot
 */
const getFileExtension = (fileName: string): string => {
  return fileName.substring(fileName.lastIndexOf("."));
};

/**
 * Gets the filename without extension
 * @param fileName - The filename to remove extension from
 * @returns The filename without extension
 */
const getFileNameWithoutExtension = (fileName: string): string => {
  return fileName.substring(0, fileName.lastIndexOf("."));
};

/**
 * Filters files by maximum size
 * @param files - Array of files to filter
 * @param maxSizeInMB - Maximum allowed size in megabytes
 * @returns Array of files under the size limit
 */
const getFilesWithValidSize = (files: File[], maxSizeInMB: number): File[] => {
  const maxBytes = megabytesToBytes(maxSizeInMB);
  return files.filter((file) => file.size <= maxBytes);
};

/**
 * Filters files by allowed extensions
 * @param files - Array of files to filter
 * @param validExtensions - Array of allowed extensions
 * @returns Array of files with valid extensions
 */
const getFilesWithValidExtension = (
  files: File[],
  validExtensions: string[],
): File[] => {
  return files.filter((file) =>
    validExtensions.includes(getFileExtension(file.name).toLowerCase()),
  );
};

/**
 * Converts megabytes to bytes
 * @param sizeInMB - Size in megabytes
 * @returns Size in bytes
 */
const megabytesToBytes = (sizeInMB: number): number => {
  return sizeInMB * 1024 * 1024;
};

/**
 * Creates a copy of file array
 * @param files - Array of files to copy
 * @returns New array with copied files
 */
const makeFilesCopy = (files: File[]): File[] => {
  return files.map((file) => new File([file], file.name, { type: file.type }));
};

export {
  makeFilesCopy,
  getFileExtension,
  megabytesToBytes,
  convertFileToBase64,
  getFilesWithValidSize,
  getMessageAttachmentDto,
  getFilesWithValidExtension,
  getFileNameWithoutExtension,
};
