export enum LanguageCode {
  NO = "no",
  EN = "en",
}

export enum MessageTypes {
  PriceRequest = "PriceRequest",
  GeneralMessage = "GeneralMessage",
  Compliment = "Compliment",
  Complaint = "Complaint",
}

export enum MessageDomain {
  CUSTOMER_SUPPLIER = "CustomerSupplier",
  SUPPLIER_CUSTOMER = "SupplierCustomer",
}

export enum TenderState {
  OFFERED = "Offered",
  ACCEPTED = "Accepted",
  REJECTED = "Rejected",
  PRICE_REQUESTED = "PriceRequested",
  INFORMATION_REQUESTED = "InformationRequested",
  COMPLETED = "Completed",
}

export enum RequestState {
  OFFERED = "Offered",
  ACCEPTED = "Accepted",
  REJECTED = "Rejected",
  PRICE_REQUESTED = "PriceRequested",
  INFORMATION_REQUESTED = "InformationRequested",
  COMPLETED = "Completed",
  LOST = "Lost",
  ARCHIVED = "Archived",
  UNANSWERED = "Unanswered",
  DRAFT = "Draft",
}

export enum OfferState {
  ACCEPTED = "Accepted",
  REJECTED = "Rejected",
  COMPLETED = "Completed",
  WITHDRAWN = "Withdrawn",
  DRAFT = "Draft",
  OFFERED = "Offered",
  LOST = "Lost",
}

export enum MessageType {
  PRICE_REQUEST = "PriceRequest",
  GENERAL_MESSAGE = "GeneralMessage",
  COMPLIMENT = "Compliment",
  COMPLAINT = "Complaint",
  PRICE_OFFER = "PriceOffer",
}

export enum JobType {
  SINGLE = "Enkeltjobb",
  REPEATING = "Repeterende",
}

export enum ActivityFrequency {
  FIXED = "Fixed",
  MONTHLY = "Monthly",
  YEARLY = "Yearly",
}

export enum BillingCycle {
  FIXED = "Fixed",
  MONTHLY = "Monthly",
  YEARLY = "Yearly",
}

export enum BillingType {
  FIXED = "Fixed",
  VARIABLE = "Variable",
}

export enum SupplierCreationSource {
  REGISTRATIONS = "Registrations",
  SUPPLIER = "Supplier",
}

export type LocalAttachment = {
  fileName: string;
  attachmentUri: string;
  downloadHandler: () => Promise<void>;
};

export const ReallyAdmin = {
  REALLY_ADMIN: "ReallyAdmin",
} as const;

export const OnceOrMorePerMonthFrequency = {
  ITEM: "Item",
  SQUARE_METERS: "SquareMeters",
  HOURS_PER_MONTH: "HoursPerMonth",
  SECTIONS: "Sections",
} as const;

export const LessThanOncePerMonthFrequency = {
  ITEM: "Item",
  SQUARE_METERS: "SquareMeters",
  HOURS: "Hours",
  SECTIONS: "Sections",
} as const;

export const SingleCostFrequency = {
  ITEM: "Item",
  SQUARE_METERS: "SquareMeters",
  HOURS: "Hours",
  SECTIONS: "Sections",
} as const;

export enum RequestType {
  SERVICE_WITHOUT_ROBOT = "ServiceWithoutRobot",
  ROBOT = "Robot",
}

export enum BillingCycleUnits {
  YEARLY = "kr/år",
  MONTHLY = "kr/mnd",
  FIXED = "kr",
}

export const Months = {
  JANUARY: 1,
  FEBRUARY: 2,
  MARCH: 3,
  APRIL: 4,
  MAY: 5,
  JUNE: 6,
  JULY: 7,
  AUGUST: 8,
  SEPTEMBER: 9,
  OCTOBER: 10,
  NOVEMBER: 11,
  DECEMBER: 12,
} as const;

export enum OfferStatus {
  DRAFT = "Draft",
  OFFERED = "Offered",
  WITHDRAWN = "Withdrawn",
  ACCEPTED = "Accepted",
  REJECTED = "Rejected",
  COMPLETED = "Completed",
  LOST = "Lost",
}

export enum Audience {
  SUPPLIER = "Supplier",
  CUSTOMER = "Customer",
}

export enum ServiceActivityCmsLabelType {
  FIXED = "fixed",
  MONTHLY = "monthly",
  YEARLY = "yearly",
}

export enum PriceType {
  AGGREGATED = "Aggregated",
  DRIVING = "Driving",
  INDEPENDENT = "Independent",
}

export interface UserDTO {
  companyName: string | null;
  displayName: string;
  email: string;
  mobilePhone: string | null;
  orgNumber: string;
  principalName: string;
  supplierId: number;
  userId: string;
  termsAndConditionsAccepted: boolean;
  newsletterAccepted: boolean;
  otherCommunicationAccepted: boolean;
}

export interface CreateUserDTO {
  name: string | null;
  displayName: string | null;
  email: string | null;
  phoneNumber: string;
  orgNumber: string;
  companyName: string | null;
  password: string;
  supplierId: number | null;
}

export interface CreatedSupplierDTO {
  margin: number;
  commission: number;
  email: string;
  logo: string;
  description: string;
  contactPerson: string;
  orgNumber: string;
  startBank: string;
  revenue: number;
  employees: number;
  reservationLower: number;
  reservationHigher: number;
  reservationKilometers: number;
  certifications: string[];
  sustainabilityGoals: string[];
  offices: string[];
  id: number;
  sysName: string;
  entityPath: string;
}

export interface User {
  userId: string;
  orgNumbers: string;
  phoneNumber?: string;
  email: string;
  name: string;
  termsAndConditionsAccepted: boolean;
  newsletterAccepted: boolean;
  otherCommunicationAccepted: boolean;
}

export interface EditUserDTO {
  userId: string;
  orgNumber?: string;
  phoneNumber?: string;
  name: string;
  termsAndConditionsAccepted: boolean;
  newsletterAccepted: boolean;
  otherCommunicationAccepted: boolean;
}

export interface SearchSuggestion {
  id: string | number;
  suggestionText: string;
  icon: string;
  disabled?: boolean;
}

export const TenderViewTabLabels = {
  priceRequest: "price-request",
  messages: "messages",
  offers: "offers",
  agreement: "agreement",
  documentation: "documentation",
  economy: "economy",
};

export interface PublicActorInfo {
  name: string;
  winningBid: {
    company: string;
    value: number;
  };
  otherBids: Array<{
    company: string;
    value: number;
  }>;
}

export interface CompetingBid {
  supplierName: string;
  amount: number;
  won: boolean;
}

export interface Feedback {
  reasons: Reason[];
  comment: string;
  competingBids?: CompetingBid[];
}

export interface AcceptanceReason {
  id: number;
  reason: string;
}

export interface Reason {
  id: number;
  reason: string;
  text: string;
}

export interface InvoiceDTO {
  id: number;
  invoiceNumber: string;
  customerName: string;
  invoiceDate: string;
  paymentDueDate: string;
  taxInclusiveAmount: number;
  statusCode: number;
  restAmount: number;
  vatTotalsAmount: number;
  taxExclusiveAmount: number;
  emailAddress: string;
  comment: string | null;
  credited: boolean;
  lastPaymentDate: string | null;
  deliveryDate: string;
  ourReference: string | null;
  yourReference: string | null;
}

export interface ActiveRequest {
  priceRequestId: number;
  supplierRequestId: number;
  offerCount: number;
  offerResponsePeriodInDays: number;
  createdOn: string;
  userResponsible: string | null;
  integrationSource: string;
  customerAddress: string;
  customerPosition: string;
  customerName: string;
  customerOrgNumber: string;
  tenderReference: string;
  categoryLabel: string;
  categoryId: number;
  supplierRequestState: string;
  hasUnreadMessages: boolean;
  deadline: string;
}
export interface AgreementValue {
  averagePriceForBillingCycle: number;
  serviceOfferPriceId: number;
  activePeriods: number;
  billingCycle: BillingCycle;
}

export interface ServiceOffer {
  serviceOfferId: number;
  tenderId: number;
  nickname: string;
  currentState: OfferStateType;
  lastStateChange: string;
  dateOffered: string | null;
  bookingNumber: string;
  serviceCategoryLabel: string;
  serviceTypeLabel: string;
  customerOrgName: string;
  customerPropertyAddress: string;
  agreementValues: AgreementValue[];
  yearOneAgreementValue: number;
  readyForCustomer: boolean;
}

export enum OfferStateType {
  DRAFT = "draft",
  OFFERED = "offered",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
  COMPLETED = "completed",
  WITHDRAWN = "withdrawn",
  LOST = "lost",
}

export enum RequestStateType {
  OFFERED = "offered",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
  COMPLETED = "completed",
  WITHDRAWN = "withdrawn",
  LOST = "lost",
  UNANSWERED = "unanswered",
  DRAFT = "draft",
  PRICE_REQUESTED = "priceRequested",
  INFORMATION_REQUESTED = "informationRequested",
  ARCHIVED = "archived",
}

export enum FeedbackType {
  ERROR = "Error",
  SUCCESS = "Success",
}

export type CreatePriceTemplateDTO = {
  priceGroupId: number;
  billingType: string;
  label: string;
  unit: string;
  frequencySegmentId: number;
};

export type CreateMandatorySupplierCostDTO = {
  externalGrouping: string;
  frequencyLabel: string;
  frequencyInputValue: number;
  frequencySegmentId: number;
  label: string;
  segmentGroupId: number;
  itemPriceUnit: string;
  itemTotal: number;
  itemUnit: string;
  valueSegmentId: number;
  limitedPeriodStart: null;
  limitedPeriodEnd: null;
  itemTotalCost: number;
  itemCost: number;
  inputPrice: number;
};

export type UpdateMandatorySupplierCostDTO = {
  segmentGroupId: number;
  valueSegmentId: number;
  frequencySegmentId: number;
  itemTotalCost: number;
  itemCost: number;
  inputPrice: number;
  serviceOfferSegmentGroupPriceId: number;
  serviceOfferSegmentValueId: number;
  serviceOfferSegmentFrequencyValueId: number;
  externalGrouping: string;
  frequencyLabel: string;
  frequencyInputValue: number;
  label: string;
  itemPriceUnit: string;
  itemTotal: number;
  itemUnit: string;
  limitedPeriodStart: null;
  limitedPeriodEnd: null;
};

export type CreateIndependentSupplierCostDTO = {
  externalGrouping: string;
  label: string;
  segmentGroupId: number;
  itemPriceUnit: string;
  itemTotal: number;
  itemUnit: string;
  valueSegmentId: number;
  inputPrice: number;
};

export type MandatorySupplierCost = {
  segmentGroupId: number;
  valueSegmentId: number;
  frequencySegmentId: number;
  itemTotalCost: number;
  itemCost: number;
  inputPrice: number;
  serviceOfferSegmentFrequencyValueId: number;
  externalGrouping: string;
  frequencyLabel: string;
  frequencyInputValue: number;
  itemTotal: number;
  itemUnit: null;
  limitedPeriodStart: null;
  limitedPeriodEnd: null;
  segmentId: number;
  serviceOfferPriceId: number;
  serviceOfferSegmentGroupPriceId: number;
  serviceOfferSegmentValueId: number;
  priceType: string;
  billingCycle: string;
  label: string;
  itemPriceUnit: string;
};

export type IndependentSupplierCost = {
  itemPrice: number;
  segmentId: number;
  segmentGroupId: number;
  serviceOfferPriceId: number;
  serviceOfferSegmentGroupPriceId: number;
  serviceOfferSegmentValueId: number;
  priceType: string;
  billingCycle: string;
  label: string;
  itemPriceUnit: string;
};

export type CreateServiceOfferDTO = {
  UserIdGivingOffer: string;
};

export type CloneServiceOfferDTO = {
  UserIdCloningOffer: string;
  ServiceOfferIdToClone: number;
};

export type FileDTO = {
  id: string;
  file: File;
};

export type FileAttachmentDto = {
  fileName: string;
  fileContent: string;
};

export type FileAttachment = {
  attachmentId: string;
  fileName: string;
  mimeType: string;
  attachmentUri: string | null;
};

interface SupplierCostBase {
  itemTotalCost: number;
  inputPrice: number;
  segmentId: number;
  segmentGroupId: number;
  serviceOfferPriceId: number;
  serviceOfferSegmentGroupPriceId: number;
  serviceOfferSegmentValueId: number;
  priceType: "Aggregated" | "Independent";
  billingCycle: "Fixed" | "Monthly" | "Yearly";
  label: string;
  itemPriceUnit: string;
}

interface MandatorySupplierCostBase extends SupplierCostBase {
  frequencySegmentId: number | null;
  itemCost: number;
  serviceOfferSegmentFrequencyValueId: number;
  externalGrouping: string;
  frequencyLabel: string | null;
  frequencyInputValue: number | null;
  itemTotal: number;
  itemUnit: string;
  limitedPeriodStart: string | null;
  limitedPeriodEnd: string | null;
}

interface IndependentSupplierCostBase extends SupplierCostBase {
  // No additional properties needed
}

interface Description {
  id: number;
  title: string;
  body: string;
}

interface ServiceOfferAttachment {
  attachmentId: string;
  fileName: string;
  mimeType: string;
  attachmentUri: string | null;
}

export interface ServiceOfferItem {
  mandatorySupplierCosts: MandatorySupplierCostBase[];
  independentSupplierCosts: IndependentSupplierCostBase[];
  yearOneAgreementValue: number;
  integrationSource: string;
  integrationExternalId: string;
  supplierOfficeId: number;
  suggestedStartDate: string | null;
  serviceTypePackagePortfolioId: number;
  frameAgreementId: null;
  offeredByUserId: string;
  acceptedByUserId: string;
  acceptanceDate: string;
  descriptions: Description[];
  serviceOfferAttachments: ServiceOfferAttachment[];
  terminationPeriodInMonths: number | null;
  serviceOfferId: number;
  supplierRequestId: number;
  tenderId: number;
  externalTenderId: string;
  nickname: string;
  currentState: string;
  lastStateChange: string;
  dateOffered: string;
  bookingNumber: string;
  serviceCategoryLabel: string;
  serviceTypeLabel: string;
  customerOrgName: string;
  customerPropertyAddress: string;
}
