<template>
  <div class="options-variable-costs-item" :class="containerClasses">
    <div class="options-variable-costs-item__content">
      <img
        :src="`/images/document.svg`"
        alt="icon"
        class="options-variable-costs-item__icon"
      />
      <p class="options-variable-costs-item__title">
        {{ supplierCost.label }}
      </p>
      <amount-section :amount="formattedTotalPrice" :text="perPieceText" />
    </div>
    <div class="options-variable-costs-item__billable-section">
      <amount-section
        :amount="formattedBillablePrice"
        :text="billablePerHourText"
      />
      <activities-costs-drop-down-menu
        v-if="!readOnly"
        class="options-variable-costs-item__billable-section-menu"
        @edit-item="$emit('open-edit-item-modal')"
        @delete-item="$emit('open-delete-item-modal')"
      />
    </div>
  </div>
</template>

<script>
import ActivitiesCostsDropDownMenu from "./ActivitiesCostsDropDownMenu.vue";
import AmountSection from "./AmountSection.vue";
import { formatCurrency } from "@/utilities/numberUtils";
import { getCmsItem } from "@/utilities/cmsUtilities";

export default {
  name: "OptionsVariableCostsItem",
  components: { ActivitiesCostsDropDownMenu, AmountSection },
  props: {
    supplierCost: {
      type: Object,
      required: true,
    },
    isFirst: {
      type: Boolean,
      default: false,
    },
    isLast: {
      type: Boolean,
      default: false,
    },
    variableCostSectionCms: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    serviceActivitiesCms: {
      type: Array,
      required: true,
    },
  },
  emits: ["open-delete-item-modal", "open-edit-item-modal"],
  data() {
    return {
      currency: "kr",
    };
  },
  computed: {
    serviceActivityCms() {
      return getCmsItem(this.serviceActivitiesCms, "variable");
    },
    itemUnit() {
      return this.supplierCost.itemUnit || this.supplierCost.itemPriceUnit;
    },
    itemCms() {
      return this.serviceActivityCms.activityItems.find(
        (item) => item.label === this.itemUnit,
      );
    },
    containerClasses() {
      return {
        first: this.isFirst,
        last: this.isLast,
      };
    },
    perPieceText() {
      return `Per ${this.itemCms ? this.itemCms.singular : this.itemUnit}`;
    },
    billablePerHourText() {
      return `Fakturerbart per ${this.itemCms ? this.itemCms.singular : this.itemUnit}`;
    },
    formattedTotalPrice() {
      return formatCurrency(parseInt(this.supplierCost.inputPrice));
    },
    formattedBillablePrice() {
      return formatCurrency(this.supplierCost.itemTotalCost);
    },
  },
};
</script>

<style lang="scss" scoped>
$fixed-costs-border: 1px solid $color-primary-pink-darker;
$fixed-costs-bg: #fff;
$fixed-costs-section-bg: $color-primary-pink-dark;
$amount-color: #000000;
$label-color: #787878;
$line-bg: #9a9a9a;
$font-400: 400;
$font-600: 600;

.options-variable-costs-item {
  display: flex;
  flex-direction: row;
  background: $fixed-costs-bg;
  border: $fixed-costs-border;

  &.first {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &.last {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &__content {
    display: flex;
    align-items: center;
    padding: 12px 16px 12px 23px;
    flex: 1 1 auto;
    width: 64%;
  }

  &__title {
    font-weight: $font-600;
    font-size: $font-size-base;
    line-height: 20px;
    color: $amount-color;
    margin-bottom: 0.3rem;
    margin-top: 0.25rem;
    margin-left: 16px;
    word-wrap: break-word;
    min-width: 400px;
    max-width: 400px;
  }

  &__billable-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: $fixed-costs-section-bg;
    box-shadow: inset 1px 0 0 $line-bg;
    align-items: center;
    padding: 0 16px 0 48px;
    gap: 24px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    text-align: right;
    flex: 1 1 auto;

    &__menu {
      border-radius: 4px;
      margin-left: 8px;
    }
  }
}
</style>
