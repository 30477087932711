<template>
  <div class="no-offers" role="alert" aria-label="No offers available">
    <img
      :src="DocumentPlusIcon"
      alt="Create new offer"
      class="no-offers__icon"
    />

    <p class="no-offers__text">
      {{ noOffersText }}
    </p>

    <div class="no-offers__button">
      <b-button
        v-if="canCreateOffer"
        :disabled="!canCreateOffer"
        class="no-offers__btn"
        size="lg"
        @click="handleCreateOffer"
        @keydown.enter="handleCreateOffer"
      >
        Gi tilbud
      </b-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import DocumentPlusIcon from "@/assets/img/document-plus.svg";
import { computed } from "vue";

import type { Tender } from "@/stores/tender/types";

interface NoServiceOffersProps {
  tender: Tender;
  canCreateOffer: boolean;
  noSupplierOffersCms?: {
    title?: string;
    description?: string;
  };
}

interface NoServiceOffersEmits {
  (e: "create-offer"): void;
}

const props = withDefaults(defineProps<NoServiceOffersProps>(), {
  noSupplierOffersCms: () => ({}),
});

const emit = defineEmits<NoServiceOffersEmits>();
// Computed
const noOffersText = computed(
  () =>
    props.noSupplierOffersCms?.description ??
    "Du har ikke laget noen tilbud på denne prisforespørselen.",
);

// Methods
const handleCreateOffer = () => {
  emit("create-offer");
};
</script>

<style lang="scss" scoped>
.no-offers {
  $color-primary: #611f69;
  $color-primary-lighter: #f9f0f0;
  $color-primary-pink-darker: #d4b5b5;

  text-align: center;
  background: $color-primary-lighter;
  border: 0.1rem solid $color-primary-pink-darker;
  border-radius: 0.25rem;
  padding: 3rem 1rem;
  min-height: 24rem;
  width: 100%;

  &__icon {
    width: 4rem;
    height: 4rem;
  }

  &__text {
    margin: 2.5rem 0;
    font-size: 0.875rem;
    font-weight: 400;
    color: #000;
  }

  &__alert {
    margin: 1.5rem;
  }

  &__btn {
    font-weight: bold;
    font-size: 1rem;
    text-transform: uppercase;
    background-color: $color-primary;
    border-color: $color-primary;
    border-width: 0.1rem;
    color: white;
    padding: 0.75rem 1.5rem;
    transition: all 0.2s ease;

    &:hover:not(:disabled) {
      background-color: darken($color-primary, 10%);
      border-color: darken($color-primary, 10%);
    }

    &:focus {
      box-shadow: 0 2px 10px 0 rgba($color-primary, 0.25);
    }

    &:disabled {
      opacity: 0.65;
      cursor: not-allowed;
    }
  }
}
</style>
