import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import "bootstrap/scss/bootstrap.scss";
import "bootstrap-vue-next/dist/bootstrap-vue-next.css";
import "@/assets/scss/_base.scss";
import { createBootstrap } from "bootstrap-vue-next";
import { registerValidateUserGuard } from "@/router/guard";
import { useConfiguration } from "@really/really-events-recorder-components";
import PrimeVue from "primevue/config";
import "primeicons/primeicons.css";
import Aura from "@primevue/themes/aura";
import Tooltip from "primevue/tooltip";
import Ripple from "primevue/ripple";
import { clearAllCaches } from "./utilities/cachingDecorator";
import ToastService from "primevue/toastservice";
import Toast from "primevue/toast";
import { setGlobalToastService } from "./composables/useToastErrorHandler";

if (history.scrollRestoration) {
  history.scrollRestoration = "manual";
}

const app = createApp(App);
const pinia = createPinia();
app.use(createBootstrap({ components: true, directives: true }));
app.use(router);
app.use(pinia);
app.use(PrimeVue, {
  locale: {
    dayNames: [
      "søndag",
      "mandag",
      "tirsdag",
      "onsdag",
      "torsdag",
      "fredag",
      "lørdag",
    ],
    dayNamesShort: ["sø.", "ma.", "ti.", "on.", "to.", "fr.", "lø."],
    dayNamesMin: ["sø", "ma", "ti", "on", "to", "fr", "lø"],
    monthNames: [
      "januar",
      "februar",
      "mars",
      "april",
      "mai",
      "juni",
      "juli",
      "august",
      "september",
      "oktober",
      "november",
      "desember",
    ],
    monthNamesShort: [
      "jan.",
      "feb.",
      "mars",
      "april",
      "mai",
      "juni",
      "juli",
      "aug.",
      "sep.",
      "okt.",
      "nov.",
      "des.",
    ],
  },
  theme: {
    preset: Aura,
    options: {
      darkModeSelector: "none",
    },
  },
  pt: {
    tooltip: {
      style: {
        background: "#fff",
        borderBottomColor: "var(--p-primary-color)",
      },
    },
  },
});
app.use(ToastService);
app.directive("tooltip", Tooltip);
app.directive("ripple", Ripple);
app.component("Toast", Toast);

// Register the global toast service
setGlobalToastService(app.config.globalProperties.$toast);

// Global error handler
app.config.errorHandler = (error, instance, info) => {
  console.error("Global error:", error);
  console.error("Error occurred in component:", instance);
  console.error("Error info:", info);

  // You can access the toast service globally via app.config.globalProperties
  const toast = app.config.globalProperties.$toast;
  if (toast) {
    toast.add({
      severity: "error",
      summary: "An error occurred",
      detail: error instanceof Error ? error.message : "Unknown error",
      life: 5000,
    });
  }

  // Optional: You could also send errors to a monitoring service here
};

const { configuration } = useConfiguration();
configuration.BASE_URI = import.meta.env.VITE_APP_BASE_URI;
configuration.API_KEY = import.meta.env.VITE_APP_API_KEY;

router.isReady().then(() => {
  registerValidateUserGuard(router);
  app.mount("#app");
});

// Clear caches on page refresh/load
window.addEventListener("beforeunload", () => {
  // This runs right before the page is unloaded/refreshed
  localStorage.setItem("pageRefreshing", "true");
});

window.addEventListener("load", () => {
  // This runs when the page loads
  if (localStorage.getItem("pageRefreshing") === "true") {
    clearAllCaches();
    localStorage.removeItem("pageRefreshing");
    console.log("Cache cleared on page refresh");
  }
});
