<template>
  <button
    class="primary-button"
    :disabled="disabled"
    :class="buttonClasses"
    :type="type"
    @click="$emit('click')"
  >
    <b-spinner v-if="isSubmitting" class="primary-button__spinner" />
    <template v-else>
      <img
        v-if="iconSrc"
        :src="iconSrc"
        class="primary-button__icon"
        alt="Button icon"
      />
    </template>
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    iconSrc: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String, //inverted
      default: "",
    },
    type: {
      type: String,
      default: "button",
    },
    isSubmitting: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["click"],
  computed: {
    buttonClasses() {
      const baseClass = "primary-button";
      return [`${baseClass}--${this.variant}-variant`];
    },
  },
};
</script>

<style lang="scss" scoped>
.primary-button {
  padding: 1rem 2.5rem;
  font-weight: 600;
  font-size: $font-size-base;
  line-height: 1.25;
  background: #611f69;
  color: $color-white;
  text-transform: uppercase;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border: 1px solid #611f69;

  &:disabled {
    color: $color-white;
    background-color: #727272;
    cursor: no-drop;
  }

  &--inverted-variant {
    background: $color-white;
    color: #441b49;
    border-radius: 0;
    text-transform: none;

    &:hover:enabled {
      font-weight: 700;
      background: #e6e6e6;
      border: 1px solid #e6e6e6;
    }

    &:disabled {
      background: #8a8a8a;
      border: 1px solid #8a8a8a;
    }
  }

  &__icon {
    flex-shrink: 0;
    width: 1.5rem;
    height: 1.5rem;
  }

  &__spinner {
    font-size: $font-size-xs;
    width: 1rem;
    height: 1rem;
  }
}
</style>
