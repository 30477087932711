const convertFileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const result = reader.result ? reader.result : "";
      const resultWithoutType = result.split(",")[1];
      resolve(resultWithoutType);
    };
    reader.onerror = (error) => reject(error);
  });
};

const getFileExtension = (fileName) => {
  return fileName.substring(fileName.lastIndexOf("."), fileName.length);
};

const getFileNameWithoutExtension = (fileName) => {
  return fileName.substring(0, fileName.lastIndexOf("."));
};

const getFilesWithValidSize = (files, maxSizeInMB) => {
  return files.filter((file) => file.size <= megabytesToBytes(maxSizeInMB));
};

const getFilesWithValidExtension = (files, validExtensions) => {
  return files.filter((file) =>
    validExtensions.includes(getFileExtension(file.name).toLowerCase()),
  );
};

const megabytesToBytes = (sizeInMB) => {
  return sizeInMB * 1024 * 1024;
};

const makeFilesCopy = (files) => {
  return files.map(
    (file) => new File([file], file.name, { type: file.type }), //Make file copy
  );
};

export {
  makeFilesCopy,
  getFileExtension,
  megabytesToBytes,
  convertFileToBase64,
  getFilesWithValidSize,
  getFilesWithValidExtension,
  getFileNameWithoutExtension,
};
