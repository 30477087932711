<template>
  <div>
    <div v-if="supplierRegistrationsCms" class="registration-content">
      <div class="registration-content__title">
        <p>{{ supplierRegistrationsCms.title }}</p>
      </div>

      <!-- Supplier Name Inputs Form -->
      <form class="registration-form mt-5" @submit.prevent="onSupplierSubmit">
        <div class="registration-content__subtitle">
          <p>1. Leverandør</p>
        </div>
        <div>
          <div
            v-for="input in supplierRegistrationsCms.supplierInputs"
            :key="input?.label"
            :class="
              input?.label === 'companyInput' || showSupplierInputs
                ? 'registration-form__inputs'
                : 'registration-form__inputs--hide'
            "
          >
            <v-field
              v-show="input?.label === 'companyInput' || showSupplierInputs"
              v-slot="{ errors }"
              :name="getInputFieldName(input)"
              :rules="getInputValidationRules(input)"
            >
              <component
                :is="resolveInputComponent(input)"
                v-bind="
                  getInputComponentProps(supplierForm, getInputFieldName(input))
                "
                v-show="input?.label === 'companyInput' || showSupplierInputs"
                :placeholder="input?.placeholder"
                :state="getValidationState(errors)"
                :disabled="isLoading"
              />
              <div
                v-if="supplierErrors[getInputFieldName(input)]"
                class="registration-form__feedback"
              >
                <InputError :text="supplierErrors[getInputFieldName(input)]" />
              </div>
            </v-field>
          </div>
        </div>

        <div v-if="showSupplierError" class="registration-form__feedback">
          <InputError :text="supplierErrorMessage" />
        </div>
        <div v-if="showSupplierSuccess" class="registration-form__feedback">
          <SuccessMessage :text="supplierSuccessMessage" />
        </div>

        <div class="registration-form__buttons">
          <button
            :disabled="isLoading || selectedSupplier !== null"
            @click="onSupplierSubmit"
          >
            <b-spinner
              v-if="isSupplierSaving"
              class="registration-form__spinner"
            />
            lagre leverandør
          </button>
          <button
            type="button"
            :disabled="isLoading"
            @click="searchSupplierByOrgNumber"
          >
            <b-spinner
              v-if="isSupplierSearching"
              class="registration-form__spinner"
            />
            søk etter leverandører
          </button>
          <button
            type="button"
            :disabled="isLoading"
            @click="clearSupplierForm"
          >
            Tøm
          </button>
        </div>
      </form>

      <!-- Create User Inputs Form -->
      <form
        class="registration-form mt-5"
        :validation-schema="userValidationSchema"
        @submit.prevent="onUserSubmit"
      >
        <div class="registration-content__subtitle">
          <p>2. Bruker</p>
        </div>
        <div>
          <div
            v-for="input in supplierRegistrationsCms.userInputs"
            :key="input?.label"
            :class="
              input?.label === 'emailInput' || showUserInputs
                ? 'registration-form__inputs'
                : 'registration-form__inputs--hide'
            "
          >
            <v-field
              v-show="input?.label === 'emailInput' || showUserInputs"
              v-slot="{ errors }"
              :name="getInputFieldName(input)"
              :rules="getInputValidationRules(input)"
            >
              <component
                :is="resolveInputComponent(input)"
                v-bind="
                  getInputComponentProps(userForm, getInputFieldName(input))
                "
                v-show="input?.label === 'emailInput' || showUserInputs"
                :placeholder="input?.placeholder"
                :state="getValidationState(errors)"
                :disabled="isLoading"
              />
              <div
                v-if="userErrors[getInputFieldName(input)]"
                class="registration-form__feedback"
              >
                <InputError :text="userErrors[getInputFieldName(input)]" />
              </div>
            </v-field>
          </div>
        </div>

        <div v-if="userErrors.supplier" class="registration-form__feedback">
          <InputError :text="userErrors.supplier" />
        </div>

        <div v-if="showUserError" class="registration-form__feedback">
          <InputError :text="userErrorMessage" />
        </div>
        <div v-if="showUserSuccess" class="registration-form__feedback">
          <SuccessMessage :text="userSuccessMessage" />
        </div>

        <div class="registration-form__buttons">
          <button
            type="button"
            :disabled="isLoading || selectedUser !== null"
            @click="onUserSubmit"
          >
            <b-spinner v-if="isUserSaving" class="registration-form__spinner" />
            lagre bruker
          </button>

          <button
            type="button"
            :disabled="isLoading"
            @click="searchUserByEmail"
          >
            <b-spinner
              v-if="isUserSearching"
              class="registration-form__spinner"
            />
            søke etter brukere
          </button>
          <button type="button" :disabled="isLoading" @click="clearUserForm">
            Tøm
          </button>
        </div>
      </form>

      <!-- Connect Supplier User Form -->
      <div class="connect-supplier-user mt-5">
        <div class="registration-content__subtitle">
          <p>3. Koble leverandør og bruker</p>
        </div>
        <p>
          Leverandør:
          <template v-if="selectedSupplier">
            {{ selectedSupplier.sysName }} - {{ selectedSupplier.id }}
          </template>
          <template v-else>-</template>
        </p>
        <p>
          Bruker:
          <template v-if="selectedUser">
            {{ selectedUser.displayName }} - {{ selectedUser.userId }}
          </template>
          <template v-else>-</template>
        </p>

        <div v-if="showConnectError" class="registration-form__feedback">
          <InputError :text="connectErrorMessage" />
        </div>
        <div v-if="showConnectSuccess" class="registration-form__feedback">
          <SuccessMessage :text="connectSuccessMessage" />
        </div>

        <div class="registration-form__buttons">
          <button
            type="button"
            :disabled="isLoading"
            @click="connectSupplierUser"
          >
            <b-spinner v-if="isConnecting" class="registration-form__spinner" />
            koble
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useForm, Field as VField } from "vee-validate";
import { useCmsStore } from "@/stores/cms";
import { useUserStore } from "@/stores/user";
import InputError from "@/components/InputError/InputError.vue";
import SuccessMessage from "@/components/SuccessMessage/SuccessMessage.vue";
import { ContentType } from "@/custom-types/CmsContentTypes";
import { useCheckUserAccess } from "@/composables/useCheckUserAccess";
import { object, string } from "yup";
import { Button, ExtraText, Input } from "@/stores/cms/types";
import BaseInput from "@/components/Base/BaseInput/BaseInput.vue";
import OrgNumberAutocomplete from "@/components/OrgNumberAutocomplete/OrgNumberAutocomplete.vue";
import { useSupplierProfileActions } from "@/composables/useSupplierProfileActions";
import { UpdateSupplierProfileDto } from "@/stores/supplier/types";
import { RouteNames } from "@/router/types";
import { useRouter } from "vue-router";
import { useUsersApi } from "@/services/api/useUserApi";
import { useSupplierApi } from "@/services/api/useSupplierApi";
import { CreateUserDTO, CreatedSupplierDTO } from "@/custom-types/GeneralTypes";
import { Company } from "@/services/api/useCompanyRegistryApi";

const { createSupplierProfile } = useSupplierProfileActions();

const { userIsReallyAdmin } = useCheckUserAccess();
const cmsStore = useCmsStore();
const userStore = useUserStore();
const router = useRouter();

const { createUser, fetchCustomerByEmail } = useUsersApi();
const { createSupplierUser, getSupplierByOrgNumber } = useSupplierApi();

interface SupplierFormValues {
  companyInput: {
    inputText: string;
    selectedCompany: {
      orgNumber: string;
      companyName: string;
    } | null;
  };
  [key: string]: string | object | undefined;
}

interface UserFormValues {
  emailInput: string;
  fullNameInput: string;
  phoneNumberInput: string;
  passwordInput: string;
  [key: string]: string | undefined;
}

interface CreatedUserDto extends CreateUserDTO {
  userId: string;
}

interface CompanyInputValue {
  inputText: string;
  selectedCompany: Company | null;
}

const isLoading = ref<boolean>(false);

const showSupplierInputs = ref(false);
const showUserInputs = ref(false);

const isSupplierSaving = ref(false);
const isSupplierSearching = ref(false);
const isUserSaving = ref(false);
const isUserSearching = ref(false);
const isConnecting = ref(false);

const selectedSupplier = ref<CreatedSupplierDTO | null>(null);
const selectedUser = ref<CreatedUserDto | null>(null);

const showSupplierSuccess = ref<boolean>(false);
const showSupplierError = ref<boolean>(false);
const supplierErrorMessage = ref<string | undefined>("");
const supplierSuccessMessage = ref<string | undefined>("");

const showUserSuccess = ref<boolean>(false);
const showUserError = ref<boolean>(false);
const userErrorMessage = ref<string | undefined>("");
const userSuccessMessage = ref<string | undefined>("");

const showConnectSuccess = ref<boolean>(false);
const showConnectError = ref<boolean>(false);
const connectErrorMessage = ref<string | undefined>("");
const connectSuccessMessage = ref<string | undefined>("");

const userForm = ref<UserFormValues>({
  emailInput: "",
  fullNameInput: "",
  phoneNumberInput: "",
  passwordInput: "",
});

const supplierForm = ref<SupplierFormValues>({
  companyInput: {
    inputText: "",
    selectedCompany: null,
  },
  supplierNameInput: "",
});

const supplierErrors = ref<Record<string, string>>({});
const userErrors = ref<Record<string, string>>({});

watch(
  () => supplierForm.value,
  (newValue) => {
    if (newValue.companyInput.inputText !== "") {
      supplierErrors.value.companyInput = "";
    }
    if (newValue.supplierNameInput !== "") {
      supplierErrors.value.supplierNameInput = "";
    }
  },
  { deep: true },
);

watch(
  () => userForm.value,
  (newValue) => {
    const { fullNameInput, emailInput, phoneNumberInput, passwordInput } =
      newValue;

    userErrors.value.fullNameInput = fullNameInput
      ? ""
      : userErrors.value.fullNameInput || "";
    userErrors.value.emailInput = emailInput
      ? ""
      : userErrors.value.emailInput || "";

    if (phoneNumberInput) {
      userErrors.value.phoneNumberInput = isValidPhoneNumber(phoneNumberInput)
        ? ""
        : "Ugyldig telefonnummerformat";
    } else {
      userErrors.value.phoneNumberInput = "";
    }

    if (passwordInput) {
      userErrors.value.passwordInput = passwordValidationRegex.value.test(
        passwordInput,
      )
        ? ""
        : "Passordet må være minst 8 tegn langt og inneholde 3 av følgende: stor bokstav, liten bokstav, tall og symbol";
    } else {
      userErrors.value.passwordInput = "";
    }
  },
  { deep: true },
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const supplierRegistrationsCms: any = computed(() => {
  const cms = cmsStore[ContentType.SUPPLIER_REGISTRATIONS_PAGE_CMS];

  if (!cms) {
    return null;
  }

  const { title, forms } = cms;
  const form = Array.isArray(forms) ? forms[0] : undefined;

  if (!form) {
    return null;
  }

  const { inputs = [], buttons = [], otherText = [] } = form;

  const getInputByLabel = (label: string) => {
    return inputs.find((input: Input) => input.label === label);
  };

  const getButtonByLabel = (label: string) => {
    return (
      buttons.find((button: Button) => button.label === label)?.title || ""
    );
  };

  const getOtherTextByLabel = (label: string) => {
    return (
      otherText.find((text: ExtraText) => text.label === label)?.title || ""
    );
  };

  return {
    title,
    supplierInputs: {
      company: getInputByLabel("companyInput"),
      supplierName: getInputByLabel("supplierNameInput"),
    },
    userInputs: {
      email: getInputByLabel("emailInput"),
      fullName: getInputByLabel("fullNameInput"),
      phoneNumber: getInputByLabel("phoneNumberInput"),
      password: getInputByLabel("passwordInput"),
    },
    connectSupplierUserInputs: {},
    buttons: {
      register: getButtonByLabel("registrationButton"),
    },
    otherText: {
      errorMessage: getOtherTextByLabel("errorMessage"),
      successMessage: getOtherTextByLabel("successMessage"),
    },
  };
});

const passwordValidationRegex = computed(() => {
  const lowercaseUppercaseNumber = /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/;
  const lowercaseUppercaseSymbol =
    /(?=.*[a-z])(?=.*[A-Z])(?=.*[-@#$%^&*_!+=[\]{}|:',.?/`~"();])/;
  const lowercaseNumberSymbol =
    /(?=.*[a-z])(?=.*\d)(?=.*[-@#$%^&*_!+=[\]{}|:',.?/`~"();])/;
  const uppercaseNumberSymbol =
    /(?=.*[A-Z])(?=.*\d)(?=.*[-@#$%^&*_!+=[\]{}|:',.?/`~"();])/;
  const characterClassesValidator = new RegExp(
    [
      lowercaseUppercaseNumber,
      lowercaseUppercaseSymbol,
      lowercaseNumberSymbol,
      uppercaseNumberSymbol,
    ]
      .map((regex) => regex.source)
      .join("|"),
  );
  const nonCapturingCharacterClassesValidator = new RegExp(
    "(?:" + characterClassesValidator.source + ")",
  );
  const lengthValidator = /[-A-Za-z0-9@#$%^&*_!+=[\]{}|:',.?/`~"();]{8,64}/;
  return new RegExp(
    nonCapturingCharacterClassesValidator.source + lengthValidator.source,
  );
});

const supplierValidationSchema = object().shape({
  companyInput: object().shape({
    inputText: string().required(),
    selectedCompany: object().nullable().shape({
      orgNumber: string().required(),
      companyName: string().required(),
    }),
  }),
  supplierNameInput: string().required(),
});

const userValidationSchema = object().shape({
  phoneNumberInput: string()
    .required()
    .matches(/^(0047|\+47|47)?[2-9]\d{7}$/, "Invalid phone number"),
  passwordInput: string()
    .required("Passord er påkrevd")
    .matches(
      passwordValidationRegex.value,
      "Passordet må være minst 8 tegn langt og inneholde 3 av følgende: stor bokstav, liten bokstav, tall og symbol",
    ),
});

const { resetForm: resetSupplierForm } = useForm<SupplierFormValues>({
  validationSchema: supplierValidationSchema,
  initialValues: supplierForm.value,
});

const { resetForm: resetUserForm } = useForm<UserFormValues>({
  validationSchema: userValidationSchema,
  initialValues: userForm.value,
});

const resetTheSupplierForm = () => {
  resetSupplierForm();
  supplierForm.value = {
    companyInput: {
      inputText: "",
      selectedCompany: null,
    },
    supplierNameInput: "",
  };
};

const resetTheUserForm = () => {
  resetUserForm();
  userForm.value = {
    emailInput: "",
    fullNameInput: "",
    phoneNumberInput: "",
    passwordInput: "",
  };
};

const getInputValidationRules = (input: Input | undefined) => {
  const rules = string().required("This field is required");

  if (input?.label === "emailInput") {
    return rules.email("Invalid email");
  }

  if (input?.label === "phoneNumberInput") {
    return rules.matches(/^(0047|\+47|47)?[2-9]\d{7}$/, "Invalid phone number");
  }

  return rules;
};

const resolveInputComponent = (input: Input | undefined) => {
  if (input?.label === "companyInput") {
    return OrgNumberAutocomplete;
  }

  return BaseInput;
};

const getInputComponentProps = (
  formValues: SupplierFormValues | UserFormValues,
  inputLabel: string,
) => {
  if (inputLabel === "companyInput") {
    return {
      modelValue: (formValues as SupplierFormValues).companyInput,
      "onUpdate:modelValue": (value: CompanyInputValue) => {
        (formValues as SupplierFormValues).companyInput = value;
      },
    };
  }
  return {
    modelValue: formValues[inputLabel] || "",
    "onUpdate:modelValue": (value: string) => {
      formValues[inputLabel] = value;
    },
  };
};

const getInputFieldName = (input: Input | undefined) => {
  return input?.label || "";
};

const getValidationState = (errors: string[]) => {
  return errors.length === 0;
};

const searchSupplierByOrgNumber = async () => {
  isSupplierSearching.value = true;
  try {
    const { companyInput } = supplierForm.value as SupplierFormValues;
    const orgNumber = companyInput?.selectedCompany?.orgNumber;

    if (orgNumber) {
      const supplierResponse = await getSupplierByOrgNumber(orgNumber);
      const supplier = supplierResponse.data;

      if (supplier) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        selectedSupplier.value = supplier as any;
        supplierForm.value.supplierNameInput = supplier.sysName;
        supplierSuccessMessage.value = "Leverandør funnet";
        showSupplierSuccess.value = true;
        showSupplierInputs.value = true;
      } else {
        selectedSupplier.value = null;
        supplierErrorMessage.value =
          "Fant ingen leverandør med dette organisasjonsnummeret. Vennligst fyll ut feltene for å opprette en ny leverandør.";
        showSupplierError.value = true;
        showSupplierInputs.value = true;
        supplierForm.value.supplierNameInput = "";
      }
    } else {
      selectedSupplier.value = null;
      supplierErrorMessage.value =
        "Vennligst velg et gyldig organisasjonsnummer";
      showSupplierError.value = true;
    }
  } catch (error) {
    console.error(error);
    supplierErrorMessage.value = "Feil under søk etter leverandør";
    showSupplierError.value = true;
  } finally {
    isSupplierSearching.value = false;
    setTimeout(() => {
      showSupplierSuccess.value = false;
      showSupplierError.value = false;
    }, 8000);
  }
};

const searchUserByEmail = async () => {
  isUserSearching.value = true;
  try {
    if (!userForm.value.emailInput) {
      throw new Error("Invalid email input");
    }
    const { emailInput } = userForm.value as UserFormValues;
    const userResponse = await fetchCustomerByEmail(emailInput);
    const user = userResponse.data;

    if (user) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      selectedUser.value = user as any;
      userForm.value.emailInput = user.email;
      userForm.value.fullNameInput = user.displayName;
      userForm.value.phoneNumberInput = user.mobilePhone;
      userSuccessMessage.value = "Bruker funnet";
      showUserSuccess.value = true;
      showUserInputs.value = true;
    } else {
      selectedUser.value = null;
      userErrorMessage.value = "Ingen bruker funnet med denne e-posten";
      showUserError.value = true;
      showUserInputs.value = true;
      userForm.value.fullNameInput = "";
      userForm.value.phoneNumberInput = "";
      userForm.value.passwordInput = "";
    }
  } catch (error) {
    console.error(error);
    userErrorMessage.value = "Feil ved søk etter bruker";
    showUserError.value = true;
  } finally {
    isUserSearching.value = false;
    setTimeout(() => {
      showUserSuccess.value = false;
      showUserError.value = false;
    }, 8000);
  }
};

const showMessage = (
  messageType: "success" | "error",
  messageText: string,
  formType: "supplier" | "user" | "connect",
) => {
  if (messageType === "success") {
    if (formType === "supplier") {
      supplierSuccessMessage.value = messageText;
      showSupplierSuccess.value = true;
    } else if (formType === "user") {
      userSuccessMessage.value = messageText;
      showUserSuccess.value = true;
    } else if (formType === "connect") {
      connectSuccessMessage.value = messageText;
      showConnectSuccess.value = true;
    }
  } else if (messageType === "error") {
    if (formType === "supplier") {
      supplierErrorMessage.value = messageText;
      showSupplierError.value = true;
    } else if (formType === "user") {
      userErrorMessage.value = messageText;
      showUserError.value = true;
    } else if (formType === "connect") {
      connectErrorMessage.value = messageText;
      showConnectError.value = true;
    }
  }

  setTimeout(() => {
    if (formType === "supplier") {
      showSupplierSuccess.value = false;
      showSupplierError.value = false;
    } else if (formType === "user") {
      showUserSuccess.value = false;
      showUserError.value = false;
    } else if (formType === "connect") {
      showConnectSuccess.value = false;
      showConnectError.value = false;
    }
  }, 4000);
};

const handleFormSubmit = async (
  formType: "supplier" | "user",
  submitAction: () => Promise<void>,
) => {
  isLoading.value = true;

  try {
    await submitAction();
    showMessage(
      "success",
      `${formType === "supplier" ? "Leverandør" : "Bruker"} opprettet vellykket`,
      formType,
    );
  } catch (error) {
    console.error(error);
    showMessage(
      "error",
      `Feil ved opprettelse av ${formType === "supplier" ? "leverandør" : "bruker"}`,
      formType,
    );
  } finally {
    isLoading.value = false;
  }
};

const onSupplierSubmit = async () => {
  supplierErrors.value = {};

  const errors: Record<string, string> = {};

  if (supplierForm.value.companyInput.selectedCompany === null) {
    errors.companyInput = "Vennligst velg et selskap";
  }
  if (supplierForm.value.supplierNameInput === "") {
    errors.supplierNameInput = "Leverandørnavn er påkrevd";
  }

  if (Object.keys(errors).length > 0) {
    supplierErrors.value = errors;
    return;
  }

  isSupplierSaving.value = true;
  const { companyInput, supplierNameInput } = supplierForm.value;

  const newSupplierDto: UpdateSupplierProfileDto = {
    contactPerson: "",
    description: "",
    email: "",
    numEmployees: 0,
    revenue: 0,
    logo: null,
    userId: null,
    sysName: supplierNameInput?.toString() || "",
    orgNumber: companyInput?.selectedCompany?.orgNumber || "",
    serviceCategories: [],
    certifications: [],
    offices: [],
    sustainabilityGoals: [],
    startBank: "",
    reservationLower: 0,
    reservationHigher: 0,
    reservationKilometers: 0,
    entityPath: "",
    margin: 0,
    commission: 0,
  };

  await handleFormSubmit("supplier", async () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const supplierResponse: any = await createSupplierProfile(newSupplierDto);
    selectedSupplier.value = supplierResponse.data as CreatedSupplierDTO;
  });
  isSupplierSaving.value = false;
};

const isValidEmail = (email: string): boolean => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
};

const isValidPhoneNumber = (phoneNumber: string): boolean => {
  const norwegianPhoneNumberPattern = /^((\+?)47|047)\d{8}$/;
  return norwegianPhoneNumberPattern.test(phoneNumber);
};

const onUserSubmit = async () => {
  userErrors.value = {};
  const errors: Record<string, string> = {};

  if (selectedSupplier.value === null) {
    errors.supplier = "Søk etter en leverandør før du fortsetter";
  }

  if (userForm.value.fullNameInput === "") {
    errors.fullNameInput = "Fullt navn er påkrevd";
  }
  if (userForm.value.emailInput === "") {
    errors.emailInput = "E-post er påkrevd";
  } else if (!isValidEmail(userForm.value.emailInput)) {
    errors.emailInput = "Ugyldig e-postformat";
  }
  if (userForm.value.phoneNumberInput === "") {
    errors.phoneNumberInput = "Telefonnummer er påkrevd";
  } else if (!isValidPhoneNumber(userForm.value.phoneNumberInput)) {
    errors.phoneNumberInput = "Ugyldig telefonnummerformat";
  }
  if (userForm.value.passwordInput === "") {
    errors.passwordInput = "Passord er påkrevd";
  } else if (
    !passwordValidationRegex.value.test(userForm.value.passwordInput)
  ) {
    errors.passwordInput =
      "Passordet må være minst 8 tegn langt og inneholde 3 av følgende: stor bokstav, liten bokstav, tall og symbol";
  }

  if (Object.keys(errors).length > 0) {
    userErrors.value = errors;
    return;
  }

  isUserSaving.value = true;

  try {
    const { phoneNumberInput, passwordInput, emailInput, fullNameInput } =
      userForm.value;

    const createUserDto: CreateUserDTO = {
      email: emailInput || "",
      password: passwordInput,
      name: selectedSupplier.value?.sysName || "",
      displayName: fullNameInput || "",
      phoneNumber: phoneNumberInput,
      orgNumber: selectedSupplier.value?.orgNumber || "",
      companyName: selectedSupplier.value?.sysName || "",
      supplierId: selectedSupplier.value?.id || null,
    };

    const userResponse = await createUser(createUserDto);
    if (userResponse.status === 200) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      selectedUser.value = userResponse.data as any;
      showMessage("success", "Bruker opprettet vellykket", "user");
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.error("Error creating user:", error);
    showMessage(
      "error",
      `Error creating user: ${error?.response.data}`,
      "user",
    );
  } finally {
    isUserSaving.value = false;
  }
};

const connectSupplierUser = async () => {
  isLoading.value = true;
  isConnecting.value = true;

  try {
    if (selectedSupplier.value && selectedUser.value) {
      await createSupplierUser({
        userId: selectedUser.value?.userId,
        supplierId: selectedSupplier.value?.id,
      });

      showMessage(
        "success",
        "Leverandør og bruker koblet vellykket",
        "connect",
      );
    } else {
      showMessage(
        "error",
        "Vennligst velg en leverandør og en bruker",
        "connect",
      );
    }
  } catch (error) {
    console.error(error);
    showMessage("error", "Feil ved kobling av leverandør og bruker", "connect");
  } finally {
    isLoading.value = false;
    isConnecting.value = false;
  }
};

const clearSupplierForm = () => {
  resetTheSupplierForm();
  selectedSupplier.value = null;
};

const clearUserForm = () => {
  resetTheUserForm();
  selectedUser.value = null;
};

const init = async () => {
  if (!userStore.userAccount || !userIsReallyAdmin(userStore.userAccount)) {
    await router.push({ name: RouteNames.SUPPLIER_ADMIN });
  }
};

init();
</script>

<style lang="scss" scoped>
.registration-content {
  padding: 2rem;
  background-color: inherit;
  padding-bottom: 12rem;

  &__title {
    display: flex;
    gap: 0.5rem;
    align-items: baseline;
    text-transform: uppercase;
    font-size: 2.5rem;
    font-weight: 500;
    color: $color-black;
  }

  &__subtitle {
    font-size: 1rem;
    font-weight: bold;
    color: $color-primary;
    margin-bottom: 0;
  }
}

.connect-supplier-user {
  p {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 700;
  }
}

.registration-form {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  max-width: 520px;
  margin-top: 1.6rem;

  &__spinner {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }

  &__inputs {
    display: block;
    margin-bottom: 1rem;
  }

  &__inputs--hide {
    display: none;
  }

  &__feedback {
    margin-top: 1rem;
  }

  &__buttons {
    margin-top: 1.5rem;
    display: flex;
    justify-content: space-between;

    button {
      padding: 1rem 2rem;
      font-size: $font-size-xs;
      font-weight: bold;
      color: white;
      background-color: $color-primary;
      border: none;
      border-radius: 4px;
      text-transform: capitalize;
      transition:
        background-color 0.3s,
        transform 0.2s;
      cursor: pointer;

      &:hover {
        background-color: $color-primary-darker;
        transform: scale(1.05);
      }

      &:active {
        transform: scale(1);
      }

      &:disabled {
        background-color: $color-primary-dark;
        color: white;
        cursor: no-drop;
        opacity: 0.5;
      }
    }

    .spinner {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.5rem;
    }

    .error-message {
      color: $color-error;
      margin-top: 1rem;
    }

    .success-message {
      color: $color-green;
      margin-top: 1rem;
    }
  }
}
</style>
