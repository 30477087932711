<template>
  <app-modal
    v-model="showModal"
    title="Add documents to offer"
    secondary-button-text="Cancel"
    primary-button-text="Upload"
    :is-submitting="state.isSubmitting"
    :submission-error="state.submissionError"
    @secondary-button-click="closeModal"
    @primary-button-click="uploadAttachments"
  >
    <div class="modal-content__attachments-list">
      <base-file-attachment
        v-for="attachment in state.localAttachments"
        :key="attachment.id"
        :file-name="attachment.file.name"
        :attachment="attachment.file"
        :removable="!state.isSubmitting"
        :hide-right-icon="true"
        @remove="removeAttachment(attachment)"
      />
    </div>
  </app-modal>
</template>

<script setup>
import { reactive, watch } from "vue";
import BaseFileAttachment from "@/components/Attachment/BaseFileAttachment.vue";
import { useTenderApi } from "@/services/api/useTenderApi";
import { convertFileToBase64 } from "@/utilities/fileUtilities.js";
import AppModal from "@/components/Base/BaseModal/AppModal.vue";

const emit = defineEmits(["new-attachments"]);
const showModal = defineModel({ required: true });
const props = defineProps({
  supplierId: {
    type: Number,
    required: true,
  },
  supplierRequestId: {
    type: Number,
    required: true,
  },
  offerId: {
    type: Number,
    required: true,
  },
  attachments: {
    type: Array,
    required: true,
  },
});

const tenderApis = useTenderApi();

const state = reactive({
  localAttachments: [],
  isSubmitting: false,
  submissionError: "",
});

watch(
  () => showModal.value,
  (newVal) => {
    if (newVal) {
      state.localAttachments = [...props.attachments];
      state.submissionError = "";
    }
  },
  { immediate: true },
);

const closeModal = () => {
  showModal.value = false;
};
const uploadAttachments = async () => {
  if (state.isSubmitting) {
    return;
  }
  state.isSubmitting = true;
  state.submissionError = "";
  const attachmentsDTO = await Promise.all(
    state.localAttachments.map(async (attachment) => {
      const fileContent = await convertFileToBase64(attachment.file);
      return {
        fileName: attachment.file.name,
        fileContent,
      };
    }),
  );
  console.log("DTO", attachmentsDTO);
  try {
    const response = await tenderApis.addServiceOfferAttachments(
      props.supplierId,
      props.supplierRequestId,
      props.offerId,
      attachmentsDTO,
    );
    emit("new-attachments", response.data);
    closeModal();
  } catch (error) {
    state.submissionError = error.toString();
    console.log("Error uploading attachments", error);
  } finally {
    state.isSubmitting = false;
  }
};
const removeAttachment = (attachment) => {
  for (let i = state.localAttachments.length - 1; i >= 0; i--) {
    if (state.localAttachments[i].id === attachment.id) {
      state.localAttachments.splice(i, 1);
    }
  }
  if (!state.localAttachments.length) {
    closeModal();
  }
};
</script>

<style lang="scss" scoped>
.modal-content {
  &__attachments-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
</style>
