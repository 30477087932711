<template>
  <div class="activities-costs-section">
    <div class="activities-costs-section__title">
      {{ activitiesCostsSectionTitle }}
    </div>
    <div class="activities-costs-section__description">
      <custom-block-content :blocks="activitiesCostsSectionDescription" />
    </div>
    <template v-if="state.systemPriceGroups">
      <fixed-cost-section
        :mandatory-supplier-costs="mandatorySupplierCosts"
        :service-offer="serviceOffer"
        :page-cms="pageCms"
        :frequency-options="frequencyOptions"
        :read-only="readOnly"
        :service-activities-cms="serviceActivitiesCms"
        :supplier-info="supplierInfo"
        :system-price-groups="state.systemPriceGroups"
        @remove-mandatory-cost="(data) => emit('remove-mandatory-cost', data)"
        @new-mandatory-cost="(data) => emit('new-mandatory-cost', data)"
      />
      <variable-cost-section
        :independent-supplier-costs="independentSupplierCosts"
        :service-offer="serviceOffer"
        :page-cms="pageCms"
        :frequency-options="frequencyOptions"
        :read-only="readOnly"
        :service-activities-cms="serviceActivitiesCms"
        :supplier-info="supplierInfo"
        :system-price-groups="state.systemPriceGroups"
        @remove-independent-cost="
          (data) => emit('remove-independent-cost', data)
        "
        @new-independent-cost="(data) => emit('new-independent-cost', data)"
      />
    </template>
    <div v-else class="activities-costs-section__loader">
      <b-spinner type="grow" />
    </div>
  </div>
</template>

<script setup>
import FixedCostSection from "./FixedCostSection.vue";
import VariableCostSection from "./VariableCostSection.vue";
import { useSystemApi } from "@/services/api/useSystemApi";
import { computed, reactive } from "vue";
import CustomBlockContent from "@/components/CustomBlockContent/CustomBlockContent.vue";

const emit = defineEmits([
  "remove-mandatory-cost",
  "new-mandatory-cost",
  "remove-independent-cost",
  "new-independent-cost",
]);
const props = defineProps({
  serviceOffer: {
    type: Object,
    required: true,
  },
  mandatorySupplierCosts: {
    type: Array,
    required: true,
  },
  independentSupplierCosts: {
    type: Array,
    required: true,
  },
  pageCms: {
    type: Object,
    required: true,
  },
  frequencyOptions: {
    type: Object,
    required: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  serviceActivitiesCms: {
    type: Array,
    required: true,
  },
  supplierInfo: {
    type: Object,
    required: true,
  },
});

const systemApis = useSystemApi();

const state = reactive({
  systemPriceGroups: undefined,
});

const activitiesCostsSectionCms = computed(() => {
  return props.pageCms.pageSections.find(
    (section) => section.label === "activitiesAndCost",
  );
});
const activitiesCostsSectionTitle = computed(() => {
  return activitiesCostsSectionCms.value.title;
});
const activitiesCostsSectionDescription = computed(() => {
  return activitiesCostsSectionCms.value.content[0];
});

const initialize = async () => {
  const response = await systemApis.getSystemPriceGroups(props.supplierInfo.id);
  state.systemPriceGroups = response.data;
};
initialize();
</script>

<style lang="scss" scoped>
.activities-costs-section {
  margin-top: 40px;

  &__title {
    font-weight: 600;
    font-size: $font-size-2xl;
    line-height: 29px;
    color: #1d1d1d;
    margin-bottom: 8px;
  }

  &__description {
    font-size: $font-size-sm;
    font-style: italic;
    font-weight: 400;
  }

  &__loader {
    margin-top: 1.5rem;
  }
}
</style>
