import { ApiConfig } from "@/config/api/types";
import { authHeader } from "@/config/api/headers";

export enum TenderApiEndpoints {
  FETCH_SUPPLIER_OFFERS = "fetchSupplierOffers",
  FETCH_TENDER_OFFERS_BY_SUPPLIER = "fetchTenderOffersBySupplier",
  COMPLETE_TENDER_OFFER_BY_SUPPLIER = "completeTenderOfferBySupplier",
  SEND_INFORMATION_REQUEST_MESSAGE = "sendInformationRequestMessage",
  GET_TENDER_CONFIRMATION_URI = "getTenderConfirmationUri",
  SEND_REJECT_PRICE_REQUEST = "sendRejectPriceRequest",
  SET_INFORMATION_REQUESTED = "setInformationRequested",
  GET_TENDER_OFFER = "getTenderOffer",
  GET_TENDER = "getTender",
  SEND_OFFER = "sendOffer",
  DELETE_SUPPLIER_OFFER = "deleteSupplierOffer",
  WITHDRAW_SUPPLIER_OFFER = "withdrawSupplierOffer",
  SET_AGREEMENT_RESPONSIBLE = "setAgreementResponsible",
  GET_INVOICE_RECIPIENT = "getInvoiceRecipient",
  SUBMIT_TENDER = "submitTender",
  GET_PUBLIC_NOTES = "GetPublicNotes",
  UPDATE_TENDER_ARCHIVE = "updateTenderArchive",
  GET_CLONEABLE_OFFERS = "getCloneableOffers",
  GET_ACTIVE_REQUESTS = "getActiveRequests",
  GET_LOST_REQUESTS = "getLostRequests",
  GET_ARCHIVED_REQUESTS = "getArchivedRequests",
  GET_AGREEMENTS = "getAgreements",
  GET_PRICE_REQUEST_DETAILS = "getPriceRequestDetails",
  GET_SERVICE_OFFERS = "getServiceOffers",
  GET_SERVICE_OFFER_DESCRIPTIONS = "getServiceOfferDescriptions",
  CREATE_SERVICE_OFFER_DESCRIPTION = "createServiceOfferDescription",
  UPDATE_SERVICE_OFFER_DESCRIPTION = "updateServiceOfferDescription",
  DELETE_SERVICE_OFFER_DESCRIPTION = "deleteServiceOfferDescription",
  CREATE_MANDATORY_SUPPLIER_COST = "createMandatorySupplierCost",
  UPDATE_MANDATORY_SUPPLIER_COST = "updateMandatorySupplierCost",
  CREATE_INDEPENDENT_SUPPLIER_COST = "createIndependentSupplierCost",
  DELETE_SUPPLIER_COST_ITEM = "deleteSupplierCostItem",
  GET_SERVICE_OFFER = "getServiceOffer",
  CREATE_SERVICE_OFFER = "createServiceOffer",
  CLONE_SERVICE_OFFER = "cloneServiceOffer",
  ADD_SERVICE_OFFER_ATTACHMENTS = "addServiceOfferAttachments",
  REMOVE_SERVICE_OFFER_ATTACHMENTS = "removeServiceOfferAttachments",
  GET_REQUEST_ACTIVITY_STATS = "getRequestActivityStats",
  UPDATE_SERVICE_OFFER_NICKNAME = "updateServiceOfferNickname",
  UPDATE_SERVICE_OFFER_START_DATE = "updateServiceOfferStartDate",
  UPDATE_SERVICE_OFFER_TERMINATION_PERIOD = "updateServiceOfferTerminationPeriod",
  CHANGE_SERVICE_OFFER_STATE = "changeServiceOfferState",
  GET_AGREEMENT_ACCEPTANCE = "getAgreementAcceptance",
}

export enum SystemApiEndpoints {
  CREATE_SUPPLIER_TENDER_OFFER = "createSupplierTenderOffer",
  UPDATE_SUPPLIER_TENDER_OFFER = "updateSupplierTenderOffer",
  GET_ACTIVITY_FORM_FREQUENCY_OPTIONS = "getActivityFormFrequencyOptions",
}

export enum CustomerServiceEndpoints {
  GET_FRAME_AGREEMENT = "GetFrameAgreement",
}

export const TenderApi: ApiConfig = {
  baseUri: import.meta.env.VITE_APP_BASE_URI || "",
  contextPath: "/Tender",
  headers: [authHeader],
  endpoints: [
    {
      key: Symbol.for(TenderApiEndpoints.FETCH_SUPPLIER_OFFERS),
      method: "GET",
      url: "",
      description: "Fetch supplier offers",
    },
    {
      key: Symbol.for(TenderApiEndpoints.FETCH_TENDER_OFFERS_BY_SUPPLIER),
      method: "GET",
      url: "/Supplier",
      description: "Fetch tender offers by supplier",
    },
    {
      key: Symbol.for(TenderApiEndpoints.COMPLETE_TENDER_OFFER_BY_SUPPLIER),
      method: "PATCH",
      url: "/Supplier",
      description: "Complete tender offer by supplier",
    },
    {
      key: Symbol.for(TenderApiEndpoints.SET_INFORMATION_REQUESTED),
      method: "PATCH",
      url: "",
      description:
        "Set the state of a supplier request to InformationRequested",
    },
    {
      key: Symbol.for(TenderApiEndpoints.GET_TENDER_CONFIRMATION_URI),
      method: "GET",
      url: "",
      description: "Get tender confirmation URI",
    },
    {
      key: Symbol.for(TenderApiEndpoints.SEND_REJECT_PRICE_REQUEST),
      method: "PATCH",
      url: "",
      description: "Send reject price request",
    },
    {
      key: Symbol.for(TenderApiEndpoints.GET_TENDER_OFFER),
      method: "GET",
      url: "/offer/:offerId",
      description: "Get tender offer",
    },
    {
      key: Symbol.for(TenderApiEndpoints.GET_TENDER),
      method: "GET",
      url: "",
      description: "Get tender",
    },
    {
      key: Symbol.for(TenderApiEndpoints.SEND_OFFER),
      method: "PATCH",
      url: "/offer/:offerId/change",
      description: "Send offer",
    },
    {
      key: Symbol.for(TenderApiEndpoints.DELETE_SUPPLIER_OFFER),
      method: "DELETE",
      url: "/Supplier",
      description: "Delete supplier offer",
    },
    {
      key: Symbol.for(TenderApiEndpoints.WITHDRAW_SUPPLIER_OFFER),
      method: "PATCH",
      url: "/offer/:offerId/change",
      description: "Withdraw supplier offer",
    },
    {
      key: Symbol.for(TenderApiEndpoints.SET_AGREEMENT_RESPONSIBLE),
      method: "PATCH",
      url: "",
      description: "Set agreement responsible",
    },
    {
      key: Symbol.for(TenderApiEndpoints.GET_INVOICE_RECIPIENT),
      method: "GET",
      url: "",
      description: "Get invoice recipient",
    },
    {
      key: Symbol.for(TenderApiEndpoints.SUBMIT_TENDER),
      method: "POST",
      url: "/Create",
      description: "Submit tender",
    },
    {
      key: Symbol.for(TenderApiEndpoints.GET_PUBLIC_NOTES),
      method: "GET",
      url: "",
      description: "Get public notes",
    },
    {
      key: Symbol.for(TenderApiEndpoints.UPDATE_TENDER_ARCHIVE),
      method: "PATCH",
      url: "",
      description: "Update tender archive",
    },
    {
      key: Symbol.for(TenderApiEndpoints.GET_CLONEABLE_OFFERS),
      method: "GET",
      url: "",
      description: "Get cloneable offers",
    },
    {
      key: Symbol.for(TenderApiEndpoints.GET_ACTIVE_REQUESTS),
      method: "GET",
      url: "/Supplier",
      description: "Get active requests for supplier",
    },
    {
      key: Symbol.for(TenderApiEndpoints.GET_LOST_REQUESTS),
      method: "GET",
      url: "/Supplier",
      description: "Get lost requests for supplier",
    },
    {
      key: Symbol.for(TenderApiEndpoints.GET_ARCHIVED_REQUESTS),
      method: "GET",
      url: "/Supplier",
      description: "Get archived requests for supplier",
    },
    {
      key: Symbol.for(TenderApiEndpoints.GET_AGREEMENTS),
      method: "GET",
      url: "/Supplier",
      description: "Get agreements for supplier",
    },
    {
      key: Symbol.for(TenderApiEndpoints.GET_PRICE_REQUEST_DETAILS),
      method: "GET",
      url: "",
      description: "Get price request details",
    },
    {
      key: Symbol.for(TenderApiEndpoints.GET_SERVICE_OFFERS),
      method: "GET",
      url: "/Supplier",
      description: "Get service offers",
    },
    {
      key: Symbol.for(TenderApiEndpoints.GET_SERVICE_OFFER_DESCRIPTIONS),
      method: "GET",
      url: "/Supplier",
      description: "Get service offer descriptions",
    },
    {
      key: Symbol.for(TenderApiEndpoints.CREATE_SERVICE_OFFER_DESCRIPTION),
      method: "POST",
      url: "/Supplier",
      description: "Create service offer description",
    },
    {
      key: Symbol.for(TenderApiEndpoints.UPDATE_SERVICE_OFFER_DESCRIPTION),
      method: "PUT",
      url: "/Supplier",
      description: "Update service offer description",
    },
    {
      key: Symbol.for(TenderApiEndpoints.DELETE_SERVICE_OFFER_DESCRIPTION),
      method: "DELETE",
      url: "/Supplier",
      description: "Delete service offer description",
    },
    {
      key: Symbol.for(TenderApiEndpoints.CREATE_MANDATORY_SUPPLIER_COST),
      method: "POST",
      url: "/Supplier",
      description: "Create mandatory supplier cost",
    },
    {
      key: Symbol.for(TenderApiEndpoints.UPDATE_MANDATORY_SUPPLIER_COST),
      method: "PUT",
      url: "/Supplier",
      description: "Update mandatory supplier cost",
    },
    {
      key: Symbol.for(TenderApiEndpoints.CREATE_INDEPENDENT_SUPPLIER_COST),
      method: "POST",
      url: "/Supplier",
      description: "Create independent supplier cost",
    },
    {
      key: Symbol.for(TenderApiEndpoints.DELETE_SUPPLIER_COST_ITEM),
      method: "DELETE",
      url: "/Supplier",
      description: "Delete supplier cost item",
    },
    {
      key: Symbol.for(TenderApiEndpoints.GET_SERVICE_OFFER),
      method: "GET",
      url: "/Supplier",
      description: "Get service offer",
    },
    {
      key: Symbol.for(TenderApiEndpoints.CREATE_SERVICE_OFFER),
      method: "POST",
      url: "/Supplier",
      description: "Create service offer",
    },
    {
      key: Symbol.for(TenderApiEndpoints.CLONE_SERVICE_OFFER),
      method: "POST",
      url: "/Supplier",
      description: "Clone service offer",
    },
    {
      key: Symbol.for(TenderApiEndpoints.ADD_SERVICE_OFFER_ATTACHMENTS),
      method: "POST",
      url: "/Supplier",
      description: "Add service offer attachments",
    },
    {
      key: Symbol.for(TenderApiEndpoints.REMOVE_SERVICE_OFFER_ATTACHMENTS),
      method: "DELETE",
      url: "/Supplier",
      description: "Remove service offer attachments",
    },
    {
      key: Symbol.for(TenderApiEndpoints.GET_REQUEST_ACTIVITY_STATS),
      method: "GET",
      url: "/Supplier",
      description: "Get request activity stats",
    },
    {
      key: Symbol.for(TenderApiEndpoints.UPDATE_SERVICE_OFFER_NICKNAME),
      method: "PATCH",
      url: "/Supplier",
      description: "Update service offer nickname",
    },
    {
      key: Symbol.for(TenderApiEndpoints.UPDATE_SERVICE_OFFER_START_DATE),
      method: "PATCH",
      url: "/Supplier",
      description: "Update service offer start date",
    },
    {
      key: Symbol.for(
        TenderApiEndpoints.UPDATE_SERVICE_OFFER_TERMINATION_PERIOD,
      ),
      method: "PATCH",
      url: "/Supplier",
      description: "Update service offer termination period",
    },
    {
      key: Symbol.for(TenderApiEndpoints.CHANGE_SERVICE_OFFER_STATE),
      method: "PATCH",
      url: "/Supplier",
      description: "Change service offer state",
    },
    {
      key: Symbol.for(TenderApiEndpoints.GET_AGREEMENT_ACCEPTANCE),
      method: "GET",
      url: "/Supplier",
      description: "Get agreement acceptance",
    },
  ],
};

export const SystemApi: ApiConfig = {
  baseUri: import.meta.env.VITE_APP_BASE_URI || "",
  contextPath: "/system",
  headers: [authHeader],
  endpoints: [
    {
      key: Symbol.for(SystemApiEndpoints.CREATE_SUPPLIER_TENDER_OFFER),
      method: "POST",
      url: "/offer",
      description: "Create supplier tender offer",
    },
    {
      key: Symbol.for(SystemApiEndpoints.UPDATE_SUPPLIER_TENDER_OFFER),
      method: "PUT",
      url: "/offer",
      description: "Update supplier tender offer",
    },
    {
      key: Symbol.for(SystemApiEndpoints.GET_ACTIVITY_FORM_FREQUENCY_OPTIONS),
      method: "GET",
      url: "/segmentOptions",
      description: "Get activity form frequency options",
    },
  ],
};

export const CustomerServiceApi: ApiConfig = {
  baseUri: import.meta.env.VITE_APP_BASE_URI || "",
  contextPath: "/Organization",
  headers: [authHeader],
  endpoints: [
    {
      key: Symbol.for(CustomerServiceEndpoints.GET_FRAME_AGREEMENT),
      method: "GET",
      url: "",
      description: "Get frame agreement",
    },
  ],
};
