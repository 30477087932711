<template>
  <div class="service-report-form">
    <b-form :disabled="formDisabled" @submit.prevent="submitForm">
      <b-row>
        <b-col md="6">
          <b-form-group label="Velg dato for uført service">
            <v-field
              v-slot="{ componentField, errors }"
              name="serviceDate"
              :rules="string().required()"
            >
              <vue-date-picker
                :model-value="componentField.modelValue"
                value-type="date"
                format="dd.MM.yyyy"
                locale="no"
                placeholder="Ingen dato valgt"
                :disabled-date="isServiceDateDisabled"
                :disabled="formDisabled"
                :enable-time-picker="false"
                :max-date="new Date()"
                :clearable="false"
                @update:model-value="componentField['onUpdate:modelValue']"
              >
                <template #clear-icon>
                  <img
                    src="/images/service-report/calendar.svg"
                    class="input-slot-image"
                    alt="Calendar"
                  />
                </template>
              </vue-date-picker>
              <b-form-invalid-feedback :state="!errors.length">
                *Fyll ut dato
              </b-form-invalid-feedback>
            </v-field>
          </b-form-group>
        </b-col>
        <b-col v-if="showNextServiceDate" md="6">
          <b-form-group label="Velg måned for neste service">
            <v-field
              v-slot="{ componentField, errors }"
              name="nextServiceDate"
              :rules="
                object().shape({
                  year: string().required(),
                  month: string().required(),
                })
              "
            >
              <vue-date-picker
                :model-value="componentField.modelValue"
                type="month"
                value-type="YYYY-MM"
                format="MM.yyyy"
                locale="no"
                placeholder="Ingen dato valgt"
                :disabled-date="isNextServiceMonthDisabled"
                :disabled="formDisabled"
                :enable-time-picker="false"
                :min-date="calculateMinDate()"
                :clearable="false"
                month-picker
                no-today
                @update:model-value="componentField['onUpdate:modelValue']"
              >
                <template #clear-icon>
                  <div class="date-picker-icon">
                    <img
                      src="/images/service-report/calendar.svg"
                      class="input-slot-image"
                      alt="Calendar"
                    />
                  </div>
                </template>
              </vue-date-picker>
              <b-form-invalid-feedback :state="!errors.length">
                *Fyll ut dato
              </b-form-invalid-feedback>
            </v-field>
          </b-form-group>
        </b-col>
      </b-row>

      <b-form-group label="Last opp filer">
        <v-field v-slot="{ errors }" name="attachments" :rules="array().min(1)">
          <file-input
            v-slot="{ openFileInput }"
            :valid-file-extensions="state.validFileExtensions"
            @selected-files="handleSelectedFiles"
          >
            <b-button
              id="file-button"
              class="form-btn file-button"
              type="button"
              variant="outline-light"
              size="sm"
              :disabled="formDisabled"
              @click.prevent="openFileInput"
              >Velg fil!
            </b-button>
            <span class="ms-3 accepted-files"
              >(Godtatte filtyper: .pdf, .jpg, .jpeg, .png)</span
            >
          </file-input>
          <b-form-invalid-feedback :state="!errors.length">
            *Vennligst last opp filer
          </b-form-invalid-feedback>
        </v-field>
      </b-form-group>
      <attachments-wrapper
        v-if="values.attachments.length"
        :attachments="values.attachments"
        @remove-attachment="removeAttachment"
      />

      <b-form-group label="Valgfri kommentar:">
        <v-field v-slot="{ componentField }" name="comment">
          <b-form-textarea
            v-bind="componentField"
            :disabled="formDisabled"
            placeholder="Skriv inn kommentar..."
            rows="4"
            max-rows="6"
          />
        </v-field>
      </b-form-group>

      <div class="service-report-form__buttons">
        <tertiary-button :disabled="formDisabled" @click="closeForm">
          Avbryt
        </tertiary-button>
        <primary-button
          type="submit"
          :disabled="formDisabled"
          :is-submitting="state.isLoading"
        >
          Lagre
        </primary-button>
      </div>
      <error-message
        v-if="state.showSubmitError"
        :error-message="state.errorText"
      />
    </b-form>
    <service-report-modal
      :show-modal-prop="state.showModal"
      @on-change="onModalChange"
    />
  </div>
</template>

<script setup>
import ErrorMessage from "@/components/ErrorHandling/ErrorMessage";
import AttachmentsWrapper from "./AttachmentsWrapper";
import ServiceReportModal from "./ServiceReportModal";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { computed, reactive } from "vue";
import { Field as VField, useForm } from "vee-validate";
import { array, object, string } from "yup";
import dayjs from "dayjs";
import FileInput from "@/components/FileInput/FileInput.vue";
import {
  convertFileToBase64,
  getFilesWithValidExtension,
  getFilesWithValidSize,
} from "@/utilities/fileUtils";
import { uuidv4 } from "@/utilities/uuid";
import { cloneDeep } from "lodash";
import PrimaryButton from "@/components/Buttons/PrimaryButton.vue";
import TertiaryButton from "@/components/Buttons/TertiaryButton.vue";
import { useSupplierStore } from "@/stores/supplier";
import { useServiceReportApi } from "@/services/api/useServiceReportApi";

const { createServiceReport } = useServiceReportApi();

const emit = defineEmits(["hide", "new-service-report"]);
const props = defineProps({
  tenderId: {
    type: Number,
    required: true,
  },
  customerOrgNumber: {
    type: String,
    required: true,
  },
  serviceOfferId: {
    type: Number,
    required: true,
  },
  isSingleJob: {
    type: Boolean,
    required: true,
  },
});

const supplierStore = useSupplierStore();
const state = reactive({
  validFileExtensions: [".pdf", ".jpg", ".jpeg", ".png"],
  maxFileSizeInMB: 20,
  isLoading: false,
  showSubmitError: false,
  errorText: "Kunne ikke utføre forespørselen. Prøv igjen senere.",
  attachmentErrorText: "*Vennligst last opp filer",
  dateErrorText: "*Fyll ut dato",
  showModal: false,
});

const { handleSubmit, setValues, values, validateField } = useForm({
  initialValues: {
    serviceDate: "",
    nextServiceDate: null,
    comment: "",
    attachments: [],
  },
});

const showNextServiceDate = computed(() => !props.isSingleJob);

const formDisabled = computed(() => {
  return state.isLoading;
});
const supplier = computed(() => {
  return supplierStore.supplier;
});

const formatNextServiceDate = (date) => {
  const formattedMonth = (date.month + 1).toString().padStart(2, "0");
  const dateString = dayjs(`${date.year}-${formattedMonth}-01`).format(
    "YYYY-MM-DDTHH:mm:ss.SSS[Z]",
  );
  return dateString;
};
const calculateMinDate = () => {
  return dayjs().add(1, "month").format("YYYY-MM");
};
const isServiceDateDisabled = (date) => {
  return dayjs(date).isAfter(dayjs(), "day");
};
const isNextServiceMonthDisabled = (date) => {
  return !dayjs(date).isAfter(dayjs(), "month");
};
const submitForm = handleSubmit(async (values) => {
  const attachmentsDto = await Promise.all(
    values.attachments.map(async (attachment) => {
      try {
        const result = await convertFileToBase64(attachment.file);
        return {
          fileName: attachment.file.name,
          fileContent: result,
        };
      } catch (error) {
        console.error("Error converting file to Base64:", error);
        throw error;
      }
    }),
  );

  const submitDto = {
    ...values,
    nextServiceDate: values.nextServiceDate
      ? formatNextServiceDate(values.nextServiceDate)
      : null,
    attachments: attachmentsDto,
    tenderId: props.tenderId,
    supplierId: supplier.value.id,
    customerOrgNumber: props.customerOrgNumber,
    serviceOfferId: props.serviceOfferId,
  };

  try {
    state.isLoading = true;
    const response = await createServiceReport(submitDto);
    emit("new-service-report", response.data);
    state.showModal = !state.showModal;
    closeForm();
  } catch (e) {
    state.showSubmitError = true;
    setTimeout(() => {
      state.showSubmitError = false;
    }, 5000);
  } finally {
    state.isLoading = false;
  }
});
const handleSelectedFiles = (files) => {
  let validFiles = getFilesWithValidExtension(files, state.validFileExtensions);
  validFiles = getFilesWithValidSize(validFiles, state.maxFileSizeInMB);
  const newAttachments = validFiles.map((file) => {
    return {
      id: uuidv4(),
      file: file,
      fileName: file.name,
      attachmentUri: null,
    };
  });
  setValues(
    {
      attachments: [...newAttachments, ...values.attachments],
    },
    false,
  );
  validateField("attachments");
};
const removeAttachment = (attachment) => {
  const attachments = cloneDeep(values.attachments);
  for (let i = attachments.length - 1; i >= 0; i--) {
    if (attachments[i].id === attachment.id) {
      attachments.splice(i, 1);
    }
  }
  setValues(
    {
      attachments: attachments,
    },
    false,
  );
  validateField("attachments");
};
const onModalChange = (showModal) => {
  state.showModal = showModal;
};
const closeForm = () => {
  emit("hide");
};
</script>

<style lang="scss" scoped>
.service-report-form {
  &__buttons {
    display: flex;
    align-items: center;
    gap: 3rem;
    margin-bottom: 3rem;
  }

  .form-buttons {
    margin-top: 7rem;
  }

  .accepted-files {
    font-weight: 400;
    font-size: $font-size-sm;
    color: #5b0050;
  }

  .form-btn {
    font-size: $font-size-base;
    font-weight: 600;
    padding: 1.125rem 2.5rem;
    text-transform: uppercase;
  }

  .file-button {
    color: #611f69;
    border-color: #611f69;
    background-color: white;
    text-transform: none;
    padding: 0.2rem 0.5rem;

    &:hover {
      background-color: $color-primary-darker;
      border-color: $color-primary-darker;
      color: #fff;
    }
  }

  .cancel-button {
    color: #611f69;
    border-color: #611f69;

    &:hover {
      background-color: $color-primary-darker;
      border-color: $color-primary-darker;
      color: #fff;
    }
  }

  .submit-button {
    color: #fff;
    background-color: #611f69;
    border-color: #611f69;

    &:hover {
      background-color: $color-primary-darker;
      border-color: $color-primary-darker;
      color: #fff;
    }
  }

  :deep(fieldset) {
    margin-bottom: 3rem;
  }

  :deep(.form-control) {
    padding: 0.5rem;
    border-radius: 0.25rem;
    border: 0.063rem solid #1d1d1d;
  }

  :deep(.form-label) {
    font-size: $font-size-base;
    font-weight: 700;
    color: $color-primary-darker;
  }

  :deep(.invalid-feedback) {
    font-size: $font-size-sm;
    font-weight: bold;
  }
}

.input-slot-image {
  height: 20px;
  width: auto;
  margin-right: 16px;
}

:deep(.dp__input_icon) {
  width: 180%;
  height: 22px;
  color: #000;
}

:deep(.dp__input) {
  padding: 1.6rem;
  border: 1px solid $color-black;
  border-radius: 4px;
  height: 2.75rem;
  font-weight: bold;
  font-size: $font-size-base;
  color: #555;
  font-family: "Montserrat", sans-serif;
}
</style>
