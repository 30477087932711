<template>
  <div class="conversation" @click="handleClick">
    <div class="conversation__header">
      <div class="conversation__recipient-name">
        {{ thread.recipientName }}
      </div>
      <div class="conversation__header-right-content">
        <div class="conversation__latest-date">
          {{ thread.latestDate }}
        </div>
        <img :src="ArrowRightIcon" alt="ArrowRight" />
      </div>
    </div>
    <div class="conversation__latest-message">
      {{ thread.latestMessage }}
    </div>
    <div class="conversation__footer">
      <div class="conversation__message-type">
        {{ thread.messageType }}
      </div>
      <img
        v-if="thread.latestMessageAttachments.length"
        :src="AttachmentIcon"
        alt="Attachment"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { FormattedThread } from "@/stores/communication/types";
import ArrowRightIcon from "@/assets/img/arrow-right.svg";
import AttachmentIcon from "@/assets/img/attachment.svg";

interface Props {
  thread: FormattedThread;
}

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: "click", conversationId: string): void;
}>();

const handleClick = () => {
  emit("click", props.thread.conversationId);
};
</script>

<style lang="scss" scoped>
.conversation {
  padding: 1rem 2rem;
  background: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  &:not(:first-child) {
    margin-top: 0.25rem;
  }

  &:hover {
    background: #f6f6f6;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__header-right-content {
    display: flex;
    align-items: center;
  }

  &__latest-date {
    font-weight: 500;
    font-size: $font-size-base;
  }

  &__recipient-name {
    font-weight: 700;
    font-size: $font-size-base;
  }

  &__latest-message {
    max-width: 20rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
    font-size: $font-size-sm;
  }

  &__footer {
    display: flex;
    align-items: center;
  }

  &__message-type {
    padding: 0.5rem 1rem;
    font-weight: 600;
    font-size: $font-size-sm;
  }
}
</style>
