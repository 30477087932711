<template>
  <div class="supplier-logo-container">
    <div v-if="loading" class="supplier-logo-skeleton">
      <Skeleton width="100%" height="100%" />
    </div>
    <img
      v-else-if="logoUrl"
      :src="logoUrl"
      alt="Supplier Logo"
      class="supplier-logo"
    />
    <div v-else class="supplier-logo-placeholder">
      <span>{{ supplierInitials }}</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted, watch } from "vue";
import Skeleton from "primevue/skeleton";
import { useSupplierStore } from "@/stores/supplier";

const supplierStore = useSupplierStore();

const loading = ref(true);
const logoUrl = ref<string | null>(null);

const supplierInitials = computed(() => {
  if (!supplierStore.supplier?.sysName) return "";

  return supplierStore.supplier.sysName
    .split(" ")
    .map((word) => word.charAt(0))
    .join("")
    .substring(0, 2)
    .toUpperCase();
});

const loadLogo = () => {
  if (!supplierStore.supplier) {
    loading.value = false;
    return;
  }

  // Check if the logo is already available in the supplier store
  if (supplierStore.supplier.logo) {
    logoUrl.value = `data:image/png;base64,${supplierStore.supplier.logo}`;
    loading.value = false;
    return;
  }

  // If supplier exists but no logo, try to fetch it
  if (supplierStore.supplier.id) {
    loading.value = true;
    supplierStore
      .fetchSupplierLogo(supplierStore.supplier.id)
      .then(() => {
        // Check if logo is now available
        if (supplierStore.supplier?.logo) {
          logoUrl.value = `data:image/png;base64,${supplierStore.supplier.logo}`;
        } else {
          logoUrl.value = null;
        }
      })
      .catch((error) => {
        console.error("Error loading supplier logo:", error);
        logoUrl.value = null;
      })
      .finally(() => {
        loading.value = false;
      });
  } else {
    loading.value = false;
  }
};

// Watch for changes to the supplier or supplier.logo
watch(
  () => supplierStore.supplier?.logo,
  (newLogo) => {
    if (newLogo) {
      logoUrl.value = `data:image/png;base64,${newLogo}`;
      loading.value = false;
    } else {
      loadLogo();
    }
  },
);

// Also watch for changes to supplier itself (different supplier)
watch(() => supplierStore.supplier?.id, loadLogo);

onMounted(loadLogo);
</script>

<style lang="scss" scoped>
.supplier-logo-container {
  width: 100%;
  height: 139px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  overflow: hidden;
  position: relative;
}

.supplier-logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.supplier-logo-skeleton {
  width: 100%;
  height: 100%;
  border-radius: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  &__text {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 0.75rem;
    color: #441b49;
    background-color: rgba(255, 255, 255, 0.7);
    padding: 4px;
  }
}

.supplier-logo-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: bold;
  color: #441b49;
  background-color: #e0d0e4;
}
</style>
