<template>
  <div
    class="attachment"
    :class="attachmentClasses"
    @click="handleAttachmentClick"
  >
    <img :src="DocumentIcon" alt="left icon" class="attachment__left-icon" />
    <div class="attachment__title">{{ fileName }}</div>
    <button
      v-if="removeAttachmentIconUrl"
      type="button"
      class="attachment__remove-button"
      @click.stop="handleRemoveButtonClick"
    >
      <img :src="removeAttachmentIconUrl" alt="" />
    </button>
    <div v-else-if="!hideRightIcon" class="attachment__right-icon">
      <img v-if="!isLoading" :src="DownloadIcon" alt="right icon" />
      <div v-else class="attachment__spinner">
        <b-spinner type="grow" small />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import DocumentIcon from "@/assets/img/document.svg";
import DownloadIcon from "@/assets/img/download.svg";

const emit = defineEmits(["attachment-clicked", "remove"]);

const props = withDefaults(
  defineProps<{
    fileName: string;
    clickable?: boolean;
    attachmentUri?: string | null;
    downloadHandler?: (() => void) | null;
    removeAttachmentIconUrl?: string;
    hideRightIcon?: boolean;
  }>(),
  {
    clickable: true,
    attachmentUri: null,
    downloadHandler: null,
    removeAttachmentIconUrl: "",
    hideRightIcon: false,
  },
);

const isLoading = ref(false);

const attachmentClasses = computed(() => {
  return props.clickable ? ["attachment--clickable"] : [];
});

const handleAttachmentClick = async () => {
  emit("attachment-clicked");
  if (props.attachmentUri) {
    window.open(props.attachmentUri, "_blank");
  } else if (props.downloadHandler) {
    isLoading.value = true;
    try {
      await props.downloadHandler();
    } catch (error) {
      console.error("Error downloading attachment:", error);
    } finally {
      isLoading.value = false;
    }
  }
};

const handleRemoveButtonClick = () => {
  emit("remove");
};
</script>

<style lang="scss" scoped>
.attachment {
  max-width: 22rem;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border-radius: 8px;
  gap: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(90, 90, 90);
  border-image: initial;
  transition: all ease-out 200ms;

  &--clickable {
    cursor: pointer;

    &:hover {
      background: #f6f6f6;
    }
  }

  &__left-icon {
    flex-shrink: 0;
  }

  &__right-icon {
    flex-shrink: 0;
    margin-left: 0.5rem;
  }

  &__title {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 15rem;
    font-size: 14px;
    font-weight: 600;
  }

  &__remove-button {
    border: none;
    background: transparent;
    border-radius: 4px;

    &:hover {
      background: #f6f6f6;
    }
  }

  &__spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
  }
}
</style>
