import { ApiConfig } from "@/config/api/types";
import { authHeader } from "@/config/api/headers";

export enum CustomerApiEndpoints {
  GET_CUSTOMER_DETAILS_FOR_GIVEN_PRICE_REQUEST = "getCustomerDetailsForGivenPriceRequest",
}

export const CustomerApi: ApiConfig = {
  baseUri: import.meta.env.VITE_APP_BASE_URI || "",
  contextPath: "/organization",
  headers: [authHeader],
  endpoints: [
    {
      key: Symbol.for(
        CustomerApiEndpoints.GET_CUSTOMER_DETAILS_FOR_GIVEN_PRICE_REQUEST,
      ),
      method: "GET",
      url: "/supplierId",
      description: "Get customer details for given price request",
    },
  ],
};
