import { ApiConfig } from "@/config/api/types";
import { authHeader } from "@/config/api/headers";

export enum ServiceReportApiEndpoints {
  FETCH_SERVICE_REPORTS = "fetchServiceReports",
  CREATE_SERVICE_REPORT = "createServiceReport",
  DELETE_SERVICE_REPORT = "deleteServiceReport",
}

export const ServiceReportApi: ApiConfig = {
  baseUri: import.meta.env.VITE_APP_BASE_URI || "",
  contextPath: "/ServiceReport",
  headers: [authHeader],
  endpoints: [
    {
      key: Symbol.for(ServiceReportApiEndpoints.FETCH_SERVICE_REPORTS),
      method: "GET",
      url: "/Report/CustomerOrganization",
      description: "Fetch service reports",
    },
    {
      key: Symbol.for(ServiceReportApiEndpoints.CREATE_SERVICE_REPORT),
      method: "POST",
      url: "/Report",
      description: "Create a service report",
    },
    {
      key: Symbol.for(ServiceReportApiEndpoints.DELETE_SERVICE_REPORT),
      method: "DELETE",
      url: "/Report",
      description: "Delete a service report",
    },
  ],
};
