const capitalizeFirstLetter = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const firstLetterUppercase = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

const parseCssStringToPx = (cssString: string) => {
  const value = parseInt(cssString);
  if (cssString.includes("rem")) {
    return value * 16;
  }
  return value;
};
const compareStatuses = <T extends string>(str1: T, str2: T) => {
  return str1.toLowerCase() === str2.toLowerCase();
};
const isValidHttpUrl = (str: string) => {
  let url;
  try {
    url = new URL(str);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
};

export {
  capitalizeFirstLetter,
  firstLetterUppercase,
  parseCssStringToPx,
  compareStatuses,
  isValidHttpUrl,
};
