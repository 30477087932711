<template>
  <div class="invoice-recipient">
    <h2 class="invoice-recipient__title">Fakturamottaker</h2>
    <p class="invoice-recipient__description">
      Faktura sendes til Really! via EHF. Merk faktura med referansekoden og
      beskriv hvilke kostnaden som blir fakturert. Dersom EHF ikke er
      tilgjengelig kan faktura sendes til oppgitt epost.
    </p>
    <div class="invoice-recipient__details">
      <div v-for="(detail, index) in details" :key="index" class="item">
        <span class="item__label">{{ detail.label }}:</span>
        <span class="item__value">{{ detail.value }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

// Define props using a single bookingNumber prop
interface Props {
  bookingNumber: string | number;
}
const props = defineProps<Props>();

// Prepare details array with computed reference code
const details = computed(() => [
  { label: "Fakturamottaker", value: "Really! Services AS" },
  { label: "Org. nr.", value: "925401919" },
  { label: "Epost", value: "invoice@really.no" },
  { label: "Referanse", value: `#${props.bookingNumber}` },
]);
</script>

<style scoped lang="scss">
.invoice-recipient {
  $background-control: $color-primary-pink;
  $padding-vertical: 24px;
  $padding-horizontal: 32px;
  $title-color: #000;
  $title-font-size: $font-size-base;
  $description-color: #000;
  $description-font-size: $font-size-sm;
  $description-margin: 16px;

  border-radius: 4px;
  background: $background-control;
  padding: $padding-vertical $padding-horizontal;
  margin-left: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);

  &__title {
    color: $title-color;
    font-size: $title-font-size;
    font-weight: 600;
    text-align: left;
  }

  &__description {
    color: $description-color;
    font-size: $description-font-size;
    margin: $description-margin 0;
    font-weight: 400;
    text-align: left;
    font-style: italic;
  }

  &__details {
    font-size: $description-font-size;
    width: 100%;
  }
}

.item {
  display: flex;
  justify-content: space-between;
  margin: 4px 0;
}

.item__label {
  font-weight: bold;
}

.item__value {
  margin-left: 8px;
}
</style>
