/**
 * Utility functions for working with cookies
 */

/**
 * Sets a cookie with the specified name, value, and expiration days
 * @param name - The name of the cookie
 * @param value - The value to store in the cookie
 * @param days - Days until the cookie expires (default: 1 days)
 */
export const setCookie = (name: string, value: string, days = 1): void => {
  try {
    const expires = new Date();
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${encodeURIComponent(
      value,
    )};expires=${expires.toUTCString()};path=/;SameSite=Lax`;
  } catch (e) {
    console.error("Error setting cookie:", e);
  }
};

/**
 * Gets a cookie value by name
 * @param name - The name of the cookie to retrieve
 * @returns The cookie value or null if not found
 */
export const getCookie = (name: string): string | null => {
  try {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      if (c) {
        while (c.charAt(0) === " ") {
          c = c.substring(1);
        }
        if (c.indexOf(nameEQ) === 0) {
          return decodeURIComponent(c.substring(nameEQ.length));
        }
      }
    }
  } catch (e) {
    console.error("Error reading cookie:", e);
  }
  return null;
};

/**
 * Checks if a cookie with the specified name exists
 * @param name - The name of the cookie to check
 * @returns True if the cookie exists, false otherwise
 */
export const hasCookie = (name: string): boolean => {
  return !!getCookie(name);
};

/**
 * Removes a cookie by setting its expiration to the past
 * @param name - The name of the cookie to remove
 */
export const removeCookie = (name: string): void => {
  setCookie(name, "", -1); // Set expiration to yesterday
};

/**
 * Creates a data caching system using cookies
 * @param keyPrefix - Prefix for all cache keys
 * @param expirationDays - Days until cache entries expire
 * @returns An object with methods for working with the cache
 */
export const createCookieCache = (keyPrefix: string, expirationDays = 1) => {
  return {
    /**
     * Generates a cache key with the specified prefix
     * @param id - Unique identifier for the cache entry
     * @returns The formatted cache key
     */
    getCacheKey: (id: string | number): string => `${keyPrefix}_${id}`,

    /**
     * Checks if data is cached for the specified ID
     * @param id - The cache entry identifier
     * @returns True if the data is cached, false otherwise
     */
    isDataCached: (id: string | number): boolean => {
      return hasCookie(`${keyPrefix}_${id}`);
    },

    /**
     * Gets cached data for the specified ID
     * @param id - The cache entry identifier
     * @returns The cached data or null if not found
     */
    getFromCache: <T>(id: string | number): T | null => {
      const cached = getCookie(`${keyPrefix}_${id}`);
      if (!cached) return null;

      try {
        return JSON.parse(cached) as T;
      } catch {
        return null;
      }
    },

    /**
     * Saves data to the cache
     * @param id - The cache entry identifier
     * @param data - The data to cache
     * @returns True if successful, false if caching failed
     */
    saveToCache: <T>(id: string | number, data: T): boolean => {
      try {
        const serialized = JSON.stringify(data);
        // Cookies have a 4KB size limit, check if data fits
        if (serialized.length < 3500) {
          setCookie(`${keyPrefix}_${id}`, serialized, expirationDays);
          return true;
        } else {
          console.warn(
            `Data too large for cookie storage (${serialized.length} bytes), caching disabled`,
          );
          return false;
        }
      } catch (e) {
        console.error(`Error caching data for ${id}:`, e);
        return false;
      }
    },

    /**
     * Removes an entry from the cache
     * @param id - The cache entry identifier to remove
     */
    removeFromCache: (id: string | number): void => {
      removeCookie(`${keyPrefix}_${id}`);
    },
  };
};
