/* eslint-disable @typescript-eslint/no-explicit-any */
import { SystemApi, SystemApiEndpoints } from "@/config/api/system/systemApi";
import { executeApiRequest } from "@/services/api/apiService";
import {
  Supplier,
  ServiceCategory,
  Certification,
  SustainabilityGoal,
} from "@/stores/supplier/types";
import { ServiceType } from "@/stores/supplier/types";
import { CreatePriceTemplateDTO } from "@/custom-types/GeneralTypes";
import cachingDecorator from "@/utilities/cachingDecorator";
import { useToastErrorHandler } from "@/composables/useToastErrorHandler";

const getEndpointFactory = (endpointKey: symbol) => ({
  endpointKey,
  config: SystemApi,
});

const cachedExecuteApiRequest = cachingDecorator(executeApiRequest);

const useSystemApiService = () => {
  // Move this inside the function so it's called when the function is used in a component
  const { withToastErrorHandling } = useToastErrorHandler();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const createSupplierTenderOffer = async (data: any) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<Supplier>(
          getEndpointFactory(
            Symbol.for(SystemApiEndpoints.CREATE_SUPPLIER_TENDER_OFFER),
          ),
          {
            data: data,
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Opprettelsesfeil",
          detail: "Kunne ikke opprette anbudstilbudet",
        },
        successOptions: {
          severity: "success",
          summary: "Opprettet",
          detail: "Anbudstilbudet er opprettet",
        },
      },
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateSupplierTenderOffer = async (data: any) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<Supplier>(
          getEndpointFactory(
            Symbol.for(SystemApiEndpoints.UPDATE_SUPPLIER_TENDER_OFFER),
          ),
          {
            data: data,
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Oppdateringsfeil",
          detail: "Kunne ikke oppdatere anbudstilbudet",
        },
        successOptions: {
          severity: "success",
          summary: "Oppdatert",
          detail: "Anbudstilbudet er oppdatert",
        },
      },
    );
  };

  const getActivityFormFrequencyOptions = async () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return withToastErrorHandling(
      async () => {
        return cachedExecuteApiRequest<any>(
          getEndpointFactory(
            Symbol.for(SystemApiEndpoints.GET_ACTIVITY_FORM_FREQUENCY_OPTIONS),
          ),
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Hentefeil",
          detail: "Kunne ikke laste frekvensalternativene",
        },
      },
    );
  };

  const getServiceTypes = async () => {
    return withToastErrorHandling(
      async () => {
        return cachedExecuteApiRequest<ServiceType[]>(
          getEndpointFactory(Symbol.for(SystemApiEndpoints.GET_SERVICE_TYPES)),
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Hentefeil",
          detail: "Kunne ikke laste tjenestetypene",
        },
      },
    );
  };

  const getServiceTypeCategories = async (serviceTypeId: number | string) => {
    return withToastErrorHandling(
      async () => {
        return cachedExecuteApiRequest<ServiceCategory[]>(
          getEndpointFactory(
            Symbol.for(SystemApiEndpoints.GET_SERVICE_TYPES_CATEGORIES),
          ),
          {
            pathParams: [serviceTypeId, "serviceCategories", "{lang=no}"],
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Hentefeil",
          detail: "Kunne ikke laste tjenestekategoriene",
        },
      },
    );
  };

  const getSystemPriceGroups = async (supplierId: number) => {
    return withToastErrorHandling(
      async () => {
        return cachedExecuteApiRequest<unknown>(
          getEndpointFactory(Symbol.for(SystemApiEndpoints.GET_PRICE_GROUPS)),
          {
            pathParams: [supplierId],
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Hentefeil",
          detail: "Kunne ikke laste prisgruppene",
        },
      },
    );
  };

  const getPriceTemplates = async (
    supplierId: number,
    priceType: string,
    queryParams: Record<string, unknown>,
  ) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<unknown>(
          getEndpointFactory(
            Symbol.for(SystemApiEndpoints.GET_PRICE_TEMPLATES),
          ),
          {
            pathParams: [
              "supplier",
              supplierId,
              "priceType",
              priceType,
              "priceTemplates",
            ],
            queryParams: queryParams,
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Hentefeil",
          detail: "Kunne ikke laste prismalene",
        },
      },
    );
  };

  const createPriceTemplate = async (
    supplierId: number,
    payload: CreatePriceTemplateDTO,
  ) => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<unknown>(
          getEndpointFactory(
            Symbol.for(SystemApiEndpoints.CREATE_PRICE_TEMPLATE),
          ),
          {
            pathParams: [supplierId, "AddPriceTemplateAction"],
            data: payload,
          },
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Opprettelsesfeil",
          detail: "Kunne ikke opprette prismønsteret",
        },
        successOptions: {
          severity: "success",
          summary: "Opprettet",
          detail: "Prismønsteret er lagret",
        },
      },
    );
  };

  const getCertifications = async () => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<Certification[]>(
          getEndpointFactory(Symbol.for(SystemApiEndpoints.GET_CERTIFICATIONS)),
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Hentefeil",
          detail: "Kunne ikke laste sertifiseringene",
        },
      },
    );
  };

  const getSustainabilityGoals = async () => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<SustainabilityGoal[]>(
          getEndpointFactory(
            Symbol.for(SystemApiEndpoints.GET_SUSTAINABILITY_GOALS),
          ),
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Hentefeil",
          detail: "Kunne ikke laste bærekraftsmålene",
        },
      },
    );
  };

  const getServiceCategories = async () => {
    return withToastErrorHandling(
      async () => {
        return executeApiRequest<ServiceCategory[]>(
          getEndpointFactory(
            Symbol.for(SystemApiEndpoints.GET_SERVICE_CATEGORIES),
          ),
        );
      },
      {
        errorOptions: {
          severity: "error",
          summary: "Hentefeil",
          detail: "Kunne ikke laste tjenestekategoriene",
        },
      },
    );
  };

  return {
    createSupplierTenderOffer,
    updateSupplierTenderOffer,
    getActivityFormFrequencyOptions,
    getServiceTypes,
    getServiceTypeCategories,
    getSystemPriceGroups,
    getPriceTemplates,
    createPriceTemplate,
    getCertifications,
    getSustainabilityGoals,
    getServiceCategories,
  };
};

export const useSystemApi = useSystemApiService;
