<template>
  <div class="sidebar-container">
    <div class="logo-container">
      <SupplierLogo />
    </div>
    <Menu
      ref="menuRef"
      :model="menuItems"
      class="sidebar"
      :pt="{
        root: { class: 'sidebar-menu' },
        list: { class: 'sidebar-menu__list' },
        item: { class: 'sidebar-menu__item' },
        itemContent: { class: 'sidebar-menu__item-content' },
        itemLink: { class: 'sidebar-menu__item-link' },
      }"
    >
      <template #item="{ item, props }">
        <router-link
          v-if="typeof item.to === 'object'"
          v-slot="{ href, navigate }"
          :to="item.to"
          custom
        >
          <a
            v-ripple
            :href="href"
            v-bind="props.action"
            class="menu-item"
            :class="{ 'menu-item--active': route.name === item.to.name }"
            @click="navigate"
          >
            <img :src="item.icon" alt="icon" class="menu-item__icon" />
            <span class="menu-item__label">{{ item.label }}</span>
          </a>
        </router-link>
        <a
          v-else
          v-ripple
          :href="item.to"
          target="_blank"
          rel="noopener noreferrer"
          v-bind="props.action"
          class="menu-item"
        >
          <img :src="item.icon" alt="icon" class="menu-item__icon" />
          <span class="menu-item__label">{{ item.label }}</span>
        </a>
      </template>
    </Menu>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref } from "vue";
import Menu from "primevue/menu";
import { useRoute } from "vue-router";
import { useUserStore } from "@/stores/user";
import { useCheckUserAccess } from "@/composables/useCheckUserAccess";
import { RouteNames } from "@/router/types";
import SupplierLogo from "./SupplierLogo.vue";

// Import icons
import MyRequestsIcon from "@/assets/img/my-requests.svg";
import MyAgreementsIcon from "@/assets/img/my-agreements.svg";
import SupplierProfileIcon from "@/assets/img/supplier-profile.svg";
import PriceRobotIcon from "@/assets/img/price-robot.svg";
import HelpIcon from "@/assets/img/help.svg";

const route = useRoute();
const userStore = useUserStore();
const { userIsReallyAdmin } = useCheckUserAccess();

const baseMenuItems = [
  {
    label: "Mine forespørsler",
    icon: MyRequestsIcon,
    to: { name: RouteNames.MY_REQUESTS },
  },
  {
    label: "Mine prosjekter",
    icon: MyAgreementsIcon,
    to: { name: RouteNames.MY_AGREEMENTS },
  },
  {
    label: "Leverandørprofil",
    icon: SupplierProfileIcon,
    to: { name: RouteNames.SUPPLIER_PROFILE },
  },
  {
    label: "Trenger du hjelp?",
    icon: HelpIcon,
    to: "https://hjelp.really.no/leverand%C3%B8r",
  },
];

const menuItems = computed(() => {
  const items = [...baseMenuItems];

  if (userStore.userAccount && userIsReallyAdmin(userStore.userAccount)) {
    items.splice(-1, 0, {
      label: "Mine registreringer",
      icon: PriceRobotIcon,
      to: { name: RouteNames.MY_REGISTRATIONS },
    });
  }

  return items;
});

const menuRef = ref<InstanceType<typeof Menu> | null>(null);
let lastScrollY = 0;

const handleScroll = () => {
  const menu = document.querySelector(".p-menu");
  if (!menu) return;

  const currentScrollY = window.scrollY;

  if (currentScrollY > lastScrollY) {
    // Scrolling down
    menu.classList.add("menu-scroll-down");
    menu.classList.remove("menu-scroll-up");
  } else {
    // Scrolling up
    menu.classList.add("menu-scroll-up");
    menu.classList.remove("menu-scroll-down");
  }

  lastScrollY = currentScrollY;
};

onMounted(() => {
  window.addEventListener("scroll", handleScroll, { passive: true });
});

onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
});
</script>

<style lang="scss" scoped>
.sidebar-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #f1e6f5;
  width: 14.5rem;
  border-right: 1px solid #f1e6f5;
}

.logo-container {
  width: 100%;
  border-bottom: 1px solid rgba(68, 27, 73, 0.1);
}

.sidebar {
  position: sticky;
  top: $headerTopBarHeight;
  height: calc(
    100vh - #{$headerTopBarHeight} - 100px
  ); /* Subtract logo height */
  background: #f1e6f5;
  width: 14.5rem;
  overflow-y: auto;
}

:deep(.sidebar-menu) {
  width: 100%;
  min-width: 14.5rem;
  height: 100%;
  background: transparent;
  border: none;
  border-radius: 0;
  padding: 0;
}

:deep(.sidebar-menu__list) {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
}

:deep(.sidebar-menu__item) {
  width: 100%;
  margin: 0;
  padding: 0;
}

:deep(.sidebar-menu__item-content) {
  border: none;
}

:deep(.sidebar-menu__item-link) {
  margin: 0;
  padding: 0;
  border-radius: 0;
  background: transparent;
}

.menu-item {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 1rem;
  color: inherit;
  text-decoration: none;
  transition: all 0.2s ease;
  background: transparent;
  width: 100%;

  &:hover {
    background: rgba(255, 255, 255, 0.3);
  }

  &:active {
    background: rgba(255, 255, 255, 0.5);
  }

  &--active {
    background: rgba(255, 255, 255, 0.2);
    border-left: 2px solid #441b49;
  }

  &__icon {
    width: 24px;
    height: 24px;
  }

  &__label {
    font-size: 0.875rem;
    font-weight: 500;
  }
}
</style>
