<template>
  <b-modal v-model="showModal" centered hide-footer hide-header>
    <div class="late-offer-modal">
      <div class="late-offer-modal__icon">
        <img :src="ClockIcon" alt="Late offer icon" />
      </div>
      <div class="late-offer-modal__content">
        <h5 class="late-offer-modal__title">Gi tilbud etter svarfrist</h5>
        <p class="late-offer-modal__description">
          Svarfristen for å gi tilbud er utløpt. Du kan likevel sende tilbud,
          men det vil bli merket som levert etter fristen.
        </p>
      </div>
      <div class="late-offer-modal__footer">
        <b-button variant="primary" @click="handleOkClick">Ok</b-button>
      </div>
    </div>
  </b-modal>
</template>

<script setup lang="ts">
import { ref } from "vue";
import ClockIcon from "@/assets/img/clock.svg";

const emit = defineEmits(["ok"]);

const showModal = ref(false);

const open = () => {
  showModal.value = true;
};

const handleOkClick = () => {
  showModal.value = false;
  emit("ok");
};

defineExpose({ open });
</script>

<style lang="scss" scoped>
.late-offer-modal {
  width: 100%;
  padding: 24px;
  background: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;

  &__icon {
    width: 40px;
    height: 40px;
    position: relative;
  }

  &__icon-inner {
    width: 32px;
    height: 32px;
    left: 4px;
    top: 4px;
    position: absolute;
    background: #1d1d1d;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
  }

  &__title {
    width: 366.27px;
    text-align: center;
    color: #1d1d1d;
    font-size: 18px;
    font-family: Montserrat, sans-serif;
    font-weight: 700;
    word-wrap: break-word;
  }

  &__description {
    width: 366.27px;
    text-align: center;
    color: #1d1d1d;
    font-size: 16px;
    font-family: Montserrat, sans-serif;
    font-weight: 400;
    word-wrap: break-word;
  }

  &__footer {
    display: inline-flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 14px;
  }

  :deep(.btn-primary) {
    padding: 12.5px 20px;
    background: #ecba4e;
    border-radius: 24px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #1d1d1d;
    font-size: 12px;
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    word-wrap: break-word;

    &:hover,
    &:focus,
    &:active {
      background: darken(#ecba4e, 10%);
    }
  }
}
</style>
