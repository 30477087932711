<template>
  <div class="agreement-details">
    <!-- Header -->
    <div class="agreement-header">
      <div class="agreement-header__left">
        <img
          :src="documentIcon"
          alt="Document icon"
          class="agreement-header__icon"
        />
        <div class="agreement-header__title">
          {{ agreementDetails.nickname }}
        </div>
      </div>
      <div class="agreement-header__right">
        <div class="agreement-header__price">
          {{ agreementDetails.supplierCost }}
        </div>
        <div class="agreement-header__subtitle">
          {{ agreementDetails.supplierCostSubtitle }}
        </div>
      </div>
    </div>

    <!-- Action Buttons -->
    <div class="agreement-actions">
      <button
        v-for="button in actionButtons"
        :key="button.id"
        class="agreement-actions__button"
        @click="button.action"
      >
        {{ button.label }}
      </button>
    </div>

    <!-- Info Grid -->
    <div class="agreement-info">
      <div class="agreement-info__item">
        <h3 class="agreement-info__title">TJENESTEPAKKE</h3>
        <p class="agreement-info__text">
          {{ agreementDetails.serviceCategoryTitle }}
        </p>
      </div>
      <div class="agreement-info__item">
        <h3 class="agreement-info__title">OPPSTARTSDATO</h3>
        <p class="agreement-info__text">
          {{ agreementDetails.suggestedStartDate }}
        </p>
      </div>
      <div class="agreement-info__item">
        <h3 class="agreement-info__title">
          UTSTEDT AV ({{ agreementDetails.signatureUsers[0]?.date }})
        </h3>
        <p class="agreement-info__text">
          {{ agreementDetails.signatureUsers[0]?.name }}<br />
          {{ agreementDetails.signatureUsers[0]?.company }}
        </p>
      </div>
      <div class="agreement-info__item">
        <h3 class="agreement-info__title">
          AKSEPT AV ({{ agreementDetails.signatureUsers[1]?.date }})
        </h3>
        <p class="agreement-info__text">
          {{ agreementDetails.signatureUsers[1]?.name }}<br />
          {{ agreementDetails.signatureUsers[1]?.company }}
        </p>
      </div>
    </div>

    <!-- Costs Table -->
    <table class="agreement-costs">
      <thead>
        <tr>
          <th colspan="2" class="agreement-info__title">
            FASTE KOSTNADER (EKSKL. MVA)
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in costSummaryItems"
          :key="index"
          class="agreement-costs__row"
        >
          <td class="agreement-costs__name">{{ item.name }}</td>
          <td class="agreement-costs__amount">{{ item.amount }} kr</td>
        </tr>
        <tr
          v-if="costSummaryItems.length > 0"
          class="agreement-costs__row agreement-costs__row--total"
        >
          <td class="agreement-costs__name">Gjennomsnittlig kostnad</td>
          <td class="agreement-costs__amount">
            {{ agreementDetails.averageCost }} kr
          </td>
        </tr>
        <tr v-else class="agreement-costs__row agreement-costs__row--empty">
          <td colspan="2" class="agreement-costs__empty">
            Ingen faste kostnader er tilgjengelige
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Attachments -->
    <div class="agreement-attachments">
      <h3 class="agreement-attachments__title">Vedlegg til avtale</h3>
      <template v-if="attachments?.length">
        <Attachments
          :attachments="attachments"
          :tender="tender"
          :supplier-id="supplierId"
        />
      </template>
      <p v-else class="agreement-attachments__empty">
        Ingen vedlegg er tilgjengelige for denne avtalen
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAgreementCosts } from "@/composables/useAgreementCosts";
import { BillingCycle } from "@/custom-types/GeneralTypes";
import type { ServiceOffer, Tender } from "@/stores/tender/types";
import type { Attachment } from "@/stores/tender/types";
import type { SignatureUsers } from "@/composables/useExtendedBillable";
import Attachments from "@/components/Attachments/Attachments.vue";
import documentIcon from "@/assets/img/document.svg";

interface ServiceActivityCms {
  title: string;
  label: string;
  [key: string]: unknown;
}

interface AgreementDetailProps {
  agreementDetails: {
    nickname: string;
    supplierCost: string;
    supplierCostSubtitle: string;
    serviceCategoryTitle: string;
    suggestedStartDate: string;
    signatureUsers: SignatureUsers[];
    acceptedServiceOffer: ServiceOffer | null;
    averageCost: string | null;
  };
  actionButtons: {
    id: number;
    label: string;
    action: () => void;
  }[];
  supplierId: number;
  tender: Tender;
  attachments?: Attachment[];
  serviceActivitiesCms: ServiceActivityCms[] | null;
}

const props = defineProps<AgreementDetailProps>();

const { costSummaryItems } = useAgreementCosts({
  mandatorySupplierCosts: (
    props.agreementDetails.acceptedServiceOffer?.mandatorySupplierCosts || []
  ).map((cost) => ({
    itemTotalCost: cost.itemTotalCost,
    billingCycle: cost.billingCycle as BillingCycle,
    frequencyLabel: cost.frequencyLabel,
    label: cost.label,
    frequencyInputValue: cost.frequencyInputValue ?? undefined,
    limitedPeriodStart: cost.limitedPeriodStart,
    limitedPeriodEnd: cost.limitedPeriodEnd,
    priceType: cost.priceType,
  })),
  serviceActivitiesCms: props.serviceActivitiesCms || [],
});
</script>

<style lang="scss" scoped>
.agreement-details {
  max-width: 800px;
  margin: 0 auto;
  background-color: #f7f0fa;
  border-radius: 6px;
  padding: 24px;
}

.agreement-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 8px;
  margin-bottom: 16px;
}

.agreement-header__title {
  font-size: 18px;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
}

.agreement-header__left {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  display: flex;
  align-items: center;
  gap: 8px;
}

.agreement-header__right {
  text-align: right;
}

.agreement-header__price {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 4px;
  color: #333;
}

.agreement-header__subtitle {
  font-size: 12px;
  color: #757575;
}

.agreement-actions {
  margin: 16px 0;
}

.agreement-actions__button {
  display: inline-block;
  background-color: transparent;
  color: black;
  border: 1px solid black;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  text-decoration: none;
  margin-right: 8px;
  cursor: pointer;
}

.agreement-actions__button:hover {
  background-color: black;
  color: #fff;
}

.agreement-info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin-top: 8px;
  margin-bottom: 24px;
}

.agreement-info__item {
  padding-top: 8px;
}

.agreement-info__title {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  border-bottom: 1px solid #bbb;
  padding-bottom: 4px;
  margin-bottom: 8px;
}

.agreement-info__text {
  font-size: 14px;
  color: #555;
  line-height: 1.4;
}

.agreement-costs {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 24px;

  &__row {
    border-bottom: 1px solid #eee;

    &--total {
      font-weight: 600;
      border-top: 1px solid #bbb;
    }

    &--empty {
      text-align: center;
      font-style: italic;
      color: #666;
    }
  }

  &__name {
    width: 70%;
    padding: 12px 0;
    font-size: 14px;
    color: #333;
  }

  &__amount {
    text-align: right;
    white-space: nowrap;
    padding: 12px 0;
    font-size: 14px;
    color: #333;
  }

  &__empty {
    padding: 24px 0;
    font-size: 14px;
  }
}

.agreement-attachments {
  padding-top: 16px;
}

.agreement-attachments__title {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  border-bottom: 1px solid #bbb;
  padding-bottom: 4px;
  margin-bottom: 8px;
}

.agreement-attachments__item {
  margin-bottom: 8px;
}

.agreement-attachments__link {
  display: inline-block;
  background-color: #e6f0ff;
  color: #0066cc;
  border: 1px solid #0066cc;
  border-radius: 4px;
  padding: 8px 12px;
  text-decoration: none;
  font-size: 14px;
}

.agreement-attachments__link:hover {
  background-color: #cce0ff;
}

.agreement-attachments__empty {
  color: #666;
  font-size: 14px;
  font-style: italic;
}
</style>
